import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { CodeBracketIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { MdFeedback } from "react-icons/md"
import { classNames } from '../../utils'
interface FeedbackPromptProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export function FeedbackPrompt({ isOpen, onClose, onConfirm }: FeedbackPromptProps) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-neutral-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white dark:bg-neutral-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                        <MdFeedback className="h-6 w-6 text-primary-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                          🔍 Spot a Misstep?
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500 dark:text-gray-300">
                            Help us refine our accuracy. If something doesn't look right, your feedback can fix it <strong>(and earn you tokens)!</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 dark:bg-neutral-800 px-4 py-3 sm:flex sm:flex-row sm:px-6">
                    <button
                      type="button"
                      className="w-full mr-4 justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium bg-primary-500 text-white  shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={onConfirm}
                    >
                      [ Give Feedback ]
                    </button>

                    <button
                      type="button"
                      className={classNames(
                        "w-full mr-4 justify-center rounded-md border border-transparent  px-4 py-2 text-base font-medium text-black dark:text-white  sm:ml-3 sm:w-auto sm:text-sm",
                        "focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2",
                        "hover:scale-105 duration-150"
                      )}
                      onClick={onClose}
                    >
                      [ Maybe Later ]
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
