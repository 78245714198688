// react
import { useEffect, useRef, useState } from 'react';
// libs
import { useNavigate } from "react-router-dom";
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { CheckBadgeIcon } from '@heroicons/react/24/solid'
import { FormHandles, SubmitHandler } from '@unform/core';
// components
import { Button, Input, LoginPattern } from '../components';
import Logo from "../assets/images/logo_name.svg"
import { useAuth } from '../hooks/useAuth';

type FormData = {
  email: string
}

export function RecoverPassword() {
  const { authUser } = useAuth()
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (authUser) {
      navigate("/dashboard");
    }
  }, [authUser])

  const handleSubmit: SubmitHandler<FormData> = async (formData, { reset }) => {
    setIsLoading(true)
    if (isLoading) return
    try {
      const schema = Yup.object().shape({
        email: Yup.string().email("Please provide a valid email address").required(),
      });
      await schema.validate(formData, {
        abortEarly: false,
      });
      // Validation passed
      console.log(formData);
      // await _signInWithEmailAndPassword(formData.email, formData.password)
      reset();

    } catch (err) {
      const validationErrors = {};
      formRef.current && formRef.current.setData({
        email: formData.email,
      });

      if (err instanceof Yup.ValidationError) {
        // Validation failed
        err.inner.forEach(error => {
          //@ts-ignore
          validationErrors[error.path] = error.message;
        });
        formRef.current && formRef.current.setErrors(validationErrors);
        console.log(err);
      }
    }
    setIsLoading(false)
  }

  const handleSignIn = () => {
    navigate("/login");
  }


  return (
    <div className="w-full h-screen bg-neutral-900 overflow-hidden" >

      <div
        className="relative h-screen w-full flex flex-col  space-y-8 shadow-2xl rounded-2xl md:flex-row md:space-y-0">

        <div className="relative w-full resize-none ">
          <div className="absolute -left-40 -top-20 resize-none z-10 min-w-[1200px] h-full opacity-40">
            <LoginPattern />
          </div>
          <img className="absolute left-6 top-6 w-72" src={Logo} alt="logo" />

          <div className="w-full relative h-full z-10 flex flex-col justify-center text-white p-8 px-12 " >
            <div className="bg-neutral-800/40 p-10 rounded-xl" >
              <h1 className="text-4xl font-extrabold " >
                {/* <Balance> */}
                Maximize Your Video Analysis with AI
                {/* </Balance> */}
              </h1>

              <div className="py-10 " >
                {/* <p className="text-neutral-400 mt-5">By signing up, you unlock:</p> */}

                <ul className="mt-3 " >
                  <li className="flex">
                    <CheckBadgeIcon className="w-6 h-6 text-primary-500 mr-2" />
                    <div>
                      <p className="font-extrabold text-2xl" >Fine-grained Detection </p>
                      <p className="text-neutral-400 text-lg" > Identify objects and actions with our cutting-edge AI models</p>
                    </div>
                  </li>

                  <li className="flex mt-2">
                    <CheckBadgeIcon className="w-6 h-6 text-primary-500 mr-2" />
                    <div>
                      <p className="font-extrabold text-2xl" >Detailed Analytics Report</p>
                      <p className="text-neutral-400 text-lg" > Get access to comprehensive analytics reports, available for download in a user-friendly format. </p>
                    </div>
                  </li>

                  <li className="flex mt-2">
                    <CheckBadgeIcon className="w-6 h-6 text-primary-500 mr-2" />
                    <div>
                      <p className="font-extrabold text-2xl" >Customizable Detection</p>
                      <p className="text-neutral-400 text-lg" >Customize your video analysis according to your needs. Focus only on what's crucial to you.</p>
                    </div>
                  </li>
                </ul>
              </div>

            </div>

          </div>
        </div>

        <div className="w-full relative z-20 flex items-center justify-center  min-h-[600px]" >
          <div
            className="max-w-lg min-h-[200px] w-full rounded-md bg-neutral-800/80 px-4 py-5 sm:p-8"
            style={{ boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)" }}
          >
            <h1 className="text-white font-bold text-2xl" >Recover Password</h1>

            <Form ref={formRef} className="w-full mt-4" onSubmit={handleSubmit}>
              <div className="text-left " >
                <label htmlFor="email_input" className="block mb-2 text-sm font-medium text-white">Your email</label>
                <Input id="email_input" name="email" type="email" placeholder="example@email.domain" className="bg-neutral-600 text-white outline-none" />

              </div>


              <Button disabled={isLoading} className="mt-8 w-full flex items-center justify-center" type="submit">Confirm</Button>

            </Form>
            <div className="mt-2 text-right w-full" >
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Already have an account? <span onClick={handleSignIn} className="cursor-pointer font-medium text-primary-600 hover:underline dark:text-primary-500">Sign in</span>
              </p>
            </div>

          </div>

        </div>


      </div>
    </div>
  );
}

