/**
 * Timeline Component.
 * 
 * This component is a visual representation of a video's timeline, It features a dynamic cursor and hover effects for 
 * better user interaction and provides functionality to select specific time points in the video.
 * Used on @VideoPlayer component
 * 
 * Features:
 * - Interactive Timeline: Displays video frames along a timeline with hover and drag interactions.
 * - Detection Pins: Shows detection data as colored pins along the timeline, renders @TimelinePin component.
 * - Dynamic Cursor: Indicates the current position in the video and updates in real-time during video playback.
 * - Selection Functionality: Allows users to select a specific time in the video and interact with detection data.
 */

// React
import React, { useState, useEffect, useRef, Fragment } from 'react';

// Components/Assets/Helpers
import { classNames, formatTime, getColorByClass } from '../utils';
import { TimelinePin } from './TimelinePin';
import { usePredictionsMenu } from '../hooks/usePredictionsMenu';
import { VideoPredictionsType } from '../types/PredictionType';
import { VideoControls } from './VideoControls';

interface TimelineProps {
  /** Duration of the video for the timeline. */
  videoDuration: number;

  /** Array of URLs for the extracted frames. */
  extractedFrames: string[];

  /** Function triggered when a time on the timeline is selected. */
  onTimeSelected?: (selectedTime: number) => void;

  /** Prediction data for the video. */
  predictions?: VideoPredictionsType;

  /** Current position on the timeline. */
  position: number;
}

interface DetectionData {
  position: number;
  items: {
    color: string
    detection: string;
    counting: number;
  }[]
}

const Timeline: React.FC<TimelineProps> = ({ extractedFrames, onTimeSelected, predictions, position }) => {
  const [detections, setDetections] = useState<DetectionData[]>([]);
  const [cursor, setCursor] = useState<{ position: number, time: string } | null>(null);
  const [hoverCursor, setHoverCursor] = useState<{ position: number, time: string } | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const { activeMenu } = usePredictionsMenu()
  const [selectedDetection, setSelectedDetection] = useState<number | null>(null)
  const [isPlaying,setIsPlaying] = useState(false)
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const video = document.getElementById("video") as HTMLVideoElement;
    setIsPlaying(!video.paused)
    if (!isDragging && !video.paused) {
      setCursor({ position, time: formatTime(video.currentTime) })
    }
  }, [position])

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setSelectedDetection(null)
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const extractDetections = (
    predictions: VideoPredictionsType,
    frameSkip: number
  ): DetectionData[] => {
    const detectionData: DetectionData[] = [];

    for (let index = 0; index < predictions.length; index++) {
      const frame = predictions[index];
      let frameDetections: DetectionData | undefined = undefined;

      // Create a Map object for each frame, to store class names and their counts
      const classCountsInFrame: Map<string, number> = new Map();

      // Count the number of each class in the frame
      frame.objects.forEach((object) => {
        const mainClass = object.classes[0];
        const className = mainClass.class;

        // Increment the count for the class
        const count = classCountsInFrame.get(className) || 0;
        classCountsInFrame.set(className, count + 1);
      });

      // Process each unique class in the frame
      classCountsInFrame.forEach((count, className) => {
        // Check if the current frame should be skipped for this class
        if (index % frameSkip !== 0) return;

        let position = (index / (predictions.length - 1)) * 100;

        if (!frameDetections) {
          frameDetections = {
            position,
            items: [],
          };
          detectionData.push(frameDetections);
        }

        frameDetections.items.push({
          color: getColorByClass(className),
          detection: className,
          counting: count,
        });
      });
    }

    return detectionData;
  };

  const playVideo = () => {
    const video = document.getElementById("video") as HTMLVideoElement;
    if(isPlaying) {
      video.pause()
      setIsPlaying(false)
    }else{
      video.play()
      setIsPlaying(true)
    }
  }

  const formatFilterMenu = () => {
    // 1. Use the reduce method on the menu array, with an initial value of an empty array.
    return activeMenu.reduce((acc, item) => {
      // 2. Filter the items in the current menu item, keeping only those that are active.
      const activeItems = item.items
        .filter((i) => i.active)
        .map((i) => i.name.toLocaleLowerCase());

      // 3. Combine the accumulated active item names (acc) with the active item names found in the current menu item.
      return [...acc, ...activeItems];
    }, [] as string[]); // Set the initial value of the accumulator (acc) as an empty array.
  };

  const handleSelectDetections = (position: number) => {
    if (position === selectedDetection) {
      return
    }
    setSelectedDetection(position)
  }


  const handleMouseHover = (e: React.MouseEvent<HTMLDivElement>) => {
    const timelineBar = e.currentTarget;
    const timelineBarRect = timelineBar.getBoundingClientRect();
    const newX = e.clientX - timelineBarRect.left;
    const video = document.getElementById("video") as HTMLVideoElement;
    const videoDuration = video.duration

    if (newX >= 0 && newX <= timelineBarRect.width) {
      // Calculate the selected time based on the cursor position (newX)
      const selectedTime = (newX / timelineBarRect.width) * videoDuration;

      // Update the state of hoverCursorPosition
      setHoverCursor({ position: newX / timelineBarRect.width * 100, time: formatTime(selectedTime) });
    }
  };

  const handleTimelineMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const timelineBar = e.currentTarget;
    const timelineBarRect = timelineBar.getBoundingClientRect();
    const newX = e.clientX - timelineBarRect.left;

    const video = document.getElementById("video") as HTMLVideoElement;
    const videoDuration = video.duration
    const selectedTime = (newX / timelineBarRect.width) * videoDuration;
    video.pause();
    setIsDragging(true);

    if (newX >= 0 && newX <= timelineBarRect.width) {
      const cursor = document.getElementById("timeline-cursor");
      if (cursor) {
        cursor.style.left = `${newX}px`;

        // Update the video's current time
        if (video) {
          video.currentTime = selectedTime;
        }

        // Call the onTimeSelected function to pass the selected time to the parent component
        // onTimeSelected(selectedTime);

        // Update the state of cursor
        setCursor({ position: newX / timelineBarRect.width * 100, time: formatTime(selectedTime) });
      }
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return;

    const timelineBar = e.currentTarget;
    const timelineBarRect = timelineBar.getBoundingClientRect();
    const newX = e.clientX - timelineBarRect.left;

    const video = document.getElementById("video") as HTMLVideoElement;
    const videoDuration = video.duration

    if (newX >= 0 && newX <= timelineBarRect.width) {
      const cursor = document.getElementById("timeline-cursor");
      if (cursor) {
        cursor.style.left = `${newX}px`;

        // Calculate the selected time based on the cursor position (newX)
        const selectedTime = (newX / timelineBarRect.width) * videoDuration;
        // Update the video's current time
        const video = document.getElementById("video") as HTMLVideoElement;
        if (video) {
          video.currentTime = selectedTime;
        }

        // Call the onTimeSelected function to pass the selected time to the parent component
        // onTimeSelected(selectedTime);

        // Update the state of cursor
        setCursor({ position: newX / timelineBarRect.width * 100, time: formatTime(selectedTime) });
      }
    }
  };


  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (predictions) {
      const _detections = extractDetections(predictions, Math.floor((predictions.length * 0.1)));
      setDetections(_detections);
    } else {
      setDetections([])
    }
  }, [predictions]);

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);


  return (
    <div ref={ref} className="timeline-container w-full relative select-none group z-50">
      <VideoControls 
      time={cursor?.time}
      percentage={cursor?.position} 
      isPlaying={isPlaying} 
      onPlay={playVideo} 
      onMouseDown={handleTimelineMouseDown}
      onMouseMove={(e) => {
        handleMouseMove(e)
        handleMouseHover(e)
      }}
      onMouseUp={handleMouseUp}
      onMouseLeave={() => setHoverCursor(null)}
      />
      <div className="timeline-bar flex items-center w-[calc(100%-32px-64px)] justify-between relative ml-8 mt-2 md:h-12 sm:h-10 "
        onMouseDown={handleTimelineMouseDown}
        onMouseMove={(e) => {
          handleMouseMove(e)
          handleMouseHover(e)
        }}
        onMouseUp={handleMouseUp}
        onMouseLeave={() => setHoverCursor(null)}
      >
        <div
          className="flex items-center w-full justify-between relative md:h-12 sm:h-10 xs:h-8 overflow-x-hidden "
        >
          {extractedFrames.map((frame, index, arr) => {
            const position = ((index) / (arr.length - 1)) * 100;
            return (
              <img
                key={`timeline_frame_${index}`}
                src={frame}
                className="frame-thumbnail absolute md:h-12 sm:h-10 xs:h-8 opacity-90"
                style={{ left: `${position}%`, transform: 'translateX(-50%)' }}
                draggable={false}
              />
            );
          })}

        </div>

        <div
          id="timeline-hover-cursor"
          className="timeline-cursor absolute h-14 w-[1px] bg-neutral-200 z-10 select-none cursor-pointer opacity-0 group-hover:opacity-100"
          style={{ left: `${hoverCursor?.position}%` }}
        ></div>

        <div
          id="timeline-hover-cursor-time"
          className="rounded-full text-xs absolute p-1 dark:bg-neutral-800 z-20 select-none cursor-pointer opacity-0 group-hover:opacity-100"
          style={{
            left: `${hoverCursor?.position}%`,
            top: -96,
            transform: "translateX(-50%) translateY(100%)"
          }}
        >
          {hoverCursor?.time}
        </div>

        <div
          id="timeline-cursor"
          className="timeline-cursor absolute h-14 w-[2px] bg-primary-400 z-10 select-none cursor-pointer"
          style={{ left: `${cursor?.position}%` }}
        ></div>
        {/* <div
          id="timeline-cursor-time"
          className="rounded-full text-xs text-primary-500 font-bold absolute p-1 z-10 select-none cursor-pointer"
          style={{
            right: 0,
            bottom: -10,
            transform: "translateY(100%)"
          }}
        >
          {cursor?.time}
        </div> */}

        {detections.map((detectionData, index) => (
          <Fragment key={`timeline_${index}`}>
            <div
              className={
                classNames(
                  ` w-[4px] h-[15px] cursor-pointer border border-neutral-300`,
                )}
              style={{
                position: "absolute",
                left: `${detectionData.position}%`,
                bottom: -12,
                backgroundColor: detectionData.items[0].color,
                transform: "translateX(-50%) translateY(-50%)"
              }}
            />

            <div
              className={
                classNames(
                  "opacity-0  cursor-pointer z-30 hover:z-40",
                  selectedDetection === null ? "group-hover:opacity-100" : selectedDetection !== detectionData.position ? "group-hover:opacity-100" : "",
                  selectedDetection === detectionData.position && "opacity-100",

                )}
              onClick={() => {
                // handleSelectDetections(detectionData.position)
              }}
              style={{
                left: `${detectionData.position}%`,
                bottom: selectedDetection === detectionData.position ? detectionData.items.length * (-40) : -30,
                position: "absolute",
                transform: "translateX(-50%)"
              }} >
              <TimelinePin
                key={index}
                detections={detectionData.items}
                isExpanded={selectedDetection === detectionData.position}
              />
            </div>


       
          </Fragment>

        ))}
        
      </div>

    </div>

  );
};

export default Timeline;
