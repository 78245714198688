import { minMax, dragBoundFunc } from './utils';
import { Line, Circle, Group } from 'react-konva';
import React, { useState, useEffect } from 'react';
import { useRegionConfiguration } from '../../../hooks/useRegionConfiguration';

// define this component's props types
type propsType = {
    delimitedAreaId: number;
    delimitedArea: any;
    changeDelimitedAreas: any;
    stageDimensions: any;
    // mousePosition: any;
    mouseClicks: number;
    ogImageDimensions: any; //
    imageDrawDimensions: any; //
    hoveredInstance: boolean
    onHover: (instance: number) => void
    mousePositionRef: any
}


const DelimitedAreaEditorInstance = ({
    delimitedAreaId, delimitedArea, changeDelimitedAreas,
    stageDimensions, mouseClicks,
    ogImageDimensions, imageDrawDimensions,
    mousePositionRef, onHover
}: propsType) => {
    // State for holding polygon points and related configurations
    const [points, setPoints] = useState<any[]>([]);
    const [flattenedPoints, setFlattenedPoints] = useState();
    const [isPolyComplete, setPolyComplete] = useState(false);
    // console.log("DelimitedAreaEditorInstance", mousePositionRef)

    // Boundary state for the polygon
    const [minMaxX, setMinMaxX] = useState([0, 0]);
    const [minMaxY, setMinMaxY] = useState([0, 0]);

    // Active state to manage drawing behavior
    const [activeDelimitedAreaId, setActiveDelimitedAreaId] = useState(-1);
    const [canDraw, setCanDraw] = useState(false);

    // Mouse over state to handle interactivity
    const [isMouseOverStartPoint, setMouseOverStartPoint] = useState(false);
    const [isMouseOverPoly, setIsMouseOverPoly] = useState(false);

    // Highlight state for visual feedback
    const [highlightPoly, setHighlightPoly] = useState(true);

    // Initial setup state to handle initial data loading
    const [initialSetup, setInitialSetup] = useState(true);

    // Constants
    const vertexRadius = highlightPoly ? 5 : 1;

    // Custom hook to get area configuration
    const { areasType } = useRegionConfiguration()

    // Event listener to keep track of which area is actively being edited
    useEffect(() => {
        function activeDelimitedAreaIdDrawChange() {
            setActiveDelimitedAreaId(parseInt(localStorage.getItem("activeDelimitedAreaIdDraw") as string));
        }
        window.addEventListener('activeDelimitedAreaIdDrawChange', activeDelimitedAreaIdDrawChange);

        // Cleanup event listener when component unmounts
        return (() => {
            window.removeEventListener('activeDelimitedAreaIdDrawChange', activeDelimitedAreaIdDrawChange);
        })
    }, []);

    // Initialization: Setup the starting point for the polygon from provided data
    useEffect(() => {
        if (initialSetup) {
            startingPoint();
            setInitialSetup(false);
        }
    }, [delimitedArea, initialSetup]);

    const startingPoint = () => {
        if (delimitedArea["POINTS"].length === 0) return;

        let startingPoints = [];
        for (let i = 0; i < delimitedArea["POINTS"].length; i++) {
            startingPoints.push([
                parseFloat(((delimitedArea["POINTS"][i][0] / ogImageDimensions["width"]) * imageDrawDimensions["width"]).toFixed(2)),
                parseFloat(((delimitedArea["POINTS"][i][1] / ogImageDimensions["height"]) * imageDrawDimensions["height"]).toFixed(2))
            ]);
        }
        setPoints(startingPoints);
        polygonClosingProcedure();
    }

    // Whenever there's a change in the points, flatten them and update the associated delimited area's data
    useEffect(() => {
        if (points.length === 0) return;

        setFlattenedPoints(
            points.concat(isPolyComplete ? [] : mousePositionRef.current).reduce((a, b) => a.concat(b), [])
        );


        // Adjust point values to respect original image dimensions
        let finalPoints = points.map((item: any) => item.map((subItem: number) => parseFloat(subItem.toFixed(1))));
        for (let i = 0; i < finalPoints.length; i++) {
            finalPoints[i] = [
                parseFloat(((finalPoints[i][0] / imageDrawDimensions["width"]) * ogImageDimensions["width"]).toFixed(2)),
                parseFloat(((finalPoints[i][1] / imageDrawDimensions["height"]) * ogImageDimensions["height"]).toFixed(2))
            ];
        }
        // Update the delimited area's details in the parent state
        changeDelimitedAreas(
            "update",
            delimitedAreaId,
            {
                "ID": delimitedAreaId,
                "EVENT_TYPE": delimitedArea["EVENT_TYPE"],
                "TARGET_CLASS_NAME": delimitedArea["TARGET_CLASS_NAME"],
                "POINTS": finalPoints,
                "COLOUR": delimitedArea["COLOUR"]
            }
        );

    }, [points,]);

    useEffect(() => {
        if (isPolyComplete) adjustAndChangeDelimitedAreas()

    }, [isPolyComplete])

    // This function adjusts the point values to respect original image dimensions
    const adjustAndChangeDelimitedAreas = () => {
        console.log("adjustAndChangeDelimitedAreas")
        let finalPoints = points.map((item: any) => item.map((subItem: number) => parseFloat(subItem.toFixed(1))));
        for (let i = 0; i < finalPoints.length; i++) {
            finalPoints[i] = [
                parseFloat(((finalPoints[i][0] / imageDrawDimensions["width"]) * ogImageDimensions["width"]).toFixed(2)),
                parseFloat(((finalPoints[i][1] / imageDrawDimensions["height"]) * ogImageDimensions["height"]).toFixed(2))
            ];
        }
        // Update the delimited area's details in the parent state
        changeDelimitedAreas(
            "update",
            delimitedAreaId,
            {
                "ID": delimitedAreaId,
                "EVENT_TYPE": delimitedArea["EVENT_TYPE"],
                "TARGET_CLASS_NAME": delimitedArea["TARGET_CLASS_NAME"],
                "POINTS": finalPoints,
                "COLOUR": delimitedArea["COLOUR"]
            }
        );
    }


    // Utility function to convert RGB array to string
    const listToString = (list: any) => {
        return ("rgba(" + list.join(",") + ")");
    }

    // Handle the active area for drawing
    useEffect(() => {
        if (activeDelimitedAreaId === delimitedAreaId) setCanDraw(true);
        else setCanDraw(false);
    }, [activeDelimitedAreaId]);

    // Handle mouse clicks for drawing points and possibly closing the polygon
    useEffect(() => {
        if (mousePositionRef.current[0] === null || !canDraw || isPolyComplete) return;

        if (isMouseOverStartPoint && points.length >= 3) {
            polygonClosingProcedure();
        } else {
            setPoints([...points, mousePositionRef.current]);
        }
    }, [mouseClicks]);

    // Highlighting behavior when hovering over the start point
    useEffect(() => {
        if (isMouseOverStartPoint) setIsMouseOverPoly(true);
        else setIsMouseOverPoly(false);
    }, [isMouseOverStartPoint]);

    // Handle polygon highlighting based on several conditions
    useEffect(() => {
        if (isPolyComplete && !isMouseOverPoly && activeDelimitedAreaId !== delimitedAreaId) {
            setHighlightPoly(false);
            // onHover(-1)
        } else if (isMouseOverPoly || !isPolyComplete || (activeDelimitedAreaId === delimitedAreaId && !isMouseOverPoly)) {
            setHighlightPoly(true);
            // onHover(delimitedAreaId)
            localStorage.setItem("activeDelimitedAreaIdDraw", delimitedAreaId + "");
            window.dispatchEvent(new Event("activeDelimitedAreaIdDrawChange"));
        } else {
            setHighlightPoly(false);
            // onHover(-1)
            localStorage.setItem("activeDelimitedAreaIdDraw", "-1");
            window.dispatchEvent(new Event("activeDelimitedAreaIdDrawChange"));
        }
    }, [isPolyComplete, isMouseOverPoly, activeDelimitedAreaId, delimitedAreaId]);

    // Procedure to mark the polygon as complete
    function polygonClosingProcedure() {
        console.log("polygonClosingProcedure")
        setPolyComplete(true);
        setActiveDelimitedAreaId(-1);
        localStorage.setItem("activeDelimitedAreaIdDraw", "-1");
        window.dispatchEvent(new Event("activeDelimitedAreaIdDrawChange"));
        // adjustAndChangeDelimitedAreas()
    }

    // Mouse event handlers for the start point
    const handleMouseOverStartPoint = (e: any) => { setMouseOverStartPoint(true) }
    const handleMouseOutStartPoint = (e: any) => { setMouseOverStartPoint(false); }

    // Handle movement of individual points within the polygon
    const handlePointDragMove = (e: any) => {

        const index = e.target.index - 1;
        const pos = [e.target._lastPos.x, e.target._lastPos.y];
        if (pos[0] < 0) pos[0] = 0;
        if (pos[1] < 0) pos[1] = 0;
        if (pos[0] > stageDimensions["width"]) pos[0] = stageDimensions["width"];
        if (pos[1] > stageDimensions["height"]) pos[1] = stageDimensions["height"];
        setPoints([...points.slice(0, index), pos, ...points.slice(index + 1)]);

    }

    // Handle end of the drag action for the entire polygon group
    const handleGroupDragEnd = (e: any) => {
        console.log("handleGroupDragEnd")

        if (e.target.name() === 'delimitedArea') {
            let result: any = [];
            let copyPoints = [...points];
            copyPoints.map((point) => result.push([point[0] + e.target.x(), point[1] + e.target.y()]));
            e.target.position({ x: 0, y: 0 });
            setPoints(result);
        }
        adjustAndChangeDelimitedAreas()
    }

    // Handle mouseover and mouseout for the entire polygon
    const handleGroupMouseOver = (e: any) => {
        console.log("handleGroupMouseOver", delimitedAreaId)
        onHover(delimitedAreaId)

        if (!isPolyComplete) return;
        setIsMouseOverPoly(true);
        setActiveDelimitedAreaId(delimitedAreaId);
        localStorage.setItem("activeDelimitedAreaIdDraw", delimitedAreaId + "");
        window.dispatchEvent(new Event("activeDelimitedAreaIdDrawChange"));
        e.target.getStage().container().style.cursor = 'move';
    }

    const handleGroupMouseOut = (e: any) => {
        console.log("handleGroupMouseOut")

        if (!isPolyComplete) return;
        setIsMouseOverPoly(false);
        setActiveDelimitedAreaId(-1);
        localStorage.setItem("activeDelimitedAreaIdDraw", "-1");
        window.dispatchEvent(new Event("activeDelimitedAreaIdDrawChange"));
        e.target.getStage().container().style.cursor = 'default';
    }

    // Set boundary state for dragging the entire polygon group
    const handleGroupDragStart = (e: any) => {
        console.log("handleGroupDragStart")

        let arrX = points.map((p: any) => p[0]);
        let arrY = points.map((p: any) => p[1]);
        setMinMaxX(minMax(arrX));
        setMinMaxY(minMax(arrY));
    }

    // Boundary function to keep dragging within the stage
    const groupDragBound = (pos: any) => {

        let { x, y } = pos;
        const sw = stageDimensions["width"];
        const sh = stageDimensions["height"];
        if (minMaxY[0] + y < 0) y = -1 * minMaxY[0];
        if (minMaxX[0] + x < 0) x = -1 * minMaxX[0];
        if (minMaxY[1] + y > sh) y = sh - minMaxY[1];
        if (minMaxX[1] + x > sw) x = sw - minMaxX[1];
        return { x, y };
    }

    // Handle end of the drag action for an individual point
    const handlePointDragEnd = (e: any) => {
        // Adjust the point's position after dragging ends
        console.log("handlePointDragEnd")

        const index = e.target.index - 1;
        const pos = [e.target._lastPos.x, e.target._lastPos.y];
        if (pos[0] < 0) pos[0] = 0;
        if (pos[1] < 0) pos[1] = 0;
        if (pos[0] > stageDimensions["width"]) pos[0] = stageDimensions["width"];
        if (pos[1] > stageDimensions["height"]) pos[1] = stageDimensions["height"];
        setPoints([...points.slice(0, index), pos, ...points.slice(index + 1)]);

        // Update the delimited area's details once dragging ends
        adjustAndChangeDelimitedAreas();
    }

    return (
        <Group
            name="delimitedArea"
            draggable={isPolyComplete}
            onDragStart={handleGroupDragStart}
            onDragEnd={handleGroupDragEnd}
            dragBoundFunc={groupDragBound}
            onMouseOver={handleGroupMouseOver}
            onMouseOut={handleGroupMouseOut}
            opacity={highlightPoly ? 1.0 : 0.5}
            onMouseEnter={(e: any) => {
                const container = e.target.getStage().container();
                container.style.cursor = "grab";
            }}>
            <Line
                points={flattenedPoints}
                stroke={listToString(delimitedArea["COLOUR"].slice(0, delimitedArea["COLOUR"].length - 1).concat([0.7]))}
                strokeWidth={3}
                closed={isPolyComplete}
                fill={listToString(delimitedArea["COLOUR"].slice(0, delimitedArea["COLOUR"].length - 1).concat([0.25]))} />
            {points.map((point: any, index: any) => {
                const x = point[0] - vertexRadius / 2
                const y = point[1] - vertexRadius / 2
                const startPointAttr =
                    index === 0
                        ? {
                            scale: !isMouseOverStartPoint ? { x: 1.25, y: 1.25 } : { x: 1.50, y: 1.50 },
                            onMouseOver: handleMouseOverStartPoint,
                            onMouseOut: handleMouseOutStartPoint,
                        }
                        : { scale: { x: 1.0, y: 1.0 } }
                return (
                    <Circle
                        key={index}
                        x={x}
                        y={y}
                        radius={vertexRadius}
                        fill={listToString(delimitedArea["COLOUR"])}
                        stroke={"black"}
                        strokeWidth={index === 0 ? 2.0 : 1}
                        draggable
                        onMouseOver={() => setIsMouseOverPoly(true)}
                        onMouseOut={() => setIsMouseOverPoly(false)}
                        onDragMove={handlePointDragMove}
                        onDragEnd={handlePointDragEnd}
                        dragBoundFunc={(pos: any) => dragBoundFunc(stageDimensions["width"], stageDimensions["height"], vertexRadius, pos)}
                        {...startPointAttr}
                    />
                )
            })}
        </Group>
    )
}

export default React.memo(DelimitedAreaEditorInstance);