// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase, } from "firebase/database";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCbveRi9AlRythdU9TsoH1oEDcwEVmna2Q",
  authDomain: "playtool-d8f5a.firebaseapp.com",
  databaseURL: "https://playtool-d8f5a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "playtool-d8f5a",
  storageBucket: "playtool-d8f5a.appspot.com",
  messagingSenderId: "69372957750",
  appId: "1:69372957750:web:55640a7c57eb3625247497",
  measurementId: "G-9Q4PFD2CY9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const database = getDatabase(app);
export const auth = getAuth(app);
export const storage = getStorage(app);