import { Fragment, ReactNode, useState } from 'react';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { KeyIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { usePredictions } from '../../hooks/usePredictions';
import { useAuth } from '../../hooks/useAuth';

interface AddTokenModalProps {
  isOpen: boolean
  tokenId?: string | null
  onClose: () => void
  onConfirm: (name: string, token: string) => void
}

export function AddTokenModal({ isOpen, onClose, onConfirm, tokenId }: AddTokenModalProps) {
  const [tokenName, setTokenName] = useState("")
  const [tokenValue, setTokenValue] = useState("")
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { uploadTokenToFirebase, updateTokenName } = usePredictions()
  const { authUser } = useAuth()

  const closeModal = () => {
    onClose()
  }

  const handleSaveToken = async () => {
    // handle save token logic
    setErrorMessage(null)
    if (!authUser) return
    try {
      if (tokenId) {
        await updateTokenName(authUser.uid, tokenId, tokenName)
      } else {
        await uploadTokenToFirebase(authUser?.uid, tokenValue, tokenName)
      }
      onConfirm(tokenName, tokenValue)
    } catch (err: any) {
      console.log(err)
      setErrorMessage(err)
    }

  }


  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-neutral-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg text-neutral-900 dark:text-white ">
                  <div className="bg-white dark:bg-neutral-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                        <KeyIcon className="h-6 w-6 text-primary-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full pr-14">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                          Token
                        </Dialog.Title>
                        <div className="mt-2 w-full">
                          <label htmlFor="nameInput" className="" >{tokenId ? "New name" : "Token name"}</label>
                          <input id="nameInput" className='dark:bg-neutral-700 bg-[#eef0f3]  p-2 rounded-lg w-full mb-8' type="text" value={tokenName} onChange={(e) => setTokenName(e.target.value)} />
                          {tokenId ? (
                            <div className='dark:bg-neutral-800 bg-[#eef0f3]  p-2 rounded-lg w-full mb-8' >
                              {tokenId}
                            </div>
                          ) : (

                            <>
                              <label htmlFor="tokenInput" className="" >Token value</label>
                              <input id="tokenInput" className='dark:bg-neutral-700 bg-[#eef0f3]  p-2 rounded-lg w-full' type="text" value={tokenValue} onChange={(e) => setTokenValue(e.target.value)} />
                              {errorMessage && (
                                <p className="text-red-600 text-xs mt-1 pr-2 text-right" >
                                  {errorMessage}
                                </p>
                              )}
                            </>
                          )}

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 dark:bg-neutral-800 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-gradient-to-r from-cyan-500 to-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleSaveToken}
                    >
                      {tokenId ? "Update": "Confirm"}
                    </button>

                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div >
        </Dialog >
      </Transition >
    </>
  );
}
