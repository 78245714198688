/**
 * Admin Page Component
 *
 * Summary:
 * This component is the Admin page for managing user feedback on video frame analysis. 
 * It features a tabbed interface for reviewing and updating the status of feedback submissions.
 *
 * Key Aspects:
 * - Dynamic Tabs: 'Not Reviewed' and 'Reviewed' tabs for sorting feedback.
 * - Feedback Management: Review and update feedback status with modal confirmations.
 * - Data Integration: Fetches and displays feedback data using custom hooks (useFeedback, useAuth).
 * - UI Components: Utilizes Material Tailwind components for a responsive and interactive UI.
 *
 * Note: This page is integral for administrators to oversee user submissions and maintain the quality of feedback data.
 */

// react
import { useEffect, useState } from 'react';
// libs
import { useNavigate } from 'react-router-dom';
import { Tooltip, Typography } from '@material-tailwind/react';
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
// components
import { Navbar } from '../components';
import { useAuth } from '../hooks/useAuth';
import { classNames } from '../utils';
import { CompareFeedback } from '../components/Modals';
import { useFeedback } from '../hooks/useFeedback';


interface AdminProps {
}

export function Admin({ }: AdminProps) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true)

  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
  const [tokenOnEdit, setTokenOnEdit] = useState<null | string>(null)

  const [reviewedFeedbackList, setReviewedFeedbackList] = useState<any>([]);
  const [notReviewedFeedbackList, setNotReviewedFeedbackList] = useState<any>([]);


  const { fetchAllFeedbacks } = useFeedback()
  const { authUser, loadingUser } = useAuth()

  const [selectedFeedback, setSelectedFeedback] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState(0)

  const handleCloseModal = () => {
    setTokenOnEdit(null)
    setIsTokenModalOpen(false)
  }

  const handleConfirmReviewFeedback = (update: boolean) => {
    if (!update || !selectedFeedback) {
      setSelectedFeedback(null);
      return;
    }

    // Determine whether the selected feedback is currently reviewed or not
    const isCurrentlyReviewed = selectedFeedback.reviewed;

    // Update the review status of the selected feedback
    const updatedFeedback = { ...selectedFeedback, reviewed: !isCurrentlyReviewed };

    if (isCurrentlyReviewed) {
      // If the feedback was reviewed, remove it from the reviewed list and add it to the not reviewed list
      setReviewedFeedbackList((currentList: any) =>
        currentList.filter((feedback: any) => feedback.id !== selectedFeedback.id)
      );
      setNotReviewedFeedbackList((currentList: any) => [updatedFeedback, ...currentList]);
    } else {
      // If the feedback was not reviewed, remove it from the not reviewed list and add it to the reviewed list
      setNotReviewedFeedbackList((currentList: any) =>
        currentList.filter((feedback: any) => feedback.id !== selectedFeedback.id)
      );
      setReviewedFeedbackList((currentList: any) => [updatedFeedback, ...currentList]);
    }

    setSelectedFeedback(null);
  };


  const loadFeedbacks = async () => {
    if (authUser) {
      const fbs = await fetchAllFeedbacks();
      if (fbs) {
        const reviewedFeedbacks = fbs.filter(feedback => feedback.reviewed);
        const notReviewedFeedbacks = fbs.filter(feedback => !feedback.reviewed);

        setReviewedFeedbackList(reviewedFeedbacks);
        setNotReviewedFeedbackList(notReviewedFeedbacks);
      }
    }
  }


  useEffect(() => {
    (async () => {
      if (!loadingUser) {
        if (authUser?.isAdmin) {
          setIsLoading(false)
          await loadFeedbacks()
        } else {
          navigate("/", { replace: true });
        }
      }
    })()
  }, [authUser, loadingUser])

  return (
    <>
      <div className="w-full min-h-[calc(100vh)] bg-[#eef0f3] dark:bg-neutral-900 text-neutral-900 dark:text-white" >
        {selectedFeedback && (
          <CompareFeedback isOpen={selectedFeedback ? true : false} feedback={selectedFeedback} onConfirm={(reload) => handleConfirmReviewFeedback(reload)} onCancel={() => setSelectedFeedback(null)} />
        )}
        <Navbar>
          <></>
        </Navbar>

        <div className="flex w-full h-[calc(100vh-80px)] overflow-x-scroll mt-2" >
          {isLoading ? (
            <div className="relative flex h-full w-full" >
              <div className="absolute right-0 top-0 bottom-0 left-0  flex items-center justify-center" >
                <div className="flex flex-col items-center justify-center z-50" >
                  <div className="loading-screen w-full flex items-center justify-center flex-col">
                    <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
           
                    <span className="text-white">Loading feedbacks...</span>

                  </div>
                  <div className="relative h-4 py-1 w-[150%] rounded mt-2 mb-1  overflow-x-hidden" >

                  </div>
                </div>

              </div>
            </div>
          ) : (
            <div className="relative h-full w-full space-y-8 rounded-2xl md:space-y-0 py-10 px-20">
              <div className="w-full  bg-[#eef0f3] dark:bg-neutral-900 text-neutral-900 dark:text-white rounded-xl p-10 py-12" >

                <Tabs value={0}>
                  <TabsHeader
                    className="bg-transparent w-96"
                    indicatorProps={{
                      className: "bg-primary-500  !text-gray-900",
                    }}
                  >
                    <Tab className="text-white" key={0} value={0}>
                      Not Reviewed
                    </Tab>
                    <Tab className="text-white" key={1} value={1}>
                      Reviewed
                    </Tab>
                  </TabsHeader>
                  <TabsBody >
                    <TabPanel key={0} value={0}>
                      {notReviewedFeedbackList.map((fb: any) => (
                        <div className="w-full dark:bg-neutral-800 p-6 mt-8 rounded-xl text-white" >

                          <div className="w-full flex items-center justify-between" >

                            <div className="flex w-full justify-center" >
                              <div onClick={() => setSelectedFeedback(fb)} className="w-[400px] h-[225px] bg-neutral-600 rounded hover:bg-neutral-700 cursor-pointer" >
                                <img className="w-full" src={fb.fileUrl} alt="temp" />
                              </div>
                            </div>

                            <div className="w-full flex flex-col justify-center gap-2" >
                              <div className="flex w-full items-center gap-4" >
                                <p className="font-bold " >Date:</p>
                                <p>11/11/2023</p>
                              </div>

                              {fb.noChange ? (
                                <></>
                              ) : (
                                <>
                                </>
                              )}


                              <Tooltip
                                placement="right"
                                animate={{
                                  mount: { scale: 1, y: 0 },
                                  unmount: { scale: 0, y: 25 },
                                }}
                                conte
                                content={
                                  <div className="">
                                    <Typography
                                      variant="small"
                                      color="white"
                                      className="font-normal opacity-80"
                                    >
                                      <p>
                                        Indicates if the original and corrected labels are in the same category
                                      </p>
                                      <p>
                                        (Consistent/Inconsistent).
                                      </p>
                                    </Typography>
                                  </div>
                                }>
                                <div className="flex w-full items-center gap-2" >
                                  <p
                                    className={classNames("font-bold")}
                                  >Category Consistency:</p>
                                  <p className={classNames("font-bold", fb.mixedCategories ? "text-red-400" : "text-green-500")}>{fb.mixedCategories ? "Inconsistent" : "Consistent"}</p>
                                </div>

                              </Tooltip>

                              <div className="flex w-full items-center gap-2" >
                                <p className="font-bold " >Bounding Box:</p>
                                <p className={classNames("font-bold", fb.boundingBoxesDifferent ? "text-red-400" : "text-green-500")}>
                                  {fb.boundingBoxesDifferent ? "Changed" : "Unchanged"}
                                </p>
                              </div>

                              <div className="flex w-full items-center gap-2" >
                                <p className="font-bold " >Labels Changed:</p>
                                <p className={classNames("font-bold", fb.allLabelsSame ? "text-primary-500" : "text-primary-500")}>
                                  {fb.allLabelsSame ? "No" : "Yes"}
                                </p>
                              </div>

                              <div className="flex w-full items-center gap-2" >
                                <p className="font-bold " >Sent by:</p>
                                <p className={classNames(" italic text-gray-400")}>
                                  {fb.userEmail}
                                </p>
                              </div>

                              <div className="flex w-full items-center gap-2" >
                                <p onClick={() => setSelectedFeedback(fb)} className="font-bold underline cursor-pointer hover:text-primary-500" >Review correction</p>

                              </div>

                            </div>
                          </div>

                        </div>
                      ))}

                    </TabPanel>

                    <TabPanel key={0} value={1}>
                      {reviewedFeedbackList.map((fb: any) => (
                        <div className="w-full dark:bg-neutral-800 p-6 mt-8 rounded-xl text-white" >

                          <div className="w-full flex items-center justify-between" >

                            <div className="flex w-full justify-center" >
                              <div onClick={() => setSelectedFeedback(fb)} className="w-[400px] h-[225px] bg-neutral-600 rounded hover:bg-neutral-700 cursor-pointer" >
                                <img className="w-full" src={fb.fileUrl} alt="temp" />
                              </div>
                            </div>

                            <div className="w-full flex flex-col justify-center gap-2" >

                              <div className="flex w-full items-center gap-4" >
                                <p className="font-bold " >Date:</p>
                                <p>11/11/2023</p>
                              </div>

                              {fb.noChange ? (
                                <></>
                              ) : (
                                <>
                                </>
                              )}


                              <Tooltip
                                placement="right"
                                animate={{
                                  mount: { scale: 1, y: 0 },
                                  unmount: { scale: 0, y: 25 },
                                }}
                                conte
                                content={
                                  <div className="">
                                    <Typography
                                      variant="small"
                                      color="white"
                                      className="font-normal opacity-80"
                                    >
                                      <p>
                                        Indicates if the original and corrected labels are in the same category
                                      </p>
                                      <p>
                                        (Consistent/Inconsistent).
                                      </p>
                                    </Typography>
                                  </div>
                                }>
                                <div className="flex w-full items-center gap-2" >
                                  <p
                                    className={classNames("font-bold")}
                                  >Category Consistency:</p>
                                  <p className={classNames("font-bold", fb.mixedCategories ? "text-red-400" : "text-green-500")}>{fb.mixedCategories ? "Inconsistent" : "Consistent"}</p>
                                </div>

                              </Tooltip>

                              <div className="flex w-full items-center gap-2" >
                                <p className="font-bold " >Bounding Box Change:</p>
                                <p className={classNames("font-bold", fb.boundingBoxesDifferent ? "text-red-400" : "text-green-500")}>
                                  {fb.boundingBoxesDifferent ? "Changed" : "Unchanged"}
                                </p>
                              </div>

                              <div className="flex w-full items-center gap-2" >
                                <p className="font-bold " >Labels Changed:</p>
                                <p className={classNames("font-bold", fb.allLabelsSame ? "text-primary-500" : "text-primary-500")}>
                                  {fb.allLabelsSame ? "No" : "Yes"}
                                </p>
                              </div>

                              <div className="flex w-full items-center gap-2" >
                                <p className="font-bold " >Sent by:</p>
                                <p className={classNames(" italic text-gray-400")}>
                                  {fb.userEmail}
                                </p>
                              </div>

                              <div className="flex w-full items-center gap-2" >
                                <p onClick={() => setSelectedFeedback(fb)} className="font-bold underline cursor-pointer hover:text-primary-500" >Review correction</p>

                              </div>

                            </div>



                          </div>




                        </div>
                      ))}

                    </TabPanel>
                  </TabsBody>
                </Tabs>



              </div>
            </div>
          )}

        </div>
      </div>
    </>
  );
}
