
/**
 * VideoPlayer Component.
 * 
 * Component designed for displaying videos with overlaid prediction data.
 * Used on @Dashboard
 * 
 * Features:
 * - Video Control: Custom play and pause functionalities tailored for the component.
 * - Full-screen Mode: Ability to toggle the video display to full screen.
 * - Filter Menu: A feature for filtering visible predictions in the video.
 * - Interactive Timeline: Displays frame previews and prediction data markers.
 * - Drag-and-Drop Label Positioning: Users can reposition labels via drag-and-drop.
 * - Prediction Analysis: Analyze and display prediction data in different formats.
 *
 * Sub-components:
 * - @VideoDrawer - Renders the video with prediction data overlay.
 * - @FilterMenu - Menu for filtering prediction types.
 * - @LoadingMask - Shows a loading screen while processing.
 * - @CountingBadge - Draggable badge for counting predictions.
 * - @Timeline - Timeline with frame previews and prediction markers.
 * - @FeedbackBanner - Component for user feedback.
 * - @SvgPolygon - Renders polygons on the video.
 *
 * Hooks and Utilities:
 * - `usePredictions`: Manages prediction data.
 * - `usePredictionsMenu`: Manages the state and interactions of the prediction menu.
 * - `useRegionConfiguration`: Provides region configuration for the video.
 * - `useVideoSize`: Manages the size of the video element.
 * - `DragDropContext`: Context for drag-and-drop functionality.
 * - `utils/regions`: Utilities for region calculations and interactions.
 */

//-------------------------------------------------------------------------
// react
import { useState, useRef, useEffect, Fragment, useCallback, useContext } from "react";
// libs
import { motion } from "framer-motion";

// components
import { VideoDrawer } from "./VideoDrawer";
// styles
//icons
import { ArrowsPointingOutIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { classNames, computeCursorPosition, organizeByClass } from "../utils";
import { FilterMenu } from "./FilterMenu";
import { INDUSTRIES_MENU, MENU_ITEMS } from "../mock/menu";
import { LoadingMask } from "./LoadingMask";
import { usePredictions } from "../hooks/usePredictions";
import { CountingBadge } from "./CountingBadge";
import { usePredictionsMenu } from "../hooks/usePredictionsMenu";
import { FrameType, VideoPredictionsType } from "../types/PredictionType";
import Timeline from "./Timeline";
import { XYCoord, useDrag, useDrop } from "react-dnd";
import { DragDropContext, DragDropProvider } from "../contexts/DragDropContext";
import { SvgPolygon } from "./SvgPolygon";
import { useRegionConfiguration } from "../hooks/useRegionConfiguration";
import laneImg from "../assets/lane.svg"
import { countIntersectionsInFrame, doPolygonAndRectIntersect, reduceRectSize, updateParkingCounting } from "../utils/regions";
import FreeParkingIcon from "../assets/icons/predictions/free_parking_space.svg"
import OccupiedPArkingIcon from "../assets/icons/predictions/occupied_parking_space.svg"
import { DelimitedArea } from "../contexts/ReagionConfigurationContext";
import { FeedbackBanner } from "./FeedbackBanner";
import { feedbackContext } from "../contexts/FeedbackContext";
import { useVideoSize } from "../hooks/useVideoSize";
import { VideoControls } from "./VideoControls";
//-------------------------------------------------------------------------


interface VideoPlayerProps {
  /** String URL of the video source. */
  videoUrl: string
  /** Array of Formatted predictions */
  predictions: VideoPredictionsType
  /** Object with initial `width` and `height` of the video. */
  imageSize: {
    width: number
    height: number
  }
  /** The video file object.*/
  file: any
  /**Array of URLs for preview frames. */
  previewFrames: string[]
  /**Callback function for feedback-related actions. */
  onFeedback: () => any
}

type ImageSizeObject = {
  width: number
  height: number
}

type VideoMetadata = {
  video: HTMLVideoElement;
  currentTime: number;
  duration: number;
}
//-------------------------------------------------------------------------

export function VideoPlayer({ videoUrl, imageSize, predictions, file, previewFrames, onFeedback }: VideoPlayerProps) {
  // refs
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoNewDimensions = useVideoSize(videoRef, imageSize);
  const componentRef = useRef(null);
  // custom hooks
  const { displayCounting, displayTotalCounting, activeMenu, setActiveMenu } = usePredictionsMenu()
  const { detectedClasses } = usePredictions()
  const { labelsPosition, setLabelsPosition } = useContext(DragDropContext)
  const { delimitedAreas, parkingLotAreas, activeAreas, areasType } = useRegionConfiguration()
  const { displayBanner } = useContext(feedbackContext)
  // data sets
  const [currentFrame, setCurrentFrame] = useState<FrameType | null>(null);
  const [isPlaying, setIsPlaying] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [menu, setMenu] = useState(MENU_ITEMS)
  const { isPreparing } = usePredictions(false)
  const [totalCounts, setTotalCounts] = useState<any>();
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [organizedByClassLabels, setOrganizedByClassLabels] = useState<{
    class: string;
    predictions: any[];
  }[]>([]);
  const [parkingAreaCounting, setParkingAreaCounting] = useState({
    free: 0,
    occupied: 0
  });
  const [initializedAreaMaps, setInitializedAreaMaps] = useState<{
    colorMap: any;
    nameMap: any;
  } | null>(null);

  const [times, setTimes] = useState<any>([])

  //------------------------------------------------------------------
  let _index = -1

  // Drag n Drop
  const [{ isDragging }, drag] = useDrag(() => ({
    // "type" is required. It is used by the "accept" specification of drop targets.
    type: 'BOX',
    item: { index: 1 },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      didDrop: monitor.didDrop(),
    }),
  }))

  const [{ }, dropTopLeft] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: 'BOX',
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),

    hover(item: any, monitor) {
      setLabelsPosition("TL")
    }

  }))

  const [{ }, dropTopRight] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: 'BOX',
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),

    hover(item: any, monitor) {
      setLabelsPosition("TR")
    }

  }))

  const [{ }, dropBottomLeft] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: 'BOX',
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),

    hover(item: any, monitor) {
      setLabelsPosition("BL")
    }

  }))

  const [{ }, dropBottomRight] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: 'BOX',
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),

    hover(item: any, monitor) {
      setLabelsPosition("BR")
    }

  }))
  //----------------------------------------------------------------

  // format filter to send to video drawer
  const formatFilterMenu = () => {
    // 1. Use the reduce method on the menu array, with an initial value of an empty array.
    return activeMenu.reduce((acc, item) => {
      // 2. Filter the items in the current menu item, keeping only those that are active.
      const activeItems = item.items
        .filter((i) => i.active)
        .map((i) => i.name.toLocaleLowerCase());

      // 3. Combine the accumulated active item names (acc) with the active item names found in the current menu item.
      return [...acc, ...activeItems];
    }, [] as string[]); // Set the initial value of the accumulator (acc) as an empty array.
  };

  // custom play and pause function to prevent default behavior
  const handleClick = (event: any) => {
    event.preventDefault();
    const video = videoRef.current;
    if (video && !video.paused) {
      video.pause();
    } else if (video) {
      video.play();
    }
  };

  // custom function to prevent double click default behavior
  const handleDoubleClick = (event: any) => {
    event.preventDefault();
    setIsFullScreen((e) => !e)
  };

  const handleCategoryToggle = (id: string) => {
    // Find the selected category and save its item IDs
    const selectedCategory = menu.find((category) => category.id === id) || activeMenu.find((category) => category.id === id);
    if (!selectedCategory) return;
    const itemIdsToToggle = selectedCategory.items.map((item) => item.id);
    // Helper function to toggle the matching items in the menu categories
    const toggleItemsInMenu = (menuData: any) => {
      return menuData.map((category: any) => {
        const active = selectedCategory.id === category.id ? !selectedCategory.active : category.active
        const updatedItems = category.items.map((item: any) => {
          return itemIdsToToggle.includes(item.id) ? { ...item, active: !selectedCategory.active } : item
        }

        );
        return { ...category, items: updatedItems, active };
      });
    };

    // Toggle the items in both menus
    const updatedMenuItems = toggleItemsInMenu(menu);
    const updatedactiveMenuItems = toggleItemsInMenu(activeMenu);

    // Update the state for both menus
    setMenu(updatedMenuItems);
    setActiveMenu(updatedactiveMenuItems);
  };

  const handleItemToggle = (categoryId: string, itemID: string) => {
    const updatedMenuItems = menu.map((category) => {
      const updatedItems = category.items.map((item, index) => {
        if (item.id === itemID) {
          category.active = true
          return { ...item, active: !item.active };
        }

        return item;
      });

      return { ...category, items: updatedItems };

    });

    const updatedIndustryMenuItems = activeMenu.map((category) => {
      const updatedItems = category.items.map((item, index) => {
        if (item.id === itemID) {
          category.active = true
          return { ...item, active: !item.active };
        }

        return item;
      });

      return { ...category, items: updatedItems };

    });
    setActiveMenu(updatedIndustryMenuItems)
    setMenu(updatedMenuItems);
  };

  const getIntersectingAreaIds = (object: any, delimitedAreas: any) => {
    const intersectingAreaIds = [] as any;
    delimitedAreas.forEach((area: any) => {
      const reducedBoundingBox = reduceRectSize(object.boundingBox);

      if (doPolygonAndRectIntersect(area.POINTS, reducedBoundingBox)) {
        intersectingAreaIds.push(area.ID);
      }
    });
    return intersectingAreaIds;
  };

  const getCurrentFrameData = (currentFrameIndex: number, predictions: any[]) => {
    let currentFrame;
    if (currentFrameIndex < predictions.length) {
      currentFrame = predictions[currentFrameIndex];
    } else {
      currentFrame = predictions[predictions.length - 1];
    }

    const organizedLabels = organizeByClass(currentFrame.objects);
    return { currentFrame, organizedLabels };
  };

  //!Update calculation is too heavy
  const updateFrame = () => {
    console.time("updateFrame");
    const startTime = performance.now();

    if (videoRef.current) {
      const video = videoRef.current
      const currentTime = video.currentTime;
      const duration = video.duration;
      // Perform operations with currentTime and duration
      // Compute the cursor position.
      setCursorPosition(computeCursorPosition(currentTime, duration));

      if (!video || !predictions || predictions.length === 0) return;

      // Compute the current frame index.
      const frameRate = predictions.length / duration;
      const frameDuration = 1 / frameRate;
      let currentFrameIndex = Math.floor(currentTime / frameDuration);
      if (currentFrameIndex >= predictions.length) {
        currentFrameIndex = predictions.length - 1
      }


      const { currentFrame, organizedLabels } = getCurrentFrameData(currentFrameIndex, predictions);

      setCurrentFrame(currentFrame);
      setOrganizedByClassLabels(organizedLabels);


      if (video.currentTime === 0) {
        video.currentTime = 0.05;
      }

      if (areasType === "DEFAULT") {
        // Update predictions and counts using the currentFrameIndex.
        const { totalCountsArray, areaCountsArray } = updatePredictionsAndCounts(predictions, delimitedAreas, formatFilterMenu, currentFrameIndex);
        setTotalCounts({ totalCountsArray, areaCountsArray });
      } else {
        // logic to update parking counting
        const parkingCounts = updateParkingCounting(currentFrame, parkingLotAreas);
        setParkingAreaCounting(parkingCounts);
      }
    }

    console.timeEnd("updateFrame");
    const endTime = performance.now();
    setTimes((e: any) => [...e, endTime - startTime])
  };

  function updatePredictionsAndCounts(
    predictions: any[],
    delimitedAreas: DelimitedArea[],
    formatFilterMenu: any,
    currentFrameIndex: number
  ) {
    const _totalCounts = new Map<string, Set<string>>();
    const areaUniqueCounts = new Map<string, Set<string>>();
    //@ts-ignore
    delimitedAreas.forEach(area => areaUniqueCounts.set(area.ID, new Set<string>()));
    const filterMenu = formatFilterMenu(); // Assuming this result is constant in the function scope

    for (let i = 0; i <= currentFrameIndex; i++) {
      const prediction = predictions[i];
      //@ts-ignore
      prediction.objects.forEach((object) => {
        const intersectingAreaIds = getIntersectingAreaIds(object, delimitedAreas);
        //@ts-ignore
        object.classes.forEach(({ class: className }) => {
          if (filterMenu.includes(className)) {
            const objectId = object.id;
            if (!_totalCounts.has(className)) {
              _totalCounts.set(className, new Set());
            }
            //@ts-ignore
            _totalCounts.get(className).add(objectId);
            //@ts-ignore
            intersectingAreaIds.forEach((areaId) => {
              //@ts-ignore
              areaUniqueCounts.get(areaId).add(objectId);
            });
          }
        });
      });
    }

    const totalCountsArray = Array.from(_totalCounts).map(([className, ids]) => ({
      className,
      totalCount: ids.size,
    }));

    const areaCountsArray = Array.from(areaUniqueCounts).map(([areaId, ids]) => ({
      areaId,
      count: ids.size,
      color: initializedAreaMaps?.colorMap[areaId],
      name: initializedAreaMaps?.nameMap[areaId],
    }));

    return { totalCountsArray, areaCountsArray };
  }

  useEffect(() => {
    const video = videoRef.current

    // const video = _metadata.video;
    if (!video) return;
    const frameRate = predictions?.length / (video ? video.duration : 25); // set the frame rate of the video
    // Start the interval to update frames
    const intervalId = setInterval(updateFrame, 400 / frameRate);

    const averageTime = times.reduce((a: any, b: any) => a + b, 0) / times.length;
    console.log(`Average execution time: ${averageTime.toFixed(2)} ms`);
    setTimes([])

    if (!isPlaying) {
      clearInterval(intervalId);
    }

    return () => {
      // Stop the interval when the component unmounts
      clearInterval(intervalId);
    };
  }, [videoUrl, isPlaying, predictions]);

  //forced frame update
  useEffect(() => {
    if (predictions) {
      updateFrame()
    }
  }, [predictions, activeMenu, delimitedAreas, parkingLotAreas])

  // Active menu update
  useEffect(() => {
    if (detectedClasses) {
      // Filter the items in each category
      const filteredCategories = INDUSTRIES_MENU.map(category => {
        const filteredItems = category.items.filter((item: any) => Array.from(detectedClasses).includes(item.id));
        return { ...category, items: filteredItems };
      });

      // Remove categories with no items
      const categoriesWithItems = filteredCategories.filter(category => category.items.length > 0);
      setActiveMenu(categoriesWithItems)
    }

  }, [detectedClasses])

  // // Space bar key listener 
  useEffect(() => {
    const handleSpacebarPress = (e: any) => {
      if (e.code === 'Space') {
        const video = videoRef.current;
        if (!video) return;
        if (video.paused || video.ended) {
          video.play();
        } else {
          video.pause();
        }
      }
    };

    const element = componentRef.current;
    //@ts-ignore
    element.addEventListener('keydown', handleSpacebarPress);

    // Cleanup: remove the event listener when the component is unmounted
    return () => {
      //@ts-ignore
      element.removeEventListener('keydown', handleSpacebarPress);
    };
  }, []);

  useEffect(() => {
    function initializeAreaMaps(delimitedAreas: any[]) {
      const colorMap = delimitedAreas.reduce((acc: any, area: any) => {
        acc[area.ID] = area.COLOUR;
        return acc;
      }, {});

      const nameMap = delimitedAreas.reduce((acc: any, area: any) => {
        acc[area.ID] = area["TARGET_CLASS_NAME"].replaceAll(" • CLASS", "");
        return acc;
      }, {});

      return { colorMap, nameMap };
    }

    const _initializedAreas = initializeAreaMaps(delimitedAreas);
    setInitializedAreaMaps(_initializedAreas)
  }, [delimitedAreas])

  return (
    <motion.div ref={componentRef} tabIndex={-1} className={
      classNames(
        "outline-none transform-gpu ",
        isFullScreen ?
          "w-full h-screen absolute bottom-0 left-0 flex items-center justify-center z-20"
          : "relative flex flex-col lg:flex-row w-full "
      )}
      transition={{
        ease: "easeOut",
        width: { duration: 0.2 },
        height: { duration: 0.2 },
        pointerEvents: { delay: 0.05 },
      }}
    >
      {/* Fullscreen overlay */}
      {isFullScreen && (
        <motion.div onClick={handleDoubleClick} className="overlay absolute top-0 w-screen h-screen z-20  bg-gray-900 dark:bg-black bg-opacity-75 dark:bg-opacity-70"
          initial={{
            width: 0,
          }}
          animate={{
            width: "100%",
          }}
          transition={{
            ease: "easeOut",
            width: { duration: 0.2 },
            height: { duration: 0.2 },
            pointerEvents: { delay: 0.05 },
          }}
        />
      )}

      {/* Filter Menu */}
      {!isFullScreen && (
        <FilterMenu
          PrimaryMenu={activeMenu}
          onCategoryToggle={handleCategoryToggle}
          onItemToggle={handleItemToggle}
          onSelectAll={() => console.log("select all")}
          visible={true}
        />
      )}

      {videoUrl && (
        <div
          className={
            classNames(
              "w-full h-full flex items-center flex-col ",
              isFullScreen ? "w-[80%] justify-center " : "lg:pt-10 justify-start "
            )} >

          <div>
            <div className={
              classNames(
                " dark:text-neutral-50  py-4",
                "relative rounded"
                )} >


              <div
                className={classNames("relative   bg-black z-30")}>

                {/* <div data-vjs-player className="w-full"> */}
                <video
                  className={classNames("z-10 ", isFullScreen ? "max-h-[80vh]" : "max-h-[64vh]")}
                  // className="video-js vjs-big-play-centered z-10"
                  preload="metadata"
                  controlsList="nofullscreen nodownload"
                  webkit-playsinline="true"
                  playsInline
                  id="video"
                  controls={false}
                  ref={videoRef}
                  src={`${videoUrl}#t=0.5`}
                  onDoubleClick={(event) => handleDoubleClick(event)}
                  onClick={(event) => handleClick(event)}
                  onPlay={() => setIsPlaying(true)}
                  onPause={() => setIsPlaying(false)}
                  onTimeUpdate={updateFrame}

                />


                {/* {displayBanner && !isFullScreen && (
                  <div className="mt-28 w-full flex justify-center absolute" >
                    <FeedbackBanner onClick={onFeedback} />
                  </div>
                )} */}

                {isPreparing && (
                  // <div className=" w-full h-[80vh] absolute z-50 top-0" >
                  <LoadingMask text={"Our models are processing your Video!"} />
                  // </div>
                )}

                {areasType === "DEFAULT" && delimitedAreas.length > 0 && delimitedAreas.map((area: any) => {
                  if (activeAreas.includes(area.ID)) {
                    return (
                      <SvgPolygon onClick={(event) => handleClick(event)} currentFrame={null} delimitedArea={area} width={videoNewDimensions?.width ?? 1} height={videoNewDimensions?.height ?? 1} originalWidth={imageSize.width ?? 1} originalHeight={imageSize.height} />
                    )
                  }
                })}

                {areasType === "PARKING" && parkingLotAreas.length > 0 && parkingLotAreas.map((area: any) => {
                  // if (activeAreas.includes(area.ID)) {
                  return (
                    <SvgPolygon onClick={(event) => handleClick(event)} currentFrame={currentFrame} delimitedArea={area} width={videoNewDimensions?.width ?? 1} height={videoNewDimensions?.height ?? 1} originalWidth={imageSize.width ?? 1} originalHeight={imageSize.height} />
                  )
                  // }
                })}

                {predictions && predictions?.length > 0 && currentFrame && (
                  <VideoDrawer
                    isPlaying={isPlaying}
                    currentFrame={currentFrame}
                    frames={predictions}
                    originalImageSize={imageSize}
                    selectedObject={null}
                    filter={formatFilterMenu()}
                    videoNewDimensions={videoNewDimensions}
                  />
                )}

                {/* DROP ZONES */}
                <div ref={dropTopLeft} onClick={(event) => handleClick(event)}
                  className={classNames("absolute w-[50%] h-[calc(50%-40px)] top-0 left-0", isDragging && "z-30")} >
                </div>
                <div ref={dropTopRight} onClick={(event) => handleClick(event)}
                  className={classNames("absolute w-[50%]  h-[calc(50%-40px)] top-0 right-0", isDragging && "z-30")} >
                </div>
                <div ref={dropBottomLeft} onClick={(event) => handleClick(event)}
                  className={classNames("absolute w-[50%] h-[calc(50%)]  top-[calc(50%)] left-0", isDragging && "z-30")} >
                </div>
                <div ref={dropBottomRight} onClick={(event) => handleClick(event)}
                  className={classNames("absolute w-[50%] h-[calc(50%)] top-[calc(50%)] right-0", isDragging && "z-30")} >
                </div>

                {!displayTotalCounting && (
                  <div ref={drag} className={classNames(
                    "absolute  z-30  flex flex-col gap-2 ",
                    labelsPosition === "TL" && "left-4 top-5",
                    labelsPosition === "TR" && "right-4 top-5",
                    labelsPosition === "BL" && "left-4 bottom-[8px]",
                    labelsPosition === "BR" && "right-4 bottom-[8px]",
                    "max-h-[500px] overflow-scroll hide-scrollbar"
                  )} >
                    {predictions && predictions?.length > 0 && currentFrame && displayCounting && (
                      <>
                        {/* @ts-ignore */}
                        {organizedByClassLabels.map((item, index) => {
                          if (formatFilterMenu().includes(item.class)) {
                            _index = _index + 1
                            return (
                              <CountingBadge index={index} isDragging={isDragging} key={`counting_badge_${_index}`}
                                style={{
                                  // top: 20 + (40 * _index)
                                }}
                                title={item.class}
                                value={item.predictions.length} />
                            )
                          }
                        })}

                        {areasType === "DEFAULT" && delimitedAreas.map((item: any, index: any) => {
                          if (activeAreas.includes(item.ID)) {
                            _index = _index + 1
                            return (
                              <div
                                className={
                                  classNames(
                                    " flex items-center  p-1 pr-4 bg-black rounded-full text-white select-none",
                                    isDragging ? "border border-dashed border-black bg-neutral-900/30" : "",
                                  )}
                              >
                                <div className={
                                  classNames("flex items-center justify-between gap-4",
                                    isDragging ? "invisible" : "visible",
                                  )
                                } >
                                  <div className="w-6 h-6 rounded-full flex items-center justify-center"
                                    style={{
                                      backgroundColor: `rgba(${item.COLOUR[0]}, ${item.COLOUR[1]}, ${item.COLOUR[2]}, ${1})`
                                    }}
                                  >
                                    <img src={laneImg} className="w-6" alt="Icon" />
                                  </div>
                                  <span className="capitalize text-sm">
                                    {item["TARGET_CLASS_NAME"].replaceAll(" • CLASS", "")} ({countIntersectionsInFrame(currentFrame, item.ID)})

                                  </span>
                                </div>

                              </div>
                            )
                          }
                        })}
                      </>
                    )}


                    {areasType === "PARKING" && parkingLotAreas.length > 0 && (
                      <>
                        <div
                          className={
                            classNames(
                              " flex items-center  p-1 pr-4 bg-black rounded-full text-white select-none",
                              isDragging ? "border border-dashed border-black bg-neutral-900/30" : "",
                            )}
                        >
                          <div className={
                            classNames("flex items-center justify-between gap-4",
                              isDragging ? "invisible" : "visible",
                            )
                          } >
                            <div className="w-6 h-6 rounded-full flex items-center justify-center"
                              style={{
                                // backgroundColor: `rgba(64, 249, 103, ${1})`
                              }}
                            >
                              <img src={FreeParkingIcon} className="w-6" alt="Icon" />
                            </div>
                            <span className="capitalize text-sm">
                              Free parking space {parkingAreaCounting.free}

                            </span>
                          </div>

                        </div>

                        <div
                          className={
                            classNames(
                              " flex items-center  p-1 pr-4 bg-black rounded-full text-white select-none",
                              isDragging ? "border border-dashed border-black bg-neutral-900/30" : "",
                            )}
                        >
                          <div className={
                            classNames("flex items-center justify-between gap-4",
                              isDragging ? "invisible" : "visible",
                            )
                          } >
                            <div className="w-6 h-6 rounded-full flex items-center justify-center"
                              style={{
                                // backgroundColor: `rgba(64, 249, 103, ${1})`
                              }}
                            >
                              <img src={OccupiedPArkingIcon} className="w-6" alt="Icon" />
                            </div>
                            <span className="capitalize text-sm">
                              Occupied parking space {parkingAreaCounting.occupied}

                            </span>
                          </div>

                        </div>
                      </>
                    )}
                  </div>
                )}

                {/* TOTAL COUNTS */}
                {predictions && predictions?.length > 0 && currentFrame && displayTotalCounting && (
                  <div ref={drag} className={classNames(
                    "absolute  z-30  flex flex-col gap-2 ",
                    labelsPosition === "TL" && "left-4 top-5",
                    labelsPosition === "TR" && "right-4 top-5",
                    labelsPosition === "BL" && "left-4 bottom-[100px]",
                    labelsPosition === "BR" && "right-4 bottom-[100px]",
                  )} >


                    {totalCounts.totalCountsArray && totalCounts.totalCountsArray.map((item: any, index: any) => {
                      return (
                        <CountingBadge
                          index={index}
                          isDragging={isDragging}
                          key={`total_counting_badge_${item.className}_${_index}`}
                          title={item.className}
                          value={item.totalCount}
                        />
                      );
                    })}

                    {totalCounts.totalCountsArray && totalCounts.areaCountsArray.map((areaItem: any, index: any) => {
                      if (activeAreas.includes(Number(areaItem.areaId))) {
                        _index = _index + 1

                        return (
                          <div key={`areaCount_${_index}`}>
                            <div
                              className={
                                classNames(
                                  " flex items-center  p-1 pr-4 bg-black rounded-full text-white select-none",
                                  isDragging ? "border border-dashed border-black bg-neutral-900/30" : "",
                                )}
                            >
                              <div className={
                                classNames("flex items-center justify-between gap-4",
                                  isDragging ? "invisible" : "visible",
                                )
                              } >
                                <div className="w-6 h-6 rounded-full flex items-center justify-center"
                                  style={{
                                    backgroundColor: areaItem.color ? `rgba(${areaItem?.color[0]}, ${areaItem?.color[1]}, ${areaItem?.color[2]}, ${1})` : ""
                                  }}>

                                  <img src={laneImg} className="w-6" alt="Icon" />
                                </div>
                                <span className="capitalize text-sm">
                                  {areaItem.name} ({areaItem.count})
                                </span>
                              </div>

                            </div>
                          </div>
                        );
                      }

                    })}

                  </div>
                )}

                <div className="absolute top-2 right-2 w-10 h-10 z-30 flex items-center justify-center" >
                  {isFullScreen ? (
                    <XMarkIcon onClick={handleDoubleClick} className="h-5 w-5 md:h-6 md:w-6 p-1 lg:h-8 lg:w-8  text-black bg-white rounded-full cursor-pointer" />
                  ) : (
                    <ArrowsPointingOutIcon onClick={handleDoubleClick} className="h-6 w-6 opacity-80 hover:opacity-95 hover:scale-105 text-black bg-white p-1  rounded-lg cursor-pointer" />
                  )}
                </div>

              </div>

              {/* <VideoControls/> */}

              <Timeline position={cursorPosition} predictions={predictions} extractedFrames={previewFrames} videoDuration={1} />

            </div>

          </div>


        </div>
      )}

    </motion.div>

  );
}