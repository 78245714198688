/**
 * Sidebar Component.
 * 
 * sidebar navigation for the application. It provides links to different sections like Predictions and Settings.
 * It features a dynamic, collapsible design with icons and labels for each menu item.
  * Used on @PredictionPage @Admin @Dashboard and @Settings pages
 * 
 * Features:
 * - Collapsible Design: Can toggle between a minimal icon-only view and an expanded view with labels.
 * - Auth User Integration: Displays navigation links based on the authentication status of the user.
 */

// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Libraries
import {  Squares2X2Icon,  Cog6ToothIcon, Square3Stack3DIcon, } from '@heroicons/react/24/outline';

// Components/Assets/Helpers
import { classNames } from "../utils";
import { useAuth } from "../hooks/useAuth";

interface SidebarProps {
  /** Children components to be rendered alongside the sidebar. */
  children: React.ReactNode;

  /** The currently selected menu item. */
  selected?: string;
}
export function Sidebar({ children, selected = "settings" }: SidebarProps) {
  const { authUser } = useAuth()
  const menus = [
    { name: "Predictions", link: "predictions", icon: Square3Stack3DIcon },
    { name: "Settings", link: "settings", icon: Cog6ToothIcon },
  ];
  const [open, setOpen] = useState(false);
  // const path = usePathname()

  const navigate = useNavigate();

  const handleNavigation = (route: string) => {
    switch (route) {
      case "home":
        navigate("/");
        break;

      case "predictions":
        navigate("/mypredictions");
        break;

      case "settings":
        navigate("/settings");
        break;

      default:
        // history.push("/")
        break;
    }
  }


  return (
    <>

      <section className="flex gap-6 w-full bg-background  h-[calc(100vh_-_80px)]">
        <div
          className={classNames(
            `dark:bg-[#101010] bg-white border-r border-primary-400 dark:border-[#3c3c3c]  h-[calc(100vh_-_80px)] ${open ? "w-72" : "w-16"
            } duration-300 dark:text-gray-100 pl-4`,
            "absolute z-10"
          )}
        >
          <div className="py-3 flex justify-end">

          </div>
          <div className="mt-4 flex flex-col gap-4 relative">
          <div onClick={() => handleNavigation("home")} className={`pr-4  dark:border-neutral-900 border-white cursor-pointe`} >
                <div
                  className={` group flex items-center text-sm  gap-3.5 font-medium p-1 dark:hover:bg-neutral-700 hover:bg-neutral-200 rounded-md`}
                >
                  <div>{React.createElement(Squares2X2Icon, { className: "h-6 w-6" })}</div>
                  <h2
                    style={{
                      transitionDelay: `${2}00ms`,
                    }}
                    className={`whitespace-pre duration-300 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                      }`}
                  >
                    Dashboard
                  </h2>
                  <h2
                    className={`${open && "hidden"
                      } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
                  >
                    Dashboard
                  </h2>
                </div>
              </div>

            {authUser && menus?.map((menu, i) => (
              <div key={i} onClick={() => handleNavigation(menu.link)} className={`pr-4  dark:border-neutral-900 border-white cursor-pointer  ${"path" === menu.link && "border-purple-500"} `} >
                <div
                  className={` group flex items-center text-sm  gap-3.5 font-medium p-1 dark:hover:bg-neutral-700 hover:bg-neutral-200 rounded-md`}
                >
                  <div>{React.createElement(menu?.icon, { className: "h-6 w-6" })}</div>
                  <h2
                    style={{
                      transitionDelay: `${i +1 + 2}00ms`,
                    }}
                    className={`whitespace-pre duration-300 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                      }`}
                  >
                    {menu?.name}
                  </h2>
                  <h2
                    className={`${open && "hidden"
                      } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
                  >
                    {menu?.name}
                  </h2>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full" >

          {children}
        </div>
      </section>
    </>
  );
}
