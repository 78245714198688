import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  getIdToken
} from "firebase/auth";
import { createContext, ReactNode, useContext, useEffect, useState, useMemo, useCallback } from "react";
import type { User } from "firebase/auth";
import { auth } from "../services/firebase";
import { database, storage } from "../services/firebase";
import { ref, push, query, orderByChild, get, child, set, remove, update, getDatabase } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL, getBlob, deleteObject } from "firebase/storage";

interface AuthContextData {
  authUser: FormattedUser | null;
  loadingUser: boolean;
  clear: () => void;
  _signInWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  _createUserWithEmailAndPassword: (email: string, password: string) => Promise<User>;
  handleGoogleAuth: () => Promise<void>;
  handleSignOut: () => Promise<void>
  getUserToken: () => Promise<string | null>
}

type AuthUser = {
  uid: string;
  email: string | null;
  avatar: string | null;
  token: string | null;
};

export type UserData = {
  email: string | null;
  displayName?: string | null | undefined;
  predictions: any
  isAdmin?: boolean
}

type FormattedUser = AuthUser & UserData


interface AuthProviderProps {
  children: ReactNode;
}


export const authContext = createContext<AuthContextData>({} as AuthContextData);

export const useAuth = () => useContext(authContext);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authUser, setAuthUser] = useState<FormattedUser | null>(null);
  const [loadingUser, setLoading] = useState(true);

  const clear = useCallback(() => {
    setAuthUser(null);
    // setLoading(true);
  }, []);

  const formatAuthUser = useCallback((user: User) => {
    return {
      uid: user.uid,
      email: user.email,
      avatar: user.photoURL,
    };
  }, []);

  const formatDatabaseUser = useCallback((firebaseUser: UserData) => {
    console.log("firebase user:", firebaseUser)

    const formattedUser = {
      displayName: firebaseUser.displayName,
      email: firebaseUser.email,
      predictions: firebaseUser.predictions ?? null,
      isAdmin: firebaseUser.isAdmin
    }

    return formattedUser
  }, []);


  const authStateChanged = useCallback(
    async (authState: User | null) => {
      try {
        if (!authState) {
          setAuthUser(null);
          setLoading(false);
          console.log("no user");
          return;
        }
        setLoading(true);

        const userToken = await authState.getIdToken()
        const formattedUser = formatAuthUser(authState);
        //@ts-ignore
        setAuthUser({ ...formattedUser, token: userToken })

        const userDbRef = ref(database, `users/${formattedUser?.uid}`)
        const dbRef = ref(database)
        const snapshot = await get(userDbRef)
        console.log(snapshot)

        if (snapshot.exists()) {
          const fireBaseObj = await snapshot.val()
          //@ts-ignore
          setAuthUser({ ...formattedUser, token: userToken, isAdmin: fireBaseObj.isAdmin })
        }

        setLoading(false);
      } catch (err) {
        console.log(err)
      }
    },
    [],
  );

  const getUserToken = useCallback(async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const token = await getIdToken(user);
        return token;
      } else {
        console.log('No user is currently signed in.');
        return null;
      }
    } catch (err) {
      console.error('Error getting user token:', err);
      return null;
    }
  }, []);

  const handleGoogleAuth = useCallback(async () => {
    // Sign in using a popup.
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential) {
        // const token = credential.accessToken;
        // const date = new Date();

        // date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000)); // Set expiration to 7 days from now
        // document.cookie = `jwt=${token}; expires=${date.toUTCString()}; SameSite=Lax; secure`
      }

    } catch (error: any) {
      const credential = GoogleAuthProvider.credentialFromError(error);
    }
  }, []);

  const _signInWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);

        const user = userCredential.user;
        console.log("user", user);
      } catch (error: any) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("code", errorCode);
        console.log("errorMessage", errorMessage);
        throw (error)
      }
    },
    [auth]
  );

  const _createUserWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        return userCredential.user;
      } catch (error: any) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("code", errorCode);
        console.log("errorMessage", errorMessage);
        throw errorMessage;
      }
    },
    [auth],
  );

  const handleSignOut = async () => {
    await signOut(auth)
  }

  useEffect(() => {
    console.log("listener");
    const unsubscribe = onAuthStateChanged(auth, authStateChanged);
    return () => unsubscribe();
  }, [authStateChanged]);

  return (
    <authContext.Provider
      value={{
        authUser,
        loadingUser,
        clear,
        _signInWithEmailAndPassword,
        _createUserWithEmailAndPassword,
        handleGoogleAuth,
        handleSignOut,
        getUserToken
      }}>
      {children}
    </authContext.Provider>
  )
}