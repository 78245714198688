import {  useEffect } from 'react';
import { LoginPattern, SignUpForm } from '../components';
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo_name.svg"
import { CheckBadgeIcon } from '@heroicons/react/24/solid'
import { useAuth } from '../hooks/useAuth';

export function SignUp() {
  const { authUser } = useAuth()
  const navigate = useNavigate();

  useEffect(() => {
    if (authUser) {
      navigate("/dashboard");
    }
  }, [authUser])

  return (
    <div className="w-full h-screen bg-neutral-900 overflow-hidden" >

      <div
        className="relative h-screen w-full flex flex-col  space-y-8 shadow-2xl rounded-2xl md:flex-row md:space-y-0">

        <div className="relative w-full resize-none ">
          <div className="absolute -left-40 -top-20 resize-none z-10 min-w-[1200px] h-full opacity-40">
            <LoginPattern />
          </div>
          <img className="absolute left-6 top-6 w-72" src={Logo} alt="logo" />

          <div className="w-full relative h-full z-10 flex flex-col justify-center text-white p-8 px-12 " >
            <div className="bg-neutral-800/40 p-10 rounded-xl" >
              <h1 className="text-4xl font-extrabold " >
                {/* <Balance> */}
                Maximize Your Video Analysis with AI
                {/* </Balance> */}
              </h1>

              <div className="py-10 " >
                {/* <p className="text-neutral-400 mt-5">By signing up, you unlock:</p> */}

                <ul className="mt-3 " >
                  <li className="flex">
                    <CheckBadgeIcon className="w-6 h-6 text-primary-500 mr-2" />
                    <div>
                      <p className="font-extrabold text-2xl" >Fine-grained Detection </p>
                      <p className="text-neutral-400 text-lg" > Identify objects and actions with our cutting-edge AI models</p>
                    </div>
                  </li>

                  {/* <li className="flex mt-2">
                  <CheckBadgeIcon className="w-6 h-6 text-primary-500 mr-2" />
                  <div>
                    <p className="font-extrabold text-xl" >Timeline Markers</p>
                    <p className="text-neutral-400" >Navigate video frames effortlessly with automatic timeline markers for each detection.</p>
                  </div>
                </li> */}

                  <li className="flex mt-2">
                    <CheckBadgeIcon className="w-6 h-6 text-primary-500 mr-2" />
                    <div>
                      <p className="font-extrabold text-2xl" >Detailed Analytics Report</p>
                      <p className="text-neutral-400 text-lg" > Get access to comprehensive analytics reports, available for download in a user-friendly format. </p>
                    </div>
                  </li>

                  <li className="flex mt-2">
                    <CheckBadgeIcon className="w-6 h-6 text-primary-500 mr-2" />
                    <div>
                      <p className="font-extrabold text-2xl" >Customizable Detection</p>
                      <p className="text-neutral-400 text-lg" >Customize your video analysis according to your needs. Focus only on what's crucial to you.</p>
                    </div>
                  </li>
                </ul>
              </div>

            </div>

          </div>
        </div>
        <div className="w-full relative z-20 flex items-center justify-center  min-h-[600px]" >
          <SignUpForm />
        </div>


      </div>
    </div>
  );
}

