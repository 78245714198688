/**
 * Component that draws bounding boxes around detected objects on images
 * It also displays labels and additional information about the objects when 
 * users interact with the bounding boxes.
 * Used on @Dashboard
 * 
 * Users can filter the displayed objects based on their class, and the boxes 
 * will only be shown for objects that match the filter.
 * 
 * This component also renders @FilterMenu
 */

import { usePredictions } from '../hooks/usePredictions';
import { Fragment, useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { FilterMenu } from './FilterMenu';
import { INDUSTRIES_MENU, MENU_ITEMS } from '../mock/menu';
import { LoadingMask } from './LoadingMask';
import { CountingBadge } from './CountingBadge';
import { organizeByClass } from '../utils';
import { usePredictionsMenu } from '../hooks/usePredictionsMenu';
import { ImagePredictionsType, FormattedPredictionType } from '../types/PredictionType';
//----------------------------------------------------------------

interface DrawerProps {
  /** Array of bounding boxes */
  predictions?: ImagePredictionsType | null
  /** Original image resolution for resize calculations */
  originalImageSize: ImageSizeObject
  fileUrl: string
  loading?: boolean
  /**image file */
  file: any
}

type ImageSizeObject = {
  width: number
  height: number
}
//----------------------------------------------------------------

const animationDurationRef = 0.12;

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}
//----------------------------------------------------------------

export function ImageDrawer({ predictions, originalImageSize, fileUrl, file, loading = true }: DrawerProps) {
  const [imageSize, setImageSize] = useState<ImageSizeObject | null>(null)
  const [visibleTooltip, setVisibleTooltip] = useState<string | null>(null)
  const [expandedTooltip, setExpandedTooltip] = useState<string | null>(null)
  const [industriesMenu, setIndustriesMenu] = useState(INDUSTRIES_MENU)
  const [menu, setMenu] = useState(MENU_ITEMS)
  const [selectedObject, setSelectedObject] = useState<string | null>(null)
  const { displayCounting, displayAllTags, activeMenu, setActiveMenu } = usePredictionsMenu()

  const { isLoading, handleDownloadImage, detectedClasses } = usePredictions(false)
  let _index = -1

  const moveLabelPosition = (from: any, to: any) => {

  }

  const formatFilterMenu = () => {
    // 1. Use the reduce method on the menu array, with an initial value of an empty array.
    return industriesMenu.reduce((acc, item) => {
      // 2. Filter the items in the current menu item, keeping only those that are active.
      const activeItems = item.items
        .filter((i) => i.active)
        .map((i) => i.name.toLocaleLowerCase());

      // 3. Combine the accumulated active item names (acc) with the active item names found in the current menu item.
      return [...acc, ...activeItems];
    }, [] as string[]); // Set the initial value of the accumulator (acc) as an empty array.
  };

  useEffect(() => {
    setExpandedTooltip(selectedObject)
    setVisibleTooltip(selectedObject)
  }, [selectedObject])

  const handleClick = (id: string | null) => {
    if (id === expandedTooltip) {
      setExpandedTooltip(null)
    } else {
      setExpandedTooltip(id)
    }
  }

  const getSize = (size: number) => {
    if (!imageSize) {
      const _sizes = handleGetImageSize()
      return _sizes.width * size / originalImageSize.width
    }
    return imageSize.width * size / originalImageSize.width
  }

  const getColorStyles = (item: string) => {
    switch (item.toLocaleLowerCase()) {
      case "person":
        if (formatFilterMenu().includes("person")) {
          return "border-neutral-600 hover:border-neutral-800"
        } else {
          return "hidden"
        }

      case "car":
        if (formatFilterMenu().includes("car")) {
          return "border-neutral-600 hover:border-neutral-800"
        } else {
          return "hidden"
        }

      case "bus":
        if (formatFilterMenu().includes("bus")) {
          return "border-neutral-600 hover:border-neutral-800"
        } else {
          return "hidden"
        }

      case "truck":
        if (formatFilterMenu().includes("truck")) {
          return "border-neutral-600 hover:border-neutral-800"
        } else {
          return "hidden"
        }

      case "fire":
        if (formatFilterMenu().includes("fire")) {
          return "border-red-500 hover:border-red-700"
        } else {
          return "hidden"
        }

      case "smoke":
        if (formatFilterMenu().includes("smoke")) {
          return "border-[#e4a872] hover:border-orange-300"
        } else {
          return "hidden"
        }

      default:
        return "border-neutral-600 hover:border-neutral-800"
    }
  }

  // gets image size and updates 
  const handleGetImageSize = () => {
    const img = document.getElementById('image') as any
    if (img) {
      const { offsetWidth, offsetHeight } = img;
      setImageSize({ width: offsetWidth, height: offsetHeight })
      return { width: offsetWidth, height: offsetHeight }
    } else {
      // if image is not rendered yet returns original measurements
      return { ...originalImageSize }
    }
  }

  const handleCategoryToggle = (id: string) => {
    const updatedMenuItems = industriesMenu.map((category) => {
      if (category.id === id) {
        const updatedItems = category.items.map((item) => ({ ...item, active: !category.active }))
        return { ...category, active: !category.active, items: updatedItems };
      }

      return category;
    });

    setIndustriesMenu(updatedMenuItems);
    setActiveMenu(updatedMenuItems)
  };

  const handleItemToggle = (categoryId: string, itemID: string) => {
    const updatedMenuItems = industriesMenu.map((category) => {
      const updatedItems = category.items.map((item, index) => {
        if (item.id === itemID) {
          category.active = true
          return { ...item, active: !item.active };
        }

        return item;
      });

      return { ...category, items: updatedItems };

    });

    const updatedIndustryMenuItems = industriesMenu.map((category) => {
      const updatedItems = category.items.map((item, index) => {
        if (item.id === itemID) {
          category.active = true
          return { ...item, active: !item.active };
        }

        return item;
      });

      return { ...category, items: updatedItems };

    });
    setIndustriesMenu(updatedIndustryMenuItems)
    setActiveMenu(updatedIndustryMenuItems)
  };

  useEffect(() => {
    handleGetImageSize()
  }, [originalImageSize])

  useEffect(() => {
    if (detectedClasses) {
      // Filter the items in each category
      const filteredCategories = INDUSTRIES_MENU.map(category => {
        const filteredItems = category.items.filter((item: any) => Array.from(detectedClasses).includes(item.id));
        return { ...category, items: filteredItems };
      });

      // Remove categories with no items
      const categoriesWithItems = filteredCategories.filter(category => category.items.length > 0);
      setIndustriesMenu(categoriesWithItems)
      setActiveMenu(categoriesWithItems)
    }

  }, [detectedClasses])

  useEffect(() => {
    const imgElement = document.getElementById('image');
    
    if (imgElement) {
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          const {width, height} = entry.contentRect;
          setImageSize({width, height}); // Update state with new size
        }
      });
  
      resizeObserver.observe(imgElement);
  
      // Clean up observer on component unmount
      return () => resizeObserver.unobserve(imgElement);
    }
  }, []); // Empty dependency array ensures this runs once on mount
  

  return (
    <>
      <div className="relative flex flex-col lg:flex-row w-full h-full" >
        <FilterMenu
          PrimaryMenu={activeMenu}
          onCategoryToggle={handleCategoryToggle}
          onItemToggle={handleItemToggle}
          onSelectAll={() => console.log("select all")}
          visible={true}
        />

        <div className={classNames("w-full  mt-2 lg:mt-8 flex justify-center",)} >
          <div>

            <div className={classNames("relative  z-20")}>
              <img id="image" src={fileUrl ?? ""} alt="Uploaded image" className={classNames("z-10 shadow-sm shadow-blue-400", "max-h-[70vh]")} />
              {isLoading && (
                <LoadingMask text={"Our model is processing your Image!"} />
              )}

              {predictions && predictions.map((object) => (
                <Fragment key={object.id}>
                  <div
                    onClick={() => handleClick(object.id)}
                    onMouseLeave={() => {
                      setVisibleTooltip(null)
                      handleClick(null)
                    }}
                    onMouseOver={() => setVisibleTooltip(object.id)}
                    className={`border-[2.4px] cursor-pointer hover:scale-105 hover:z-20 transition-transform duration-300 ${getColorStyles(object.name.split("_")[0])}`}
                    style={{
                      position: "absolute",
                      left: getSize(object.boundingBox[0]),
                      top: getSize(object.boundingBox[1]),
                      width: getSize(object.boundingBox[2]),
                      height: getSize(object.boundingBox[3]),
                    }}
                  >
                    <div
                      className={
                        classNames(`animation-pulse w-2 h-2 rounded-full bg-neutral-800`,
                          object.id === visibleTooltip ? "invisible" : "visible",
                        )}
                      style={{
                        position: "absolute",
                        left: (getSize(object.boundingBox[2]) / 2) - 6,
                        top: (getSize(object.boundingBox[3]) / 2) - 8,
                      }}
                    />

                    {/* expandable name tag */}
                    <motion.div
                      onMouseLeave={() => setVisibleTooltip(null)}
                      onMouseOver={() => setVisibleTooltip(object.id)}
                      className={
                        classNames(
                          `transition-all duration-300 overflow-hidden`,
                          visibleTooltip === object.id ? "opacity-100" : "opacity-0",
                          displayAllTags ? "opacity-100" : "opacity-0",
                        )}
                      style={{
                        position: "absolute",
                        left: 0,
                        bottom: getSize(object.boundingBox[3]),
                      }}
                      animate={{
                        height: expandedTooltip === object.id ? 100 : 36,
                        width: expandedTooltip === object.id ? object.name.length * 12 : 100
                      }}

                      transition={{
                        ease: "easeOut",
                        width: { duration: animationDurationRef },
                        height: { duration: animationDurationRef },
                        pointerEvents: { delay: 0.05 },
                      }}
                    >
                      {expandedTooltip === object.id ? (
                        <div className="flex items-center flex-col w-full bg-neutral-800 text-white  justify-center text-sm p-1 rounded z-50" >
                          <p>
                            {object.name}
                          </p>
                          <p>
                            Object ID: {object.id}
                          </p>

                          <p>
                            Class: {object.classes[0].class}
                          </p>
                          <p>
                            Confidence: <span className={classNames(
                              Number(object.classes[0].confidence) >= 0.5 ? "text-green-500" : "text-red-500"
                            )} >{object.classes[0].confidence}</span>
                          </p>
                        </div>
                      ) : (
                        <div className="capitalize items-center flex flex-col w-full text-white  justify-center text-sm p-1 rounded overflow-hidden">
                          <div key={`${object.id}-${object.classes[0].class}`}
                            className={
                              classNames(
                                "capitalize mt-[2px] items-center flex flex-col w-full text-white  justify-center text-sm p-1 rounded ",
                                visibleTooltip === object.id ? "bg-neutral-800 z-[60]" : "bg-neutral-700",
                              )}
                          >
                            <p>
                              {object.classes[0].class}
                            </p>
                          </div>

                        </div>
                      )}
                    </motion.div>
                  </div>
                </Fragment>
              ))}

              {predictions && predictions?.length > 0 && displayCounting && (
                <>
                  {organizeByClass(predictions).map((item, index) => {
                    if (formatFilterMenu().includes(item.class)) {
                      _index = _index + 1
                      return (
                        <CountingBadge
                          index={index}

                          isDragging={false}
                          key={_index}
                          className={`absolute left-4`}
                          style={{
                            top: 30 + (40 * _index)
                          }}
                          title={item.class}
                          value={item.predictions.length} />
                      )
                    }
                  })}
                </>
              )}
            </div>
          </div>
        </div>

      </div>

    </>

  );
}
