/**
 * TimelinePin Component.
 * 
 * This component displays a series of detection pins, each representing a specific detection category in a timeline.
 * It shows icons with tooltips for each detection and can be expanded or collapsed to show more or less detail.
 * Used on @Timeline component
 * 
 * Features:
 * - Dynamic Display: Shows detection icons in an expandable/collapsible list.
 * - Tooltip Integration: Provides tooltips for each detection with counts.
 * - Animation: Uses Framer Motion for smooth expanding and collapsing animations.
 */

//libs
import { Avatar } from "@material-tailwind/react";
import { motion, AnimatePresence } from "framer-motion"
// vehicles
import CarIcon from "../assets/icons/predictions/car.svg"
import BusIcon from "../assets/icons/predictions/bus.svg"
import TruckIcon from "../assets/icons/predictions/truck.svg"
import TrainIcon from "../assets/icons/predictions/train.svg"
import Motorcycle from "../assets/icons/predictions/motorcycle.svg"
// objects
import Handbag from "../assets/icons/predictions/handbag.svg"
import Backpack from "../assets/icons/predictions/backpack.svg"
import AbandonedObjectIcon from "../assets/icons/predictions/abandoned_object.svg"
import Suitcase from "../assets/icons/predictions/suitcase.svg"

// people and actions
import PersonIcon from "../assets/icons/predictions/person.svg"
import Climbing from "../assets/icons/predictions/climbing.svg"
import Jumping from "../assets/icons/predictions/jumping.svg"
import Walking from "../assets/icons/predictions/walking.svg"
import Running from "../assets/icons/predictions/running.svg"
import Fighting from "../assets/icons/predictions/fighting.svg"
import Standing from "../assets/icons/predictions/standing.svg"
import StandingUp from "../assets/icons/predictions/standing_up.svg"
import SittingDown from "../assets/icons/predictions/sitting_down.svg"
import Riding from "../assets/icons/predictions/riding.svg"
import Lying from "../assets/icons/predictions/lying.svg"
import Throwing from "../assets/icons/predictions/throwing.svg"
import Falling from "../assets/icons/predictions/falling.svg"
//environment
import Fire from "../assets/icons/predictions/fire.svg"
import Smoke from "../assets/icons/predictions/smoke.svg"
import FireRaising from "../assets/icons/predictions/fire_raising.svg"
// vandalizing
import Graffiti from "../assets/icons/predictions/graffiti.svg"
import { Tooltip } from "@material-tailwind/react"
import { useState } from "react";


const detectionIcons = {
  car: CarIcon,
  bus: BusIcon,
  truck: TruckIcon,
  train: TrainIcon,
  motorcycle: Motorcycle,
  person: PersonIcon,
  backpack: Backpack,
  handbag: Handbag,
  fire: Fire,
  smoke: Smoke,
  abandoned_object: AbandonedObjectIcon,
  climbing: Climbing,
  jumping: Jumping,
  walking: Walking,
  running: Running,
  fire_raising: FireRaising,
  graffiti: Graffiti,
  fighting: Fighting,
  standing: Standing,
  standing_up: StandingUp,
  sitting_down: SittingDown,
  riding: Riding,
  lying: Lying,
  throwing: Throwing,
  falling: Falling,
  suitcase: Suitcase,
};

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.01,
      staggerChildren: 0.2
    }
  }
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
}


interface TimelinePinProps {
  detections: {
    color: string
    detection: string;
    counting: number
  }[]
  isExpanded: boolean
}

export function TimelinePin({ detections, isExpanded }: TimelinePinProps) {

  return (
    <>
      <AnimatePresence>
        {isExpanded && (
          <motion.ul
            variants={container}
            initial="hidden"
            animate="visible"
            className=" flex flex-col-reverse gap-1"
          >
            {detections.map((detection, index) => (
              <motion.li key={`${detection.detection}_${index}`} variants={item} className="min-w-[32px] ">
                <Tooltip content={`${detection.detection} (${detection.counting})`} placement="right-end" >
                  <Avatar
                    className={`
                  w-8 h-8 min-w-[32px] rounded-full flex items-center justify-center opacity-90 
                  hover:opacity-100 hover:z-30 hover:scale-105`}
                    //@ts-ignore
                    src={detectionIcons[detection.detection]}
                  />
                </Tooltip>
              </motion.li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {!isExpanded && (
          <motion.ul
            variants={container}
            initial="hidden"
            animate="visible"
            className="flex items-center -space-x-4">
            {detections.map((detection, index) => (
              <motion.li key={`${detection.detection}__${index}`} variants={item} className="min-w-[28px]" >

                <Tooltip content={`${detection.detection} (${detection.counting})`} >
                  <Avatar className="w-7 h-7 min-w-[28px] rounded-full flex items-center justify-center opacity-90 hover:opacity-100 hover:z-30 "
                    //@ts-ignore
                    src={detectionIcons[detection.detection]}
                  />
                </Tooltip>
              </motion.li>

            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </>
  );
}
