/**
 * Page component responsible for rendering the main page of the application, which
 * includes the @AnimatedBackground @ImageDrawer @VideoPlayer 
 * It handles file uploading, drag and drop events, and calls the API to retrieve 
 * image and video predictions.
 *
 * Props:
 * - test: An optional boolean flag to enable using sample data for video predictions. Default is false.
 *
 * Main functionalities:
 * - Handles file uploading with drag and drop events or file input.
 * - Determines the type of the uploaded file (image or video).
 * - Calls the API to retrieve predictions for image and video files.
 * - Displays predictions using ImageDrawer and VideoPlayer components.
 * - Manages error handling for the API calls and shows error messages.
 * - Provides a loading screen while waiting for the API response.
 */

// React
import React, { useContext, useEffect, useState } from 'react';
// external libraries
import { motion } from "framer-motion";
import { Toaster } from 'react-hot-toast';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import Joyride from 'react-joyride';
import { useLocation } from 'react-router-dom';

// data
import { classNames, getVideoDetectedClasses, } from '../utils';
// components
import { AnimatedBackground, ImageDrawer, Navbar, Sidebar, TourTooltip, VideoPlayer } from '../components';
import { PredictionsModal, ErrorModal, DownloadModal, ExamplesModal, FeedbackModal, FeedbackPrompt, FeedbackConfirmation } from '../components/Modals';
// Icons
import { ArrowUpTrayIcon, ArrowPathIcon, PhotoIcon, CubeIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import GridIcon from "../assets/icons/grid.svg"
// styles & animations
import { hideShow } from '../utils/animations';
import { usePredictions } from '../hooks/usePredictions';
import { HeroPattern } from '../components/HeroPattern';
// import '.../styles/App.css';
import { configureBucketCors, extractFrames, getFileType, getFromLocalStorage, parseUrlParameterToObject, shouldRunTour, updateTourProgress } from '../utils/helpers';
import { DragDropContext } from '../contexts/DragDropContext';
import { useFileLoader } from '../hooks/useFileLoader';
import DelimitedAreasModal from '../components/DelimitedAreasModal';
import { feedbackContext } from '../contexts/FeedbackContext';
import { PredictionsTutorial } from '../components/Tutorials';
import tutorialImg from "../assets/images/predTutorial.gif"
import { authContext, usePredictionsMenu, useRegionConfiguration } from '../hooks';
import { getTokenInfo } from '../services/API';
//------------------------------------------------------------------------------


interface DashboardProps {
  test?: boolean;
}

const steps = [

  {
    target: '.obtain_predictions',
    title: "Start Your AI Analysis",
    content: 'Customize your analysis by selecting different models that suit your needs, and let our advanced AI do the rest.',
    disableBeacon: true,
    placement: "bottom",
    image: tutorialImg,
    imageSize: "large"
  },
  {
    target: '.filter_menu',
    title: "AI-Powered Insights Menu",
    content: 'You can easily toggle individual items to filter and interact with the AI predictions for a more focused analysis.',
    disableBeacon: true,
    placement: "right"
  },
  // Add more steps as needed
];

//------------------------------------------------------------------------------
function Dashboard({ test = false }: DashboardProps) {
  const { displayBanner, setDisplayBanner } = useContext(feedbackContext)
  const { authUser } = useContext(authContext);
  // react hooks
  const [isDragActive, setIsDragActive] = useState(false);
  const [predictionsModal, setPredictionsModal] = useState(false)
  const [examplesModal, setExamplesModal] = useState(false)
  const [predictionError, setPredictionError] = useState<any>(null)
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)
  // feedback
  const [isFeedBackModalOpen, setIsFeedBackModalOpen] = useState(false)
  const [isFeedBackPromptOpen, setIsFeedBackPromptOpen] = useState(false)
  const [isFeedbackConfirmedDialog, setIsFeedbackConfirmedDialog] = useState(false)
  const [isRegionModalOpen, setIsRegionModalOpen] = useState(false)
  const [predictionsTutorialOpen, setPredictionsTutorial] = useState<boolean | null>(null)
  //tour guide

  const [runSecondTour, setRunSecondTour] = useState(false);
  const [warning, setWarning] = useState(false)// used to warn predictions is unavailable //!temp

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const data = queryParams.get('data'); // 'someData'
  const [isSampleLoaded, setIsSampleLoaded] = useState(false)
  const { setTracking } = usePredictionsMenu()
  const {
    getImagePredictions,
    getVideoPredictions,
    isLoading,
    isPreparing,
    imagePredictions,
    setImagePredictions,
    setVideoPredictions,
    videoPredictions,
    fileUrl,
    setFileUrl,
    selectedFile,
    setSelectedFile,
    processingStatus,
    handleDownloadVideo,
    handleDownloadImage,
    setDetectedClasses,
    handleTestEnvironment
  } = usePredictions(test)

  const {
    fileType, setFileType,
    originalImageSize, setOriginalImageSize,
    imageSize, setFileSize,
    previewFrames, setPreviewFrames,
    setVideoMetadata, loadSample,
    setStoredFileID
  } = useFileLoader()
  const { labelsPosition } = useContext(DragDropContext)

  const {
    setDelimitedAreas,
    setParkingLotAreas,
    updateVideoPredictionsWithAreas,
    updateVideoPredictionsWithParkingAreas,
    setAreasType, setSavedDelimitedArea, setSavedParkingArea
  } = useRegionConfiguration()

  const _test = async () => {
    try {
      // const response = await fetch('localhost:3333', {
      //   method: 'GET', // or 'POST' etc.
      //   headers: {
      //     'Content-Type': 'application/json',
      //     // Include any other headers your request needs
      //   }})

      // if (!response.ok) {
      //   throw new Error(`HTTP error! status: ${response.status}`);
      // }
      // // Assuming the response is JSON. Use response.text() if it's text.
      // const data = await response.json();
    await configureBucketCors()

      // const data = await getTokenInfo("do_7b6b95795305455b8f3958d5734f4f9c")
      console.log(data);
    } catch (error) {
      console.error("Fetch error: " + error.message);
    }
  }


  const [, drop] = useDrop({
    accept: NativeTypes.FILE,
    drop(item, monitor) {
      //@ts-ignore
      const droppedFiles = monitor.getItem().files;
      if (droppedFiles.length > 0) {
        handleFileUpload(droppedFiles[0]);
      }
      setIsDragActive(false);
      return undefined;
    },
    hover(item, monitor) {
      // You can perform actions when a compatible item is being hovered over the component
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });


  useEffect(() => {
    (async () => {
      if (data) {
        const { videoId } = parseUrlParameterToObject(data)
        const { predictions, video, videoDimensions } = loadSample(videoId)
        await handleLoadSample(predictions, video.src, videoDimensions)
        setIsSampleLoaded(true)
        const _detectedClasses = getVideoDetectedClasses(predictions) // return {'smoke', 'fire', 'person'}
        setDetectedClasses(_detectedClasses)
      }
    })()
  }, [data])

  useEffect(() => {
    if (isSampleLoaded) {
      const { dataArray, areasType } = parseUrlParameterToObject(data)

      if (areasType === "DEFAULT") {
        setAreasType("DEFAULT")
        setTracking(true)
        setSavedDelimitedArea(dataArray)
        setDelimitedAreas(dataArray)
        updateVideoPredictionsWithAreas(dataArray)
        localStorage.setItem("activeDelimitedAreaIdDraw", "-1");
        localStorage.setItem("delimitedAreas", "[]");
      } else {
        setAreasType("PARKING")
        setParkingLotAreas(dataArray)
        setSavedParkingArea(dataArray)
        updateVideoPredictionsWithParkingAreas(dataArray)
        // localStorage.setItem("activeDelimitedAreaIdDraw", "-1");
        // localStorage.setItem("parkingAreas", "[]");
      }
      // window.location.reload()
      setIsSampleLoaded(false)
      closeRegionModal()
    }
  }, [isSampleLoaded])

  useEffect(() => {
    if (predictionsTutorialOpen === null && selectedFile) {
      setPredictionsTutorial(true)
    }
  }, [selectedFile])

  useEffect(() => {
    (async () => {


      const savedFile = getFromLocalStorage("@saved_file")
      if (savedFile) {
        const { blob, type } = JSON.parse(savedFile) as { blob: string, type: "video" | "image" }
        setFileType(type)
        setFileUrl(blob)
      }

      const savedPredictions = getFromLocalStorage("@saved_predictions")
      if (savedPredictions) {
        console.log(savedPredictions)
      }
    })()
  }, [])

  const handleError = (err: any) => {
    console.log(err.message)
    console.log(err.json)
    console.log(err.status)

    switch (err.status) {
      case 400:
        setPredictionError({
          title: "Error Precessing video:",
          message: err.message
        })
        break

      case 401:
        setPredictionError({
          title: "Authorization error:",
          message: err.message
        })
        break
    }

  }

  const handleDownloadButton = async (classNames: string[], counting: string | null, delimitedAreas: any) => {
    setIsDownloadModalOpen(false)
    if (processingStatus === "processing") return
    if (imagePredictions) {
      await handleDownloadImage(imagePredictions, selectedFile, originalImageSize, classNames,labelsPosition, counting)
      return
    }
    const video = document.getElementById("video") as HTMLVideoElement;

    const frameRate = Math.round(videoPredictions.length / video.duration)
    await handleDownloadVideo(videoPredictions, selectedFile, imageSize, frameRate, classNames, counting, labelsPosition, delimitedAreas)
  }

  const handleConfirmFeedbackPrompt = () => {
    setIsFeedBackPromptOpen(false)
    setIsFeedBackModalOpen(true)
  }

  const handleConfirmFeedback = () => {
    setIsFeedBackModalOpen(false)
    setIsFeedbackConfirmedDialog(true)
    setDisplayBanner(false)
  }

  //file upload functions ------------------------------------------------------
  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    file && handleFileUpload(file);
  };

  const handleFileUpload = (file: File) => {
    // const file = event.target.files[0];
    if (isLoading) return


  // Check if the file type starts with 'image/'
  if (!file.type.startsWith('image/')) {
    alert('Please upload an image file.');
    return;
  }

    const fileType = getFileType(file)
    setFileType(fileType)
    setImagePredictions(null)
    setVideoPredictions(null)
    setStoredFileID(null)
    setFileUrl(URL.createObjectURL(file));
    // setFile(file)
    setSelectedFile(file)
    // create a new Image and Video object and load the uploaded file
    const img = new Image();
    const video = document.createElement('video');
    img.src = URL.createObjectURL(file);
    video.src = URL.createObjectURL(file);

    // Onload callback to get the width and height of the image
    img.onload = () => {
      const { width, height } = img;
      setOriginalImageSize({ width, height })
      setFileSize({ width, height })
    };

    // Onload metadata callback to get the width and height of the video
    video.onloadedmetadata = async () => {
      const { videoWidth: width, videoHeight: height } = video;
      setOriginalImageSize({ width, height })
      setFileSize({ width, height })
      setVideoMetadata({
        duration: video.duration,
        src: video.src,
        width: video.videoWidth,
        height: video.videoHeight,
      })

      // Extract frames from the video
      const numberOfFrames = 16; // Change this value to the desired number of frames
      const frames = await extractFrames(video, numberOfFrames);
      setPreviewFrames(frames)
    };

  };

  // Modals ----------------------------------------------------------------
  const openPredictionsModal = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setPredictionsModal(true)
  }

  const closePredictionsModal = () => {
    setPredictionsModal(false)
  }

  const openExamplesModal = () => {
    setPredictionsModal(false)
    setExamplesModal(true)
    setWarning(false)
  }

  const closeExamplesModal = () => {
    setExamplesModal(false)
  }

  const closeRegionModal = () => {
    setIsRegionModalOpen(false)
  }

  //  -------------------------------------------------------------------------

  /** Submit function that call @getImagePredictions or @getVideoPredictions */
  const handleSubmit = async (models: string[], tracking: boolean, token: string) => {
    if (!selectedFile) return
    // setWarning(true)
    setPredictionsModal(false)
    // return
    try {
      if (fileType === "image") {
        const pred = await getImagePredictions(models, token, selectedFile)
        //@ts-ignore
        setImagePredictions(pred)
        return
      }

      if (fileType === "video") {
        const pred = await getVideoPredictions(models, token, selectedFile)
        setVideoPredictions(pred as any)
        setDisplayBanner(true)
        return
      }
    } catch (err) {
      handleError(err)
    }
  };

  // const handleLoadSample = async (predictions: any, file: any, dimensions: any) => {
  //   const response = await fetch(file)
  //   const fileBlob = await response.blob()
  //   setSelectedFile(fileBlob)
  //   setFileUrl(file)
  //   setFileType("video")
  //   setOriginalImageSize(dimensions)

  //   const video = document.createElement('video');
  //   video.src = file;
  //   // Onload metadata callback to get the width and height of the video
  //   video.onloadedmetadata = async () => {
  //     const { videoWidth: width, videoHeight: height } = video;
  //     setOriginalImageSize({ width, height })
  //     setFileSize({ width, height })
  //     setVideoMetadata({
  //       duration: video.duration,
  //       src: video.src,
  //       width: video.videoWidth,
  //       height: video.videoHeight,
  //     })
  //     // Extract frames from the video
  //     const numberOfFrames = 16; // Change this value to the desired number of frames
  //     const frames = await extractFrames(video, numberOfFrames);
  //     setPreviewFrames(frames)
  //   };

  //   setVideoPredictions(predictions)
  //   setExamplesModal(false)

  // }

  const handleLoadSample = async (predictions, file, dimensions) => {
    try {
      // Fetch the video file and convert it to a blob
      const response = await fetch(file);
      if (!response.ok) throw new Error('Network response was not ok.');
      const fileBlob = await response.blob();
  
      // Set initial video information
      setSelectedFile(fileBlob);
      setFileUrl(file);
      setFileType("video");
      setOriginalImageSize(dimensions);
  
      // Create a video element to load and read metadata
      const video = document.createElement('video');
      video.src = URL.createObjectURL(fileBlob); // Use the blob URL for the video source
  
      // Load metadata promise to wait for the video metadata to be loaded
      await new Promise((resolve, reject) => {
        video.onloadedmetadata = resolve; // Resolve the promise when metadata is loaded
        video.onerror = reject; // Reject the promise if there is an error loading the video
      });
  
      // Now that metadata is loaded, set the video metadata and dimensions
      const { videoWidth: width, videoHeight: height } = video;
      setOriginalImageSize({ width, height });
      setFileSize({ width, height });
      setVideoMetadata({
        duration: video.duration,
        src: video.src,
        width,
        height,
      });
  
      // Optionally, extract frames from the video
      const numberOfFrames = 16; // Adjust as needed
      const frames = await extractFrames(video, numberOfFrames);
      setPreviewFrames(frames);
  
      // Set predictions and close the modal
      setVideoPredictions(predictions);
      setExamplesModal(false);
  
    } catch (error) {
      console.error('Error loading and processing the video:', error);
      // Handle error (e.g., show error message to the user)
    }
  };

  
  const handleJoyrideCallback = (data: any, tourKey: string) => {
    const { status } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (finishedStatuses.includes(status)) {
      updateTourProgress(tourKey);
    }
  };

  const handleAnimationComplete = () => {
    if (shouldRunTour('firstTourKey') && fileUrl) {
      setRunSecondTour(true)
    }
  }

  const handleTestBucket = async () => {
    // const fileRef = await uploadFileToGoogleCloud(selectedFile)
    // await configureBucketCors()
    // await uploadPredictionToFirebase(authUser?.uid ?? "default_user_id", "predictionsList", fileRef ?? "", originalImageSize);
    // try {
    //   const response = await fetch(`http://localhost:3333/api/user-files/${authUser?.uid}`);

    //   if (!response.ok) {
    //     throw new Error(`HTTP error! status: ${response.status}`);
    //   }
    //   const files = await response.json();
    //   console.log(files)
    //   return files;
    // } catch (error) {
    //   console.error("Could not fetch user files", error);
    //   // Handle errors appropriately in your app
    // }

    await handleTestEnvironment(selectedFile, authUser.uid)

  }


  const containerStyle = {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: isDragActive ? "rgba(33, 150, 243, 0.2)" : "transparent",
    top: 0,
    left: 0,
    zIndex: isDragActive ? 9999 : -1,
  };

  return (
    <div className="upload-wrapper"
      ref={drop}
      style={{ ...containerStyle, position: "fixed" }}
      onDragEnter={() => setIsDragActive(true)}
      onDragLeave={() => setIsDragActive(false)}
    >

      <Toaster
        position="top-right"
      />

      <Joyride
        //@ts-ignore
        steps={steps}
        run={runSecondTour}
        tooltipComponent={TourTooltip}
        callback={(data) => handleJoyrideCallback(data, 'firstTourKey')}
        styles={{
          options: {
            arrowColor: '#181818',
            backgroundColor: '#181818',
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            primaryColor: '#4D9DFA',
          }
        }}
      />

      <ErrorModal isOpen={warning} onClose={() => setWarning(false)} title={"Feature is unavailable"}>
        <p>Obtaining predictions is unavailable right now</p>
        <p>Select one of our <strong className="text-primary-500 hover:underline cursor-pointer" onClick={openExamplesModal} >samples</strong> instead.</p>
      </ErrorModal>

      <FeedbackPrompt
        isOpen={isFeedBackPromptOpen}
        onClose={() => setIsFeedBackPromptOpen(false)}
        onConfirm={handleConfirmFeedbackPrompt}
      />

      {isFeedBackModalOpen && (
        <FeedbackModal
          isOpen={isFeedBackModalOpen}
          onCancel={() => setIsFeedBackModalOpen(false)}
          onConfirm={handleConfirmFeedback}
        />
      )}



      <FeedbackConfirmation
        isOpen={isFeedbackConfirmedDialog}
        onClose={() => setIsFeedbackConfirmedDialog(false)}
      />

      {isRegionModalOpen && (
        <DelimitedAreasModal
          key={1}
          inBrowser={true}
          theme={"Light"}
          language={"English"}
          isOpen={isRegionModalOpen}
          onClose={closeRegionModal}
        />
      )}

      {predictionsModal && (
        <PredictionsModal
          detectionType={fileType ?? "image"}
          isOpen={predictionsModal && !isRegionModalOpen}
          onCancel={closePredictionsModal}
          onConfirm={handleSubmit}
          onSelectDelimitedArea={() => setIsRegionModalOpen(true)}
        />
      )}

      {examplesModal && (
        <ExamplesModal isOpen={examplesModal} onCancel={closeExamplesModal} onConfirm={handleLoadSample} />
      )}

      <ErrorModal
        title={predictionError ? predictionError.title : ""}
        isOpen={predictionError ? true : false}
        onClose={() => setPredictionError(null)} >
        <p>
          {predictionError?.message}
        </p>
      </ErrorModal>

      {isDownloadModalOpen && (
        <DownloadModal
          onSelectDelimitedArea={() => setIsRegionModalOpen(true)}
          preSelectedTab={1}
          imageSize={originalImageSize}
          isOpen={isDownloadModalOpen && !isRegionModalOpen}
          onCancel={() => setIsDownloadModalOpen(false)}
          onConfirm={handleDownloadButton}
        />
      )}
      <div className="h-screen w-full bg-[#eef0f3] dark:bg-[#141414] flex flex-col items-center overflow-hidden relative">

        {/* Background image */}
        <motion.div
          className="w-full h-screen absolute top-0 left-0 bg-neutral-100 dark:bg-[#121212] overflow-hidden"
          variants={hideShow()}
          animate={fileUrl ? "hidden" : "visible"}>
          {!fileUrl && (
            <>
              <AnimatedBackground />
              <HeroPattern />
            </>
          )}

          {/* <div className="w-24 h-24 absolute_center rounded-xl overflow-hidden flex items-center justify-center bg-cyan-300" >
            <div className="w-[94%]  h-[94%] flex items-center justify-center upload_button" >
              <ArrowUpTrayIcon className="w-8 h-8 text-cyan-500 " />
            </div>
          </div> */}
        </motion.div>

        <Navbar>
          {fileUrl && (
            <form onSubmit={openPredictionsModal} className={classNames("flex flex-col items-center text-neutral-600 dark:text-white  w-full", fileType ? "h-full" : "")} >
              <div className="flex items-center w-full h-full pl-10 " >
                <div className="form-group flex gap-4">
                  <label
                    htmlFor="file"
                    className={classNames(
                      "gradientButton text-xs  cursor-pointer px-2 rounded flex items-center h-6 ",
                      "bg-white border border-neutral-300",
                      "dark:bg-neutral-800 border dark:border-neutral-800",
                      "md:px-4 md:h-10 md:text-base sm:text-sm ",
                      "dark:hover:border-white duration-200",
                      "shadow hover:shadow-md"
                    )} >
                    <ArrowUpTrayIcon className="h-3 w-3 mr-1 md:h-4 md:w-4 text-cyan-500 " />
                    <PhotoIcon className="h-3 w-3 mr-3 md:h-4 md:w-4 text-cyan-500 " />
                    {fileUrl ? "Upload" : "Upload Image or Video"}
                  </label>
                  <input id="file" type="file" onChange={handleFileInputChange} placeholder="file" className="hidden" />

                  <button
                    type="button"
                    onClick={openExamplesModal}
                    className={
                      classNames(
                        "h-6 cursor-pointer py-2 px-2 dark:text-white rounded text-xs flex items-center disabled:cursor-default md:px-4 md:h-10 md:text-base sm:text-sm ",
                        "bg-white border border-neutral-300",
                        "dark:bg-neutral-800 border dark:border-neutral-800",
                        "dark:hover:border-white duration-200",
                        "shadow hover:shadow-md"
                      )}>
                    <img className="mr-2" src={GridIcon} alt="" />
                    Samples
                  </button>
                  {fileType === "video" && (
                    <button
                      type="button"
                      onClick={() => setIsRegionModalOpen(true)}
                      className={
                        classNames(
                          "h-6 cursor-pointer py-2 px-2 dark:text-white rounded text-xs flex items-center disabled:cursor-default md:px-4 md:h-10 md:text-base sm:text-sm ",
                          "bg-white border border-neutral-300",
                          "dark:bg-neutral-800 border dark:border-neutral-800",
                          "dark:hover:border-white duration-200",
                          "shadow hover:shadow-md"
                        )}>

                      <CubeIcon className="h-5 w-5 mr-2 md:h-6 md:w-6 text-[#F69C2D]" />
                      Region Configuration
                    </button>
                  )}

                  {/* <button onClick={handleTestBucket} >test</button> */}


                  <div className="form-group ">
                    {fileUrl && (
                      <button
                        type="submit"
                        disabled={!fileUrl || isLoading || isPreparing}
                        className={
                          classNames(
                            "h-6 cursor-pointer py-1 px-2 text-white rounded text-xs flex items-center disabled:cursor-default md:px-4 md:h-10 md:text-base sm:text-sm obtain_predictions",
                            fileUrl ? "bg-gradient-to-r from-cyan-500 to-blue-500 " : "bg-neutral-400 opacity-80")}>
                        {isLoading ? (
                          <>
                            <ArrowPathIcon className="animate-spin h-5 w-5 mr-3" />
                            <span className="">Loading...</span>
                          </>
                        ) : (
                          'Obtain Predictions'
                        )}
                      </button>
                    )}

                  </div>

                  <div className="mr-5" >
                    {(videoPredictions || imagePredictions) && (
                      <button
                        type='button'
                        onClick={() => setIsDownloadModalOpen(true)}
                        className={
                          classNames(
                            "h-6 cursor-pointer py-2 px-2 dark:text-white rounded text-xs flex items-center disabled:cursor-default md:px-4 md:h-10 md:text-base sm:text-sm ",
                            "bg-white border border-neutral-300",
                            "dark:bg-neutral-800 border dark:border-neutral-800",
                            "dark:hover:border-white duration-200",
                            "shadow hover:shadow-md"
                          )}>
                        <ArrowDownTrayIcon className="h-4 w-4 mr-2 md:h-5 md:w-5" />
                        Download
                      </button>
                    )}

                  </div>

                </div>

              </div>
            </form>
          )}

        </Navbar>

        {/* Header */}
        <motion.div
          className={classNames(
            "w-full   h-screen bg-transparent shadow-md shadow-primary-500 dark:text-white flex  items-center ",
            fileUrl ? "justify-between " : "flex-col pt-10 z-0"
          )}
          animate={{
            height: fileUrl ? 0 : "100vh"
          }}
          transition={{
            height: {
              duration: 0.5
            }
          }}
          onAnimationComplete={handleAnimationComplete}
        >

          {!fileUrl && (
            <form onSubmit={openPredictionsModal} className={classNames("flex flex-col items-center justify-center", fileType ? "h-full" : "")} >
              <div className="w-96 text-center flex flex-col items-center justify-center" >
                <div className="h-16 w-16 bg-primary-200 rounded-full flex items-center justify-center" >
                  <ArrowUpTrayIcon className="h-8 w-8 text-primary-500 " />

                </div>
                <h2 className="text-primary-500 font-bold text-3xl mt-4" >
                  Drag and drop images to upload them.
                </h2>
              </div>
              <h2 className=" font-bold text-lg mt-4" >
                Or
              </h2>

              <div className="flex items-center mt-8" >
                <div className="form-group flex gap-4">
                  <label
                    htmlFor="file"
                    className={classNames(
                      "gradientButton text-xs  cursor-pointer px-2 rounded flex items-center h-6 ",
                      "bg-white border border-neutral-300",
                      "dark:bg-neutral-800 border dark:border-neutral-800",
                      "md:px-4 md:h-10 md:text-base sm:text-sm ",
                      "dark:hover:border-white duration-200",
                      "shadow hover:shadow-md"

                    )}  >
                    <ArrowUpTrayIcon className="h-3 w-3 mr-1 md:h-4 md:w-4 text-cyan-500 " />
                    <PhotoIcon className="h-3 w-3 mr-3 md:h-4 md:w-4 text-cyan-500 " />
                    {"Upload"}
                  </label>
                  <input id="file" type="file" onChange={handleFileInputChange} placeholder="file" className="hidden" />

                  <button
                    type="button"
                    onClick={openExamplesModal}
                    // onClick={_test}
                    
                    className={
                      classNames(
                        "h-6 cursor-pointer py-2 px-2 rounded text-xs flex items-center disabled:cursor-default md:px-4 md:h-10 md:text-base sm:text-sm ",
                        "bg-white border border-neutral-300",
                        "dark:bg-neutral-800 border dark:border-neutral-800",
                        "dark:hover:border-white duration-200",
                        "shadow hover:shadow-md"
                      )}>
                    <img className="mr-2" src={GridIcon} alt="" />
                    Samples
                  </button>


                  {/* commented 23/11/2023 */}
                  {/* 
                  <div className="form-group ">
                    {fileUrl && (
                      <button
                        type="submit"
                        disabled={!fileUrl || isLoading || isPreparing}
                        className={
                          classNames(
                            "h-6 cursor-pointer py-1 px-2 text-white rounded text-xs flex items-center disabled:cursor-default md:px-4 md:h-10 md:text-base sm:text-sm ",
                            fileUrl ? "bg-gradient-to-r from-cyan-500 to-blue-500 " : "bg-neutral-400 opacity-80")}>
                        {isLoading ? (
                          <>
                            <ArrowPathIcon className="animate-spin h-5 w-5 mr-3" />
                            <span className="">Loading...</span>
                          </>
                        ) : (
                          'Obtain Predictions'
                        )}
                      </button>
                    )}


                  </div>

                  <div className="mr-5" >
                    {videoPredictions && (
                      <button
                        type='button'
                        onClick={() => setIsDownloadModalOpen(true)}
                        className={
                          classNames(
                            "h-6 cursor-pointer py-1 px-2 rounded text-xs flex items-center disabled:cursor-default md:px-4 md:h-10 md:text-base sm:text-sm ",
                            "bg-neutral-800 border border-neutral-800 text-white",
                            "hover:border-white duration-200")}>
                        <ArrowDownTrayIcon className="h-4 w-4 mr-2 md:h-5 md:w-5" />
                        Download
                      </button>
                    )}

                    {imagePredictions && (
                      <button
                        type='button'

                        onClick={() => setIsDownloadModalOpen(true)}
                        className={
                          classNames(
                            "h-6 cursor-pointer py-1 px-2 rounded text-xs flex items-center disabled:cursor-default md:px-4 md:h-10 md:text-base sm:text-sm ",
                            "bg-neutral-800 text-white border border-neutral-800",
                            "hover:border-white duration-200")}>
                        <ArrowDownTrayIcon className="h-4 w-4 mr-2 md:h-5 md:w-5" />
                        Download
                      </button>
                    )}
                  </div> */}

                </div>

              </div>

              <div className="mt-8 text-center text-gray-500 dark:text-gray-400" >
                <p className="font-bold" >Supported Formats</p>
                <div className="flex gap-4 text-sm mt-1" >
                  <span><strong>Images</strong> in .jpg, .png, .svg</span>
                  {/* <span><strong>Videos</strong> in .mov, .mp4</span> */}
                </div>

              </div>
            </form>
          )}

        </motion.div>

        {/* Body */}
        <motion.div
          className="w-full transform-gpu"
          initial={{
            height: 0
          }}
          animate={{
            height: fileUrl ? 900 : 0
          }}

          transition={{
            delay: 0.3,
            height: {
              duration: 0.2
            }
          }}>

          {fileType === "video" && (
            <Sidebar>
              <div className="flex w-full h-full overflow-x-hidden overflow-y-scroll custom-scrollbar" >
                {/* <VideoMenu isVisible /> */}
                <VideoPlayer
                  onFeedback={handleConfirmFeedbackPrompt}
                  imageSize={originalImageSize}
                  predictions={videoPredictions}
                  videoUrl={fileUrl ?? ""}
                  file={selectedFile}
                  previewFrames={previewFrames}
                />
              </div>
            </Sidebar>

          )}

          {fileType === "image" && (
            <Sidebar>
              <div className="w-full h-full flex flex-col items-center justify-center overflow-x-hidden overflow-y-scroll custom-scrollbar" >
                <ImageDrawer fileUrl={fileUrl ?? ""} predictions={imagePredictions} originalImageSize={originalImageSize} file={selectedFile} />
              </div>
            </Sidebar>

          )}

        </motion.div>
      </div>
    </div>

  );
}

export default Dashboard;