
/**
 * Prediction Context Provider
 * 
 * React context for managing and sharing region configuration data across the application.
 * The context provider component, `RegionConfigurationProvider`, is 
 * responsible for maintaining and updating this context data, which can be 
 * accessed and manipulated by its children components using the 
 * `regionConfigurationContext` object.
 * 
 */

import { createContext, useContext, useState, useEffect, ReactNode } from 'react';

//-----------------------------------------------------------------------------


//-----------------------------------------------------------------------------

// Type definition
interface RegionContextData {
  /**array of drawn areas*/
  delimitedAreas: DelimitedArea[]
  setDelimitedAreas: React.Dispatch<DelimitedArea[]>

  /**array of drawn parking lot areas */
  parkingLotAreas: DelimitedArea[]
  setParkingLotAreas: React.Dispatch<React.SetStateAction<DelimitedArea[]>>

  /**copy of delimited areas, if the cancel button is pressed or the modal is closed, 
    * it sets delimited areas with the previously saved values*/
  savedDelimitedArea: DelimitedArea[]
  setSavedDelimitedArea: React.Dispatch<DelimitedArea[]>

  /** same as saved delimited areas, if the cancel button is pressed or the modal is closed
    * it resets the parking areas*/
  savedParkingArea: DelimitedArea[]
  setSavedParkingArea: React.Dispatch<React.SetStateAction<DelimitedArea[]>>

  /**array of active areas ids */
  activeAreas: number[]
  setActiveAreas: React.Dispatch<React.SetStateAction<number[]>>

  areasType: "DEFAULT" | "PARKING"
  setAreasType: React.Dispatch<React.SetStateAction<"DEFAULT" | "PARKING">>
  displayedParkingAreas: {
    free: boolean;
    occupied: boolean;
}
setDisplayedParkingAreas: React.Dispatch<React.SetStateAction<{
  free: boolean;
  occupied: boolean;
}>>

hoveredInstance: number
setHoveredInstance: React.Dispatch<React.SetStateAction<number>>
}

type RegionConfigurationProviderProps = {
  children: ReactNode;
}

export type DelimitedArea = {
  COLOUR: number[]
  EVENT_TYPE: string
  ID: number
  POINTS: number[][]
  TARGET_CLASS_NAME: string
}
//-----------------------------------------------------------------------------

// Context
export const regionConfigurationContext = createContext<RegionContextData>({} as RegionContextData);

// Provider
export const RegionConfigurationProvider = ({ children }: RegionConfigurationProviderProps) => {
  const [delimitedAreas, setDelimitedAreas] = useState<DelimitedArea[]>([]);
  const [parkingLotAreas, setParkingLotAreas] = useState<DelimitedArea[]>([]);
  const [savedDelimitedArea, setSavedDelimitedArea] = useState<DelimitedArea[]>([])
  const [savedParkingArea, setSavedParkingArea] = useState<DelimitedArea[]>([])
  const [activeAreas, setActiveAreas] = useState<number[]>([])
  const [areasType, setAreasType] = useState<"DEFAULT" | "PARKING">("DEFAULT")
  const [displayedParkingAreas, setDisplayedParkingAreas] = useState({free: true,occupied: true,})
  const [hoveredInstance, setHoveredInstance] = useState(-1);
  
  // The context value to be provided to the consumers
  const value = {
    delimitedAreas, setDelimitedAreas,
    savedDelimitedArea, setSavedDelimitedArea,
    activeAreas, setActiveAreas,
    parkingLotAreas, setParkingLotAreas,
    areasType, setAreasType,
    savedParkingArea, setSavedParkingArea,
    displayedParkingAreas, setDisplayedParkingAreas,
    hoveredInstance, setHoveredInstance
  };

  // Provide the context value to the children components
  return (
    <regionConfigurationContext.Provider value={value} >
      {children}
    </regionConfigurationContext.Provider>
  );
};