/**
 * Custom Hook: useFileLoader
 * 
 * This custom hook provides utility functions and data related to predictions,
 * including API calls for fetching image and video predictions, processing and
 * downloading videos, and extracting video frames. The hook consumes the
 * `predictionContext` and `VideoProcessingContext` to manage and share state
 * across the application.
 */

//------------------------------------------------------------------------------
// react
import { useContext } from "react";
// libs
import { fileLoaderContext } from "../contexts/FileLoaderContext";
// data
import sample1 from "../assets/videos/sample.mp4"
import sample2 from "../assets/videos/fire_smoke.mp4"
import sample3 from "../assets/videos/smoke_vehicle.mp4"
import sample4 from "../assets/videos/parking.mp4"

import sampleData1 from "../assets/predictions/smoke&vehicles.json"
import sampleData2 from "../assets/predictions/sample2.json"
import sampleData3 from "../assets/predictions/sample1.json"
import sampleData4 from "../assets/predictions/parkingSample.json"
import { object as sampleData } from "../assets/predictions/sample3"
import { handleFormatVideoData, handleFormatVideoDataWithParkingStatus } from "../utils";
//------------------------------------------------------------------------------


const EXAMPLES = [
  {
    id: "vehicles",
    name: "Traffic",
    src: sample1,
    predictions: sampleData,
    dimensions: { width: 1280, height: 720 }
  },
  {
    id: "fire_smoke",
    name: "Fire and Smoke",
    src: sample2,
    predictions: sampleData2,
    dimensions: { width: 1280, height: 720 }
  },
  {
    id: "smoke_vehicles",
    name: "Smoke and Vehicles",
    src: sample3,
    predictions: sampleData3,
    dimensions: { width: 1280, height: 720 }
  },
  {
    id: "parking",
    name: "Parking lot",
    src: sample4,
    predictions: sampleData4,
    dimensions: { width: 1280, height: 720 }
  },
]

//------------------------------------------------------------------------------
interface VideoDimension {
  width: number;
  height: number;
}
//------------------------------------------------------------------------------

export function useFileLoader() {
  const pContext = useContext(fileLoaderContext)

  //----------------------------------------------------------

  const loadSample = (id: string) => {
    const modelPredictions = {} as any;
    const video = EXAMPLES.find((e) => e.id === id);

    let videoDimensions: VideoDimension = { width: 1280, height: 720 }
    let predictions = null as any

    try {
      switch (video.id) {
        case "smoke_vehicles":
          const _predictions1 = sampleData as any
          predictions = (handleFormatVideoData(_predictions1[0] as any))
          videoDimensions = { width: 1280, height: 720 }
          break;

        case "fire_smoke":
          const _predictions = sampleData2 as any

          Object.keys(_predictions).forEach((key) => {
            const modelPredictionData = _predictions[key][0]

            Object.keys(modelPredictionData).forEach((innerKey) => {
              if (!modelPredictions[innerKey]) {
                modelPredictions[innerKey] = {};
              }
              modelPredictions[innerKey] = {
                ...modelPredictions[innerKey],
                ...modelPredictionData[innerKey],
              };
            });
          });

          const f = handleFormatVideoData(modelPredictions as any)
          videoDimensions = { width: 1920, height: 1080 }
          predictions = f
          break;

        case "vehicles":
          const formattedSampleData3 = handleFormatVideoData(sampleData3 as any)
          predictions = formattedSampleData3
          console.log("formattedSampleData3", formattedSampleData3)
          console.log("sampleData3", sampleData3)
          videoDimensions = { width: 1280, height: 720 }
          break;

        case "parking":
          const formattedParkingSample = handleFormatVideoDataWithParkingStatus(sampleData4)
          predictions = formattedParkingSample
          videoDimensions = { width: 1920, height: 1080 }
          break

        default:
          break;
      }
      return { video, videoDimensions, predictions };

    } catch (err) {
      console.log("could not load sample data", err)
    }
  }

  // Example of extracted function for fire_smoke case
  function handleFireSmokePredictions(data: any) {
    // Your logic for processing fire_smoke predictions
  }


  return {
    ...pContext,
    loadSample
  };
}









