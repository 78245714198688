/**
 * Utility functions for formatting data
 */
import { FormattedPredictionType, VideoPredictionsType } from "../types/PredictionType";
import { getColorByClass } from "./classes";

type PredictionsResponse = typeof JSON

type ImagePredictionInput = {
  class: string;
  id: string;
  confidence: string;
  boundingBox: number[];
};

type VideoPredictionInput = {


}

const input = [
  {
    "person_1_0.9": [100, 150, 50, 60],
    "car_2_0.8": [200, 250, 80, 100],
  },
  {
    "person_3_0.95": [102, 152, 51, 61],
    "dog_4_0.75": [300, 350, 60, 80],
  }
];

const calculateIoU = (box1: any, box2: any) => {
  const [x_box1, y_box1, width_box1, height_box1] = box1;
  const [x_box2, y_box2, width_box2, height_box2] = box2;

  const x1_inter = Math.max(x_box1, x_box2);
  const y1_inter = Math.max(y_box1, y_box2);
  const x2_inter = Math.min(x_box1 + width_box1, x_box2 + width_box2);
  const y2_inter = Math.min(y_box1 + height_box1, y_box2 + height_box2);

  const width_inter = Math.max(0, x2_inter - x1_inter);
  const height_inter = Math.max(0, y2_inter - y1_inter);
  const area_inter = width_inter * height_inter;

  const area_box1 = width_box1 * height_box1;
  const area_box2 = width_box2 * height_box2;

  const area_union = area_box1 + area_box2 - area_inter;

  const IoU = area_inter / area_union;

  return IoU;
};

export const handleFormatImageData = (data: any) => {
  const transformedData = {};

  // Process each top-level key (e.g., file names or "model_predictions")
  Object.keys(data).forEach(topLevelKey => {
    const detectionsArray = data[topLevelKey][0]; // Assuming the first element contains the detections

    Object.keys(detectionsArray).forEach(detectionKey => {
      const detections = detectionsArray[detectionKey];

      // Directly assigning detections under the topLevelKey or another chosen category name
      transformedData[topLevelKey] = { ...transformedData[topLevelKey], ...detections };
    });
  });
  
  // Initialize a Map to hold the processed predictions. Maps are used for efficient lookup and update.
  const predictionsMap = new Map();
  // Define a threshold for IoU. Detections with IoU above this threshold are considered the same object.
  const iouThreshold = 0.5;

  // Iterate over each category in the provided data
  for (const category in transformedData) {
    // Iterate over each detection within the category
    Object.entries(transformedData[category]).forEach(([name, boundingBox]) => {
      // Split the detection name to extract class, ID, and confidence
      const parts = name.split('_');

      // Construct an object representing the current detection
      const obj = {
        class: parts[0], // The detected class
        id: `${parts[1]}`, // Unique ID for the detection
        confidence: parts[2], // Confidence score for the detection
        boundingBox, // Bounding box of the detection
      };

      // Flag to track if the current object was merged with an existing one
      let isUpdated = false;

      // Compare the current detection with existing ones in the predictionsMap
      predictionsMap.forEach((prediction: any, key: any) => {
        // Calculate the IoU between the current detection and existing ones
        const iou = calculateIoU(prediction.boundingBox, obj.boundingBox);

        // If IoU exceeds the threshold, merge the current detection with the existing one
        if (iou >= iouThreshold) {
          // Merge by adding the current class and confidence to the existing detection's classes array
          prediction.classes.push({ class: obj.class, confidence: obj.confidence });
          // Update the Map with the merged prediction
          predictionsMap.set(key, prediction);
          // Mark as updated
          isUpdated = true;
        }
      });

      // If the detection was not merged with an existing one, add it as a new entry in the Map
      if (!isUpdated) {
        predictionsMap.set(obj.id, {
          name,
          id: obj.id,
          classes: [{ class: obj.class, confidence: obj.confidence }],
          boundingBox,
        });
      }
    });
  }

  // Convert the Map of processed predictions to an array and return it
  return Array.from(predictionsMap.values());
};


// export const handleFormatImageData = (data: any) => {
//   const predictions = [];

//   for (const category in data) {
//     Object.entries(data[category]).forEach(([name, boundingBox]) => {
//       const parts = name.split('_');

//       let obj;

//       obj = {
//         class: parts[0],
//         id: `${parts[1]}`,
//         confidence: parts[2],
//       };

//       predictions.push({
//         name,
//         id: obj.id,
//         class: obj.class,
//         confidence: obj.confidence,
//         boundingBox,
//       });
//     });
//   }
//   return predictions;
// };

// export const handleFormatVideoData = (json: any[]) => {
//   const formattedFrames = {} as any;

//   for (const frame in json) {
//     const frameData = json[frame];
//     formattedFrames[frame] = { objects: [] };

//     for (const name in frameData) {
//       const boundingBox = frameData[name];
//       const parts = name.split('_');

//       const obj = {
//         class: parts[0],
//         id: `${parts[0].charAt(0).toLocaleUpperCase()}${parts[1]}`,
//         confidence: parts[2],
//       };

//       formattedFrames[frame].objects.push({
//         name,
//         id: obj.id,
//         class: obj.class,
//         confidence: obj.confidence,
//         boundingBox,
//         counting: parseInt(parts[1], 10),
//       });
//     }
//   }

//   return Object.values(formattedFrames);
// };

// export const handleFormatVideoData = (json: any[]) => {
//   const formattedFrames = {} as any;

//   for (const frame in json) {
//     const frameData = json[frame];
//     formattedFrames[frame] = { objects: [] };

//     for (const name in frameData) {
//       const boundingBox = frameData[name];
//       const parts = name.split('_');

//       const obj = {
//         class: parts[0],
//         id: `${parts[1]}`,
//         confidence: parts[2],
//       };

//       let existingObject = null;

//       for (const object of formattedFrames[frame].objects) {
//         const iou = calculateIoU(object.boundingBox, boundingBox);

//         if (iou > 0.5) {
//           existingObject = object;
//           break;
//         }
//       }

//       if (existingObject) {
//         existingObject.classes.push({
//           class: obj.class,
//           confidence: obj.confidence,
//         });
//       } else {
//         formattedFrames[frame].objects.push({
//           name: `${parts[0]}_${parts[1]}`,
//           id: obj.id,
//           boundingBox,
//           classes: [{ class: obj.class, confidence: obj.confidence }],
//         });
//       }
//     }
//   }

//   return Object.values(formattedFrames);
// };

/**
 * Organizes detections within video frames by formatting detection data
 * and merging detections based on Intersection over Union (IoU) calculations.
 * 
 * @param json An array of frame data where each frame contains detection objects
 * with their names indicating class, ID, and confidence.
 * @returns An array of objects, each representing a frame with organized detections.
 */
export const handleFormatVideoData = (json: any[]): any[] => {
  // json = input
  // Object to hold the formatted frames data
  const formattedFrames = {} as any;

  // Iterate over each frame in the input JSON
  for (const frame in json) {
    const frameData = json[frame];
    // Initialize the frame entry with an empty objects array
    formattedFrames[frame] = { objects: [] };

    // Process each detection in the frame
    for (const name in frameData) {
      const boundingBox = frameData[name];
      // Split the name to extract class, ID, and confidence
      const parts = name.split('_');
      const obj = {
        class: parts[0],
        id: `${parts[1]}`,
        confidence: parts[2],
      };

      // Attempt to find an existing object with a high IoU
      let existingObject = null;
      for (const object of formattedFrames[frame].objects) {
        const iou = calculateIoU(object.boundingBox, boundingBox);
        if (iou > 0.5) {
          existingObject = object;
          break;
        }
      }

      // If a similar object is found, merge the detection; otherwise, add a new object
      if (existingObject) {
        existingObject.classes.push({
          class: obj.class,
          confidence: obj.confidence,
        });
      } else {
        formattedFrames[frame].objects.push({
          name: `${parts[0]}_${parts[1]}`,
          id: obj.id,
          boundingBox,
          classes: [{ class: obj.class, confidence: obj.confidence }],
        });
      }
    }
  }

  // Return the organized data for each frame
  return Object.values(formattedFrames);
};

type Prediction = Record<string, [number, number, number, number]>;

export function extractPredictions(input: Record<string, any[]>): Prediction[] {
  const predictions: Prediction[] = [];

  // Iterate over the top-level keys in the input object, though we expect only 'generic_inference.yaml'
  for (const key of Object.keys(input)) {
    const items = input[key];
    
    // Iterate over each item in the 'generic_inference.yaml' array
    for (const item of items) {
      
      // Each 'item' can have multiple keys, but we're interested in those similar to file names (ignoring 'model_predictions' for the structure)
      for (const fileName of Object.keys(item).filter(k => k !== 'model_predictions')) {
        const fileData = item[fileName];
        
        // The first element of 'fileData' array contains the predictions
        const predictionData = fileData[0];

        // Extracting predictions
        const framePredictions: Prediction = {};
        for (const frame of Object.keys(predictionData)) {
          const objects = predictionData[frame];
          for (const objectKey of Object.keys(objects)) {
            framePredictions[objectKey] = objects[objectKey];
          }
        }

        // Adding the compiled predictions for the current file
        predictions.push(framePredictions);
      }
    }
  }

  return predictions;
}

export const flattenPredictions = (predictions: any) => {
  const modelPredictions = {} as any;
  try {
    Object.keys(predictions).forEach((key) => {
      const modelPredictionData = predictions[key][0]

      Object.keys(modelPredictionData).forEach((innerKey) => {
        if (!modelPredictions[innerKey]) {
          modelPredictions[innerKey] = {};
        }
        modelPredictions[innerKey] = {
          ...modelPredictions[innerKey],
          ...modelPredictionData[innerKey],
        };
      });
    });

    return modelPredictions;

  } catch (err) {
    console.log(err)

  }
}


export function handleFormatVideoPredictions(json: PredictionsResponse) {
  const formattedFrames = {};

  for (const model in json) {
    for (const frame in json[model]) {
      if (!formattedFrames[frame]) {
        formattedFrames[frame] = { objects: [] };
      }

      json[model][frame].forEach(obj => {
        const objectKey = Object.keys(obj)[0];
        formattedFrames[frame].objects.push({
          id: `${obj[objectKey].class.charAt(0).toLocaleUpperCase()}${obj[objectKey].id}`,
          name: `${obj[objectKey].class}_${obj[objectKey].id}`,
          confidence: obj[objectKey].confidence,
          class: obj[objectKey].class,
          boundingBox: obj[objectKey].bbox,
          counting: obj[objectKey].id
        });
      });
    }
  }
  return Object.values(formattedFrames);
}

export const handleFormatVideoDataWithParkingStatus = (json: any) => {
  const videoName = Object.keys(json)[0];
  const videoDataFrames = json[videoName][0];
  const formattedFrames = {} as any;

  for (const frame in videoDataFrames) {
    const frameData = videoDataFrames[frame];
    formattedFrames[frame] = { objects: [] };

    for (const name in frameData) {
      const boundingBox = frameData[name];

      // Ignore the bounding box if it is [-1, -1, -1, -1]
      if (JSON.stringify(boundingBox) === JSON.stringify([-1, -1, -1, -1])) {
        continue;
      }

      const parts = name.split(" \u2022 ");
      let vehicleName;
      let vehicleConfidence;
      let status;
      let objId;

      if (parts.length < 2) {
        const subparts = parts[0].split("_");
        vehicleName = subparts[0];
        vehicleConfidence = subparts[2];
        status = null;
        objId = subparts[1];
      } else {
        status = parts[0];
        const subparts = parts[1].split("_");
        vehicleName = subparts[0];
        vehicleConfidence = subparts[2];
        objId = subparts[1];
      }

      const obj = {
        class: vehicleName,
        id: `${vehicleName}_${objId}`,
        confidence: vehicleConfidence,
      };

      let existingObject = null;

      for (const object of formattedFrames[frame].objects) {
        const iou = calculateIoU(object.boundingBox, boundingBox);

        if (iou > 0.5) {
          existingObject = object;
          break;
        }
      }

      if (existingObject) {
        existingObject.classes.push({
          class: obj.class,
          confidence: obj.confidence,
        });
      } else {
        formattedFrames[frame].objects.push({
          name: `${obj.class}_${frame}`,
          id: obj.id,
          boundingBox,
          classes: [{ class: obj.class, confidence: obj.confidence }],
        });
      }
    }
  }

  return Object.values(formattedFrames);
}

export const organizeByClass = (predictions: FormattedPredictionType[]) => {
  const organizedPredictions: { [key: string]: FormattedPredictionType[] } = {};

  predictions.forEach((prediction) => {
    prediction.classes.forEach(({ class: predictionClass }) => {
      if (!organizedPredictions[predictionClass]) {
        organizedPredictions[predictionClass] = [];
      }
      organizedPredictions[predictionClass].push(prediction);
    });
  });

  return Object.entries(organizedPredictions).map(([predictionClass, predictions]) => ({
    class: predictionClass,
    predictions,
  }));
};


type MaskDataConfig = {
  enableCounting: boolean
  enableTracking: boolean

}

export const convertJsonToMaskData = (json: VideoPredictionsType, config?: MaskDataConfig) => {
  let maskData = [] as any;
  let frameCount = 0;

  json.forEach((data) => {
    const { objects } = data;
    let elements = [] as any;

    objects.forEach((obj) => {
      const { boundingBox, classes, id } = obj;
      const [x, y, w, h] = boundingBox;

      // Get classNames from the classes array
      const classNames = classes.map(({ class: className }) => className);

      elements.push({
        type: 'div',
        x,
        y,
        width: w,
        height: h,
        color: getColorByClass(classNames[0]), 
        label: [classNames[0]],
        id
      });
    });

    maskData.push({
      frame: frameCount,
      elements,
    });

    frameCount++;
  });

  return maskData;
};

export const convertJsonToImageMaskData = (objects: any[], config?: MaskDataConfig) => {
  let elements = [] as any;

  objects.forEach((obj) => {
    const { boundingBox, classes } = obj;
    const [x, y, w, h] = boundingBox;

    // Get classNames from the classes array
    const classNames = classes.map(({ class: className }) => className);

    elements.push({
      x,
      y,
      width: w,
      height: h,
      color: getColorByClass(classNames[0]),
      label: [classNames[0]]
    });
  });

  return elements
};


/**
 * Help function to format class names 
 * @param classes any
 * @returns Formatted className
 */
export function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

