/**
 * Animated pattern
 * Used on @Dashboard page
 */

import { motion } from 'framer-motion'

export function HeroPattern() {
  return (
    <svg id="hero_pattern" width="2000" height="700" viewBox="0 0 2000 700" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1038.62 245.512V178.288C1038.62 165.374 1050.39 154.906 1064.91 154.906H1086.28" stroke="url(#pulse-1)" strokeWidth="0.5" />
      <path d="M1133.94 0V131.524C1133.94 144.438 1122.17 154.906 1107.65 154.906H1086.28" stroke="url(#pulse-1)" strokeWidth="0.5" />
      <path d="M960.969 0L961.003 270" stroke="url(#pulse-1)" strokeWidth="0.5" />
      <path d="M748 228V258.649C748 271.546 764.714 282 785.333 282H979" stroke="url(#pulse-1)" strokeWidth="0.5" />
      <path d="M271.156 0V181.211C271.156 194.125 282.928 204.593 297.45 204.593H394.41" stroke="url(#pulse-1)" strokeWidth="0.5" />
      <path d="M747.736 227.976C747.736 215.062 735.965 204.594 721.442 204.594H369.758" stroke="url(#pulse-1)" strokeWidth="0.5" />
      <path d="M177 389V358.351C177 345.454 189.672 335 205.305 335H966" stroke="url(#pulse-1)" strokeWidth="0.5" />
      <path d="M177.483 385.805V409.187C177.483 422.101 165.711 432.569 151.189 432.569H1.64062" stroke="url(#pulse-1)" strokeWidth="0.5" />
      <path d="M379.622 581.629V616.702C379.622 629.616 367.85 640.084 353.328 640.084H0" stroke="url(#pulse-1)" strokeWidth="0.5" />
      <path d="M828.266 431.106V400.417C828.266 387.503 840.037 377.035 854.56 377.035H910.435" stroke="url(#pulse-1)" strokeWidth="0.5" />

      <path d="M379.625 581.628V477.87C379.625 464.956 391.397 454.488 405.919 454.488H461.794" stroke="url(#pulse-1)" strokeWidth="0.5" />
      <path d="M828.264 431.105C828.264 444.02 816.492 454.488 801.97 454.488H461.789" stroke="url(#pulse-1)" strokeWidth="0.5" />
      <path d="M1003 377L1002.05 629.852" stroke="url(#pulse-2)" strokeWidth="0.5" />
      <path d="M1631.88 628.394V523.175C1631.88 510.261 1620.11 499.793 1605.59 499.793H1533.28" stroke="url(#pulse-2)" strokeWidth="0.5" />
      <path d="M1631.88 628.391V647.389C1631.88 660.303 1643.65 670.771 1658.18 670.771H2000" stroke="url(#pulse-2)" strokeWidth="0.5" />
      <path d="M1247 431V400.351C1247 387.454 1231.32 377 1211.97 377H1039" stroke="url(#pulse-2)" strokeWidth="0.5" />
      <path d="M1247.33 431.105V476.408C1247.33 489.322 1259.1 499.79 1273.62 499.79H1533.28" stroke="url(#pulse-2)" strokeWidth="0.5" />
      <path d="M1670 393V358.2C1670 345.386 1657.16 335 1641.32 335H1039" stroke="url(#pulse-2)" strokeWidth="0.5" />
      <path d="M1669.68 390.188V409.185C1669.68 422.1 1681.45 432.567 1695.97 432.567H1829.09" stroke="url(#pulse-2)" strokeWidth="0.5" />
      <path d="M1906.33 325.888V306.89C1906.33 293.976 1918.09 283.508 1932.62 283.508H2000" stroke="url(#pulse-2)" strokeWidth="0.5" />
      <path d="M1906.33 325.887V409.185C1906.33 422.099 1894.56 432.567 1880.04 432.567H1797.87" stroke="url(#pulse-2)" strokeWidth="0.5" />
      <path d="M1829.09 0V169.52C1829.09 182.434 1817.32 192.902 1802.79 192.902H1725.55" stroke="url(#pulse-2)" strokeWidth="0.5" />
      <path d="M1193 216V246.649C1193 259.546 1171.4 270 1144.74 270H1006" stroke="url(#pulse-2)" strokeWidth="0.5" />
      <path d="M1193.1 216.284C1193.1 203.37 1204.87 192.902 1219.4 192.902H1725.56" stroke="url(#pulse-2)" strokeWidth="0.5" />
      <mask id="path-25-inside-1_495_87" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M1028.19 349.856L976.758 399.253L976.881 360.471L998.867 339.411L1009.99 329.213L1019.9 319.057C1023.76 314.664 1026.26 307.631 1026.26 299.706C1026.26 296.083 1025.74 292.646 1024.8 289.562C1033.14 294.847 1038.97 306.948 1038.97 321.031C1038.97 333.118 1034.68 343.746 1028.19 349.856Z" />
      </mask>
      <path d="M1028.19 349.856L1027.5 349.128L1027.49 349.135L1028.19 349.856ZM976.758 399.253L975.758 399.249L975.75 401.607L977.451 399.974L976.758 399.253ZM976.881 360.471L976.189 359.749L975.882 360.043L975.881 360.468L976.881 360.471ZM998.867 339.411L998.192 338.674L998.184 338.681L998.176 338.689L998.867 339.411ZM1009.99 329.213L1010.67 329.95L1010.69 329.931L1010.71 329.911L1009.99 329.213ZM1019.9 319.057L1020.61 319.756L1020.63 319.737L1020.65 319.717L1019.9 319.057ZM1024.8 289.562L1025.34 288.718L1023.06 287.277L1023.84 289.853L1024.8 289.562ZM1027.49 349.135L976.065 398.531L977.451 399.974L1028.88 350.577L1027.49 349.135ZM977.758 399.256L977.881 360.474L975.881 360.468L975.758 399.249L977.758 399.256ZM977.573 361.193L999.559 340.133L998.176 338.689L976.189 359.749L977.573 361.193ZM999.543 340.148L1010.67 329.95L1009.32 328.475L998.192 338.674L999.543 340.148ZM1010.71 329.911L1020.61 319.756L1019.18 318.359L1009.28 328.514L1010.71 329.911ZM1020.65 319.717C1024.7 315.104 1027.26 307.82 1027.26 299.706H1025.26C1025.26 307.443 1022.81 314.223 1019.15 318.397L1020.65 319.717ZM1027.26 299.706C1027.26 295.987 1026.72 292.452 1025.76 289.272L1023.84 289.853C1024.75 292.839 1025.26 296.178 1025.26 299.706H1027.26ZM1024.27 290.407C1032.23 295.453 1037.97 307.173 1037.97 321.031H1039.97C1039.97 306.723 1034.05 294.241 1025.34 288.718L1024.27 290.407ZM1037.97 321.031C1037.97 332.907 1033.75 343.247 1027.5 349.128L1028.87 350.584C1035.61 344.244 1039.97 333.329 1039.97 321.031H1037.97Z"  mask="url(#path-25-inside-1_495_87)" stroke="url(#pulse-2)" strokeWidth="0.5" />
      <path d="M1023.93 299.808C1023.93 307.962 1020.66 315.134 1015.7 319.432L1015.55 319.274L1015.1 318.824L1015.07 318.871L977.781 281.192V242.204L1016.87 281.16L1016.94 281.309L1016.97 281.383L1017.03 281.442C1021.24 285.811 1023.93 292.407 1023.93 299.808Z" stroke="url(#pulse-2)" strokeWidth="0.5" />
      <path d="M986.603 321.237C989.374 321.237 991.91 320.226 993.86 318.552C993.95 319.268 993.997 319.997 993.997 320.737C993.997 330.263 986.274 337.985 976.748 337.985C967.222 337.985 959.5 330.263 959.5 320.737C959.5 311.211 967.222 303.488 976.748 303.488C977.034 303.488 977.319 303.495 977.601 303.509C976.251 305.353 975.454 307.628 975.454 310.088C975.454 316.246 980.445 321.237 986.603 321.237Z" stroke="url(#pulse-1)" strokeWidth="0.5" />

      <defs>
        <motion.linearGradient
          animate={{
            x1: [0, 1700],
            x2: [0, 1000],
            y1: [700, 700 / 2],
            y2: [700 * 2, 700]
          }}
          transition={{
            duration: 10,
            repeat: Infinity,
            ease: 'linear'
          }}
          id="pulse-1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2084F9" stopOpacity="0" />
          <stop stopColor="#2084F9" />
          <stop offset="1" stopColor="#f142b6" stopOpacity="0" />
        </motion.linearGradient>

        <motion.linearGradient
          animate={{
            x1: [2000, 300],
            x2: [2500 , 500],
            y1: [700, 700 / 2],
            y2: [700 * 2, 700]
          }}
          transition={{
            duration: 10,
            repeat: Infinity,
            ease: 'linear'
          }}
          id="pulse-2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2084F9" stopOpacity="0" />
          <stop stopColor="#2084F9" />
          <stop offset="1" stopColor="#f142b6" stopOpacity="0" />
        </motion.linearGradient>

      </defs>
    </svg>
  )
}