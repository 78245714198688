/**
 * DelimitedAreasModal Component.
 * 
 * This component provides an interface for managing and interacting with delimited areas within a given region. 
 * It's used for defining, editing, and visualizing different areas like parking lots or general regions of interest. 
 * These areas can be used for various purposes such as surveillance or specific area monitoring in video feeds.
 * 
 * Features:
 * - Area Management: Allows adding, removing, duplicating, and clearing delimited areas.
 * - Dynamic Area Type Switching: Supports switching between different types of areas (DEFAULT, PARKING).
 * - Area Visualization: Offers a visual representation of defined areas and allows for direct manipulation.
 * - Tutorial Integration: Includes a tutorial section for user guidance.
 * - Localization Support: Supports multiple languages and dynamically updates based on user selection.
 * 
 *  @DelimitedAreasDraw - Renders visual representation of delimited areas.
 *  @DelimitedAreasList - Lists all delimited areas with management options.
 *  @RegionTutorial - Provides a user tutorial for region configuration.
 */

// React
import { useState, useEffect, Fragment } from "react";

// Libraries and Hooks
import useMeasure from 'react-use-measure';
import { useRegionConfiguration } from '../../hooks/useRegionConfiguration';

// Components/Icons
import DelimitedAreasDraw from '../DelimitedAreasDraw';
import DelimitedAreasList from '../DelimitedAreasList';
import { Dialog, Transition } from '@headlessui/react';
import { FaCaretDown, FaRegQuestionCircle } from "react-icons/fa";
import { BiShapeSquare, BiImageAdd } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri"
import { Tooltip, Typography, Menu, MenuItem } from '@material-tailwind/react';

// Styles and Assets
import styles from "./delimited_areas_modal.module.scss";
import variables from "../../styles/variables.module.scss";

// JSON Data
import json_delimited_areas_modal_en from "./delimited_areas_modal_en.json";
import json_delimited_areas_modal_pt from "./delimited_areas_modal_pt.json";

import { classNames, shouldRunTour, updateTourProgress } from '../../utils';
import {
    MenuHandler,
    MenuList,
} from "@material-tailwind/react";
import { DelimitedArea } from '../../contexts/ReagionConfigurationContext';
import { RegionTutorial } from '../Tutorials';

type propsType = {
    inBrowser: boolean; // Indicates whether the component is rendered on the client or server side.
    theme: string; // Specifies the current theme.
    language: string; // Sets the language for the component.
    isOpen: boolean; // Controls the visibility of the modal.
    onClose: () => void; // Function to be called on modal close.
}

function DelimitedAreasModal({ inBrowser, theme, language, isOpen, onClose }: propsType) {
    const [jsonData, setJsonData]: any = useState(language === "English" ? json_delimited_areas_modal_en : json_delimited_areas_modal_pt);
    const innerPadding = 20;
    const [rootDivRef, rootDivDimensions] = useMeasure();
    const [animateNewDelimitedAreaButton, setAnimateNewDelimitedAreaButton] = useState(0);
    const [animateClearAllButton, setAnimateClearAllButton] = useState(0);
    const [isTutorialOpen, setTutorialOpen] = useState(false)
    // const [hoveredInstance, setHoveredInstance] = useState(-1);
    const [copiedInstance, setCopiedInstance] = useState<DelimitedArea | null>(null)

    const {
        delimitedAreas,
        setDelimitedAreas,
        parkingLotAreas,
        setParkingLotAreas,
        areasType,
        setAreasType,
        savedDelimitedArea,
        setSavedDelimitedArea,
        setActiveAreas,
        updateVideoPredictionsWithAreas,
        savedParkingArea,
        setSavedParkingArea,
        updateVideoPredictionsWithParkingAreas,
        hoveredInstance,
    } = useRegionConfiguration()



    useEffect(() => {
        setJsonData(language === "English" ? json_delimited_areas_modal_en : json_delimited_areas_modal_pt);
    }, [language]);
    

    useEffect(() => {
        if(shouldRunTour('regionConfig')){
            setTutorialOpen(true)
        }
    }, [])

    const handleCloseTutorial = () => {
        setTutorialOpen(false)
        updateTourProgress("regionConfig")
    }


    const handleDuplicatePolygon = (item: DelimitedArea) => {
        // Step 1: Deep clone the item object
        const newItem = JSON.parse(JSON.stringify(item));

        // Step 2: Find the maximum ID in the existing delimitedAreas
        let maxID = 1
        if (areasType === "DEFAULT") {
            maxID = delimitedAreas.reduce((max: any, currItem: any) => Math.max(max, currItem.ID), 0);
        } else {
            maxID = parkingLotAreas.reduce((max: any, currItem: any) => Math.max(max, currItem.ID), 0);
        }

        // Set the new item's ID to be one greater than the maximum ID
        newItem.ID = maxID + 1;

        // Append "copy" to the end of the TARGET_CLASS_NAME, but only if it doesn't already end with "copy"
        if (!newItem.TARGET_CLASS_NAME.trim().endsWith('copy')) {
            newItem.TARGET_CLASS_NAME = `${newItem.TARGET_CLASS_NAME} copy`;
        }


        // Step 3: Slightly shift the POINTS on the X-axis. For example, shift by 50 units.
        newItem.POINTS = newItem.POINTS.map((point: number[]) => [point[0] + 50, point[1]]);
        console.log("newItem:", newItem)
        if (areasType === "DEFAULT") {
            //@ts-ignore Step 4: Update the state array 
            setDelimitedAreas((prevDelimitedAreas) => [...prevDelimitedAreas, newItem]);
        } else {
            setParkingLotAreas((prevDelimitedAreas) => [...prevDelimitedAreas, newItem])
        }
    };

    useEffect(() => {
        const handleKeyDown = (e: any) => {
            if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
                console.log("copy", hoveredInstance)
                if (hoveredInstance === -1) return
                console.log(parkingLotAreas)

                let instanceToBeCopied = null
                if (areasType === "DEFAULT") {
                    instanceToBeCopied = delimitedAreas.find((a) => a.ID === hoveredInstance) as DelimitedArea
                } else {
                    instanceToBeCopied = parkingLotAreas.find((a) => a.ID === hoveredInstance) as DelimitedArea
                    console.log(instanceToBeCopied)
                }
                //save a copy
                setCopiedInstance(instanceToBeCopied)

            } else if ((e.ctrlKey || e.metaKey) && copiedInstance && e.key === 'v') {
                //duplicate the copy
                handleDuplicatePolygon(copiedInstance)
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [delimitedAreas, parkingLotAreas, hoveredInstance, copiedInstance]);


    function changeDelimitedAreas(action: string, delimitedAreaId: number, delimitedArea: any) {
        // we have to clear every delimited area
        if (action === "clearAll") {
            setDelimitedAreas([]);
            localStorage.setItem("activeDelimitedAreaIdDraw", "-1");
            localStorage.setItem("delimitedAreas", "[]");
        }

        // we have to add a new delimited area
        else if (action === "add")
            //@ts-ignore
            setDelimitedAreas((prevDelimitedAreas: any) => [...prevDelimitedAreas, delimitedArea]);

        // we have to remove an existing delimited area
        else if (action === "remove")
            setDelimitedAreas(delimitedAreas.filter((item: any) => item["ID"] !== delimitedAreaId));

        // we have to update an existing delimited area
        else if (action === "update") {
            let delimitedAreasCopy = [...delimitedAreas];

            let delimitedAreaIndex = delimitedAreasCopy.indexOf(delimitedAreasCopy.filter((item: any) => item["ID"] === delimitedAreaId)[0])
            //@ts-ignore

            delimitedAreasCopy[delimitedAreaIndex] = delimitedArea;
            setDelimitedAreas(delimitedAreasCopy);
        }
    }

    function changeParkingAreas(action: string, delimitedAreaId: number, parkingArea: any) {
        // we have to clear every delimited area
        if (action === "clearAll") {
            setParkingLotAreas([]);
            localStorage.setItem("activeDelimitedAreaIdDraw", "-1");
            localStorage.setItem("delimitedAreas", "[]");
        }

        // we have to add a new delimited area
        else if (action === "add")
            //@ts-ignore
            setParkingLotAreas((prevParkingAreas: any) => [...prevParkingAreas, parkingArea]);

        // we have to remove an existing delimited area
        else if (action === "remove")
            setParkingLotAreas(parkingLotAreas.filter((item: any) => item["ID"] !== delimitedAreaId));

        // we have to update an existing delimited area
        else if (action === "update") {
            let parkingAreasCopy = [...parkingLotAreas];

            let parkingAreaIndex = parkingAreasCopy.indexOf(parkingAreasCopy.filter((item: any) => item["ID"] === delimitedAreaId)[0])
            //@ts-ignore

            parkingAreasCopy[parkingAreaIndex] = parkingArea;
            setParkingLotAreas(parkingAreasCopy);
        }
    }

    const handleChangeAreaType = (type: "DEFAULT" | "PARKING") => {
        setAreasType(type)
        if (type === "DEFAULT") {
            localStorage.setItem("activeDelimitedAreaIdDraw", "-1");
            localStorage.setItem("delimitedAreas", "[]");
        } else {
            localStorage.setItem("activeDelimitedAreaIdDraw", "-1");
            localStorage.setItem("parkingAreas", "[]");
        }
    }

    const onNewDelimitedAreaClick = () => {
        setAnimateNewDelimitedAreaButton(1);
        window.dispatchEvent(new Event("newDelimitedAreaButtonClick"));
    }

    const onClearAllClick = () => {
        setAnimateClearAllButton(1);
        setActiveAreas([])
        window.dispatchEvent(new Event("clearAllButtonClick"));
    }

    const handleConfirm = () => {
        if (areasType === "DEFAULT") {
            setSavedDelimitedArea(delimitedAreas)
            updateVideoPredictionsWithAreas(delimitedAreas)
        } else {
            setSavedParkingArea(parkingLotAreas)
            updateVideoPredictionsWithParkingAreas(parkingLotAreas)
        }
        onClose()
        return
    }

    const handleCancel = () => {
        if (areasType === "DEFAULT") {
            console.log("delimited areas",savedDelimitedArea)
            setDelimitedAreas(savedDelimitedArea)
            updateVideoPredictionsWithAreas(savedDelimitedArea)
        } else {
            setParkingLotAreas(savedParkingArea)
            updateVideoPredictionsWithParkingAreas(savedParkingArea)
        }
        onClose()
        return
    }

    function isAreaVisible(areasType: string) {
        return areasType === "DEFAULT"
            ? delimitedAreas.length > 0
            : parkingLotAreas.length > 0;
    }


    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-20 overflow-hidden" onClose={handleCancel}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900 dark:bg-black bg-opacity-75 dark:bg-opacity-70 " />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-neutral-900 text-left shadow-xl transition-all ">
                                    <div
                                        ref={rootDivRef}
                                        className={classNames(
                                            styles.root_div,
                                            "p-4 bg-white dark:bg-neutral-900 border dark:border-neutral-600",
                                            "min-w-[820px] w-[820px]",
                                            "hide-scrollbar"
                                        )}
                                        style={{
                                            // width: (parseInt(variables.maxWidth) * 0.9),
                                        }}>



                                        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white ml-4">Region Configuration</h3>
                                        <h3 className="text-md  text-gray-900 dark:text-gray-300 ml-4 mt-4 px-4 mb-4">Draw polygons to identify areas like <span className="dark:text-white text-neutral-900 font-bold" >roads</span>  or <span className="dark:text-white text-neutral-900  font-bold" >parking lots</span>  and get real-time data on vehicle counts and parking availability.</h3>
                                        <div className={styles.top_bar_div}>
                                            <div
                                                className={styles.top_bar_bottom_separator}
                                                style={{
                                                    opacity: (theme === "Light" ? "0.75" : "0.65"),
                                                    backgroundColor: (theme === "Light" ? variables.borderColourLightMode : variables.borderColourDarkMode),
                                                }} />

                                            <div
                                                className={styles.top_bar_title_div}
                                                style={{
                                                    color: (theme === "Light" ? variables.primaryColourLightMode : variables.primaryColourDarkMode)
                                                }}>

                                            </div>

                                            <div className={styles.buttons_div}>
                                                <Tooltip
                                                    className='bg-neutral-800'
                                                    content={
                                                        <div className="">
                                                            <Typography color="white" className="font-light">New delimited area</Typography>
                                                        </div>
                                                    }>
                                                    <div
                                                        className={classNames(
                                                            styles.new_delimited_area_div,
                                                            "dark:bg-neutral-800 bg-white border border-neutral-500"
                                                        )}
                                                        onClick={() => onNewDelimitedAreaClick()}
                                                        //@ts-ignore

                                                        animatenewdelimitedareabutton={animateNewDelimitedAreaButton}
                                                        onAnimationEnd={() => setAnimateNewDelimitedAreaButton(0)}
                                                        style={{
                                                            // backgroundColor: (theme === "Light" ? variables.accentColourLightMode : variables.accentColourDarkMode),
                                                        }}>
                                                        <BiShapeSquare className="w-5 h-5 text-cyan-500" />
                                                    </div>
                                                </Tooltip>


                                                {/* <div
                                                    className={styles.vertical_separator}
                                                    style={{
                                                        backgroundColor: (theme === "Light" ? variables.textColourLightMode : variables.textColourDarkMode),
                                                        opacity: (theme === "Light" ? "0.15" : "0.3"),
                                                    }} />

                                                <Tooltip
                                                    className='bg-neutral-800'
                                                    content={
                                                        <div className="">
                                                            <Typography color="white" className="font-light">Settings</Typography>
                                                        </div>
                                                    }>
                                                    <div
                                                        className={classNames(
                                                            styles.new_delimited_area_div,
                                                            "dark:bg-neutral-800 bg-white border border-neutral-500"
                                                        )}
                                                        onClick={() => handleOpenSettings()}
                                                        //@ts-ignore
                                                        animateclearallbutton={animateClearAllButton}
                                                        onAnimationEnd={() => setAnimateClearAllButton(0)}
                                                    >

                                                        <IoSettingsOutline className="text-white w-5 h-5" />
                                                    </div>
                                                </Tooltip> */}

                                                <div
                                                    className={styles.vertical_separator}
                                                    style={{
                                                        backgroundColor: (theme === "Light" ? variables.textColourLightMode : variables.textColourDarkMode),
                                                        opacity: (theme === "Light" ? "0.15" : "0.3"),
                                                    }} />

                                                <Tooltip
                                                    className='bg-red-200'
                                                    content={
                                                        <div className="">
                                                            <Typography color="red" className="font-light">Clear areas</Typography>
                                                        </div>
                                                    }>
                                                    <div
                                                        className={classNames(
                                                            styles.clear_all_div,
                                                            "border border-red-500",
                                                            ""
                                                        )}
                                                        onClick={() => onClearAllClick()}
                                                        //@ts-ignore
                                                        animateclearallbutton={animateClearAllButton}
                                                        onAnimationEnd={() => setAnimateClearAllButton(0)}
                                                        style={{
                                                            backgroundColor: (theme === "Light" ? variables.backgroundDeleteColourLightMode : variables.backgroundDeleteColourDarkMode),
                                                        }}>

                                                        <RiDeleteBin5Line className="text-red-500 w-5 h-5" />
                                                    </div>
                                                </Tooltip>

                                                <div
                                                    className={styles.vertical_separator}
                                                    style={{
                                                        backgroundColor: (theme === "Light" ? variables.textColourLightMode : variables.textColourDarkMode),
                                                        opacity: (theme === "Light" ? "0.15" : "0.3"),
                                                    }} />


                                                <Menu>
                                                    <MenuHandler>
                                                        <div
                                                            className={classNames(
                                                                // styles.new_delimited_area_div,
                                                                "dark:bg-neutral-900 dark:text-white text-gray-900 bg-white border border-neutral-500",
                                                                "rounded-md p-2 py-3 cursor-pointer",
                                                                " leading-4 flex gap-1"
                                                            )} >
                                                            <p className="dark:text-white text-gray-800" >
                                                                {areasType === "DEFAULT" ? "Road Marking" : "Parking Slot"}
                                                            </p>
                                                            <FaCaretDown className="dark:text-white text-gray-800" />
                                                        </div>
                                                    </MenuHandler>
                                                    <MenuList>
                                                        <MenuItem
                                                            onClick={() => handleChangeAreaType("DEFAULT")}>
                                                            Road Marking
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => handleChangeAreaType("PARKING")}
                                                        >Parking lot occupancy
                                                        </MenuItem>
                                                    </MenuList>
                                                </Menu>

                                                <FaRegQuestionCircle onClick={() => setTutorialOpen(true)} className="text-primary-500 absolute top-2 right-12 w-5 h-5 cursor-pointer" />

                                            </div>

                                            <div
                                                className={styles.close_button_div}
                                                //onClick = {() => handleExitClick()}
                                                style={{
                                                    visibility: "hidden",
                                                    backgroundColor: (theme === "Light" ? variables.accentColourLightMode : variables.primaryColourDarkMode)
                                                }}>
                                                <div
                                                    className={styles.close_button}
                                                    style={{
                                                        backgroundImage: jsonData.close_button_icon,
                                                    }} />

                                            </div>
                                        </div>

                                        {/* Mid (image) */}
                                        <div
                                            className={classNames(
                                                styles.draw_div,
                                                "hide-scrollbar"
                                            )}
                                            style={{
                                                padding: innerPadding + "px",
                                                paddingTop: "10px",
                                            }}>
                                            {areasType === "DEFAULT" ? (
                                                <DelimitedAreasDraw
                                                    inBrowser={inBrowser}
                                                    theme={theme}
                                                    language={language}
                                                    innerPadding={innerPadding}
                                                    rootDivDimensions={rootDivDimensions}
                                                    delimitedAreas={delimitedAreas}
                                                    changeDelimitedAreas={changeDelimitedAreas}
                                                />
                                            ) : (
                                                <DelimitedAreasDraw
                                                    inBrowser={inBrowser}
                                                    theme={theme}
                                                    language={language}
                                                    innerPadding={innerPadding}
                                                    rootDivDimensions={rootDivDimensions}
                                                    delimitedAreas={parkingLotAreas}
                                                    changeDelimitedAreas={changeParkingAreas}
                                                />
                                            )}
                                        </div>

                                        {/* Bottom (list) */}
                                        <div
                                            className={classNames(
                                                styles.list_div,
                                                "hide-scrollbar"
                                            )}
                                            style={{
                                                maskImage: (!isAreaVisible(areasType) ? "" : "linear-gradient(to bottom, transparent 0%, black 4px, black calc(100% - 4px), transparent 100%)"),
                                            }}>

                                            <div
                                                className={classNames(
                                                    styles.list_inner_div,
                                                "overflow-x-hidden overflow-y-scroll custom-scrollbar"
                                                )}
                                                style={{
                                                    display: (!isAreaVisible(areasType) ? "none" : "flex"),
                                                    visibility: (!isAreaVisible(areasType) ? "hidden" : "visible"),
                                                    opacity: (!isAreaVisible(areasType) ? "0.0" : "1.0"),
                                                }}>
                                                {areasType === "DEFAULT" ? (
                                                    <DelimitedAreasList
                                                        inBrowser={inBrowser}
                                                        theme={theme}
                                                        language={language}
                                                        delimitedAreas={delimitedAreas}
                                                        changeDelimitedAreas={changeDelimitedAreas}
                                                        isEditing={true}
                                                    />
                                                ) : (
                                                    <DelimitedAreasList
                                                        inBrowser={inBrowser}
                                                        theme={theme}
                                                        language={language}
                                                        delimitedAreas={parkingLotAreas}
                                                        changeDelimitedAreas={changeParkingAreas}
                                                        isEditing={true}
                                                    />
                                                )}

                                            </div>

                                            <div
                                                className={styles.no_delimited_areas_div}
                                                style={{
                                                    minHeight: 100,
                                                    display: (!isAreaVisible(areasType) ? "flex" : "none"),
                                                    visibility: (!isAreaVisible(areasType) ? "visible" : "hidden"),
                                                    opacity: (!isAreaVisible(areasType) ? "1.0" : "0.0"),
                                                    border: "1px dashed " + (theme === "Light" ? variables.borderColourLightMode : variables.borderColourDarkMode),
                                                }}>
                                                <div
                                                    className={styles.no_delimited_areas_text}
                                                    style={{
                                                        color: (theme === "Light" ? variables.textColourLightMode : variables.textColourDarkMode)
                                                    }}>
                                                    {jsonData.no_delimited_areas_text}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="sm:flex sm:flex-row-reverse pr-4 pb-4" >
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-gradient-to-r from-cyan-500 to-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                                onClick={handleConfirm}
                                            >
                                                Confirm
                                            </button>
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 dark:border-gray-500 bg-white dark:bg-neutral-600 dark:text-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </button>

                                        </div>

                                    </div>

                                    <RegionTutorial isOpen={isTutorialOpen} onClose={handleCloseTutorial} />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}

export default DelimitedAreasModal;