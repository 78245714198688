/**
 * Custom Hook: usePredictions
 * 
 * This custom hook provides utility functions and data related to predictions,
 * including API calls for fetching image and video predictions, processing and
 * downloading videos, and extracting video frames. The hook consumes the
 * `predictionContext` and `VideoProcessingContext` to manage and share state
 * across the application.
 */

//------------------------------------------------------------------------------
// react
import { useContext } from "react";
// libs
import request from 'superagent';
import axios from 'axios';
import toast from 'react-hot-toast';
// data
import { convertJsonToImageMaskData, convertJsonToMaskData, extractPredictions, getFileExtension, getImageDetectedClasses, getVideoDetectedClasses, handleFormatImageData, handleFormatVideoData, handleFormatVideoPredictions } from "../utils";
import jsonData from "../mock/test.json"
import jsonData2 from "../mock/sample2.json"
// contexts
import { predictionContext } from "../contexts/PredictionsContext";
import { VideoProcessingContext } from "../contexts/VideoProcessingContext";
import { VideoPredictionsType } from "../types/PredictionType";
import { database, storage } from "../services/firebase";
import { ref, push, query, orderByChild, get, child, set, remove, update } from 'firebase/database';
import { authContext } from "./useAuth";
import { ref as storageRef, uploadBytes, getDownloadURL, getBlob, deleteObject } from "firebase/storage";
import { fileLoaderContext } from "../contexts/FileLoaderContext";
import { TOKENS } from "../mock/token";
import { useRegionConfiguration } from "./useRegionConfiguration";
import { checkTaskStatus, startPredictionTaskAndPushData, uploadFileToGoogleCloud } from "../services/API";
//------------------------------------------------------------------------------
// const storage = new Storage({ keyFilename: '../services/simtest.json' });
// const bucket = storage.bucket('buss_test_bucket');

//------------------------------------------------------------------------------

let apiBaseUrl = process.env.REACT_APP_API_BASE_URL || "https://playgroundapi.deepneuronic.com"
let nodeAPIUrl = process.env.REACT_APP_NODE_API_URL || "https://playgroundapi-aux-prod.deepneuronic.com"
// nodeAPIUrl = "http://localhost:3333"
// apiBaseUrl = "http://51.124.206.137:8085"
//------------------------------------------------------------------------------


//TODO copy the upload functionality from test environment functions to production functions

export function usePredictions(test = false) {
  let INTERVAL = {} as any
  const pContext = useContext(predictionContext)
  const { originalImageSize, videoMetadata, setStoredFileID, storedFileID, setOriginalImageSize } = useContext(fileLoaderContext)
  const { authUser } = useContext(authContext);
  const { delimitedAreas, parkingLotAreas, areasType } = useRegionConfiguration()
  const {
    setIsLoading,
    setProgress,
    setEstimatedTime,
    selectedFile,
    setTokenInfo,
    setIsPreparing,
    setDetectedClasses,
    setMetadata,
    setMainToken,
    mainToken,
    tokenList
  } = pContext

  const { setProcessingStatus, processingStatus } = useContext(VideoProcessingContext);
  //----------------------------------------------------------

  /**
 * Simulates the progress of the API call by incrementing the progress state
 * variable based on the provided time.
 * @param {number} time - The total time for the progress simulation
 */
  const initiateProgressBar = (time: number) => {
    setIsLoading(true)
    setEstimatedTime(time)
    setProgress(0)
    console.log(time)
    const intervalDuration = time >= 10 ? 333 : 222;
    const progressIncrement = (intervalDuration / (time * 1000)) * 100;

    INTERVAL = setInterval(() => {
      setProgress((prevProgress) => {
        return (prevProgress + progressIncrement);
      });

    }, intervalDuration);
  };
  //----------------------------------------------------------

  /**
  * Fetches image predictions from the API.
  * @param {string[]} actions - The predictions to be used for predictions
  * @param {string} token - The API token
  * @param {any} file - The file to be processed
  * @returns {Promise<any>} - The formatted response with image predictions
  */
  const getImagePredictions = async (actions: string[], token: string, file: any): Promise<any> => {
    if (!file) return;
    setIsLoading(true)
    
    if (test) {
      initiateProgressBar(3.2)
      //@ts-ignore  Sample data 
      const sampleData = await handleImageTestEnvironment(file, authUser?.uid)
      setIsPreparing(false)
      return sampleData
    }

    try {
      const fileRef = storedFileID ?? await uploadFileToGoogleCloud(file);
      //initiate task and push initial data to firebase
      const { firebaseRecordRef, taskId, estimatedTime } = await startPredictionTaskAndPushData(file, fileRef, originalImageSize, actions,token, authUser?.uid )
      setIsLoading(true); //starts the loading bar
      initiateProgressBar(estimatedTime)
      setStoredFileID(fileRef)
      // Wait for a short interval before making the request
      initiateProgressBar(estimatedTime)
      // check task status and update data on firebase
      const { data: sampleData, status } = await checkTaskStatus(taskId, authUser?.uid, firebaseRecordRef?.key);
      console.log("sampleData", sampleData)

      const formattedResponse = handleFormatImageData(sampleData);
      const classes = getImageDetectedClasses(formattedResponse)
      setDetectedClasses(classes)
      console.log(classes)
      clearInterval(INTERVAL);
      setIsLoading(false);
      console.log("classes", classes)

      // set file dimensions
      const keys = Object.keys(sampleData);
      const firstKey = keys[0];
      setOriginalImageSize({ width: sampleData[firstKey][1], height: sampleData[firstKey][2] })
      console.log("formatted",formattedResponse )
      return formattedResponse;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };
  //----------------------------------------------------------

  /**
 * Fetches video predictions from the API.
 * @param {string[]} actions - The actions to be used for predictions
 * @param {string} token - The API token
 * @param {any} file - The file to be processed
 * @returns {Promise<any>} - The formatted response with video predictions
 */
  const getVideoPredictions = async (actions: string[], token: string, file: any): Promise<any> => {
    if (!file) throw new Error("Please upload a file first")
    setIsPreparing(true)
    console.log("actions:", actions)

    if (test) {
      //@ts-ignore  Sample data 
      const sampleData = await handleTestEnvironment(file, authUser.uid)
      setIsPreparing(false)
      return sampleData

    } else {
      try {
        const response = await new Promise(async (resolve, reject) => {
          // upload file
          const fileRef = storedFileID ?? await uploadFileToGoogleCloud(file);
          //initiate task and push initial data to firebase
          const { firebaseRecordRef, taskId, estimatedTime } = await startPredictionTaskAndPushData(file, fileRef, videoMetadata, actions, token, authUser?.uid)
          setIsLoading(true); //starts the loading bar
          setStoredFileID(fileRef)
          // Wait for a short interval before making the request
          initiateProgressBar(estimatedTime)
          await new Promise((resolve) => setTimeout(resolve, ((estimatedTime > 60 ? 60 : estimatedTime) - 10) * 1000));
          // check task status and update data on firebase
          const { data: sampleData, status } = await checkTaskStatus(taskId, authUser?.uid, firebaseRecordRef?.key);
          console.log("sampleData", sampleData)
          const flatData = flattenPredictions(sampleData);
          const formatted = handleFormatVideoData(flatData as any);
          setDetectedClasses(getVideoDetectedClasses(formatted))
          setMetadata(sampleData)
          setIsLoading(false);
          // log
          console.log("formatted data", formatted)

          resolve(formatted);
          clearInterval(INTERVAL);

        });

        return response;
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
        setIsPreparing(false);
      }
    }
  };
  //----------------------------------------------------------

  const handleTestEnvironment = async (file: any, userID: string) => {

    try {
      const fileRef = storedFileID ?? await uploadFileToGoogleCloud(file);
      const { firebaseRecordRef, taskId } = await startPredictionTaskAndPushData(file, fileRef, videoMetadata, [], "", userID, true)
      setIsLoading(true); //starts the loading bar
      setStoredFileID(fileRef)
      initiateProgressBar(30)

      const { data: sampleData, status } = await checkTaskStatus(taskId, userID, firebaseRecordRef.key, true);

      const flatData = flattenPredictions(sampleData);
      const formatted = handleFormatVideoData(flatData as any);
      setDetectedClasses(getVideoDetectedClasses(formatted))
      setMetadata(sampleData)
      setIsLoading(false);
      console.log("formatted data", formatted)
      return formatted;

    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };


  const handleImageTestEnvironment = async (file: any, userID: string) => {

    try {
      const fileRef = storedFileID ?? await uploadFileToGoogleCloud(file);
      const { firebaseRecordRef, taskId } = await startPredictionTaskAndPushData(file, fileRef, videoMetadata, [], "", userID,)
      setIsLoading(true);
      setStoredFileID(fileRef)
      initiateProgressBar(30)

      const { data: sampleData, status } = await checkTaskStatus(taskId, userID, firebaseRecordRef && firebaseRecordRef.key);

      setMetadata(sampleData)
      const formattedResponse = handleFormatImageData(sampleData);
      const classes = getImageDetectedClasses(formattedResponse)
      setDetectedClasses(classes)

      return formattedResponse;



    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };


  //----------------------------------------------------------

  /**
   * Processes and downloads the video with the provided predictions.
   * @param {VideoPredictionsType} predictions - The video predictions data
   * @param {any} file - The original video file
   * @param {any} imageSize - The dimensions of the video
   * @param {number} [frameRate=25] - The frame rate of the video
   * @param {string[]} selectedPredictions - The predictions to be processed
   * @param {string | null} counting - The predictions to be processed
   */
  const handleDownloadVideo = async (predictions: VideoPredictionsType, file: any, imageSize: any, frameRate: number = 25, selectedPredictions: string[], counting: string | null, side: string, delimitedAreas: any) => {
    const url = areasType === "DEFAULT" ? `${nodeAPIUrl}/api/process-video` : `${nodeAPIUrl}/api/process-video-parking`
    // const url =  `${nodeAPIUrl}/test`
    if (!predictions) return;
    setProcessingStatus("processing");

    const downloadVideoPromise = new Promise((resolve, reject) => {
      try {
        const maskData = convertJsonToMaskData(predictions);

        const req = request.post(url)
        req.field("fileName", JSON.stringify(storedFileID));
        req.field("fileExtension", getFileExtension(file.type));
        req.field("size", JSON.stringify(imageSize));
        req.field("maskData", JSON.stringify(maskData));
        req.field("frameRate", JSON.stringify(frameRate));
        req.field("selectedPredictions", JSON.stringify(selectedPredictions));
        req.field("side", JSON.stringify(side));
        req.field("delimitedAreas", JSON.stringify(delimitedAreas));
        if (counting) {
          req.field("counting", JSON.stringify(counting));
        } else {
          req.field("counting", JSON.stringify(false));
        }

        req.end((err, res) => {
          if (err) {
            console.error(err); // handle any errors
            setProcessingStatus("idle");
            reject(err);
          } else {
            const downloadLink = res.body.downloadLink;
            const tempLink = document.createElement('a');
            tempLink.href = downloadLink;
            tempLink.setAttribute('download', 'processed_video.mp4');
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            setProcessingStatus("done");
            resolve(res.body);
          }
        });
      } catch (err) {
        console.log("Error processing video", err);
        setProcessingStatus("idle");
        reject(err);
      }
    });

    toast.promise(downloadVideoPromise, {
      loading: 'Processing your video...',
      success: 'Downloading your video!',
      error: 'Error processing the video',
    },
      {
        success: {
          duration: 5000,
        }
      },);
  };

  /**
   * Processes and downloads the image with the provided predictions.
   * @param {any} predictions - The image predictions data
   * @param {any} file - The original image file
   * @param {any} imageSize - The dimensions of the image
   */
  const handleDownloadImage = async (predictions: any, file: any, imageSize: any, selectedPredictions: string[], side:string, counting: string | null) => {

    const url = `${nodeAPIUrl}/api/process-image`;
    if (!predictions) return;
    if (!file) return;

    setProcessingStatus("processing");

    const downloadImagePromise = new Promise((resolve, reject) => {
      try {
        const maskData = convertJsonToImageMaskData(predictions);

        const req = request.post(url);
        req.field("size", JSON.stringify(imageSize));
        req.field("maskData", JSON.stringify(maskData));
        req.field("selectedPredictions", JSON.stringify(selectedPredictions));
        req.field("side", JSON.stringify(side));
        req.field("fileRef", JSON.stringify(storedFileID));
        req.field("fileExtension", getFileExtension(file.type))
        if (counting) {
          req.field("counting", JSON.stringify(true));
        } else {
          req.field("counting", JSON.stringify(false));
        }

        req.end((err, res) => {
          if (err) {
            console.error(err); // handle any errors
            setProcessingStatus("idle");
            reject(err);
          } else {
            const downloadLink = res.body.downloadLink;
            const tempLink = document.createElement('a');
            tempLink.href = downloadLink;
            tempLink.setAttribute('download', 'processed_image.png');
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            setProcessingStatus("done");
            resolve(res.body);
          }
        });
      } catch (err) {
        console.log("Error processing image", err);
        setProcessingStatus("idle");
        reject(err);
      }
    });

    toast.promise(downloadImagePromise, {
      loading: 'Processing your image...',
      success: 'Downloading your image!',
      error: 'Error processing the image',
    },
      {
        success: {
          duration: 5000,
        }
      },);
  };
  //----------------------------------------------------------

  const resolvePredictionTask = async (_taskId: string): Promise<any> => {
    if (!_taskId) {
      setIsLoading(false);
      throw new Error("No task ID provided");
    }

    try {
      const req = request.get(apiBaseUrl + `/DeepNeuronicAPI/task/${_taskId}`);

      const response = await new Promise((resolve, reject) => {
        req.end((err: any, res: any) => {
          if (err) {
            reject(err);
          } else {
            const _response = res.body;
            resolve(_response);
          }
        });
      }) as any;

      console.log(response);

      switch (response.task_status) {
        case "PENDING":
        case "RETRY":
        case "RECEIVED":
        case "STARTED":
          // Wait for a short interval before making the request again
          await new Promise((resolve) => setTimeout(resolve, 1000));

          // Make the request again
          return await resolvePredictionTask(_taskId);

        case "SUCCESS":
          setIsLoading(false);
          const predictions = response.task_result.predictions;
          const modelPredictions = {} as any;

          Object.keys(predictions).forEach((key) => {
            const modelPredictionData = predictions[key][0].model_predictions[0];

            Object.keys(modelPredictionData).forEach((innerKey) => {
              if (!modelPredictions[innerKey]) {
                modelPredictions[innerKey] = {};
              }
              modelPredictions[innerKey] = {
                ...modelPredictions[innerKey],
                ...modelPredictionData[innerKey],
              };
            });
          });

          console.log("Model predictions:", modelPredictions)
          return modelPredictions;



        case "FAILURE":
        case "REVOKED":
          setIsLoading(false);
          setIsPreparing(false)
          throw new Error("Task failed or was revoked");

        default:
          setIsLoading(false);
          setIsPreparing(false)
          throw new Error("Invalid task status");
      }
    } catch (error) {
      setIsLoading(false);
      setIsPreparing(false)
      throw error;
    }
  };

  const flattenPredictions = (predictions: any) => {
    const modelPredictions = {} as any;
    try {
      Object.keys(predictions).forEach((key) => {
        const modelPredictionData = predictions[key][0]

        Object.keys(modelPredictionData).forEach((innerKey) => {
          if (!modelPredictions[innerKey]) {
            modelPredictions[innerKey] = {};
          }
          modelPredictions[innerKey] = {
            ...modelPredictions[innerKey],
            ...modelPredictionData[innerKey],
          };
        });
      });

      return modelPredictions;

    } catch (err) {
      console.log(err)

    }
  }


  const uploadPredictionToFirebase = async (userID: string, prediction: any, fileRef: string, dimensions: any) => {
    try {
      await push(ref(database, `users/${userID}/predictions/`), {
        json: JSON.stringify(prediction),
        detections: [],
        timeStamp: Date.now(),
        filePath: fileRef,
        dimensions,
        videoMetadata: videoMetadata
      })
    } catch (err) {
      console.log("error uploading to firebase", err)
    }
  }



  const getFileUrlFromBucket = async (path: string) => {
    try {
      const pathReference = await getDownloadURL(storageRef(storage, path));
      // const httpsReference = storageRef(storage, 'https://firebasestorage.googleapis.com/b/bucket/o/images%20stars.jpg')
      console.log(pathReference)
      return (pathReference)
    } catch (err) {
      console.log("error downloading", err)
    }

  }

  const listUserPredictions = async (userId: string) => {
    try {
      const response = await fetch(`${nodeAPIUrl}/api/user-files/${userId}`);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const files = await response.json();
      console.log(files)
      return files;
    } catch (error) {
      console.error("Could not fetch user files", error);
      // Handle errors appropriately in your app
    }
  }

  // TODO remove file from bucket
  const deletePredictions = async (id: string, fileRef: string) => {
    try {
      // Delete the file
      const predRef = ref(database, 'users/' + authUser?.uid + '/predictions/' + id)
      await remove(predRef)

      // handle remove file from bucket

      console.log("removed")
    } catch (err) {
      console.error("error deleting predictions", err)
    }


  }

  const uploadTokenToFirebase = async (userID: string, token: string, tokenName: string) => {
    const tokenInfo = verifyToken(token)

    try {
      if (!tokenInfo.exists) {
        toast("Invalid token")
        throw ("Invalid token")
      }

      await push(ref(database, `users/${userID}/tokens/`), {
        timeStamp: Date.now(),
        tokenName,
        token,
        totalCredits: tokenInfo.totalCredits,
        consumedCredits: tokenInfo.consumedCredits,
        isMain: tokenList.length === 0 ? true : false
      })
    } catch (err) {
      console.log(err)
      throw (err)
    }

  }

  const updateTokenName = async (userID: string, tokenID: string, tokenName: string) => {
    const dbRef = ref(database, `users/${userID}/tokens/${tokenID}`);
    await update(dbRef, {
      tokenName
    })
  }

  const setTokenAsMain = async (userID: string, tokenID: string) => {
    if (mainToken) {
      await update(ref(database, `users/${userID}/tokens/${mainToken.id}`), {
        isMain: false
      })
    }

    const dbRef = ref(database, `users/${userID}/tokens/${tokenID}`);
    await update(dbRef, {
      isMain: true
    })
  }

  const listUserTokensFromFirebase = async (userId: string) => {
    const dbRef = ref(database, `users/${userId}/tokens`);
    const conversionsRef = query(dbRef);
    const snapshot = await get(conversionsRef)

    if (snapshot.exists()) {
      const fireBaseObj = await snapshot.val()
      const formattedData = Object.entries(fireBaseObj).map(([key, value]) => ({
        id: key,
        // @ts-ignore
        ...value
      }));
      // Find the token with "isMain" property set to true
      const mainTokenFromFirebase = formattedData.find(token => token.isMain === true);

      // Update the mainToken state if found
      if (mainTokenFromFirebase) {
        setMainToken(mainTokenFromFirebase);
      }

      return formattedData
    } else {
      console.log("No data available");
      return null
    }
  }

  const verifyToken = (inputToken: string) => {
    const tokenEntry = TOKENS.find(t => t.token === inputToken);
    if (tokenEntry) {
      const { consumedCredits, totalCredits } = tokenEntry;
      return {
        exists: true,
        consumedCredits,
        totalCredits,
        remainingCredits: totalCredits - consumedCredits
      };
    } else {
      return {
        exists: false
      };
    }
  }

  // async function uploadFileToGoogleCloud(file) {
  //   try {
  //     // Get the signed URL from node server 
  //     const response = await fetch(`${nodeAPIUrl}/api/generate-signed-url`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ fileName: file.name, contentType: file.type })
  //     });

  //     if (!response.ok) {
  //       throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
  //     }

  //     const { url, fileID } = await response.json();

  //     console.log("url generated:", url, "fileID:", fileID);

  //     // Upload the file to Google Cloud Storage using the signed URL
  //     const uploadResponse = await fetch(url, {
  //       method: 'PUT',
  //       headers: {
  //         'Content-Type': file.type
  //       },
  //       body: file
  //     });

  //     if (!uploadResponse.ok) {
  //       throw new Error(`Upload failed: ${uploadResponse.status}`);
  //     }
  //     console.log('File uploaded successfully.');
  //     return fileID
  //   } catch (error) {
  //     console.error('Error during file upload:', error);
  //   }
  // }



  return {
    initiateProgressBar,
    getVideoPredictions,
    getImagePredictions,
    handleDownloadVideo,
    handleDownloadImage,
    handleTestEnvironment,
    // extractFrames,
    processingStatus,
    getFileUrlFromBucket,
    listUserPredictions,
    deletePredictions,
    uploadTokenToFirebase,
    listUserTokensFromFirebase,
    updateTokenName,
    setTokenAsMain,
    uploadPredictionToFirebase,
    checkTaskStatus,
    ...pContext
  };
}




