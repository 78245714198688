export const getAvaragePoint = (points : any) => {
    let totalX = 0;
    let totalY = 0;
    for (let i = 0; i < points.length; i += 2) {
      totalX += points[i];
      totalY += points[i + 1];
    }
    return {
      x: totalX / (points.length / 2),
      y: totalY / (points.length / 2),
    };
  };
  export const getDistance = (node1 : any, node2 : any) => {
    let diffX = Math.abs(node1[0] - node2[0]);
    let diffY = Math.abs(node1[1] - node2[1]);
    const distaneInPixel = Math.sqrt(diffX * diffX + diffY * diffY);
    return Number.parseFloat(distaneInPixel + "").toFixed(2);
  };
  export const dragBoundFunc = (stageWidth : any, stageHeight : any, vertexRadius : any, pos : any) => {
    let x = pos.x;
    let y = pos.y;
    if (pos.x + vertexRadius > stageWidth) x = stageWidth;
    if (pos.x - vertexRadius < 0) x = 0;
    if (pos.y + vertexRadius > stageHeight) y = stageHeight;
    if (pos.y - vertexRadius < 0) y = 0;
    return { x, y };
  };
  export const minMax = (points : any) => {
    return points.reduce((acc : any, val : any) => {
      acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];
      acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];
      return acc;
    }, []);
  };