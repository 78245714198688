/**
 * Used on @Forms
 */

import GoogleIcon from "../assets/images/Google.svg"
import { classNames } from "../utils";

interface GoogleButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isOutlined?: boolean
  hasIcon?: boolean
}

export function GoogleButton({ isOutlined, ...props }: GoogleButtonProps) {
  return (
    <>
      <button
        {...props}
        style={{ boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)" }}
        className={classNames(
          `
          mt-5 
          flex w-full 
          items-center 
          justify-center 
          rounded-lg border 
          py-3 
          px-8 
          text-base 
          font-medium 
          text-zinc-500 
          focus:outline-none focus:ring-2 
          focus:ring-offset-2
          duration-200
          ${props.className}
        `,
        isOutlined ? "bg-transparent border-white hover:bg-neutral-100/10 " : "bg-white border-transparent "
        )}
      >
        <img className="mr-2" src={GoogleIcon} width={24} height={24} alt="Google Logo" />
        {props.children}
      </button>

    </>
  );
}
