import randomColor from "randomcolor";
import { Stage, Layer, Image, FastLayer } from 'react-konva'
import styles from "./delimited_areas_draw.module.scss";
import React, { useRef, useState, useEffect } from 'react';
import variables from "../../styles/variables.module.scss";
// import CircularProgress from '@mui/material/CircularProgress';
import { isDelimitedAreaValid } from "../../utils/auxiliaryFunctions";
import DelimitedAreaEditorInstance from './DelimitedAreaEditorInstance';
import { PhotoIcon } from '@heroicons/react/24/outline'
import { useFileLoader } from "../../hooks/useFileLoader";
import { useRegionConfiguration } from "../../hooks/useRegionConfiguration";
import { classNames } from "../../utils";
import { AnimatePresence, motion } from "framer-motion";

// define this component's props types
type propsType = {
    inBrowser: boolean; // whether we are rendering in the client or server side
    theme: string; // the theme that is currently active
    language: string; // the chosen language
    innerPadding: number; // 
    rootDivDimensions: any; // 
    delimitedAreas: any;
    changeDelimitedAreas: any;
    // hoveredInstance: number
    // onHover: (instance: number) => void
}

console.log("DelimitedAreasDraw")

let image = null as any
const MAX_IMAGE_HEIGHT = 362

const DelimitedAreasDraw = ({ theme, innerPadding, rootDivDimensions, delimitedAreas, changeDelimitedAreas }: propsType) => {
    // Calculated dimensions
    const maxWidth = (rootDivDimensions.width - (innerPadding * 2));
    // Component state
    // const [image, setImage] = useState();
    const imageRef = useRef(null);
    const [imageDrawDimensions, setImageDrawDimensions] = useState({ "width": 0, "height": 0 });
    const [ogImageDimensions, setOgImageDimensions] = useState({ "width": 0, "height": 0 });

    const mousePositionRef = useRef([0, 0]);
    // const [mousePosition, setMousePosition] = useState([0, 0]);

    const [activeDelimitedAreaId, setActiveDelimitedAreaId] = useState(-1);
    const [mouseClicks, setMouseClicks] = useState(0);
    // const [freshDelimitedAreaCounter, setFreshDelimitedAreaCounter] = useState(0);
    const [defaultAreaCounter, setDefaultAreaCounter] = useState(0);
    const [parkingAreaCounter, setParkingAreaCounter] = useState(1000);

    const [isActive, setIsActive] = useState(false);
    const [render, setRender] = useState(false);
    const [isTooltipDisplayed, setTooltip] = useState(false);
    const [isFirstInteraction, setFirstInteraction] = useState(true);

    // Using custom hooks
    const { previewFrames } = useFileLoader();
    const { areasType, hoveredInstance, setHoveredInstance } = useRegionConfiguration();

    // useEffect to hide the element after a delay
    useEffect(() => {
        let timer: any;
        if (isTooltipDisplayed) {
            timer = setTimeout(() => {
                setTooltip(false);
            }, 5000); // Adjust time as needed (5000ms = 5 seconds)
        }
        return () => clearTimeout(timer);
    }, [isTooltipDisplayed]);

    // Handle events related to area drawing and clearing areas
    useEffect(() => {
        // Find the maximum ID for delimited areas
        let biggestDelimitedAreaID = -1;
        for (let i = 0; i < delimitedAreas.length; i++) {
            if (delimitedAreas[i]["ID"] > biggestDelimitedAreaID)
                biggestDelimitedAreaID = delimitedAreas[i]["ID"];
        }

        const defaultMaxID = delimitedAreas
            .reduce((max: any, area: any) => Math.max(max, area.ID), -1);

        const parkingMaxID = delimitedAreas
            .reduce((max: any, area: any) => Math.max(max, area.ID), -1);

        setDefaultAreaCounter(defaultMaxID + 1);
        setParkingAreaCounter(parkingMaxID + 1);

        // setFreshDelimitedAreaCounter(biggestDelimitedAreaID + 1 + (areasType === "DEFAULT" ? 0 : 1000));

        function newDelimitedAreaButtonClick() {
            onNewDelimitedAreaClick();
        }
        function clearAllButtonClick() {
            onClearAllClick();
        }

        // Add event listeners
        window.addEventListener('newDelimitedAreaButtonClick', newDelimitedAreaButtonClick);
        window.addEventListener('clearAllButtonClick', clearAllButtonClick);

        // Cleanup when component is unmounted
        return () => {
            window.removeEventListener('newDelimitedAreaButtonClick', newDelimitedAreaButtonClick);
            window.removeEventListener('clearAllButtonClick', clearAllButtonClick);
        }
    }, [theme, delimitedAreas]);

    // Load and setup the preview image
    useEffect(() => {
        (async () => {
            const imageElement = new window.Image();
            imageElement.src = previewFrames[0];

            imageElement.onload = () => {
                // Set the original dimensions of the image
                setOgImageDimensions({
                    "width": imageElement.width,
                    "height": imageElement.height,
                });

                // Calculate the dimensions for the image display area
                let maxHeight = MAX_IMAGE_HEIGHT;
                let possibleWidth = maxWidth;
                let possibleHeight = maxWidth * (imageElement.height / imageElement.width);
                if (possibleHeight > maxHeight) {
                    possibleHeight = maxHeight;
                    possibleWidth = (imageElement.width / imageElement.height) * maxHeight;
                }
                setImageDrawDimensions({
                    "width": possibleWidth,
                    "height": possibleHeight,
                });
                //@ts-ignore
                // setImage(imageElement);
                image = imageElement
                //@ts-ignore
                imageRef.current = imageElement;
            };
        })();

    }, [previewFrames, isActive, delimitedAreas, imageRef]);

    useEffect(() => {
        if (isActive) {
            setTimeout(() => {
                setRender(true);
            }, 0);
        }
        setIsActive(true);
    }, [isActive]);

    // Handle mouse movements and clicks on the canvas
    const handleMouseMove = (e: any) => {
        e.target.getStage().container().style.cursor = "pointer";
        const stage = e.target.getStage();
        mousePositionRef.current = [stage.getPointerPosition().x, stage.getPointerPosition().y];
    }

    const handleMouseDown = (e: any) => {
        setMouseClicks(mouseClicks + 1);
    }

    // Add a new delimited area
    const onNewDelimitedAreaClick = () => {
        if (isFirstInteraction) {
            setFirstInteraction(false)
            setTooltip(true)
        }

        const toRGBA = (color: string) => {
            const { style } = new Option();
            style.color = color;
            return (style.color.replace("rgb", "").replace("(", "").split(", ").map((item: any) => parseInt(item)).concat([1.0]));
        }

        // Validation: Check and remove any invalid delimited areas
        for (let i = 0; i < delimitedAreas.length; i++) {
            if (!isDelimitedAreaValid(delimitedAreas[i])) {
                changeDelimitedAreas("remove", delimitedAreas[i]["ID"], delimitedAreas[i]);
                window.dispatchEvent(new Event("delimitedAreaFragmentColourShouldUpdate"));
            }
        }

        // Add the new delimited area with a random color
        changeDelimitedAreas(
            "add",
            -1,
            {
                "ID": areasType === "DEFAULT" ? defaultAreaCounter : parkingAreaCounter,
                "EVENT_TYPE": "",
                "TARGET_CLASS_NAME": (areasType === "DEFAULT" ? "transgressing-" + (defaultAreaCounter + 1) : "area-" + (parkingAreaCounter + 1)) + " • CLASS",
                "POINTS": [],
                "COLOUR": areasType === "DEFAULT" ? toRGBA(randomColor()) : [64, 249, 103, 1]
            }
        );
        setActiveDelimitedAreaId(areasType === "DEFAULT" ? defaultAreaCounter : parkingAreaCounter);
        setHoveredInstance(areasType === "DEFAULT" ? defaultAreaCounter : parkingAreaCounter)

        // localStorage.setItem("activeDelimitedAreaIdDraw", freshDelimitedAreaCounter + "");
        // window.dispatchEvent(new Event("activeDelimitedAreaIdDrawChange"));
        //@ts-ignore
        mousePositionRef.current = [null, null];
    }

    // Clear all delimited areas
    const onClearAllClick = () => {
        changeDelimitedAreas("clearAll", null, {});
        //@ts-ignore
        mousePositionRef.current = [null, null];
    }

    return (
        <div className={classNames(
            styles.root_div,
            "relative"
        )}>
            <div className={classNames(
                styles.draw_div,
                "relative"
            )}>
                {image && (
                    <Stage width={imageDrawDimensions["width"]} height={imageDrawDimensions["height"]} onMouseMove={handleMouseMove} onMouseDown={handleMouseDown}>
                        <Layer listening={false}>
                            <Image ref={imageRef} image={image} x={0} y={0} width={imageDrawDimensions["width"]} height={imageDrawDimensions["height"]} />
                        </Layer>
                        {render && delimitedAreas.map((delimitedArea: any) => (
                            <Layer>
                                <DelimitedAreaEditorInstance
                                    key={delimitedArea["ID"]}
                                    mousePositionRef={mousePositionRef}
                                    delimitedAreaId={delimitedArea["ID"]}
                                    delimitedArea={delimitedArea}
                                    changeDelimitedAreas={changeDelimitedAreas}
                                    stageDimensions={imageDrawDimensions}
                                    mouseClicks={mouseClicks}
                                    ogImageDimensions={ogImageDimensions}
                                    imageDrawDimensions={imageDrawDimensions}
                                    hoveredInstance={delimitedArea["ID"] === hoveredInstance}
                                    onHover={(i) => setHoveredInstance(i)}
                                />
                            </Layer>
                        ))}
                    </Stage>
                )}

                <AnimatePresence>
                    {isTooltipDisplayed && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 1 }} // Adjust animation duration as needed
                            className={classNames(
                                "absolute text-white bg-gray-800 text-sm rounded-xl px-2 py-1 opacity-80",
                                "top-2 select-none left-1/2 -ml-52"
                            )}
                        >
                            Click on the image to start drawing. Each click adds a new corner.
                        </motion.div>
                    )}
                </AnimatePresence>

            </div>
        </div>
    );
}

export default React.memo(DelimitedAreasDraw);



