
//@ts-nocheck

import { Dialog, Disclosure, Listbox, Transition } from '@headlessui/react';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { classNames, dataURLToBlob, getClassIconById, getColorStyles, getFrameAsImage, updateTourProgress } from '../../utils';
import { useFileLoader } from '../../hooks/useFileLoader';
import { Frame } from '../../types/PredictionType';
import { usePredictions } from '../../hooks/usePredictions';
import { Menu, MenuHandler, MenuItem, MenuList, Tooltip, Typography } from '@material-tailwind/react';
import { MENU_ITEMS } from '../../mock/menu';
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { FEEDBACK_PREDICTIONS_LIMIT } from '../../constants';
import { MdFeedback } from "react-icons/md"
import { useAuth } from '../../hooks/useAuth';
import { Circle, Image, Layer, Rect, Stage, Text, Transformer } from 'react-konva';
import EditIcon from "../../assets/icons/edit.svg"
import MoveIcon from "../../assets/icons/move.svg"
import Joyride from 'react-joyride';
import { TourTooltip } from '../TourTooltip';
import feedbackLabel from "../../assets/images/feedback_label.gif"
import feedbackShape from "../../assets/images/feedback_shape.gif"

let nodeAPIUrl = process.env.REACT_APP_NODE_API_URL || "https://nodeserverdn.azurewebsites.net"
nodeAPIUrl = "http://localhost:80"

interface FeedbackModalProps {
  isOpen?: boolean
  onCancel: () => any
  onConfirm: () => any
}

type ImageSizeObject = {
  width: number
  height: number
}

const steps = [

  {
    target: '.edit_shapes',
    title: "Refine Detection Areas",
    content: 'You can fine-tune the accuracy of our AI detections in this interactive panel. Click and drag the corners of the bounding boxes to adjust their size and shape around the detected objects in the image.',
    disableBeacon: true,
    placement: "right",
    image:feedbackShape,
    imageSize: "large"
  },
  {
    target: '.edit_predictions',
    title: "Correct Detection Labels",
    content: 'If you  notice any label is incorrect you can simply change it to the most accurate term.',
    disableBeacon: true,
    placement: "left",
    image:feedbackLabel,
    imageSize: "small"
  },
  // Add more steps as needed
];


const flattenedItems = MENU_ITEMS.reduce((items, category) => {
  //@ts-ignore
  return items.concat(category.items);

}, []);

const getNodePositions = (shape: any) => {
  // Calculate the positions of the four corners
  // Assuming no rotation for simplicity. If your shapes can rotate,
  // you'll need to calculate these positions using rotation matrices
  return [
    { x: shape.boundingBox[0], y: shape.boundingBox[1] }, // Top-left
    { x: shape.boundingBox[0] + shape.boundingBox[2], y: shape.boundingBox[1] }, // Top-right
    { x: shape.boundingBox[0] + shape.boundingBox[2], y: shape.boundingBox[1] + shape.boundingBox[3] }, // Bottom-right
    { x: shape.boundingBox[0], y: shape.boundingBox[1] + shape.boundingBox[3] }, // Bottom-left
  ];
};

const getEdgeHandlePositions = (shape: any) => {
  const [x, y, width, height] = shape.boundingBox;
  const handleWidth = 20; // Width of the edge handles

  return {
    top: { x: x, y: y - handleWidth / 2, width: width, height: handleWidth },
    right: { x: x + width - handleWidth / 2, y: y, width: handleWidth, height: height },
    bottom: { x: x, y: y + height - handleWidth / 2, width: width, height: handleWidth },
    left: { x: x - handleWidth / 2, y: y, width: handleWidth, height: height },
  };
};

const EdgeHandle = ({ x, y, width, height, onDragMove }: any) => {
  return (
    <Rect
      className="cursor-pointer"
      x={x}
      y={y}
      width={width}
      height={height}
      fill="red" // Invisible or semi-transparent
      draggable
      onDragMove={onDragMove}
    />
  );
};


const CustomNode = ({ x, y, onDragMove, onDragEnd }: any) => {
  const [savedX, saveX] = useState()

  useEffect(() => {
    saveX(x)
  }, [])

  return (
    <Circle
      x={x}
      y={y}
      radius={4} // Customize as needed
      fill="rgba(15,170,218,0.9)" // Customize as needed
      draggable
      stroke={"rgb(27,105,199)"}
      onDragMove={(e) => onDragMove(e, savedX)}
      onDragEnd={onDragEnd}
    />
  );
};

const KonvaButton = ({ x, y, width, height, text, onClick, iconSrc, fillColor, stageRef }: any) => {
  const [icon, setIcon] = useState(null);

  const loadImage = (src: any, callback: any) => {
    const img = new window.Image();
    img.src = src;
    img.onload = () => callback(img);
  };


  useEffect(() => {
    loadImage(iconSrc, setIcon);
  }, [iconSrc]);

  const handleMouseEnter = () => {
    // Change cursor to pointer
    const stage = stageRef.current;
    if (stage) {
      stage.container().style.cursor = 'pointer';
    }
  };

  const handleMouseLeave = () => {
    // Change cursor back to default
    const stage = stageRef.current;
    if (stage) {
      stage.container().style.cursor = 'default';
    }
  };

  // Calculate the size of the image (e.g., 70% of the rectangle's size)
  const imageSize = Math.min(width, height) * 0.7; // 70% of the smaller dimension
  // Calculate the position to center the image
  const imageX = x + (width - imageSize) / 2;
  const imageY = y + (height - imageSize) / 2;

  return (
    <>
      <Rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fillColor ?? "lightgray"}
        cornerRadius={4}
        onClick={onClick}
        onTap={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />

      {icon ? (
        <Image
          image={icon}
          x={imageX}
          y={imageY}
          width={imageSize}
          height={imageSize}
          onClick={onClick}
          onTap={onClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      ) : (
        <Text
          x={x}
          y={y}
          width={width}
          height={height}
          text={text}
          align="center"
          fill='white'
          verticalAlign="middle"
          fontSize={12}
          onClick={onClick}
          onTap={onClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      )}

    </>
  );
};
//----------------------------------------------------------------

const BoundingBox = ({ shapeProps, isSelected, onSelect, onChange, onHover, imageSize, onEditClick, isEditing, allEditing, stageRef }: any) => {
  const shapeRef = useRef();
  const transformerRef = useRef<any>();
  const buttonSize = 20;
  const buttonOffset = 5;
  const nodePositions = getNodePositions(shapeProps);

  const [allowMove, setAllowMove] = useState(false)
  const [savedProps, setSavedProps] = useState<any>()

  useEffect(() => {
    setSavedProps({
      x: getSize(shapeProps.boundingBox[0]),
      y: getSize(shapeProps.boundingBox[1]),
      width: shapeProps.boundingBox[2],
      height: shapeProps.boundingBox[3],
    })
  }, [])

  useEffect(() => {
    if (isSelected && transformerRef.current) {
      // Attach the transformer to the shapeRef
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const getSize = (size: number) => {
    return 500 * size / imageSize.width
  }

  const onMoveClick = () => {
    setAllowMove(true)
  }


  const handleNodeDragMove = (event: any, index: any) => {
    const node = event.target;
    const scaleX = imageSize.width / 500;
    const scaleY = imageSize.width / 500;

    let newProps = {
      ...shapeProps,
      x: shapeProps.boundingBox[0],
      y: shapeProps.boundingBox[1],
      width: shapeProps.boundingBox[2],
      height: shapeProps.boundingBox[3],
    }

    let newX, newY, newWidth, newHeight;

    newWidth = (getSize(shapeProps.boundingBox[2]) - (node.x() - getSize(shapeProps.boundingBox[0])));
    newHeight = (getSize(shapeProps.boundingBox[3]) - (node.y() - getSize(shapeProps.boundingBox[1])));
    newX = node.x() * scaleX;
    newY = node.y() * scaleY;

    switch (index) {
      case 0: // Top-left
        // Check if the node is dragged past the right corners
        if (newX < (savedProps.x * scaleX + savedProps.width)) {
          newProps.x = newX;
          newProps.width = newWidth
        } else {
          newProps.x = savedProps.x * scaleX + savedProps.width
          newProps.width = (node.x() - getSize(shapeProps.boundingBox[0]))
        }

        // Check if the node is dragged past the bottom corners
        if (newY < (savedProps.y * scaleX + savedProps.height)) {
          newProps.y = newY;
          newProps.height = newHeight;
        } else {
          newProps.height = node.y() - getSize(shapeProps.boundingBox[1])
        }
        break;

      case 1: // Top-right
        // Check if the node is dragged past the left corners
        if (newX > (savedProps.x * scaleX)) {
          newProps.width = (node.x() - getSize(shapeProps.boundingBox[0]))
        } else {
          newProps.width = newWidth
          newProps.x = newX
        }

        // Check if the node is dragged past the bottom corners
        if (newY < (savedProps.y * scaleX + savedProps.height)) {
          newProps.height = newHeight;
          newProps.y = newY
        } else {
          newProps.height = node.y() - getSize(shapeProps.boundingBox[1])
        }

        break;
      case 2: // Bottom-right

        // Check if the node is dragged past the left corners
        if (newX > (savedProps.x * scaleX)) {
          newProps.width = (node.x() - getSize(shapeProps.boundingBox[0]))
        } else {
          newProps.width = newWidth
          newProps.x = newX
        }

        // Check if the node is dragged past the top corners
        if (newY > (savedProps.y * scaleY)) {
          newProps.height = (node.y() - getSize(shapeProps.boundingBox[1]))
        } else {
          newProps.height = newHeight
          newProps.y = newY
        }


        break;
      case 3: // Bottom-left

        // Check if the node is dragged past the bottom-right corner
        if (newX < (savedProps.x * scaleX + savedProps.width)) {
          newProps.x = newX;
          newProps.width = newWidth
        } else {
          newProps.x = savedProps.x * scaleX + savedProps.width
          newProps.width = (node.x() - getSize(shapeProps.boundingBox[0]))
        }

        // Check if the node is dragged past the top-left corner
        if (newY > (savedProps.y * scaleY)) {
          newProps.height = (node.y() - getSize(shapeProps.boundingBox[1]))
        } else {
          newProps.height = newHeight
          newProps.y = newY
        }

        break;
      default:
        return;
    }

    let updatedProps = {
      ...shapeProps,
      x: shapeProps.boundingBox[0],
      y: shapeProps.boundingBox[1],
      width: newProps.width * scaleX,
      height: newProps.height * scaleX,
    }

    updatedProps.x = newProps.x ?? updatedProps.boundingBox[0]
    updatedProps.y = newProps.y ?? updatedProps.boundingBox[1]

    // Update the state or call onChange with newProps
    onChange({ ...updatedProps });
  };


  const handleNodeDragEnd = () => {
    // Update the rectangle's final position and size after dragging ends
    setSavedProps({
      x: getSize(shapeProps.boundingBox[0]),
      y: getSize(shapeProps.boundingBox[1]),
      width: shapeProps.boundingBox[2],
      height: shapeProps.boundingBox[3],
    })
  };

  //TODO refactor 
  //https://codesandbox.io/s/he5jq?file=/src/index.js
  const handleEdgeDragMove = (event: any, side: any) => {
    const node = event.target;
    const [x, y, width, height] = shapeProps.boundingBox;
    let newProps = {
      ...shapeProps,
      x, y, width, height
    }

    const scaleX = imageSize.width / 500;
    const scaleY = imageSize.width / 500;

    switch (side) {
      case 'top':
        newProps.y = node.y() * scaleY;
        newProps.height = height - (node.y() * scaleY - getSize(y));
        break;
      case 'right':
        newProps.width = (node.x() - getSize(shapeProps.boundingBox[0]))
        break;
      case 'bottom':
        newProps.height = (node.y() * scaleY - getSize(y));
        break;
      case 'left':
        newProps.x = node.x() * scaleX;
        newProps.width = width - (node.x() * scaleX - getSize(x));
        break;
      default:
        break;
    }

    let updatedProps = {
      ...shapeProps,
      x: shapeProps.boundingBox[0],
      y: shapeProps.boundingBox[1],
      width: newProps.width * scaleX,
      height: newProps.height * scaleX,
    }

    // updatedProps.x = newProps.x ?? updatedProps.boundingBox[0]
    // updatedProps.y = newProps.y ?? updatedProps.boundingBox[1]

    // // Ensure the width and height are not negative
    // newProps.width = Math.max(newProps.width, 0);
    // newProps.height = Math.max(newProps.height, 0);

    onChange({ ...updatedProps });
  };


  return (
    <>
      <Rect
        onClick={() => onSelect(shapeProps.id)}
        onTap={() => onSelect(shapeProps.id)} // For touch devices
        ref={shapeRef}
        x={getSize(shapeProps.boundingBox[0])}
        y={getSize(shapeProps.boundingBox[1])}
        width={getSize(shapeProps.boundingBox[2])}
        height={getSize(shapeProps.boundingBox[3])}
        onMouseOver={() => onHover(shapeProps.name)}
        onMouseLeave={() => onHover(null)}
        {...shapeProps}
        cornerRadius={1}
        draggable={(isEditing || allEditing)}
        onDragMove={(e) => {
          onChange({
            ...shapeProps,
            x: (e.target.x() * imageSize.width) / 500,
            y: (e.target.y() * imageSize.width) / 500,
            // Use the setWidth and setHeight methods from Konva to get the new dimensions
            width: (e.target.width() * imageSize.width) / 500,
            height: (e.target.height() * imageSize.width) / 500,
          });
          handleNodeDragEnd()
        }}
        // onDragEnd={(e) => {
        //   onChange({
        //     ...shapeProps,
        //     x: (e.target.x() * imageSize.width) / 500,
        //     y: (e.target.y() * imageSize.width) / 500,
        //     // Use the setWidth and setHeight methods from Konva to get the new dimensions
        //     width: (e.target.width() * imageSize.width) / 500,
        //     height: (e.target.height() * imageSize.width) / 500,
        //   });
        //   handleNodeDragEnd()
        // }}
        ignoreStroke={false}
        strokeScaleEnabled={false}
        onTransformEnd={(e) => {
          // transformer is changing scale
          const node = shapeRef.current as any;
          if (node) {
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            onChange({
              ...shapeProps,
              x: node.x() * imageSize.width / 500,
              y: node.y() * imageSize.width / 500,
              // Use the setWidth and setHeight methods from Konva to get the new dimensions
              width: Math.max(5, node.width() * scaleX) * imageSize.width / 500,
              height: Math.max(5, node.height() * scaleY) * imageSize.width / 500,
            });
          }
        }}
      />
      {/* {isSelected && isEditing && <Transformer ref={transformerRef} enabledAnchors={[]} />} */}

      {(allEditing || isEditing) && nodePositions.map((pos, index) => (
        <CustomNode
          key={index}
          x={getSize(pos.x)}
          y={getSize(pos.y)}
          onDragMove={(e: any, savedX: any) => handleNodeDragMove(e, index)}
          onDragEnd={handleNodeDragEnd}
        />
      ))}

      {/* {isSelected && (
        Object.entries(getEdgeHandlePositions(shapeProps)).map(([side, pos]) => (
          <EdgeHandle
            key={side}
            {...pos}
            x={getSize(pos.x)}
            y={getSize(pos.y)}
            width={getSize(pos.width)}
            height={getSize(pos.height)}
            onDragMove={(e: any) => handleEdgeDragMove(e, side)}
          />
        ))
      )} */}

      {!allEditing && isSelected && (
        <>
          <KonvaButton
            x={getSize(shapeProps.boundingBox[0])}
            y={getSize(shapeProps.boundingBox[1]) - buttonOffset - buttonSize}
            width={buttonSize}
            height={buttonSize}
            text="M"
            // iconSrc={MoveIcon}
            // onClick={onMoveClick}
            stageRef={stageRef}
            iconSrc={EditIcon}
            onClick={() => onEditClick(shapeProps.id)}
            fill="lightblue"
            cornerRadius={4}
          />

          {isEditing && (
            <KonvaButton
              x={getSize(shapeProps.boundingBox[0]) + buttonSize + buttonOffset}
              y={getSize(shapeProps.boundingBox[1]) - buttonOffset - buttonSize}
              width={buttonSize + 20}
              height={buttonSize}
              text="Done"
              stageRef={stageRef}
              // iconSrc={MoveIcon}
              // onClick={onMoveClick}
              onClick={() => {
                onEditClick(null)
                onSelect(null)
              }}
              fillColor={"#3687F1"}
              cornerRadius={4}
            />
          )}
          {/* <KonvaButton
            x={getSize(shapeProps.boundingBox[0]) + buttonSize + buttonOffset}
            y={getSize(shapeProps.boundingBox[1]) - buttonOffset - buttonSize}
            width={buttonSize}
            height={buttonSize}
            iconSrc={EditIcon}
            onClick={onEditClick}
            text="E"
            fill="lightgrey"
            cornerRadius={4}
          /> */}
        </>
      )}
    </>
  );
};
//----------------------------------------------------------------

export function FeedbackModal({ isOpen, onCancel, onConfirm }: FeedbackModalProps) {
  const cancelButtonRef = useRef(null)
  const stageRef = useRef(null);

  const { previewFrames, originalImageSize } = useFileLoader();
  const { videoPredictions, saveImageOnBucket } = usePredictions()
  const { authUser, getUserToken } = useAuth()

  const [selectedFramePredictions, setSelectedFramePredictions] = useState<any>([])
  const originalFramePredictions = useRef<any[]>([]);

  const [selectedFrame, setSelectedFrame] = useState("")
  const [hoveredClass, setHoveredClass] = useState<any>(null)

  // bounding boxes
  const [selectedId, selectShape] = useState(null);
  const [image, setImage] = useState<any>(null);
  const [isEditing, setIsEditing] = useState<any>(null);
  const [allEditing, setAllEditing] = useState<Boolean>(false);

  const [runGuide, setRunGuide] = useState(true);


  const handleConfirm = async (pred: any) => {
    const formattedFeedbackPredictions = pred.map((prediction: any) => ({
      originalLabel: prediction.originalClass,
      correctedLabel: prediction.classes[0].class,
      confidence: parseFloat(prediction.classes[0].confidence),
      boundingBox: prediction.boundingBox,
      originalBoundingBox: prediction.originalBoundingBox,
      id: prediction.name
    }));

    const userToken = await getUserToken()
    console.log("getUserToken", userToken)

    const feedbackObject = {
      videoId: "sample",
      frameTimestamp: 123456789,
      predictions: formattedFeedbackPredictions,
      frameDimensions: originalImageSize

    };

    const fileBlob = await dataURLToBlob(selectedFrame)

    try {
      const fileUrl = await saveImageOnBucket(fileBlob, `${authUser?.uid ?? "default_user"}/${Date.now().toString()}`)
      console.log("fileUrl:", fileUrl)
      const response = await fetch(nodeAPIUrl + `/api/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`, // Include the ID token in the Authorization header
        },
        body: JSON.stringify({ ...feedbackObject, fileUrl })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        const data = await response.json();
        console.log(data);
        onConfirm(); // Call this function to handle any post-confirm actions
      }
    } catch (error) {
      console.error('There was an error sending the feedback', error);
      // Handle the error, perhaps show a user-friendly message or retry logic
    }
  }

  const updatePrediction = (itemKey: string, newPred: any) => {
    const newClass = newPred.id
    const newName = newPred.name

    const updatedPredictions = selectedFramePredictions.map((pred: any) => {
      if (pred.name === itemKey) {
        const isReverted = originalFramePredictions.current.some((origPred) =>
          origPred.name === itemKey && origPred.classes[0].class === newClass
        );

        return {
          ...pred,
          isChanged: !isReverted,
          classes: pred.classes.map((cls: any, index: number) =>
            index === 0 ? { ...cls, class: newClass } : cls
          ),
        };
      }
      return pred;
    });

    setSelectedFramePredictions(updatedPredictions);
  };

  const getSize = (size: number) => {
    return 500 * size / originalImageSize.width
  }

  const findFrameWithLowestConfidence = (frames: Frame[]): number => {
    let lowestConfidence = 1.0; // Start with the highest confidence possible
    let frameIndexWithLowestConfidence = -1;
    if (!frames) return frameIndexWithLowestConfidence

    frames.forEach((frame, index) => {
      frame.objects.forEach((obj) => {
        obj.classes.forEach((cls) => {
          const confidence = parseFloat(cls.confidence);
          if (confidence < lowestConfidence) {
            lowestConfidence = confidence;
            frameIndexWithLowestConfidence = index;
          }
        });
      });
    });

    return frameIndexWithLowestConfidence;
  };

  async function processPredictionsData(videoPredictions, limit) {
    // Find the frame with the lowest confidence
    const index = findFrameWithLowestConfidence(videoPredictions);
    let frameObjects = videoPredictions[index]?.objects ?? [];

    const blob = await getFrameAsImage(index, 25, 'video')
    const imageUrl = URL.createObjectURL(blob);
    console.log('Frame URL:', imageUrl);
    setSelectedFrame(imageUrl)
    let processedPredictions = frameObjects.map(pred => ({
      ...pred,
      originalClass: pred.classes[0]?.class,
      originalBoundingBox: [pred.boundingBox[0], pred.boundingBox[1], pred.boundingBox[2], pred.boundingBox[3]]
    }));

    processedPredictions.sort((a, b) => parseFloat(a.classes[0].confidence) - parseFloat(b.classes[0].confidence));

    if (limit !== null) {
      processedPredictions = processedPredictions.slice(0, limit);
    }

    return { processedPredictions, imageUrl };
  }

  const getPercentageColor = (value: number) => {
    const percentage = (value / 1) * 100;
    if (percentage >= 40) return 'text-green-500';
    if (percentage <= 39) return 'text-yellow-500';
    if (percentage <= 29) return 'text-red-500';
    return 'text-green-500';
  }

  useEffect(() => {
    (async () => {
      if (videoPredictions && isOpen) {
        console.log("call")
        const { processedPredictions, imageUrl } = await processPredictionsData(videoPredictions, FEEDBACK_PREDICTIONS_LIMIT);
        setSelectedFramePredictions(processedPredictions);
        originalFramePredictions.current = JSON.parse(JSON.stringify(processedPredictions));
        // setSelectedFrame(previewFrames[0]);
        const img = new window.Image();
        img.src = imageUrl
        img.onload = () => {
          // set image state to the loaded image
          setImage(img);
        };
      }
    })()
  }, [isOpen]);

  const handleJoyrideCallback = (data: any, tourKey: string) => {
    const { status } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (finishedStatuses.includes(status)) {
      updateTourProgress(tourKey);
    }
  };
  return (
    <Transition.Root show={isOpen} as={Fragment} >
      <Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={onCancel} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 dark:bg-black bg-opacity-75 dark:bg-opacity-70  transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className=" relative transform overflow-hidden min-w-[900px]  rounded-lg dark:bg-neutral-900 bg-white text-left shadow-xl transition-all sm:mb-8 pt-4 sm:w-full sm:max-w-lg border-[0.5px] dark:border-neutral-600">
                <div className=" dark:bg-neutral-900 bg-white max-h-[80vh] overflow-scroll w-full px-4 pb-4 sm:px-6 sm:pb-4 text-gray-600 dark:text-gray-300">

                  <Joyride
                    //@ts-ignore
                    steps={steps}
                    run={runGuide}
                    tooltipComponent={TourTooltip}
                    callback={(data) => handleJoyrideCallback(data, 'firstTourKey')}
                    styles={{
                      options: {
                        arrowColor: '#181818',
                        backgroundColor: '#181818',
                        overlayColor: 'rgba(0, 0, 0, 0.5)',
                        primaryColor: '#4D9DFA',
                      }
                    }}
                  />

                  <div className="sm:flex sm:items-start w-full">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                      <MdFeedback className="h-6 w-6 text-primary-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">
                        Your Expertise Makes Us Better
                      </Dialog.Title>

                      <div className="mt-2 w-full">
                        <p className="text-sm ">
                          Your input directly improves our AI, making it smarter with every correction you submit.
                        </p>

                        <div className="flex mt-4">
                          <div className="w-full flex justify-center" >
                            <div className="min-w-[500px] w-[500px] mt-2 relative rounded edit_shapes" >
                              {/* <img id="img" className="rounded-md" src={selectedFrame} alt="" /> */}
                              <Stage className="rounded" ref={stageRef} width={500} height={281}>
                                <Layer
                                  listening={false}>
                                  <Image image={image} x={0} y={0} width={500} height={281} />
                                </Layer>

                                <Layer>
                                  <KonvaButton
                                    x={20} // Adjust these values based on button size and desired margin
                                    y={10}
                                    width={60}
                                    height={20}
                                    text="Edit All"
                                    fillColor="black"
                                    onClick={() => setAllEditing(!allEditing)}
                                    stageRef={stageRef}
                                  />

                                </Layer>
                                <Layer >


                                  {selectedFramePredictions?.map((object: any, index: number) => {
                                    return (
                                      <Fragment key={`${object.id}_${index}`}>
                                        <BoundingBox
                                          key={index}
                                          stageRef={stageRef}
                                          imageSize={originalImageSize}
                                          shapeProps={{
                                            ...object,
                                            fill: 'transparent',
                                            stroke: object.name === hoveredClass ? "white" : 'black',
                                            strokeWidth: 2,
                                            cornerRadius: 2
                                          }}
                                          isSelected={object.id === selectedId}
                                          isEditing={isEditing === object.id}
                                          onHover={(e: any) => setHoveredClass(e)}
                                          onSelect={(id: any) => {
                                            selectShape(id);
                                          }}
                                          onEditClick={(id: any) => setIsEditing(id)}
                                          allEditing={allEditing}
                                          onChange={(newAttrs: any) => {
                                            const rects = selectedFramePredictions.slice();
                                            rects[index].boundingBox[0] = newAttrs.x;
                                            rects[index].boundingBox[1] = newAttrs.y;
                                            rects[index].boundingBox[2] = newAttrs.width;
                                            rects[index].boundingBox[3] = newAttrs.height;
                                            setSelectedFramePredictions(rects);
                                          }}
                                        />
                                      </Fragment>
                                    )
                                  })}
                                </Layer>
                              </Stage>
                            </div>
                          </div>


                          <div className="flex w-full flex-col  mt-2 gap-4 items-center" >
                            Detections:
                            <div className={classNames(
                              "flex w-full flex-col  mt-2 gap-4 items-center justify-center text-sm ",
                              "max-h-[300px] overflow-scroll",
                              "edit_predictions"
                            )}>
                              {selectedFramePredictions?.map((item: any) => (
                                <Menu placement="right">
                                  <MenuHandler>
                                    <div key={`${item.name}`}
                                      onMouseOver={() => setHoveredClass(item.name)}
                                      onMouseLeave={() => setHoveredClass(null)}
                                      className={
                                        classNames(
                                          "capitalize items-center flex px-2 w-52 dark:text-white  justify-between  p-1 rounded ",
                                          "border cursor-pointer border-neutral-500 ",
                                          item.name === hoveredClass ? "dark:border-neutral-100 border-primary-500 shadow shadow-primary-500 dark:shadow-neutral-100" : ""
                                        )}
                                    >
                                      <div className="w-6 h-6 relative" >
                                        <img src={getClassIconById(item.classes[0].class) ?? ""} alt={getClassIconById(item.classes[0].class) ?? ""} />
                                        {item.isChanged && (
                                          <Tooltip content={
                                            <div className="text-xs">
                                              <p color="white" className="font-light">{item.originalClass} {item.classes[0].confidence} </p>
                                            </div>
                                          }>
                                            <ExclamationCircleIcon className="w-6 h-6 text-blue-400 absolute top-[0px] -left-10" />
                                          </Tooltip>
                                        )}
                                      </div>
                                      <p>
                                        {item.classes[0].class.split('_').map((w: string) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()).join(' ')}
                                      </p>
                                      <Tooltip
                                        placement="right"
                                        content={
                                          <div className="text-xs">
                                            <p color="white" className="font-light">Confidence</p>
                                          </div>
                                        }>

                                        <p
                                          className={classNames(
                                            getPercentageColor(item.isChanged ? 1 : item.classes[0].confidence)
                                          )}
                                        >
                                          {item.isChanged ? "-.-- " : item.classes[0].confidence}
                                        </p>
                                      </Tooltip>

                                    </div>
                                  </MenuHandler>
                                  <MenuList className="max-h-60">
                                    {flattenedItems.map((i: any) => (
                                      <>

                                        <MenuItem className="flex items-center" onClick={() => updatePrediction(item.name, i)} >
                                          {i.id === item.classes[0].class && (
                                            <CheckIcon className="w-4 h-4 mr-1 text-green-500 " />
                                          )}
                                          <p>{i.name}</p>
                                        </MenuItem>
                                      </>
                                    ))}
                                  </MenuList>
                                </Menu>
                              ))}
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                {/* Confirm/Cancel buttons */}
                <div className="bg-gray-50 dark:bg-neutral-800  px-4 py-3 mt-4 sm:flex sm:px-6">

                  <div className="input-wrapper flex items-center  sm:w-full mb-2 sm:mb-0" >
                  </div>

                  <div className="sm:flex sm:flex-row-reverse" >
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-gradient-to-r from-cyan-500 to-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => handleConfirm(selectedFramePredictions)}
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white dark:border-gray-500 dark:bg-neutral-600 dark:text-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={onCancel}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>

                  </div>


                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root >
  );
}

