import { ReactNode, useState } from 'react';
import styles from "./delimited_areas_list.module.scss";
import variables from "../../styles/variables.module.scss";
import { SliderPicker } from 'react-color';
import { classNames } from '../../utils';
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";

import { MdOutlineContentCopy } from "react-icons/md"
import { useRegionConfiguration } from '../../hooks/useRegionConfiguration';

type delimitedAreaFragmentPropsType = {
  inBrowser: boolean; // whether we are rendering in the client or server side
  theme: string; // the theme that is currently active
  jsonData: any; //
  isEditing: boolean;
  delimitedArea: any; //
  delimitedAreaId: number; //
  changeDelimitedAreas: any;
  onCopy: () => void
}

export function DelimitedAreaFragment({ inBrowser, theme, jsonData, isEditing, delimitedArea, delimitedAreaId, changeDelimitedAreas, onCopy }: delimitedAreaFragmentPropsType) {
  const [nameTextfieldValue, setNameTextfieldValue] = useState("");
  const [showColourPicker, setShowColourPicker] = useState(false);
  const [animateDoneButton, setAnimateDoneButton] = useState(0);

  const { areasType } = useRegionConfiguration()
  const genericName = areasType === "DEFAULT" ? "transgressing" : "parking area";


  // Precompute the defaultValue outside of the JSX
  const defaultInputValue = delimitedArea["TARGET_CLASS_NAME"] !== ""
    ? delimitedArea["TARGET_CLASS_NAME"].replaceAll(" • CLASS", "")
    : genericName;

  const handleChanges = (newName: any, newEventType: any, newColour: any) => {

    let new_target_class_name = (newName !== null ? newName : delimitedArea["TARGET_CLASS_NAME"])

    changeDelimitedAreas(
      "update",
      delimitedAreaId,
      {
        "ID": delimitedAreaId,
        "EVENT_TYPE": (newEventType !== null ? newEventType : delimitedArea["EVENT_TYPE"]),
        "TARGET_CLASS_NAME": new_target_class_name + (new_target_class_name.includes(" • CLASS") ? "" : " • CLASS"),
        "POINTS": delimitedArea["POINTS"],
        "COLOUR": (newColour !== null ? newColour : delimitedArea["COLOUR"])
      }
    );

    if (nameTextfieldValue === "" && delimitedArea["TARGET_CLASS_NAME"] === "")
      setNameTextfieldValue(genericName);
    else
      setNameTextfieldValue(newName);
  }

  const toggleColourPicker = () => {
    if(areasType === "DEFAULT")
    setShowColourPicker((e) => !e);
  }

  return (
    <div className={classNames(
      "w-[230px]"
    )}>
      <div
        className={classNames(
          "flex p-2 gap-2"
        )}
        style={{
          cursor: ((isEditing && !showColourPicker) ? "pointer" : "normal"),
        }}>

        {/* Color div */}
        <div>
          <div
            className={classNames(
              "w-8 h-16 rounded"
            )}
            onClick={() => toggleColourPicker()}
            style={{
              backgroundColor: ("rgba(" + delimitedArea["COLOUR"].map((item: number) => (item + "")).join(", ") + ")"),
              cursor: ((isEditing && !showColourPicker) ? "pointer" : "normal"),
            }} >


            <div
              className={classNames(
                styles.delimited_area_fragment_colour_code_div,
                areasType === "DEFAULT" ? "" : "opacity-0"
              )}
              style={{
                transform: (showColourPicker ? "translateY(0px)" : "translateY(18px)"),
                cursor: ((isEditing && !showColourPicker) ? "pointer" : "normal"),
              }}>

              <div
                className={classNames(
                  styles.done_button_div,
                  showColourPicker ? "opacity-100" : "opacity-0",
                  "hover:opacity-80"
                )}
                onClick={(e) => {
                  e.stopPropagation()
                  toggleColourPicker()
                }}
                //@ts-ignore
                animateDoneButton={animateDoneButton}
                onAnimationEnd={() => setAnimateDoneButton(0)}
                style={{
                  transform: (showColourPicker ? "translateY(0px)" : "translateY(-18px)"),
                }}>
                <div
                  className={styles.done_button_icon}
                  style={{
                    backgroundImage: jsonData.done_button_icon,
                  }} />
              </div>
            </div>
          </div>

          <div
            className={classNames(
              "border w-8 h-8 flex items-center border-gray-400 rounded-md justify-center mt-1 hover:bg-neutral-700/70"
            )}>

            <MdOutlineContentCopy onClick={onCopy} className="w-4 h-4 text-white" />
          </div>
        </div>


        <div
          className={styles.delimited_area_fragment_details_div}
          style={{
            // border: (showColourPicker ? ("1px solid " + (theme === "Light" ? variables.borderColourLightMode : variables.borderColourDarkMode)) : "1px solid rgba(0, 0, 0, 0)"),
            // paddingTop: (isEditing ? "3px" : "5px"),
            // paddingBottom: (isEditing ? "3px" : "5px"),
          }}>

          <div
            className={styles.blurred_background_div}
            style={{
              visibility: (showColourPicker ? "visible" : "hidden"),
              opacity: (showColourPicker ? "1.0" : "0.0"),
              backgroundColor: (theme === "Light" ? "rgba(200, 200, 200, 0.5)" : "rgba(30, 30, 30, 0.5)"),
              borderRadius: (theme === "Light" ? "4px" : "6px"),
            }} />

          <div
            className={styles.colour_picker_div}
            style={{
              visibility: (showColourPicker ? "visible" : "hidden"),
              opacity: (showColourPicker ? "1.0" : "0.0"),
              zIndex: (showColourPicker ? "400" : "-1"),
            }}>
            <SliderPicker
              color={"rgba(" + delimitedArea["COLOUR"].map((item: number) => (item + "")).join(", ") + ")"}
              onChangeComplete={(newColour: any) => handleChanges(null, null, [newColour["rgb"]["r"], newColour["rgb"]["g"], newColour["rgb"]["b"], newColour["rgb"]["a"]])}
            />
          </div>

          <div
            className={classNames(
              "flex flex-col border border-gray-400 rounded-lg px-2 py-1 hover:bg-neutral-700/70"
            )}
          >
            <span
              className={
                classNames(
                  styles.delimited_area_fragment_title,
                  "w-16"
                )
              }
              style={{
              }}>
              NAME
            </span>

            <div
            >
              <input
                className={classNames(
                  `block h-6 w-full rounded-md border border-none shadow-sm outline-none sm:text-sm`,
                  "dark:bg-transparent ",
                  "font-bold focus:text-white"
                )}
                id={"fieldName"}
                defaultValue={defaultInputValue}  // Use the precomputed variable here
                onChange={(e) => { handleChanges((delimitedArea["TARGET_CLASS_NAME"] ? e.target.value : null), (null), null) }}
              />

            </div>
          </div>

          <div
            className={classNames(
              "flex w-full gap-2 mt-2"
            )}>

            <div
              className={classNames(
                "w-full border border-gray-400 rounded-lg px-2 py-1 leading-4 hover:bg-neutral-700/70"
              )}>
              <span
                className={
                  classNames(
                    styles.delimited_area_fragment_title,
                    "w-16"
                  )
                }
                style={{
                }}>
                AREA TYPE
              </span>
              <div className="text-xs leading-4" >
                {areasType}
              </div>



            </div>

            <div
              className={classNames(
                "w-full  max-w-[82px] border border-gray-400 rounded-lg px-2 py-1 leading-4 hover:bg-neutral-700/70"
              )}
            >
              <span
                className={
                  classNames(
                    styles.delimited_area_fragment_title,
                    "w-16"
                  )
                }
                style={{
                }}>
                POINTS
              </span>

              <div className="w-full text-xs leading-4 overflow-hidden truncate" >
                {JSON.stringify(delimitedArea["POINTS"])}
              </div>
            </div>

          </div>
        </div>
      </div>


    </div>
  );
}