import { ReactNode } from 'react';
import { PlayIcon, PauseIcon } from '@heroicons/react/24/solid'

interface VideoControlsProps {
  percentage: number;
  isPlaying: boolean;
  time:string
  onPlay: () => any
  onMouseDown: any
  onMouseMove: any
  onMouseUp: any
  onMouseLeave: any
}

export function VideoControls({ percentage, isPlaying, onPlay, onMouseLeave, onMouseUp, onMouseMove, onMouseDown, time }: VideoControlsProps) {
  return (
    <>
      <div className="w-full flex items-center z-50 bg-black" >
        <div onClick={() => onPlay()} className="w-8 h-8  flex items-center justify-center cursor-pointer" >
          {isPlaying ? (
            <PauseIcon className="w-6 h-6 text-white " />
          ) : (
            <PlayIcon className="w-6 h-6 text-white " />
          )}
        </div>
        <div className="relative w-full bg-white/50 h-2 cursor-pointer"
          onMouseLeave={onMouseLeave}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onMouseDown={onMouseDown}
        >
          <div
            className={`absolute left-0 top-0 h-2 bg-gradient-to-r from-blue-500 to-cyan-500 `}
            style={{
              width: `${percentage}%`
            }}

          ></div>
        </div>
        <div className="w-16 flex justify-center" >
        <div
          id="timeline-cursor-time"
          className="rounded-full text-xs text-primary-500 font-bold  p-1 z-10 select-none cursor-pointer"
        >
          {time}
        </div>
        </div>
      </div>
    </>
  );
}
