/**
 * Confirmation Modal for selecting the type of detection.
 * Allows the user to choose detection categories that AI models will analyze.
 * The user can either select a pre-defined detection category stored on @INDUSTRIES_MENU or create a custom one.
 * Each category has a unique set of detection items, and the user can add or remove items from the selected category.
 */

import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Form } from '@unform/web'
import { classNames, getVideoDetectedClasses, handleFormatVideoData, handleFormatVideoDataWithParkingStatus } from '../../utils';
import Icon from "../../assets/icons/predictions/car.svg"
import { INDUSTRIES_MENU, MENU_ITEMS } from '../../mock/menu';
import { FormHandles, SubmitHandler } from '@unform/core';
import { PencilSquareIcon, XMarkIcon, QuestionMarkCircleIcon, KeyIcon, PencilIcon, CheckIcon } from '@heroicons/react/24/outline'

import { usePredictionsMenu } from '../../hooks/usePredictionsMenu';

import sample1 from "../../assets/videos/sample.mp4"
import sample2 from "../../assets/videos/fire_smoke.mp4"
import sample3 from "../../assets/videos/smoke_vehicle.mp4"
import sample4 from "../../assets/videos/parking.mp4"

import sampleData1 from "../../assets/predictions/smoke&vehicles.json"
import sampleData2 from "../../assets/predictions/sample2.json"
import sampleData3 from "../../assets/predictions/sample1.json"
import sampleData4 from "../../assets/predictions/parkingSample.json"
import { object as sampleData } from "../../assets/predictions/sample3"
import { usePredictions } from '../../hooks/usePredictions';
import ReactGA from "react-ga4";
import { useRegionConfiguration } from '../../hooks/useRegionConfiguration';
//----------------------------------------------------------------

interface ExamplesModal {
  children?: React.ReactNode
  isOpen?: boolean
  onConfirm: (prediction: any, file: any, dimensions: any) => void
  onCancel: () => void
}

type SelectedSample = {
  id: string;
  src: string;
  name:string;
  predictions: any
  dimensions: { width: number, height: number }
}

const EXAMPLES = [
  {
    id: "vehicles",
    name: "Traffic",
    src: sample1,
    predictions: sampleData,
    dimensions: { width: 1280, height: 720 }
  },
  {
    id: "fire_smoke",
    name: "Fire and Smoke",
    src: sample2,
    predictions: sampleData2,
    dimensions: { width: 1280, height: 720 }
  },
  {
    id: "smoke_vehicles",
    name: "Smoke and Vehicles",
    src: sample3,
    predictions: sampleData3,
    dimensions: { width: 1280, height: 720 }
  },
  {
    id: "parking",
    name: "Parking lot",
    src: sample4,
    predictions: sampleData4,
    dimensions: { width: 1280, height: 720 }
  },
]

//----------------------------------------------------------------
export function ExamplesModal({ isOpen, onConfirm, onCancel, children }: ExamplesModal) {
  // refs
  const formRef = useRef<FormHandles>(null)
  const cancelButtonRef = useRef(null)
  // custom hooks
  const {  setTracking } = usePredictionsMenu()
  const { setDetectedClasses } = usePredictions()
  const {setDelimitedAreas, setParkingLotAreas} = useRegionConfiguration()
  // react hooks
  const [detectableItems, setDetectableItems] = useState<any | null>(null)

  const [selectedSample, setSelectedSample] = useState<SelectedSample>()
  const [predictions, setPredictions] = useState<any>([])
  const [videoDimensions, setVideoDimensions] = useState({ width: 1280, height: 720 })
  //----------------------------------------------------------------------------

  const handleSelectVideo = (video: any) => {
    const modelPredictions = {} as any;
    switch (video.id) {
      case "smoke_vehicles":
        setSelectedSample(video)
        const _predictions1 = sampleData as any
        console.log(_predictions1)

        setPredictions(handleFormatVideoData(_predictions1[0] as any))
        setVideoDimensions({ width: 1280, height: 720 })
        break;

      case "fire_smoke":
        setSelectedSample(video)

        const _predictions = sampleData2 as any
        // const modelPredictions = {} as any;

        Object.keys(_predictions).forEach((key) => {
          const modelPredictionData = _predictions[key][0]

          Object.keys(modelPredictionData).forEach((innerKey) => {
            if (!modelPredictions[innerKey]) {
              modelPredictions[innerKey] = {};
            }
            modelPredictions[innerKey] = {
              ...modelPredictions[innerKey],
              ...modelPredictionData[innerKey],
            };
          });
        });


        const f = handleFormatVideoData(modelPredictions as any)
        setVideoDimensions({ width: 1920, height: 1080 })
        setPredictions(f)
        break;

      case "vehicles":
        setSelectedSample(video)
        const formattedSampleData3 = handleFormatVideoData(sampleData3 as any)
        setPredictions(formattedSampleData3)
        console.log("formattedSampleData3", formattedSampleData3)
        console.log("sampleData3", sampleData3)
        setVideoDimensions({ width: 1280, height: 720 })
        break;

      case "parking":
        setSelectedSample(video)
        const formattedParkingSample = handleFormatVideoDataWithParkingStatus(sampleData4)
        setPredictions(formattedParkingSample)
        setVideoDimensions({ width: 1920, height: 1080 })
        break

      default:
        break;
    }
  }

  const handleConfirmButton = async () => {
    ReactGA.event({
      category: "Samples",
      action: "Select Sample",
      label: selectedSample?.name,
    });

    onConfirm(predictions, selectedSample?.src, videoDimensions)
    const _detectedClasses = getVideoDetectedClasses(predictions) // return {'smoke', 'fire', 'person'}
    setDetectedClasses(_detectedClasses)
    setTracking(true)
    setDelimitedAreas([])
    setParkingLotAreas([])
  }

  useEffect(() => {
    if (predictions) {

      const _detectedClasses = getVideoDetectedClasses(predictions) // return {'smoke', 'fire', 'person'}
      // Filter the items in each category
      const filteredItems = MENU_ITEMS.flatMap(category =>
        category.items.filter(item => _detectedClasses.has(item.id))
      );


      console.log("filteredItems", filteredItems)

      setDetectableItems({
        items: filteredItems
      })
    }

  }, [predictions])

  useEffect(() => {
    handleSelectVideo(EXAMPLES[0])
  }, [])

  return (
    <Transition.Root show={isOpen} as={Fragment} >
      <Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={onCancel} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 dark:bg-black bg-opacity-75 dark:bg-opacity-70  transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="dark:border-neutral-600 border relative transform overflow-hidden min-w-[800px] rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="dark:bg-neutral-900 bg-white w-full px-4 pt-5 pb-4 sm:p-6 sm:pb-4 text-gray-600 dark:text-gray-300">
                  <div className="sm:flex sm:items-start w-full">
                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                      <CodeBracketIcon className="h-6 w-6 text-primary-600" aria-hidden="true" />
                    </div> */}
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">
                        Examples
                      </Dialog.Title>

                      <div className="mt-2 w-full">
                        {children ? (children) : (
                          <p className="text-sm">
                            Start right away with one of our samples
                          </p>
                        )}

                        {/* MENU */}
                        <div className="flex w-full  mt-2 gap-2 justify-center items-center text-sm">
                          {EXAMPLES.map((item) => (
                            <div
                            key={item.id}
                              onClick={() => handleSelectVideo(item)}
                              className={classNames(
                                item.src === selectedSample?.src && "bg-neutral-100 shadow-inner dark:bg-neutral-700",
                                "p-4 h-[176px] w-40 overflow-hidden flex flex-col items-center relative text-center cursor-pointer rounded-md hover:bg-neutral-100 dark:hover:bg-neutral-700 hover:shadow-inner  hover:text-primary-500"
                              )}>

                              {item.src === selectedSample?.src && (
                                <div className="absolute -top-[55%] -right-[55%] w-32 h-32 bg-blue-500 flex justify-center items-center rotate-45 ">
                                  <CheckIcon className="w-4 h-4 -rotate-45 absolute left-[55%] bottom-1 font-bold text-white" />
                                </div>
                              )}
                              <div className="w-32 h-24 bg-blue-400 mb-2 rounded-md overflow-hidden" >
                                <video className="object-cover h-full w-full" src={item.src} />
                              </div>
                              <label >{item.name}</label>
                            </div>
                          ))}
                        </div>

                        <Dialog.Title as="h4" className="text-md mt-2 flex items-center font-medium leading-6 text-gray-900 dark:text-gray-200">
                          Detections
                        </Dialog.Title>

                        <div className="max-h-[280px] min-h-[200px] h-full w-full overflow-x-hidden overflow-y-scroll scrollbar-hide mt-2" >
                          {detectableItems && detectableItems.items.map((i: any) => (
                            <div
                              key={`${i.id}_`}
                              onClick={() => console.log(i.id)}
                              className={
                                classNames(
                                  "py-2 px-4 pl-8 flex items-center justify-between border-b border-neutral-50 dark:border-neutral-700",
                                  i.active ? "hover:bg-neutral-900/10 font-medium" : "hover:bg-neutral-900/10 text-neutral-400"
                                )}>
                              {/* <CustomCheckbox label={i.name} checked={i.active} /> */}
                              <div className="flex items center" >
                                <img className={classNames("w-6 mr-4")} src={i.icon ?? Icon} alt={i.id} />
                                <span>{i.name}</span>
                              </div>
                            </div>
                          ))}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full px-12 flex text-end justify-end text-primary-500" >


                </div>

                <Form ref={formRef} onSubmit={handleConfirmButton} className="bg-gray-50 dark:bg-neutral-800  px-4 py-3 sm:flex sm:px-6">

                  <div className="input-wrapper flex items-center  sm:w-full mb-2 sm:mb-0" >
                  </div>

                  <div className="sm:flex sm:flex-row-reverse" >
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-gradient-to-r from-cyan-500 to-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => formRef?.current?.submitForm()}
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white dark:border-gray-500 dark:bg-neutral-600 dark:text-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={onCancel}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>

                  </div>


                </Form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
