import JSZip from 'jszip';
import { FrameType, ImagePredictionsType, FormattedPredictionType } from '../types/PredictionType';


export function convertVideoToYOLO(data: FrameType[], imgWidth: number, imgHeight: number, format: "txt" | "csv"): string[][] {
  let classMapping = new Map();

  return data.map((imageData) => {
    return imageData.objects.flatMap((objectData: FormattedPredictionType) => {
      return objectData.classes.map(({ class: className, confidence }) => {
        if (!classMapping.has(className)) {
          classMapping.set(className, classMapping.size);
        }

        const classId = classMapping.get(className);
        const [x, y, width, height] = objectData.boundingBox;

        // Convert to YOLO format
        const centerX = (x + width / 2) / imgWidth;
        const centerY = (y + height / 2) / imgHeight;
        const normWidth = width / imgWidth;
        const normHeight = height / imgHeight;

        const output = [classId, centerX, centerY, normWidth, normHeight];
        return format === 'csv' ? output.join(',') : output.join(' ');
      });
    });
  });
}

export function convertImageToYOLO(data: ImagePredictionsType, imgWidth: number, imgHeight: number, format: "txt" | "csv"): string[] {
  let classMapping = new Map();

  return data.flatMap((objectData: FormattedPredictionType) => {
    return objectData.classes.map(({ class: className, confidence }) => {
      if (!classMapping.has(className)) {
        classMapping.set(className, classMapping.size);
      }

      const classId = classMapping.get(className);
      const [x, y, width, height] = objectData.boundingBox;

      // Convert to YOLO format
      const centerX = (x + width / 2) / imgWidth;
      const centerY = (y + height / 2) / imgHeight;
      const normWidth = width / imgWidth;
      const normHeight = height / imgHeight;

      const output = [classId, centerX, centerY, normWidth, normHeight];
      return format === 'csv' ? output.join(',') : output.join(' ');
    });
  });
}

export async function downloadYOLOAnnotations(yoloData: string[][], fileFormat: 'txt' | 'csv', framesData?: any, singleFile = false) {
  try {
    const separator = fileFormat === 'csv' ? ',' : ' ';
    const lineBreak = fileFormat === 'csv' ? '\r\n' : '\n';

    if (singleFile) {
      // Create a single file for the entire video
      let content = '';
      yoloData.forEach((annotations, frameNumber) => {
        annotations.forEach(annotation => {
          content += `${frameNumber + 1}${separator}${annotation}${lineBreak}`;
        });
      });

      const blob = new Blob([content + lineBreak], { type: 'text/plain' });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `annotations.${fileFormat}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // Create separate files for each frame and package them into a ZIP archive
      const zip = new JSZip();
      const annotationsFolder = zip.folder("annotations") as JSZip;
      const framesZip = framesData ? await JSZip.loadAsync(framesData) : null

      if (framesZip) {
        // Add frames to the main zip
        framesZip.forEach((relativePath, file) => {
          zip.file(`frames/${relativePath}`, file.async('blob'));
        });
      }

      yoloData.forEach((annotations, frameNumber) => {
        const content = annotations.map(a => a.replace(/ /g, separator)).join(lineBreak);
        annotationsFolder.file(`frame-${frameNumber + 1}.${fileFormat}`, content + lineBreak);
      });

      const zipBlob = await zip.generateAsync({ type: "blob" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(zipBlob);
      link.download = framesZip ? `annotations_and_frames.zip` : `annotations.zip`
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (err) {
    console.log(err)
  }
}

export async function downloadImageYOLOAnnotations(yoloData: string[], fileFormat: 'txt' | 'csv',) {
  const separator = fileFormat === 'csv' ? ',' : ' ';
  const lineBreak = fileFormat === 'csv' ? '\r\n' : '\n';

  // Create a single file for the entire video
  let content = '';
  yoloData.forEach(annotation => {
    content += `${annotation}${separator}${lineBreak}`;
  });

  const blob = new Blob([content + lineBreak], { type: 'text/plain' });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `annotations.${fileFormat}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function extractFrames(videoUrl: string, fps: number): Promise<HTMLCanvasElement[]> {
  return new Promise(async (resolve) => {
    const video = document.createElement('video');
    const seekInterval = 1 / fps;
    let currentTime = 0;

    const frames: HTMLCanvasElement[] = [];

    video.src = videoUrl;
    await video.play();

    video.pause();
    video.currentTime = 0;

    video.addEventListener('seeked', async () => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const context = canvas.getContext('2d');
      context?.drawImage(video, 0, 0, canvas.width, canvas.height);

      frames.push(canvas);

      currentTime += seekInterval;
      if (currentTime < video.duration) {
        video.currentTime = currentTime;
      } else {
        resolve(frames);
      }
    });

    video.currentTime = currentTime;
  });
}

export async function downloadObjectAsJson(exportObj: any, exportName: string, framesData?: any) {
  const zip = new JSZip();
  zip.file(`${exportName}.json`, JSON.stringify(exportObj));

  const framesZip = framesData ? await JSZip.loadAsync(framesData) : null

  if (framesZip) {
    // Add frames to the main zip
    framesZip.forEach((relativePath, file) => {
      zip.file(`frames/${relativePath}`, file.async('blob'));
    });
  }

  const content = await zip.generateAsync({ type: "blob" });
  downloadZipFile(content, `${exportName}.zip`);
}

function downloadZipFile(data: Blob, filename: string) {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}


export function getVideoData() {
  const video = document.getElementById("video") as HTMLVideoElement;
  const currentTime = video.currentTime;
  const duration = video.duration;

  return { video, currentTime, duration };
}

export function computeCursorPosition(currentTime: number, duration: number) {
  return (currentTime / duration) * 100;
}

export function getFrameAsImage(frameNumber: number, frameRate: number, videoId: string) {
  // Calculate the time in seconds
  const timeInSeconds = frameNumber / frameRate;

  // Access the video element
  const video = document.getElementById(videoId) as HTMLVideoElement;
  if (!video) {
    console.error("Video not found!");
    return Promise.reject("Video not found");
  }

  // Create a canvas element
  const canvas = document.createElement('canvas');
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  const context = canvas.getContext('2d') as CanvasRenderingContext2D;

  // Return a Promise that resolves with the image blob
  return new Promise((resolve, reject) => {
    video.addEventListener('seeked', function onSeeked() {
      video.removeEventListener('seeked', onSeeked);

      // Draw the video frame to the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert the canvas to a JPEG image
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob); // Resolve the Promise with the Blob
        } else {
          reject(new Error('Could not extract frame as image'));
        }
      }, 'image/jpeg');
    });

    // Seek the video to the specific time
    video.currentTime = timeInSeconds;
  });
}
