/**
 * This module provides a context and provider for drag-and-drop label positioning.
 * The context allows child components to access and modify the current position of labels.
 */

import { createContext, useState, FC } from 'react';

// Define the shape of our context state.
interface DragDropContextType {
  labelsPosition: "TL" | "TR" | "BL" | "BR"; // Represents the labels' position: TopLeft, TopRight, BottomLeft, BottomRight.
  setLabelsPosition: React.Dispatch<React.SetStateAction<"TL" | "TR" | "BL" | "BR">>; // Function to set the labels' position.
}

// Create the context with the defined shape.
export const DragDropContext = createContext<DragDropContextType>({} as DragDropContextType);

// Provider component which wraps parts of our app where we want to access the context.
export const DragDropProvider: FC = ({ children }:any) => {
  // State to manage the position of labels.
  const [labelsPosition, setLabelsPosition] = useState<"TL" | "TR" | "BL" | "BR">("TL");

  // Provide the labels' position and its updater function to the children.
  return (
    <DragDropContext.Provider
      value={{ labelsPosition, setLabelsPosition }}
    >
      {children}
    </DragDropContext.Provider>
  );
};
