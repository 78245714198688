/**
 * DownloadPreview Component.
 * 
 * This component is designed for previewing images or videos with overlay elements like polygons and labels. 
 * It's mainly used for displaying a preview of the output with selected classes, areas, and various label options.
 * Used on @DownloadModal component
 * 
 * Features:
 * - Overlay Visualization: Displays polygons and labels over the preview image.
 * - Dynamic Label Placement: Adjusts label positions based on user settings.
 * - Customizable Label Display: Supports counting labels, area labels, and parking area labels.
 */

// React
import { useContext } from 'react';

// Libraries
import { classNames, getClassIconById } from '../utils';
import { DragDropContext } from '../contexts/DragDropContext';

// Components/Assets/Helpers
import { SvgPolygon } from './SvgPolygon';
import { usePredictionsMenu, useFileLoader, usePredictions } from '../hooks';
import laneIcon from "../assets/lane.svg";

interface DownloadPreviewProps {
  /** Array of selected class names for which labels should be displayed. */
  selectedClasses: string[];

  /** Array of selected areas to display as polygons. */
  selectedAreas: any[];

  /** Boolean to determine if counting labels should be displayed. */
  countingLabels: boolean;

  /** Boolean to determine if area labels should be displayed. */
  areaLabels: boolean;

  /** Boolean to determine if parking area labels should be displayed. */
  parkingAreas: boolean;
}


export function DownloadPreview({ selectedClasses, selectedAreas, countingLabels, areaLabels, parkingAreas }: DownloadPreviewProps) {
  const { previewFrames, originalImageSize, fileType,  } = useFileLoader();
  const { labelsPosition, setLabelsPosition } = useContext(DragDropContext);
  const { displayCounting, displayTotalCounting } = usePredictionsMenu();
  const {fileUrl} = usePredictions()
  const getLabelPositionStyle = () => {
    switch (labelsPosition) {
      case "BL":
        return "bottom-1";
      case "BR":
        return "bottom-1 right-1";
      case "TL":
        return "top-1 ";
      case "TR":
        return "top-1 right-1";
      default:
        return "top-1 ";
    }
  };

  const getMaxLabels = () => {
    let classLabelsLimit = countingLabels ? 9 : 0;
    let areaLabelsLimit = areaLabels ? 9 : 0;

    if (countingLabels && areaLabels) {
      classLabelsLimit = 4;
      areaLabelsLimit = 5;
    }

    return {
      classLabelsLimit,
      areaLabelsLimit,
    };
  };

  const { classLabelsLimit, areaLabelsLimit } = getMaxLabels();

  return (
    <>
      <div className=" rounded-lg relative ">
        <img className=" rounded-lg max-h-48 object-contain" src={fileType === "image" ? fileUrl : previewFrames[previewFrames.length-1]} alt="preview" />
        {
          selectedAreas.map((area: any) => {
            // if (selectedAreas.includes(area)) {
              return (
                <SvgPolygon
                  currentFrame={null}
                  delimitedArea={area}
                  width={300}
                  height={168}
                  originalWidth={originalImageSize.width}
                  originalHeight={originalImageSize.height}
                />
              );
            // }
          })}
        {(displayCounting || displayTotalCounting) && (
          <div className={classNames("absolute z-20", getLabelPositionStyle())}>
            {countingLabels &&
              selectedClasses.slice(0, classLabelsLimit).map((item) => (
                <div
                  className={classNames(
                    "flex items-center p-[3px]  px-1 bg-black rounded-full text-white select-none mb-1 capitalize"
                  )}
                >
                  <img className="w-2 mr-1" src={getClassIconById(item) ?? ""} alt={item} />
                  <p style={{ fontSize: 5 }}>{item}</p>
                </div>
              ))}

            {parkingAreas ? (<>
              {areaLabels && (
                <>
                  <div
                    className={classNames(
                      "flex items-center p-[3px]  px-1 bg-black rounded-full text-white select-none mb-1 capitalize"
                    )}
                  >
                    <div className="w-2 mr-1 rounded-full "
                      style={{
                        backgroundColor: (`rgb(64, 249, 103)`),
                      }} >
                      <img className="w-2 " src={laneIcon} alt={"lane"} />
                    </div>
                    <p style={{ fontSize: 5 }}>Free Space {selectedAreas.length}</p>

                  </div>

                  <div
                    className={classNames(
                      "flex items-center p-[3px]  px-1 bg-black rounded-full text-white select-none mb-1 capitalize"
                    )}
                  >
                    <div className="w-2 mr-1 rounded-full "
                      style={{
                        backgroundColor: (`rgb(254,83,90)`),
                      }} >
                      <img className="w-2 " src={laneIcon} alt={"lane"} />
                    </div>
                    <p style={{ fontSize: 5 }}>Occupied Space {selectedAreas.length}</p>

                  </div>
                </>
              )}
            </>) : (<>
              {areaLabels &&
                selectedAreas.slice(0, areaLabelsLimit).map((item, index) => (
                  <div
                    className={classNames(
                      "flex items-center p-[3px]  px-1 bg-black rounded-full text-white select-none mb-1 capitalize"
                    )}
                  >
                    <div className="w-2 mr-1 rounded-full "
                      style={{
                        backgroundColor: ("rgba(" + item["COLOUR"].map((item: number) => (item + "")).join(", ") + ")"),
                      }} >
                      <img className="w-2 " src={laneIcon} alt={"lane"} />
                    </div>
                    <p style={{ fontSize: 5 }}>Area {index + 1}</p>

                  </div>
                ))}
            </>)}


          </div>
        )}


      </div>
    </>
  );
}
