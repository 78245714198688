export function getFileType(file: any) {
  const imageExtensions = ["jpg", "jpeg", "png", "gif"];
  const videoExtensions = ["mp4", "mov", "avi"];
  const extension = file.name.split(".").pop();

  if (imageExtensions.includes(extension)) {
    return "image";
  } else if (videoExtensions.includes(extension)) {
    return "video";
  } else {
    return null;
  }
}

export function saveToLocalStorage(key: string, data: any) {
  try {
    const serializedData = JSON.stringify(data);
    localStorage.setItem(key, serializedData);
  } catch (error) {
    console.error('Error saving data to local storage:', error);
  }
}

export function getFromLocalStorage(key: string) {
  try {
    const serializedData = localStorage.getItem(key);
    if (serializedData === null) {
      return undefined;
    }
    return JSON.parse(serializedData);
  } catch (error) {
    console.error('Error retrieving data from local storage:', error);
    return undefined;
  }
}

export const formatTime = (timeInSeconds: number) => {
  const sec_num = parseInt(timeInSeconds.toString(), 10); // don't forget the second parameter
  let hours   = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = sec_num - (hours * 3600) - (minutes * 60);

  const hoursStr   = hours   < 10 ? "0"+hours.toString()   : hours.toString();
  const minutesStr = minutes < 10 ? "0"+minutes.toString() : minutes.toString();
  const secondsStr = seconds < 10 ? "0"+seconds.toString() : seconds.toString();

  return hoursStr + ':' + minutesStr + ':' + secondsStr;
}

export function isElementInside(parentElement:any, childElement:any) {
  const parentRect = parentElement.getBoundingClientRect();
  const childRect = childElement.getBoundingClientRect();

  return (
      childRect.top >= parentRect.top &&
      childRect.left >= parentRect.left &&
      childRect.right <= parentRect.right &&
      childRect.bottom <= parentRect.bottom
  );
}

export const extractFrames = async (video: HTMLVideoElement, frameCount: number) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    throw new Error('Failed to get canvas context');
  }

  const duration = video.duration;
  const interval = duration / frameCount;
  const frames: string[] = [];

  for (let i = 0; i < frameCount; i++) {
    const currentTime = interval * i;
    video.currentTime = currentTime;
    await new Promise((resolve) => (video.onseeked = resolve));

    const { videoWidth: width, videoHeight: height } = video;
    canvas.width = width;
    canvas.height = height;

    context.drawImage(video, 0, 0, width, height);
    const frame = canvas.toDataURL('image/jpeg');
    frames.push(frame);
  }

  return frames;
};


export const dataURLToBlob = (dataUrl:any) => {
  return fetch(dataUrl)
    .then(res => res.blob());
};

/**
 * Calculates the new dimensions of an image to fit within max dimensions
 * while maintaining the aspect ratio.
 * 
 * @param {number} originalWidth - The original width of the image.
 * @param {number} originalHeight - The original height of the image.
 * @param {number} maxWidth - The maximum allowed width.
 * @param {number} maxHeight - The maximum allowed height.
 * @returns { {width: number, height: number} } - The new dimensions.
 */
export function calculateDimensions(originalWidth:number, originalHeight:number, maxWidth:number, maxHeight:number) {
  let newWidth = originalWidth;
  let newHeight = originalHeight;

  // Check if the width is larger than the max width
  if (originalWidth > maxWidth) {
    newWidth = maxWidth;
    newHeight = (originalHeight / originalWidth) * maxWidth;
  }

  // Check if the height is larger than the max height
  if (newHeight > maxHeight) {
    newHeight = maxHeight;
    newWidth = (originalWidth / originalHeight) * maxHeight;
  }

  return { width: newWidth, height: newHeight };
}


export const updateTourProgress = (tourKey:string) => {
  //@ts-ignore
  const tourProgress = JSON.parse(localStorage.getItem('tourProgress')) || {};
  tourProgress[tourKey] = true;
  localStorage.setItem('tourProgress', JSON.stringify(tourProgress));
};

export const shouldRunTour = (tourKey:string) => {
  //@ts-ignore
  const tourProgress = JSON.parse(localStorage.getItem('tourProgress')) || {};
  return !tourProgress[tourKey];
};

export function parseUrlParameterToObject(paramString: string) {
  try {
      const decodedString = decodeURIComponent(paramString);
      const parsedObject = JSON.parse(decodedString);

      const videoId = parsedObject.videoId;
      const dataArray = parsedObject.data.map(item => ({
          ID: item.ID,
          EVENT_TYPE: "",
          TARGET_CLASS_NAME: item.name,
          POINTS: item.POINTS,
          COLOUR: item.COLOUR
      }));
      const areasType = parsedObject.type;

      return { videoId, dataArray, areasType };
  } catch (error) {
      console.error("Failed to parse URL parameter:", error);
      // Return null or default values in case of error
      return { videoId: null, dataArray: [], areasType: null };
  }
}

export async function configureBucketCors() {
  try {
      const response = await fetch('http://localhost:3333/configure-cors', { // Replace with your server's URL
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              origin: "*", // e.g., "http://localhost:3000"
              responseHeader: "Content-Type", // e.g., "Content-Type"
              maxAgeSeconds: 3600, // e.g., 3600
              method: ["PUT", "GET"] // e.g., ["PUT", "POST", "GET"]
          })
      });

      if (!response.ok) {
          throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
      }

      const result = await response.text();
      console.log(result);
  } catch (error) {
      console.error('Error configuring CORS:', error);
  }
}

export function getFileExtension(mimeType:string) {
  const mimeToExtension = {
    // Images
    'image/jpeg': '.jpg',
    'image/png': '.png',
    'image/gif': '.gif',
    'image/webp': '.webp',
    // Videos
    'video/mp4': '.mp4',
    'video/webm': '.webm',
    'video/ogg': '.ogv',
    // Add other image/video MIME types you support
  };

  return mimeToExtension[mimeType] || undefined;
}


