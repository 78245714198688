/**
 * Confirmation Modal for selecting the type of detection.
 * Allows the user to choose detection categories that AI models will analyze.
 * The user can either select a pre-defined detection category stored on @INDUSTRIES_MENU or create a custom one.
 * Each category has a unique set of detection items, and the user can add or remove items from the selected category.
 */

import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { Fragment, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CodeBracketIcon, XMarkIcon, QuestionMarkCircleIcon, KeyIcon, PencilIcon, CheckIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { Form } from '@unform/web'
import { classNames, convertImageToYOLO, convertVideoToYOLO, downloadImageYOLOAnnotations, downloadObjectAsJson, downloadYOLOAnnotations, getClassIconById } from '../../utils';
import Icon from "../../assets/icons/predictions/car.svg"
import { fromBottom } from '../../utils/animations';
import { motion } from "framer-motion";
import { INDUSTRIES_MENU, MENU_ITEMS } from '../../mock/menu';
import { Accordion, AccordionBody, Checkbox, Popover, PopoverContent, PopoverHandler, Switch, Tooltip, Typography } from '@material-tailwind/react';
import { FormHandles, SubmitHandler } from '@unform/core';
import * as Yup from 'yup';
import { Input } from '../Input';
import { Option, Select, } from '@material-tailwind/react'
import { toast } from 'react-hot-toast'

import { usePredictionsMenu } from '../../hooks/usePredictionsMenu';
import { usePredictions } from '../../hooks/usePredictions';
import { useFileLoader } from '../../hooks/useFileLoader';
import { useRegionConfiguration } from '../../hooks/useRegionConfiguration';
import { DragDropContext } from '../../contexts/DragDropContext';
import { DownloadPreview } from '../DownloadPreview';
import { AiOutlinePlusSquare } from "react-icons/ai"
import { extractFrames } from '../../services/API';
//----------------------------------------------------------------

interface DownloadModalProps {
  children?: React.ReactNode
  isOpen?: boolean
  // detectionType: "image" | "video"
  imageSize: {
    width: number
    height: number
  }
  preSelectedTab?: number
  onConfirm: (detections: string[], counting: string | null, delimitedAreas: any) => void
  onCancel: () => void
  onSelectDelimitedArea: () => void
}

type DataType = "YOLO" | "ACTION" | "DEFAULT" | null
type DataFormat = "csv" | "txt" | null

const imageSize = {
  width: 1000,
  height: 700
}
//----------------------------------------------------------------
export function DownloadModal({ isOpen, onConfirm, onCancel,  preSelectedTab, onSelectDelimitedArea }: DownloadModalProps) {
  // refs
  const formRef = useRef<FormHandles>(null)
  const cancelButtonRef = useRef(null)
  // custom hooks
  const {
    displayCounting,
    setDisplayCounting,
    setDisplayTotalCounting,
    displayTotalCounting,
    tracking,
    savedToken,
  } = usePredictionsMenu()
  const { detectedClasses } = usePredictions()
  const { videoPredictions, imagePredictions, fileUrl, metadata, selectedFile } = usePredictions()
  const { videoMetadata, fileType } = useFileLoader()
  const { delimitedAreas, parkingLotAreas, areasType } = useRegionConfiguration()
  const { labelsPosition, setLabelsPosition } = useContext(DragDropContext)
  // react hooks
  const [isCustom, setIsCustom] = useState(false)
  const [preselectedMenu, setPreselectedMenu] = useState(INDUSTRIES_MENU)
  const [detectableItems, setDetectableItems] = useState<typeof INDUSTRIES_MENU[0] | null>(null)
  const [usedTokens, setUsedTokens] = useState(0)
  const [openPopover, setOpenPopover] = useState(false);
  const [tokenPopover, setTokenPopover] = useState(false);
  const [selectedClasses, setSelectedClasses] = useState(Array.from(detectedClasses ?? []))
  const [selectedAreas, setSelectedAreas] = useState(areasType === "DEFAULT" ? Array.from(delimitedAreas ?? []) : Array.from(parkingLotAreas ?? []))
  const [instantCounting, setInstantCounting] = useState(false)
  const [displayedLabelsType, setDisplayedLabelsType] = useState<"AREA" | "DETECTIONS" | "ALL">("ALL")
  const [selectedTab, setSelectedTab] = useState<number>(preSelectedTab ?? 1)

  // -------
  const [selectedFormat, setSelectedFormat] = useState<DataFormat>(null)
  const [selectedDataType, setSelectedDataType] = useState<DataType>(null)
  const [includeFrames, setIncludeFrames] = useState(false)
  const [selectError, setSelectError] = useState(false)
  const [previewData, setPreviewData] = useState<string[]>([])

  //popover triggers
  const tokenTriggers = {
    onMouseEnter: () => setTokenPopover(true),
    onMouseLeave: () => setTokenPopover(false),
  };
  const triggers = {
    onMouseEnter: () => setOpenPopover(true),
    onMouseLeave: () => setOpenPopover(false),
  };
  //----------------------------------------------------------------------------

  useEffect(() => {
    setPreselectedMenu(preselectedMenu.map((m) => {
      if (m.id === preselectedMenu[1].id) {
        return { ...m, active: true }
      }
      return { ...m, active: false }
    }))
    setIsCustom(false)
    setDetectableItems(preselectedMenu.filter((i) => i.id === preselectedMenu[1].id)[0])
  }, [])

  useEffect(() => {
    setSelectedAreas(Array.from(delimitedAreas ?? []));
  }, [delimitedAreas])

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue('token', savedToken);
    }
  }, [savedToken, formRef, isOpen])


  const handleSelectClass = (className: string) => {
    // Create a copy of the selectedClasses array
    const updatedSelectedClasses = [...selectedClasses];

    // Check if the className already exists in the array
    const index = updatedSelectedClasses.indexOf(className);

    // If the className exists, remove it from the array
    if (index !== -1) {
      updatedSelectedClasses.splice(index, 1);
    } else {
      // If the className does not exist, add it to the array
      updatedSelectedClasses.push(className);
    }

    // Update the state with the new selectedClasses array
    setSelectedClasses(updatedSelectedClasses);
  }

  const handleSelectArea = (areaId: number) => {
    // Find the area object from delimitedAreas by the areaId
    const area = areasType === "DEFAULT" ? delimitedAreas.find((area: any) => area.ID === areaId) : parkingLotAreas.find((area: any) => area.ID === areaId);

    // If the area was found, proceed with the update
    if (area) {
      // Create a copy of the selectedAreas array
      const updatedSelectedAreas = [...selectedAreas];

      // Check if the area object already exists in the selectedAreas array
      const index = updatedSelectedAreas.findIndex((selectedArea: any) => selectedArea.ID === areaId);

      // If the area object exists, remove it from the array
      if (index !== -1) {
        updatedSelectedAreas.splice(index, 1);
      } else {
        // If the area object does not exist, add it to the array
        updatedSelectedAreas.push(area);
      }

      // Update the state with the new selectedAreas array
      setSelectedAreas(updatedSelectedAreas);
    } else {
      console.error(`Area with ID ${areaId} not found in delimitedAreas`);
    }
  };


  const handleConfirm = () => {
    if (selectedClasses.length === 0) {
      return
    }
    if(fileType === "image"){
      onConfirm(selectedClasses, "instant", [])
      return
    }
    const counting = displayCounting ? "instant" : displayTotalCounting ? "total" : null
    onConfirm(selectedClasses, counting, areasType === "DEFAULT" ? selectedAreas : parkingLotAreas)
  }

  const handleSelectAll = () => {
    if (detectedClasses && Array.from(detectedClasses).every(c => selectedClasses.includes(c))) {
      setSelectedClasses([]);
    } else {
      setSelectedClasses(Array.from(detectedClasses ?? []));
    }
  };

  const handleSelectAllAreas = () => {
    if(areasType === "DEFAULT") {
      if (delimitedAreas && Array.from(delimitedAreas).every(c => selectedAreas.includes(c))) {
        setSelectedAreas([]);
      } else {
        setSelectedAreas(Array.from(delimitedAreas ?? []));
      }
    }else{
      if (parkingLotAreas && Array.from(parkingLotAreas).every(c => selectedAreas.includes(c))) {
        setSelectedAreas([]);
      } else {
        setSelectedAreas(Array.from(parkingLotAreas ?? []));
      }
    }

  };

  const handleChangeTab = (tab: number) => {
    setSelectedTab(tab)
  }
  // --------

  const downloadMetadata = async () => {
    // const video = document.getElementById('video') as HTMLVideoElement;

    if (!fileUrl) return
    if (!selectedFormat) return
    if (!videoMetadata) return
    if (!selectedDataType) {
      setSelectError(true)
      return
    }

    if (videoPredictions) {
      const prom = new Promise<any>(async (resolve, reject) => {
        try {
          const fps = videoPredictions.length / videoMetadata.duration
          console.log("0")

          const frames = includeFrames ? await extractFrames(fps, selectedFile) : null

          switch (selectedDataType) {
            case "YOLO":
              const yolo = convertVideoToYOLO(videoPredictions, imageSize.width, imageSize.height, selectedFormat)
              await downloadYOLOAnnotations(yolo, selectedFormat, frames)
              resolve("")
              break

            case "DEFAULT":
              downloadObjectAsJson(metadata, 'annotations', frames);
              resolve("")
              break
          }

        } catch (err) {
          reject(err)
        }
      })

      toast.promise(prom, {
        loading: 'Extracting frames...',
        success: 'Extraction completed!',
        error: 'Error processing the image',
      },
        {
          success: {
            duration: 5000,
          }
        },);

      closeModal()
      return
    } else if (imagePredictions) {


      switch (selectedDataType) {
        case "YOLO":
          const yolo = convertImageToYOLO(imagePredictions, imageSize.width, imageSize.height, selectedFormat)
          await downloadImageYOLOAnnotations(yolo, selectedFormat)
          break

        case "DEFAULT":
          downloadObjectAsJson(metadata, 'annotations');
          break
      }

      return
    }
    closeModal()
    return
  }


  function closeModal() {
    onCancel()
  }

  return (
    <Transition.Root show={isOpen} as={Fragment} >
      <Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={onCancel} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 dark:bg-black bg-opacity-75 dark:bg-opacity-70 transition-opacity" />
        </Transition.Child>


        <div className="fixed inset-0 z-40 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {selectedTab === 1 ? (
                <Dialog.Panel className="text-gray-600 dark:text-gray-300 relative transform overflow-hidden min-w-[860px] rounded-lg  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">

                  <div className="flex cursor-pointer">
                    <button onClick={() => handleChangeTab(1)} className={classNames(
                      "p-3  rounded-tl-lg text-center",
                      selectedTab === 1 ? "dark:bg-neutral-900 bg-white " : "bg-gray-300 dark:bg-neutral-700"
                    )} >Download video/Image</button>
                    <button onClick={() => handleChangeTab(2)} className={classNames(
                      "p-3  rounded-tr-lg text-center",
                      "bg-gray-300 dark:bg-neutral-700"
                    )} >
                      Download Metadata
                    </button>
                  </div>

                  <div className="dark:bg-neutral-900 bg-white w-full px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start w-full">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ArrowDownTrayIcon className="h-6 w-6 text-primary-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6  text-gray-900 dark:text-gray-200">
                          Download Preferences
                        </Dialog.Title>


                        <div className="mt-2 w-full relative select-none">

                          <p className="text-sm">
                            Choose the detection elements you'd like to include in the video/image.
                          </p>

                          {/* MAIN */}
                          <div onClick={() => {
                            handleSelectAll()
                          }}
                            className="w-40 cursor-pointer group justify-between items-center flex px-3 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800" >
                            <p
                              onClick={() => {
                                handleSelectAll()
                              }}
                              className={
                                classNames(
                                  "w-full capitalize",
                                )
                              } >
                              Select all
                            </p>
                            <Checkbox
                              id={"select_all"}
                              color='blue'
                              onChange={() => {
                                handleSelectAll()
                              }}
                              ripple={true}
                              checked={detectedClasses ? Array.from(detectedClasses).every(c => selectedClasses.includes(c)) : false}
                            />
                          </div>

                          <div className="flex">
                            <div className="flex w-full flex-wrap  mt-2 gap-2 items-center text-sm ">
                              {detectedClasses && Array.from(detectedClasses).map((c) => (
                                <div onClick={() => {
                                  handleSelectClass(c)
                                }}
                                  className="w-40 cursor-pointer group justify-between items-center flex px-3 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800" >
                                  <img className="w-6 mr-2" src={getClassIconById(c) ?? ""} alt="" />
                                  <p
                                    onClick={() => {
                                      handleSelectClass(c)
                                    }}
                                    className={
                                      classNames(
                                        "w-full capitalize",
                                      )
                                    } >
                                    {c}
                                  </p>
                                  <Checkbox
                                    id={c}
                                    color='blue'
                                    onChange={() => {
                                      handleSelectClass(c)
                                    }}
                                    ripple={true}
                                    checked={selectedClasses.includes(c)}
                                  />
                                </div>
                              ))}

                            </div>

                            <div className="w-full flex justify-center" >
                              <div className="max-w-[300px] mt-2 " >
                                <DownloadPreview
                                  selectedClasses={selectedClasses}
                                  selectedAreas={selectedAreas}
                                  parkingAreas={areasType === "PARKING"}
                                  areaLabels={(displayedLabelsType === "ALL" || displayedLabelsType === "AREA")}
                                  countingLabels={(displayedLabelsType === "ALL" || displayedLabelsType === "DETECTIONS")}
                                />
                              </div>
                            </div>
                          </div>


                          {/* Separator*/}
                          <div className="w-[100%] h-[2px] flex items-center justify-center shadow-md shadow-blue-500 my-4" >
                            <div className="w-[85%] h-[1px] bg-neutral-100 opacity-50  shadow-md shadow-blue-500" />
                          </div>
                          <div className="h-[250px] overflow-scroll pb-4 hide-scrollbar" >

                            <p className="text-sm font-bold">
                              Display counting labels?
                            </p>

                            {/* Labels configuration */}
                            <div className="flex w-full  mt-2 gap-2  items-center text-sm select-none">

                              <div className="w-48 cursor-pointer group justify-between items-center flex px-3 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800" >
                                <p
                                  onClick={(e) => {
                                    setDisplayCounting((e) => !e)
                                    setDisplayTotalCounting(false)
                                  }}
                                  className={
                                    classNames(
                                      "w-full",
                                    )
                                  } >
                                  Instant Counting
                                </p>
                                <Checkbox
                                  id="instant_counting"
                                  color='blue'
                                  onChange={() => {
                                    setDisplayCounting((e) => !e)
                                    setDisplayTotalCounting(false)
                                  }}
                                  ripple={true}
                                  checked={displayCounting}
                                />
                              </div>

                              {videoPredictions && (
                                <Popover
                                  // placement='top-start'
                                  open={!tracking && openPopover}
                                  handler={setOpenPopover}
                                  animate={{
                                    mount: { scale: 1, y: 0, transition: { duration: 0.3 } },
                                    unmount: { scale: 0, y: 25, transition: { duration: 0.3 } },
                                  }}
                                >
                                  <PopoverHandler {...triggers}>
                                    <div className="w-48 cursor-pointer group justify-between items-center flex px-3 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800"  >

                                      <p
                                        onClick={(e) => {
                                          if (tracking) {
                                            setDisplayTotalCounting((e) => !e)
                                            setDisplayCounting(false)
                                          }
                                        }}
                                        className={
                                          classNames(
                                            "w-full",
                                            tracking ? "" : "text-neutral-400"
                                          )
                                        } >
                                        Total Counting
                                      </p>
                                      <Checkbox
                                        id="total_counting"
                                        color='blue'
                                        onChange={() => {
                                          if (tracking) {
                                            setDisplayTotalCounting((e) => !e)
                                            setDisplayCounting(false)
                                          }
                                        }}
                                        ripple={true}
                                        checked={tracking ? displayTotalCounting : tracking}
                                      />
                                    </div>

                                  </PopoverHandler>
                                  <PopoverContent className="z-50" {...triggers} >
                                    <p>To display total counting you need to obtain predictions with <strong>Tracking enabled</strong></p>
                                  </PopoverContent>
                                </Popover>
                              )}
                            </div>

                            {(displayTotalCounting || displayCounting) && (
                              <>

                                <p className="text-sm mt-8 font-bold">
                                  Displayed side:
                                </p>

                                <div className="flex w-full  mt-2 gap-2  items-center text-sm select-none">

                                  <div
                                    onClick={(e) => {
                                      setLabelsPosition("TL")
                                    }}
                                    className=" cursor-pointer group justify-between items-center flex px-3 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800" >
                                    <p
                                      onClick={(e) => {
                                        setLabelsPosition("TL")
                                      }}
                                      className={
                                        classNames(
                                          "w-full",
                                        )
                                      } >
                                      Top left
                                    </p>
                                    <Checkbox
                                      id="instant_counting"
                                      color='blue'
                                      onChange={() => {
                                        setLabelsPosition("TL")
                                      }}
                                      ripple={true}
                                      checked={labelsPosition === "TL"}
                                    />
                                  </div>

                                  <div onClick={(e) => {
                                    setLabelsPosition("TR")
                                  }}
                                    className=" cursor-pointer group justify-between items-center flex px-3 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800" >
                                    <p
                                      onClick={(e) => {
                                        setLabelsPosition("TR")
                                      }}
                                      className={
                                        classNames(
                                          "w-full",
                                        )
                                      } >
                                      Top right
                                    </p>
                                    <Checkbox
                                      id="instant_counting"
                                      color='blue'
                                      onChange={() => {
                                        setLabelsPosition("TR")
                                      }}
                                      ripple={true}
                                      checked={labelsPosition === "TR"}
                                    />
                                  </div>

                                  <div
                                    onClick={(e) => {
                                      setLabelsPosition("BL")
                                    }}
                                    className=" cursor-pointer group justify-between items-center flex px-3 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800" >
                                    <p
                                      onClick={(e) => {
                                        setLabelsPosition("BL")
                                      }}
                                      className={
                                        classNames(
                                          "w-full",
                                        )
                                      } >
                                      Bottom left
                                    </p>
                                    <Checkbox
                                      id="instant_counting"
                                      color='blue'
                                      onChange={() => {
                                        setLabelsPosition("BL")
                                      }}
                                      ripple={true}
                                      checked={labelsPosition === "BL"}
                                    />
                                  </div>

                                  <div
                                    onClick={(e) => {
                                      setLabelsPosition("BR")
                                    }}
                                    className=" cursor-pointer group justify-between items-center flex px-3 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800" >
                                    <p
                                      onClick={(e) => {
                                        setLabelsPosition("BR")
                                      }}
                                      className={
                                        classNames(
                                          "w-full",
                                        )
                                      } >
                                      Bottom right
                                    </p>
                                    <Checkbox
                                      id="instant_counting"
                                      color='blue'
                                      onChange={() => {
                                        setLabelsPosition("BR")
                                      }}
                                      ripple={true}
                                      checked={labelsPosition === "BR"}
                                    />
                                  </div>

                                </div>

                                <p className="text-sm mt-8 font-bold">
                                  Displayed labels:
                                </p>

                                <div className="flex w-full  mt-2 gap-2  items-center text-sm select-none">

                                  <div
                                    onClick={(e) => {
                                      setDisplayedLabelsType("ALL")
                                    }}
                                    className=" cursor-pointer group justify-between items-center flex px-3 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800" >
                                    <p
                                      onClick={(e) => {
                                        setDisplayedLabelsType("ALL")
                                      }}
                                      className={
                                        classNames(
                                          "w-full",
                                        )
                                      } >
                                      All labels
                                    </p>
                                    <Checkbox
                                      id="instant_counting"
                                      color='blue'
                                      onChange={() => {
                                        setDisplayedLabelsType("ALL")
                                      }}
                                      ripple={true}
                                      checked={displayedLabelsType === "ALL"}
                                    />
                                  </div>

                                  <div onClick={(e) => {
                                    setDisplayedLabelsType("DETECTIONS")
                                  }}
                                    className=" cursor-pointer group justify-between items-center flex px-3 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800" >
                                    <p
                                      onClick={(e) => {
                                        setDisplayedLabelsType("DETECTIONS")
                                      }}
                                      className={
                                        classNames(
                                          "w-full",
                                        )
                                      } >
                                      Detections only
                                    </p>
                                    <Checkbox
                                      id="instant_counting"
                                      color='blue'
                                      onChange={() => {
                                        setDisplayedLabelsType("DETECTIONS")
                                      }}
                                      ripple={true}
                                      checked={displayedLabelsType === "DETECTIONS"}
                                    />
                                  </div>

                                  <div
                                    onClick={(e) => {
                                      setDisplayedLabelsType("AREA")
                                    }}
                                    className=" cursor-pointer group justify-between items-center flex px-3 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800" >
                                    <p
                                      onClick={(e) => {
                                        setDisplayedLabelsType("AREA")
                                      }}
                                      className={
                                        classNames(
                                          "w-full",
                                        )
                                      } >
                                      Areas only
                                    </p>
                                    <Checkbox
                                      id="instant_counting"
                                      color='blue'
                                      onChange={() => {
                                        setDisplayedLabelsType("AREA")
                                      }}
                                      ripple={true}
                                      checked={displayedLabelsType === "AREA"}
                                    />
                                  </div>

                                </div>
                              </>
                            )}

                            {/* Separator*/}
                            <div className="w-[100%] h-[2px] flex items-center justify-center shadow-md shadow-blue-500 my-4" >
                              <div className="w-[85%] h-[1px] bg-neutral-100 opacity-50  shadow-md shadow-blue-500" />
                            </div>

                            {/* Area configuration */}
                            {areasType === "DEFAULT" ? (<>
                              {delimitedAreas && Array.from(delimitedAreas).length > 0 ? (
                                <>
                                  <div onClick={() => {
                                    handleSelectAllAreas()
                                  }}
                                    className="w-52 cursor-pointer group justify-between items-center flex px-3 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800" >
                                    <p
                                      onClick={() => {
                                        handleSelectAllAreas()
                                      }}
                                      className={
                                        classNames(
                                          "w-full capitalize",
                                        )
                                      } >
                                      Include all regions
                                    </p>
                                    <Checkbox
                                      id={"select_all"}
                                      color='blue'
                                      onChange={() => {
                                        handleSelectAllAreas()
                                      }}
                                      ripple={true}
                                      checked={delimitedAreas ? Array.from(delimitedAreas).every(c => selectedAreas.includes(c)) : false}
                                    />
                                  </div>
                                  <div className="flex w-full flex-wrap  mt-2 gap-2 items-center text-sm ">
                                    {delimitedAreas && Array.from(delimitedAreas).map((c: any) => (
                                      <div onClick={() => {
                                        handleSelectArea(c.ID)
                                      }}
                                        className=" cursor-pointer group justify-between items-center flex px-3 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800" >
                                        <p
                                          onClick={() => {
                                            handleSelectArea(c.ID)
                                          }}
                                          className={
                                            classNames(
                                              "w-full capitalize",
                                            )
                                          } >
                                          {JSON.stringify(c.TARGET_CLASS_NAME).replaceAll(",", ", ").replace(" • CLASS", "").replaceAll("\"", "")}
                                        </p>
                                        <div
                                          className="w-10 h-4 block ml-2 rounded-xl"
                                          style={{
                                            backgroundColor: ("rgba(" + c["COLOUR"].map((item: number) => (item + "")).join(", ") + ")"),
                                          }} />
                                        <Checkbox
                                          id={JSON.stringify(c.TARGET_CLASS_NAME).replaceAll(",", ", ").replace(" • CLASS", "").replaceAll("\"", "")}
                                          color='blue'
                                          onChange={() => {
                                            handleSelectArea(c.ID)
                                          }}
                                          ripple={true}
                                          checked={selectedAreas.includes(c)}
                                        />
                                      </div>
                                    ))}

                                    <div
                                      onClick={onSelectDelimitedArea}
                                      className=" cursor-pointer group justify-between items-center flex px-3 py-2 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800" >
                                      <p
                                        onClick={onSelectDelimitedArea}
                                        className={
                                          classNames(
                                            "w-full capitalize",
                                          )
                                        } >
                                        Add new area
                                      </p>
                                      <AiOutlinePlusSquare className="w-7 h-7 text-primary-500" />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <Tooltip content={
                                  <div className="w-80">
                                    <Typography
                                      variant="small"
                                      color="white"
                                      className="font-normal opacity-80"
                                    >
                                      Defined new area.
                                    </Typography>
                                  </div>
                                }>
                                  <div
                                    onClick={onSelectDelimitedArea}
                                    className='w-full p-4 flex items-center justify-center border hover:bg-neutral-800 rounded-lg cursor-pointer' >

                                    <p>No defined areas</p>
                                  </div>
                                </Tooltip>
                              )}
                            </>) : (<>
                              {parkingLotAreas && Array.from(parkingLotAreas).length > 0 ? (
                                <>
                                  <div onClick={() => {
                                    handleSelectAllAreas()
                                  }}
                                    className="w-52 cursor-pointer group justify-between items-center flex px-3 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800" >
                                    <p
                                      onClick={() => {
                                        handleSelectAllAreas()
                                      }}
                                      className={
                                        classNames(
                                          "w-full capitalize",
                                        )
                                      } >
                                      Include all regions
                                    </p>
                                    <Checkbox
                                      id={"select_all"}
                                      color='blue'
                                      onChange={() => {
                                        handleSelectAllAreas()
                                      }}
                                      ripple={true}
                                      checked={parkingLotAreas ? Array.from(parkingLotAreas).every(c => selectedAreas.includes(c)) : false}
                                    />
                                  </div>
                                  <div className="flex w-full flex-wrap  mt-2 gap-2 items-center text-sm ">
                                    {parkingLotAreas && Array.from(parkingLotAreas).map((c: any) => (
                                      <div onClick={() => {
                                        handleSelectArea(c.ID)
                                      }}
                                        className=" cursor-pointer group justify-between items-center flex px-3 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800" >
                                        <p
                                          onClick={() => {
                                            handleSelectArea(c.ID)
                                          }}
                                          className={
                                            classNames(
                                              "w-full capitalize",
                                            )
                                          } >
                                          {JSON.stringify(c.TARGET_CLASS_NAME).replaceAll(",", ", ").replace(" • CLASS", "").replaceAll("\"", "")}
                                        </p>
                                        <div
                                          className="w-10 h-4 block ml-2 rounded-xl"
                                          style={{
                                            backgroundColor: ("rgba(" + c["COLOUR"].map((item: number) => (item + "")).join(", ") + ")"),
                                          }} />
                                        <Checkbox
                                          id={JSON.stringify(c.TARGET_CLASS_NAME).replaceAll(",", ", ").replace(" • CLASS", "").replaceAll("\"", "")}
                                          color='blue'
                                          onChange={() => {
                                            handleSelectArea(c.ID)
                                          }}
                                          ripple={true}
                                          checked={selectedAreas.includes(c)}
                                        />
                                      </div>
                                    ))}

                                    <div
                                      onClick={onSelectDelimitedArea}
                                      className=" cursor-pointer group justify-between items-center flex px-3 py-2 rounded-xl  hover:bg-neutral-100 dark:hover:bg-neutral-800" >
                                      <p
                                        onClick={onSelectDelimitedArea}
                                        className={
                                          classNames(
                                            "w-full capitalize",
                                          )
                                        } >
                                        Add new area
                                      </p>
                                      <AiOutlinePlusSquare className="w-7 h-7 text-primary-500" />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <Tooltip content={
                                  <div className="w-80">
                                    <Typography
                                      variant="small"
                                      color="white"
                                      className="font-normal opacity-80"
                                    >
                                      Defined new area.
                                    </Typography>
                                  </div>
                                }>
                                  <div
                                    onClick={onSelectDelimitedArea}
                                    className='w-full p-4 flex items-center justify-center border hover:bg-neutral-800 rounded-lg cursor-pointer' >

                                    <p>No defined areas</p>
                                  </div>
                                </Tooltip>
                              )}
                            </>)}
                          </div>

                          <div className="w-full h-4 dark:bg-neutral-900/50 bg-neutral-50/50 absolute bottom-0 left-0" ></div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="w-full px-12 py-2 flex text-end bg-white dark:bg-neutral-900 justify-end text-primary-500" >
                    <Popover
                      placement='top-end'
                      open={tokenPopover}
                      handler={setTokenPopover}
                      animate={{
                        mount: { scale: 1, y: 0, transition: { duration: 0.3 } },
                        unmount: { scale: 0, y: 25, transition: { duration: 0.3 } },
                      }}
                    >
                      <PopoverHandler {...tokenTriggers}>
                        <QuestionMarkCircleIcon className="w-6 h-6 cursor-pointer text-primary-500 mr-2" />
                      </PopoverHandler>
                      <PopoverContent className="z-50" >
                        <span>Total tokens consumed for generating your predictions</span>
                      </PopoverContent>
                    </Popover>
                    <p>Used Tokens: {usedTokens}
                    </p>

                  </div>

                  {/* Confirm/Cancel buttons */}
                  <div className="bg-gray-50 dark:bg-neutral-800  px-4 py-3 sm:flex sm:px-6">

                    <div className="input-wrapper flex items-center  sm:w-full mb-2 sm:mb-0" >
                    </div>

                    <div className="sm:flex sm:flex-row-reverse" >
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-gradient-to-r from-cyan-500 to-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={handleConfirm}
                      >
                        Confirm
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white dark:border-gray-500 dark:bg-neutral-600 dark:text-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={onCancel}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>

                    </div>


                  </div>

                </Dialog.Panel>
              ) : (
                <Dialog.Panel className="text-gray-600 dark:text-gray-300 relative transform overflow-hidden min-w-[800px] rounded-lg  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="flex cursor-pointer">
                    <button onClick={() => handleChangeTab(1)} className={classNames(
                      "p-3  rounded-tl-lg text-center",
                      selectedTab === 1 ? " dark:bg-neutral-900 bg-white " : "bg-gray-300 dark:bg-neutral-700"
                    )} >Download video/Image</button>
                    <button onClick={() => handleChangeTab(2)} className={classNames(
                      "p-3  rounded-tr-lg text-center",
                      " dark:bg-neutral-900 bg-white "
                    )} >Download Metadata</button>
                  </div>

                  <div className=" dark:bg-neutral-900 bg-white  px-4 pt-5 pb-4 sm:p-6 sm:pb-4 min-h-[250px]">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ArrowDownTrayIcon className="h-6 w-6 text-primary-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 w-full  text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">
                          Select Metadata
                        </Dialog.Title>

                        {videoPredictions && (
                          <div className="mt-2 flex items-center">
                            {/* <p className="text-sm text-gray-500 my-1">Select file format</p> */}

                            <Checkbox
                              id="include_frames"
                              color='blue'
                              onChange={() => {
                                setIncludeFrames((f) => !f)
                              }}
                              ripple={true}
                              checked={includeFrames}
                              label={
                                <p className="text-gray-600 dark:text-gray-300" >
                                  Include frames folder
                                </p>}
                            />
                            <Popover
                              // placement='top-start'
                              open={openPopover}
                              handler={setOpenPopover}
                              animate={{
                                mount: { scale: 1, y: 0, transition: { duration: 0.3 } },
                                unmount: { scale: 0, y: 25, transition: { duration: 0.3 } },
                              }}
                            >
                              <PopoverHandler {...triggers}>
                                <QuestionMarkCircleIcon className="w-6 h-6 text-primary-500 ml-2" />
                              </PopoverHandler>
                              <PopoverContent className="z-50" {...triggers} >
                                <p>This will extract all frames and include it on separate folder</p>
                              </PopoverContent>
                            </Popover>
                          </div>
                        )}


                        <div className="mt-2 text-gray-600 dark:text-gray-300" >
                          <Select error={selectError}
                            className="text-gray-600 dark:text-gray-300"
                            onChange={(e: any) => {
                              setSelectedDataType(e)
                              setSelectError(false)
                            }}
                            size="md" label="Select Data Format">
                            <Option value="YOLO" >YOLO format</Option>
                            <Option value="DEFAULT" >Original format</Option>
                            <Option value="ACTION" disabled >Action format</Option>
                          </Select>
                        </div>

                        {selectedDataType === "YOLO" && (
                          <div className="mt-4">
                            <p className="text-sm my-1">Select file format</p>

                            <Checkbox
                              id="txt"
                              color='blue'
                              onChange={() => {
                                setSelectedFormat("txt")
                              }}
                              ripple={true}
                              checked={selectedFormat === "txt"}
                              label={
                                <p className="text-gray-600 dark:text-gray-300" >
                                  txt file
                                </p>}
                            />

                            <Checkbox
                              id="csv"
                              color='blue'
                              onChange={() => {
                                setSelectedFormat("csv")
                              }}
                              ripple={true}
                              checked={selectedFormat === "csv"}
                              label={
                                <p className="text-gray-600 dark:text-gray-300" >
                                  csv file
                                </p>}
                            />
                          </div>

                        )}


                        <div className="mt-4 flex items-center justify-center">
                          {previewData.length > 0 && (
                            <div className="border p-4 rounded-md" >
                              {previewData.map((preview) => (
                                <p className="text-sm my-1 truncate"
                                  style={{ maxWidth: "500px" }}
                                >
                                  {preview}
                                </p>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 dark:bg-neutral-800 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-gradient-to-r from-cyan-500 to-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={downloadMetadata}
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white dark:border-gray-500 dark:bg-neutral-600 dark:text-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={onCancel}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>

                </Dialog.Panel>
              )}

            </Transition.Child>
          </div>
        </div>



      </Dialog>
    </Transition.Root>
  )
}
