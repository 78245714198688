import request from 'superagent';
import { ref, push, query, orderByChild, get, child, set, remove, update } from 'firebase/database';
import { database, storage } from "../services/firebase";
import { getFileExtension, getFileType } from '../utils';

let deepNeuronicAPIUrl = process.env.REACT_APP_API_BASE_URL || "https://playgroundapi.deepneuronic.com"
let nodeAPIUrl = process.env.REACT_APP_NODE_API_URL || "https://playgroundapi-aux-prod.deepneuronic.com"
// nodeAPIUrl = "http://localhost:3333"


export const getTokenInfo = async (token: string) => {
  const url = `${deepNeuronicAPIUrl}/DeepNeuronicAPI/token/${token}`;
  return new Promise(async (resolve, reject) => {
    try {
      const req = request.get(url);
      req.end(async (err, res) => {
        if (err) {
          console.error(err); // handle any errors
          throw (err)
        } else {
          if (res.body.token_id) {
            resolve(res.body)
          } else {
            resolve(null)
          }
          return
        }
      });
    } catch (err) {
      console.log("Error getting token information", err);
      reject(err)
      return
    }
  })
}

/**
 * Extracts framed from a given video.
 * @param {number} frameRate - The image predictions data
 * @returns {Promise<Blob | undefined>} - Blob with extracted frames
 */
export const extractFrames = async (frameRate: number, selectedFile: any): Promise<Blob | undefined> => {
  const url = `${nodeAPIUrl}/api/extract-frames`; // replace with the actual API endpoint
  if (!selectedFile) return;

  return new Promise<Blob>((resolve, reject) => {
    try {
      const req = request.post(url);
      req.attach("video", selectedFile);
      req.field("frameRate", JSON.stringify(frameRate));
      req.responseType('blob') // Add this line to handle the response as binary data

      req.end(async (err, res) => {
        if (err) {
          console.error(err); // handle any errors
          reject(err);
        } else {

          if (res.body) {
            const framesZipBlob = new Blob([res.body], { type: 'application/zip' });
            // const url = URL.createObjectURL(framesZipBlob);
            // downloadFile(url);
            console.log(framesZipBlob)

            resolve(framesZipBlob);

          } else {
            console.error('No file received');
            reject('No file received')
          }
        }
      });
    } catch (err) {
      console.log("Error processing video", err);
      reject(err);
    }
  });
}

export async function uploadFileToGoogleCloud(file) {
  try {
    // Get the signed URL from node server 
    const response = await fetch(`${nodeAPIUrl}/api/generate-signed-url`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileName: file.name, contentType: file.type })
    });

    if (!response.ok) {
      throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
    }

    const { url, fileID } = await response.json();

    console.log("url generated:", url, "fileID:", fileID);

    // Upload the file to Google Cloud Storage using the signed URL
    const uploadResponse = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type
      },
      body: file
    });

    if (!uploadResponse.ok) {
      throw new Error(`Upload failed: ${uploadResponse.status}`);
    }
    console.log('File uploaded successfully.');
    return fileID
  } catch (error) {
    console.error('Error during file upload:', error);
  }
}

//TODO update api url & include config on request body
export async function startPredictionTaskAndPushData(file: any, fileRef: string, fileMetadata: any, actions: string[], token: string, userID?: string, test = false) {

  try {
    let taskId = ""
    let estimatedTime = 0
    // token= "dced69d2448c821d00f1bba84cea1d08"

    if (test) {
      const response = await fetch(`${nodeAPIUrl}/test/start-task`);
      const data = await response.json();
      taskId = data.taskId;
      console.log(`Task started. ID: ${taskId}, file ref: ${fileRef}`);
      console.log("actions", actions)

    } else {
      // Assuming request is set up to handle multipart/form-data and JSON properly
      const res = await request.post(`${deepNeuronicAPIUrl}/DeepNeuronicAPI/predictions`)
        .field("actions", actions)
        .field("fileID", fileRef)
        .field("fileType", getFileType(file))
        .field("fileExtension", getFileExtension(file.type))
        .field("tokenID", "deepneuronic_playground");

      taskId = res.body.task_id;
      estimatedTime = res.body.estimed_time ?? 10

      console.log(`Task started. ID: ${taskId}, file ref: ${fileRef}`);
      console.log(res)
    }

    console.log("fileMetadata", fileMetadata)

    const firebaseRecordRef = userID ? await push(ref(database, `users/${userID}/predictions/`), {
      json: {},
      detections: [],
      timeStamp: Date.now(),
      filePath: `filesToProcess/${fileRef}${getFileExtension(file.type)}`,
      fileID: fileRef,
      fileType: file.type,
      fileMetadata: fileMetadata,
      dimensions:{width: fileMetadata.width, height: fileMetadata.height},
      status: "PROCESSING",
      taskId
    }) : null;

    console.log("firebaseRed", firebaseRecordRef)

    return { firebaseRecordRef, taskId, estimatedTime }
  } catch (err) {
    throw new Error("failed starting task")
  }

}


export function checkTaskStatus(taskId: string, userID: string, firebaseRecordRef?: any, test = false): Promise<any> {
  return new Promise(async (resolve, reject) => {
    const intervalId = setInterval(async () => {
      try {
        if (test) {
          const response = await fetch(`${nodeAPIUrl}/test/task-status/${taskId}`);
          const data = await response.json();

          if (data.status === 'COMPLETED') {
            console.log('Task completed! Updating Firebase record.');

            if (firebaseRecordRef) {
              // Update the Firebase record
              await update(ref(database, `users/${userID}/predictions/${firebaseRecordRef}`), {
                status: "COMPLETED",
                json: JSON.stringify(data.result) // assuming data.result holds the response from the task
              });

            }

            clearInterval(intervalId);
            resolve({ data: data.result.predictions["generic_inference.yaml"][0], status: data.status }); // Resolve the promise with the formatted data


          } else if (data.status === "FAILED") {
            if (firebaseRecordRef) {
              await update(ref(database, `users/${userID}/predictions/${firebaseRecordRef}`), {
                status: "FAILED",
              });
            }

            clearInterval(intervalId);
            resolve({ data: [], status: data.status }); // Resolve the promise with the formatted data
          } else {
            console.log('Task still in progress...');
          }
          return
        }
        const response = await fetch(`${deepNeuronicAPIUrl}/DeepNeuronicAPI/task/${taskId}`);
        const data = await response.json();

        console.log("status response", data)

        if (data.task_status === 'SUCCESS') {
          console.log('Task completed! Updating Firebase record.');
          if (firebaseRecordRef) {
            // Update the Firebase record
            await update(ref(database, `users/${userID}/predictions/${firebaseRecordRef}`), {
              status: "COMPLETED",
              json: JSON.stringify(data.task_result.predictions["generic_inference.yaml"][0]) // assuming data.result holds the response from the task
            });
          }


          clearInterval(intervalId);
          resolve({ data: data.task_result.predictions["generic_inference.yaml"][0], status: data.task_status }); // Resolve the promise with the formatted data

        } else if (data.task_status === "FAILURE") {
          if (firebaseRecordRef) {
            await update(ref(database, `users/${userID}/predictions/${firebaseRecordRef}`), {
              status: "FAILED",
            });
          }

          clearInterval(intervalId);
          resolve({ data: [], status: data.status }); // Resolve the promise with the formatted data
          console.log("task failed")
        } else {
          console.log('Task still in progress...');
        }
      } catch (error) {
        console.error("Error checking task status", error);
        clearInterval(intervalId);

        reject(error); // Reject the promise if there's an error
      }
    }, 5000);
  });
}


export async function obtainNewPredictionsTask(fileRef: string, userID: string, itemId: string, fileType: string, replace = false, data = []) {
  try {
    const response = await fetch(`${nodeAPIUrl}/test/start-task`);
    if (!response.ok) throw new Error('Network response was not ok.');

    const responseData = await response.json(); // Renamed to avoid conflict with `initialData`
    const taskId = responseData.taskId;
    console.log(`Task started. ID: ${taskId}, file ref: ${fileRef}`);

    let firebaseRecordRef;
    let newItemId = itemId;
    const commonData = {
      json: {},
      detections: [], // Assuming you want to use `initialData` here
      timeStamp: Date.now(),
      status: "PROCESSING",
      taskId,
      fileType,
      filePath: fileRef
    };

    if (replace) {
      // Update the existing record
      firebaseRecordRef = ref(database, `users/${userID}/predictions/${itemId}`);
      await update(firebaseRecordRef, commonData);
    } else {
      // Create a new record under `predictions`
      const predictionsRef = ref(database, `users/${userID}/predictions`);
      firebaseRecordRef = await push(predictionsRef, commonData);
      newItemId = firebaseRecordRef.key; // Get the newly created item's ID
    }

    return { firebaseRecordRef, taskId, pushedData: commonData, itemId: newItemId, };
  } catch (error) {
    console.error("Error in obtainNewPredictionsTask:", error);
    throw error; // or handle it as needed
  }
}


const sample = {
  "generic_inference.yaml": [
    {
      "e1cd054516995a8965a403b6a8b88e13.mp4": [
        {
          "1": {},
          //...
          "18": {
            "person_97_0.28": [
              44,
              314,
              23,
              65
            ]
          },
          "19": {
            "person_97_0.27": [
              44,
              314,
              23,
              65
            ]
          },

        },
        1280,
        720,
        -1,
        -1,
        "-"
      ],
      "model_predictions": [
        {
          "1": {},
          //...
          "18": {
            "person_97_0.28": [
              44,
              314,
              23,
              65
            ]
          },
          "19": {
            "person_97_0.27": [
              44,
              314,
              23,
              65
            ]
          },

        },
        1280,
        720,
        -1,
        -1,
        "-"
      ]
    },

    {

    }
  ]
}


// "csv": {
//   "e1cd054516995a8965a403b6a8b88e13.mp4": "18,person_97_0.28,44,314,23,65\n19,person_97_0.27,44,314,23,65\n20,person_97_0.27,43,314,23,66\n24,person_97_0.28,42,316,23,64\n46,person_98_0.26,431,450,17,27\n80,zone-1 • person_99_0.25,912,682,15,37\n82,zone-1 • person_100_0.25,543,546,16,26\n85,zone-1 • person_100_0.27,553,555,16,26\n85,zone-1 • person_101_0.28,557,558,19,24\n86,zone-1 • person_102_0.27,584,681,16,37\n86,zone-1 • person_103_0.27,592,682,17,37\n94,zone-1 • person_104_0.31,668,618,19,38\n95,zone-1 • person_104_0.26,671,622,16,33\n103,person_97_0.28,43,318,21,60\n104,person_97_0.25,44,319,21,59\n105,person_97_0.29,43,318,21,59\n107,zone-1 • person_105_0.26,881,661,15,43\n109,zone-1 • person_106_0.36,764,698,25,21\n118,person_97_0.26,43,318,20,59\n119,person_97_0.29,44,318,20,59\n132,person_97_0.28,44,319,20,59\n137,zone-1 • person_107_0.26,671,681,19,36\n145,person_97_0.33,43,318,20,59\n210,person_97_0.4,43,318,21,61\n211,person_97_0.38,43,318,21,62\n325,zone-1 • person_108_0.26,714,548,13,37\n326,zone-1 • person_108_0.29,714,547,14,39\n331,zone-1 • person_108_0.26,714,546,13,38\n348,zone-1 • person_108_0.25,707,542,13,36\n421,zone-1 • person_109_0.28,943,695,16,24\n508,person_110_0.27,544,383,15,24\n509,person_110_0.26,544,384,14,23\n512,person_110_0.26,544,384,14,22\n513,person_110_0.26,543,383,15,23\n514,person_110_0.26,543,383,15,23\n515,person_110_0.26,543,383,15,24\n516,person_110_0.25,543,384,15,24\n"
// },
// "yolo": {
//   "e1cd054516995a8965a403b6a8b88e13.mp4": "18 20 0.043 0.481 0.018 0.09\n19 20 0.043 0.481 0.018 0.09\n20 20 0.042 0.482 0.018 0.092\n24 20 0.041 0.483 0.018 0.089\n46 20 0.343 0.643 0.013 0.037\n80 113 0.718 0.972 0.012 0.051\n82 113 0.43 0.776 0.013 0.036\n85 113 0.438 0.789 0.013 0.036\n85 113 0.442 0.792 0.015 0.033\n86 113 0.463 0.971 0.013 0.051\n86 113 0.469 0.972 0.013 0.051\n94 113 0.529 0.885 0.015 0.053\n95 113 0.53 0.886 0.013 0.046\n103 20 0.041 0.483 0.016 0.083\n104 20 0.042 0.483 0.016 0.082\n105 20 0.041 0.482 0.016 0.082\n107 113 0.694 0.947 0.012 0.06\n109 113 0.606 0.983 0.02 0.029\n118 20 0.041 0.482 0.016 0.082\n119 20 0.042 0.482 0.016 0.082\n132 20 0.042 0.483 0.016 0.082\n137 113 0.531 0.971 0.015 0.05\n145 20 0.041 0.482 0.016 0.082\n210 20 0.041 0.483 0.016 0.085\n211 20 0.041 0.485 0.016 0.086\n325 113 0.562 0.786 0.01 0.051\n326 113 0.563 0.786 0.011 0.054\n331 113 0.562 0.785 0.01 0.053\n348 113 0.557 0.778 0.01 0.05\n421 113 0.743 0.982 0.013 0.033\n508 20 0.43 0.549 0.012 0.033\n509 20 0.43 0.549 0.011 0.032\n512 20 0.43 0.549 0.011 0.031\n513 20 0.43 0.547 0.012 0.032\n514 20 0.43 0.547 0.012 0.032\n515 20 0.43 0.549 0.012 0.033\n516 20 0.43 0.55 0.012 0.033\n"
// }