/**
 * App Component.
 * 
 * This is the main component of the application. It sets up the routing for different pages and initializes Google Analytics.
 * It uses React Router for navigation and React DND for drag-and-drop functionalities.
 *
 * Features:
 * - Routing: Manages the application's navigation to different pages like Dashboard, Login, Predictions, etc.
 * - Google Analytics: Integrates with Google Analytics for tracking page views and user interactions.
 * - Drag-and-Drop: Enables drag-and-drop features across the application using React DND.
 *
 * Routes:
 * - "/": Renders the Dashboard page.
 * - "/login": Navigates to the Login page.
 * - "/recover-password": Displays the Recover Password page.
 * - "/signup": Directs to the Sign Up page.
 * - "/mypredictions": Shows the Predictions Page.
 * - "/settings": Leads to the Settings page.
 * - "/admin/feedbacks": Opens the Admin page for feedback management.
 */

// React and Libraries
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactGA from "react-ga4";

// Styles
import "./styles/global.scss";

// Pages
import { Admin, Login, PredictionsPage, RecoverPassword, SampleLoader, Settings, SignUp } from './pages';
import Dashboard from './pages/Dashboard';

// Initialize Google Analytics
ReactGA.initialize("your GA measurement id");
//------------------------------------------------------------------------------

interface DemoPageProps {
  test?: boolean;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard/>,
  },
  {
    path: "/login",
    element: <Login/>,
  },
  {
    path: "/recover-password",
    element: <RecoverPassword/>,
  },
  {
    path: "/signup",
    element: <SignUp/>,
  },
  {
    path: "/dashboard",
    element: <Dashboard/>,
  },
  {
    path: "/mypredictions",
    element: <PredictionsPage/>,
  },
  {
    path: "/settings",
    element: <Settings/>,
  },
  {
    path: "/admin/feedbacks",
    element: <Admin/>,
  },
  {
    path: "/sample",
    element: <SampleLoader />,
  },
]);

function App() {
  return (
    // @ts-ignore
    <DndProvider backend={HTML5Backend} >
      <RouterProvider router={router} />
    </DndProvider>
  );
}

export default App;