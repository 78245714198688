// menu icons
import CarIcon from "../assets/icons/predictions/car.svg"
import BusIcon from "../assets/icons/predictions/bus.svg"
import TruckIcon from "../assets/icons/predictions/truck.svg"
import TrainIcon from "../assets/icons/predictions/train.svg"
import Motorcycle from "../assets/icons/predictions/motorcycle.svg"
import Bicycle from "../assets/icons/predictions/bicycle.svg"

import Backpack from "../assets/icons/predictions/backpack.svg"
import Handbag from "../assets/icons/predictions/handbag.svg"
import AbandonedObjectIcon from "../assets/icons/predictions/abandoned_object.svg"
import Suitcase from "../assets/icons/predictions/suitcase.svg"

import Fire from "../assets/icons/predictions/fire.svg"
import Smoke from "../assets/icons/predictions/smoke.svg"
import FireRaising from "../assets/icons/predictions/fire_raising.svg"

import PersonIcon from "../assets/icons/predictions/person.svg"
import StandingUp from "../assets/icons/predictions/standing_up.svg"
import Climbing from "../assets/icons/predictions/climbing.svg"
import SittingDown from "../assets/icons/predictions/sitting_down.svg"
import Riding from "../assets/icons/predictions/riding.svg"
import Lying from "../assets/icons/predictions/lying.svg"
import Throwing from "../assets/icons/predictions/throwing.svg"
import Falling from "../assets/icons/predictions/falling.svg"
import Standing from "../assets/icons/predictions/standing.svg"
import Jumping from "../assets/icons/predictions/jumping.svg"
import Walking from "../assets/icons/predictions/walking.svg"
import Running from "../assets/icons/predictions/running.svg"

import Graffiti from "../assets/icons/predictions/graffiti.svg"
import Fighting from "../assets/icons/predictions/fighting.svg"

// use case images
import FireModel from "../assets/images/fire_use_case.png"
import CrowdUseCase from "../assets/images/crowd_use_case.jpeg"
import Traffic from "../assets/images/traffic_use_case.jpeg"
import Vandalism from "../assets/images/vandalism_use_case.jpg"

export const INDUSTRIES_MENU = [
  {
    name: "Crowd Analysis",
    id: "10",
    active: true,
    margin: true,
    background: CrowdUseCase,
    items: [
      { name: "Person", active: true, id: "person", icon:PersonIcon,  model: "normal" },
      { name: "Backpack", active: true, id: "backpack", icon:Backpack,  model: "normal" },
      { name: "Handbag", active: true, id: "handbag", icon:Handbag,  model: "normal" },
      { name: "Walking", active: true, id: "walking", icon:Walking, },
      { name: "Running", active: true, id: "running",icon:Running,  },
      { name: "Standing", active: true, id: "standing",icon:Standing,  },
      { name: "Standing up", active: true, id: "standing_up",icon:StandingUp,  },
      { name: "Sitting down", active: true, id: "sitting_down",icon:SittingDown,  },
      { name: "Jumping", active: true, id: "jumping",icon:Jumping,  },
      { name: "Riding", active: true, id: "riding",icon:Riding,  },
      { name: "Lying", active: true, id: "lying",icon:Lying,  },
      { name: "Throwing", active: true, id: "throwing",icon:Throwing,  },
      { name: "Falling", active: true, id: "falling",icon:Falling,  },
      { name: "Fighting", active: true, id: "fighting", icon: Fighting },
      { name: "Suitcase", active: true, id: "suitcase", icon: Suitcase },
    ],
  },
  {
    name: "Road Traffic Control",
    id: "11",
    active: true,
    margin: true,
    background: Traffic,
    items: [
      { name: "Person", active: true, id: "person", icon:PersonIcon,  model: "normal" },
      { name: "Car", active: true, id: "car", icon:CarIcon,  model: "normal" },
      { name: "Bus", active: true, id: "bus", icon:BusIcon,  model: "normal" },
      { name: "Truck", active: true, id: "truck", icon:TruckIcon,  model: "normal" },
      { name: "Train", active: true, id: "train", icon:TrainIcon,  model: "normal" },
      { name: "Bicycle", active: true, id: "bicycle", icon:Bicycle,  model: "normal" },
      { name: "Motorcycle", active: true, id: "motorcycle", icon:Motorcycle,  model: "normal" },
      // { name: "Airplane", active: true, id: "airplane", icon:CarIcon,  model: "normal" },
      // { name: "Boat", active: true, id: "boat", icon:CarIcon,  model: "normal" },
      // { name: "Traffic Light", active: true, id: "traffic_light", icon:CarIcon,  model: "normal" },
      // { name: "Fire Hydrant", active: true, id: "fire_hydrant", icon:CarIcon,  model: "normal" },
      // { name: "Stop Sign", active: true, id: "stop_sign", icon:CarIcon,  model: "normal" },
      // { name: "Parking Meter", active: true, id: "parking_meter", icon:CarIcon,  model: "normal" },
    ],
  },
  {
    name: "Fire & Smoke Detection",
    id: "12",
    active: true,
    margin: true,
    background: FireModel,
    items: [
      { name: "Fire", active: true, id: "fire", model: "fire", icon:Fire,  },
      { name: "Smoke", active: true, id: "smoke", model: "fire", icon:Smoke,  },
    ],
  },
  {
    name: "Vandalism",
    id: "13",
    active: true,
    margin: true,
    background: Vandalism,
    items: [
      { name: "Graffiti", active: true, id: "graffiti", icon:Graffiti, model: "graffiti" },
    ],
  },

];

export const MENU_ITEMS = [
  {
    name: "Vehicles", id: "1", active: true, items: [
      { name: "Car", active: true, id: "car",  icon:CarIcon,  model: "normal" },
      { name: "Bus", active: true, id: "bus", icon:BusIcon,  model: "normal" },
      { name: "Truck", active: true, id: "truck",icon:TruckIcon,  model: "normal" },
      { name: "Motorbike", active: true, id: "motorbike", icon:Motorcycle,  model: "normal"},
      { name: "Train", active: true, id: "train", icon:TrainIcon,model: "normal" },
      // { name: "Bicycle", active: true, id: "bicycle", icon:PersonIcon, },
      // { name: "Airplane", active: true, id: "airplane",icon:PersonIcon,  },
      // { name: "Boat", active: true, id: "boat", icon:PersonIcon, },
    ]
  },
  {
    name: "People and Actions", id: "2", active: true, items: [
      { name: "Person", active: true, id: "person", icon:PersonIcon,  model: "normal" },
      { name: "Backpack", active: true, id: "backpack", icon:PersonIcon,  model: "normal" },
      { name: "Handbag", active: true, id: "handbag", icon:PersonIcon,  model: "normal" },
      { name: "Walking", active: true, id: "walking", icon:Walking,  },
      { name: "Running", active: true, id: "running",icon:PersonIcon,  },
      { name: "Standing", active: true, id: "standing",icon:PersonIcon,  },
      { name: "Standing up", active: true, id: "standing_up",icon:PersonIcon,  },
      { name: "Sitting down", active: true, id: "sitting_down",icon:PersonIcon,  },
      { name: "Jumping", active: true, id: "jumping",icon:Jumping,  },
      { name: "Riding", active: true, id: "riding",icon:PersonIcon,  },
      { name: "Lying", active: true, id: "lying",icon:PersonIcon,  },
      { name: "Throwing", active: true, id: "throwing",icon:PersonIcon,  },
      { name: "Falling", active: true, id: "falling",icon:PersonIcon,  },
      { name: "Fighting", active: true, id: "fighting", icon: Fighting },
      { name: "Carrying Weapon", active: true, id: "carrying_weapon",icon:PersonIcon,  },
      { name: "Abandoned Object", active: true, id: "abandoned_object",icon:PersonIcon,  },
      { name: "Stealing", active: true, id: "stealing",icon:PersonIcon,  },
      { name: "Shooting", active: true, id: "shooting",icon:PersonIcon,  },
      { name: "Vandalizing", active: true, id: "vandalizing",icon:PersonIcon,  },
      { name: "Road Accident", active: true, id: "road_accident",icon:PersonIcon,  },
    ]
  },

  {
    name: "Environment",
    id: "4",
    active: true,
    items: [
      { name: "Fire", active: true, id: "fire", icon:Fire, model: "fire" },
      { name: "Smoke", active: true, id: "smoke", icon:Smoke, model: "fire" },
      { name: "Graffiti", active: true, id: "graffiti", icon:Graffiti, model: "graffiti" },

    ],
  },
];


export type MenuType = typeof MENU_ITEMS