// react
import { useEffect } from 'react';
// libs
import { useNavigate } from "react-router-dom";
import { CheckBadgeIcon } from '@heroicons/react/24/solid'
// components
import { LoginForm, LoginPattern } from '../components';
import { useAuth } from '../hooks/useAuth';
import Logo from "../assets/images/logo_name.svg"

export function Login() {
  const { authUser } = useAuth()
  const navigate = useNavigate();

  useEffect(() => {
    if (authUser) {
      navigate("/dashboard");
    }
  }, [authUser])

  return (
    <div className="w-full h-screen bg-[#eef0f3] dark:bg-neutral-900 overflow-hidden" >
      <div
        className="relative h-screen w-full flex flex-col  space-y-8 shadow-2xl rounded-2xl md:flex-row md:space-y-0">

        <div className="relative w-full resize-none ">
          <div className="absolute -left-40 -top-20 resize-none z-10 min-w-[1200px] h-full opacity-40">
            <LoginPattern />
          </div>
          <img className="absolute left-6 top-6 w-72" src={Logo} alt="logo" />

          <div className="w-full relative h-full z-10 flex flex-col justify-center text-neutral-800 dark:text-white p-8 px-12 " >
            <div className="dark:bg-neutral-800/40 bg-white/50  p-10 rounded-xl shadow" >
              <h1 className="text-4xl font-extrabold " >
                {/* <Balance> */}
                Maximize Your Video Analysis with AI
                {/* </Balance> */}
              </h1>

              <div className="py-10 " >

                <ul className="mt-3 " >
                  <li className="flex">
                    <CheckBadgeIcon className="w-6 h-6 text-primary-500 mr-2" />
                    <div>
                      <p className="font-extrabold text-2xl" >Fine-grained Detection </p>
                      <p className="dark:text-neutral-400 text-neutral-600 text-lg" > Identify objects and actions with our cutting-edge AI models</p>
                    </div>
                  </li>

                  <li className="flex mt-2">
                    <CheckBadgeIcon className="w-6 h-6 text-primary-500 mr-2" />
                    <div>
                      <p className="font-extrabold text-2xl" >Detailed Analytics Report</p>
                      <p className="dark:text-neutral-400 text-neutral-600 text-lg" > Get access to comprehensive analytics reports, available for download in a user-friendly format. </p>
                    </div>
                  </li>

                  <li className="flex mt-2">
                    <CheckBadgeIcon className="w-6 h-6 text-primary-500 mr-2" />
                    <div>
                      <p className="font-extrabold text-2xl" >Customizable Detection</p>
                      <p className="dark:text-neutral-400 text-neutral-600  text-lg" >Customize your video analysis according to your needs. Focus only on what's crucial to you.</p>
                    </div>
                  </li>
                </ul>
              </div>

            </div>

          </div>
        </div>

        <div className="w-full relative z-20 flex items-center justify-center  min-h-[600px]" >
          <LoginForm />

        </div>


      </div>
    </div>
  );
}

