/**
 * CountingBadge Component.
 * 
 * This component is used to display a draggable badge, often representing a count or a value associated with a specific item. 
 * It's typically used to overlay the image/video.
 *
 * Features:
 * - Draggable: Utilizes 'react-dnd' for drag-and-drop functionality.
 * - Dynamic Content: Displays dynamic content based on the `title` and `value` props.
 * - Customizable: Supports custom class names and inline styles.
 * - Icon Integration: Displays an icon corresponding to the `title` from a predefined menu or a default icon.
 */

// react
import {  useRef } from 'react';
// libs
import { XYCoord, useDrag, useDrop } from 'react-dnd';
// components/assets/helpers
import CarIcon from "../assets/icons/predictions/car.svg"
import { MENU_ITEMS } from '../mock/menu';
import { classNames } from '../utils';

interface CountingBadgeProps {
  /** The title of the badge, used to identify the item and fetch its corresponding icon. */
  title: string;

  /** The value or count to display in the badge. */
  value: string | number;

  /** (Optional) Additional CSS class names for styling. */
  className?: string;

  /** (Optional) Inline styles for further customization. */
  style?: any;

  /** The index of the badge, used in the drag-and-drop logic. */
  index: number;

  /** (Optional) A boolean indicating if the badge is currently being dragged. */
  isDragging?: boolean;
}

export function CountingBadge({ title, value, className, style, index, isDragging }: CountingBadgeProps) {
  const ref = useRef<any>()

  const [{  }, drag, dragPreview] = useDrag(() => ({
    // "type" is required. It is used by the "accept" specification of drop targets.
    type: 'BOX',
    item: { index},
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }))

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: 'BOX',
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),

    }),
    hover(item: any, monitor) {
      const targetIndex = index
      const draggedIndex = item.index

      if(targetIndex === draggedIndex) {
        return;
      }

      const targetSize = ref.current.getBoundingClientRect()
      const targetCenter = (targetSize.bottom - targetSize.top )/ 2

      const draggedOffset = monitor.getClientOffset() as XYCoord
      const draggedTop = draggedOffset.y - targetSize.top

      if(draggedIndex < targetIndex && draggedTop < targetCenter){
        return;
      }

      if(draggedIndex > targetIndex && draggedTop > targetCenter){
        return;
      }
      
      // move(draggedIndex, targetIndex)
      item.index = targetIndex
    }
  }))

  drag(drop(ref))


  function getItemByName(itemName: string) {
    for (let i = 0; i < MENU_ITEMS.length; i++) {
      for (let j = 0; j < MENU_ITEMS[i].items.length; j++) {
        if (MENU_ITEMS[i].items[j].name.toLowerCase() === itemName.toLowerCase()) {
          return MENU_ITEMS[i].items[j];
        }
      }
    }
    return null; // if no item is found
  }

  return (
    <>
      <div
        className={
          classNames(
            " flex items-center  p-1 pr-4 bg-black rounded-full text-white select-none",
            isDragging ? "border border-dashed border-black bg-neutral-900/30" : "",
            className
          )}
        style={style}
      >
        <div className={
          classNames("flex items-center justify-between gap-4",
            isDragging ? "invisible" : "visible",
          )
        } >
          <img src={getItemByName(title)?.icon ?? CarIcon} className="w-6" alt="Icon" />
          <span className="capitalize text-sm">
            {title} ({value})
          </span>
        </div>

      </div>
    </>
  );
}
