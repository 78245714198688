/**
 * PredictionCard Component.
 * 
 * This component serves as a container card for displaying prediction results or related content.
 * It features interactive mouse movement effects and supports selection actions.
 * Used on @PredictionsPage
 *
 * Features:
 * - Clickable: Can be clicked to trigger a selection action, typically used for further interaction or detail viewing.
 * - Flexible Content: Accepts any ReactNode as children, allowing for versatile content display within the card.
 */

// React
import { ReactNode } from 'react';

interface PredictionCardProps {
  /** Child elements or components to be displayed within the card. */
  children: ReactNode;

  /** Function to execute when the card is selected or clicked. */
  onSelect: any;
  /** */
}

export function PredictionCard({ children, onSelect,  }: PredictionCardProps) {


  const handleOnMouseMove = (e: any) => {
    const cards = document.getElementsByClassName("card");

    for (let i = 0; i < cards.length; i++) {
      const card = cards[i] as HTMLElement;
      const rect = card.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      card.style.setProperty("--mouse-x", `${x}px`);
      card.style.setProperty("--mouse-y", `${y}px`);
    }
  }

  return (
    <>
      <div onClick={onSelect} onMouseMove={handleOnMouseMove} className="cursor-pointer duration-200 rounded-2xl relative  max-w-[340px]">
        {/* <div className="card-border" ></div> */}
        <div className=" w-[calc(100%-2px)] m-[1px] bg-neutral-800 flex group flex-col items-center  rounded-2xl z-[2] relative h-[245px]" >
          {children}

        </div>

      </div>
    </>

  );
}
