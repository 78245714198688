/**
 * Component with toggleable categories and items, 
 * designed for filtering and customizing the view of content.
 * Used on @VideoPlayer and @ImageDrawer components
 *
 * The component has an accordion-style structure with expandable categories.
 * for controlling its active state. Users can customize the view by selecting filters
 * based on categories and items.
 */

// react
import { useEffect, useState } from 'react';
// libraries
import { motion } from "framer-motion";
import { Disclosure, Menu, Transition } from '@headlessui/react';

// data
import { MenuType } from '../mock/menu';
import { classNames, shouldRunTour} from '../utils';
// icons & animations
import { fromBottom, translateFromLeft, translateFromTop, expandPaddingRight } from '../utils/animations';
import { ChevronDoubleRightIcon, XMarkIcon, Bars3Icon, ChevronDownIcon, EyeIcon, EyeSlashIcon, ChevronDoubleLeftIcon, PencilSquareIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import ItemIcon from "../assets/icons/menu_icons/item.svg"
import { Checkbox, Popover, PopoverContent, PopoverHandler, Tooltip, Typography } from '@material-tailwind/react';
import { usePredictionsMenu } from '../hooks/usePredictionsMenu';
import { usePredictions } from '../hooks/usePredictions';
import { useRegionConfiguration } from '../hooks/useRegionConfiguration';
//------------------------------------------------------------------------------

interface FilterMenuProps {
  // Determines the visibility of the filter menu.
  visible: boolean;

  // Contains the primary menu data.
  PrimaryMenu: MenuType;

  // Contains the general menu data.
  menu?: MenuType;

  // Callback function to toggle the category visibility by id.
  onCategoryToggle: (id: string) => void;

  // Callback function to toggle an item visibility by its parent category id and item id.
  onItemToggle: (id: string, itemID: string) => void;

  // Callback function to select all items in the menu.
  onSelectAll: () => void;

}
//------------------------------------------------------------------------------

export function FilterMenu({ menu, onCategoryToggle, onItemToggle, PrimaryMenu, onSelectAll, visible }: FilterMenuProps) {
  const [expanded, setExpanded] = useState(true)
  const [openPopover, setOpenPopover] = useState(false);
  const [editToken, setEditToken] = useState(true)
  const [tokenPopover, setTokenPopover] = useState(false);
  const {
    displayCounting,
    setDisplayCounting,
    setDisplayTotalCounting,
    displayTotalCounting,
    tracking,
    savedToken,
    setSavedToken,
    displayAllTags,
    setDisplayAllTags
  } = usePredictionsMenu()
  const {  videoPredictions } = usePredictions()
  const [input, setInput] = useState("")
  const [isAllParkingAreasDisplayed, setIsAllParkingAreasDisplayed] = useState(false)
  const { delimitedAreas, activeAreas, setActiveAreas, areasType, parkingLotAreas, setDisplayedParkingAreas, displayedParkingAreas } = useRegionConfiguration()

  const triggers = {
    onClick: () => setOpenPopover(true),
    onMouseLeave: () => setOpenPopover(false),
  };


  const handleToggleArea = (id: number) => {
    setActiveAreas((prevActiveAreas) => {
      if (prevActiveAreas.includes(id)) {
        return prevActiveAreas.filter(areaId => areaId !== id);
      } else {
        return [...prevActiveAreas, id];
      }
    });
  }

  const handleToggleAllAreas = () => {
    if (areasType === "DEFAULT") {
      if (activeAreas.length === 0) {
        if (delimitedAreas.length > 0) {
          setActiveAreas(delimitedAreas.map((area: any) => area.ID))
        }
      } else {
        setActiveAreas([])
      }
    } else {
      if (displayedParkingAreas.free === false && displayedParkingAreas.occupied === false) {
        setDisplayedParkingAreas({
          free: true,
          occupied: true
        })
      } else {
        setDisplayedParkingAreas({
          free: false,
          occupied: false
        })
      }
    }

  }

  useEffect(() => {
    setInput(savedToken)
  }, [savedToken])

  useEffect(() => {
    if (delimitedAreas.length > 0) {
      setActiveAreas(delimitedAreas.map((area: any) => area.ID))
    }
  }, [delimitedAreas])

  useEffect(() => {
    if (displayedParkingAreas.free === false && displayedParkingAreas.occupied === false) {
      setIsAllParkingAreasDisplayed(false)
    } else {
      setIsAllParkingAreasDisplayed(true)
    }
  }, [displayedParkingAreas])

  const onAnimationComplete = () => {
    if (shouldRunTour('filterMenuTourKey') && PrimaryMenu.length > 0) {
      // setRunGuide(true)
    }
  }

  return (
    <>
      {/* Desktop Menu */}
      <motion.div
        className={classNames("mr-8 lg:block hidden relative text-sm select-none ")}
        variants={expandPaddingRight({})}
        animate={expanded ? "visible" : "hidden"}
        initial={"hidden"}
      >
        <motion.div
          variants={translateFromLeft()}
          animate={expanded ? "visible" : "small"}
          initial={"hidden"}
          onAnimationComplete={onAnimationComplete}
          className={
            classNames(
              " border-r min-w-[50px] min-h-[calc(100vh-80px)] max-h-[calc(100vh-90px)] left-16 w-full  pb-4 z-10 absolute ",
              " dark:text-neutral-50 dark:bg-[#181818] bg-[#f3f4f6]",
              "rounded-r-lg",
              "border-t-2 border-primary-400 dark:border-[#3c3c3c] border-t-primary-400",

            )}>

          <div onClick={() => setExpanded(!expanded)}
            className="absolute -right-5 w-5 top-10 h-10 z-10 bg-primary-400 dark:bg-neutral-600 flex items-center justify-center rounded-r" >
            {expanded ? (
              <ChevronDoubleLeftIcon className="h-4 w-4 text-white " />
            ) : (
              <ChevronDoubleRightIcon className="h-4 w-4 text-white " />
            )}
          </div>

          <div className="w-full h-full max-h-[calc(100vh-90px)] overflow-x-hidden overflow-y-scroll scrollbar-hide filter_menu" >
            {expanded && (
              <>
                {/* <motion.h1 className="font-bold text-center mb-4 uppercase" >MENU</motion.h1> */}
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={fromBottom()}
                  onClick={onSelectAll}
                  className={
                    classNames(
                      "flex items-center w-full cursor-pointer  p-2 ",
                      "mt-2"
                    )} >
                </motion.div>

                {visible && (
                  <>
                    <div>
                      <div className="w-full cursor-pointer group justify-between items-center flex pl-3 pr-8  dark:hover:bg-neutral-800 hover:bg-neutral-200" >
                        <p
                          onClick={() => {
                            setDisplayAllTags((e) => !e)
                          }}
                          className={
                            classNames(
                              "w-full",
                            )
                          } >
                          Display all labels
                        </p>
                        <Checkbox
                          id="display_tags"
                          color="blue"
                          onChange={() => {
                            setDisplayAllTags((e) => !e)
                          }}
                          ripple={true}
                          checked={displayAllTags}
                        />
                      </div>
                      {areasType === "DEFAULT" ? (
                        <>
                          <div
                            onClick={(e) => {
                              setDisplayCounting((e) => !e)
                              setDisplayTotalCounting(false)
                            }} className="w-full cursor-pointer group justify-between items-center flex pl-3 pr-8  dark:hover:bg-neutral-800 hover:bg-neutral-200" >
                            <div className="flex items-center" >
                              <p
                                className={
                                  classNames(
                                    "w-full",
                                  )
                                } >
                                Instant Counting
                              </p>
                              <Tooltip content={
                                <div className="w-80">
                                  <Typography color="white" className="font-medium">Instant counting</Typography>
                                  <Typography
                                    variant="small"
                                    color="white"
                                    className="font-normal opacity-80"
                                  >
                                    Display the number of detected objects and actions on the current frame or image.
                                  </Typography>
                                </div>
                              }>
                                <InformationCircleIcon
                                  strokeWidth={2}
                                  className="text-blue-gray-500 w-5 h-5 cursor-pointer ml-2"
                                />
                              </Tooltip>
                            </div>

                            <Checkbox
                              id="instant_counting"
                              color="blue"
                              ripple={true}
                              onChange={(e) => console.log(e)}
                              checked={displayCounting}
                            />
                          </div>

                          {videoPredictions && (
                            <Popover
                              // placement='top-start'
                              open={!tracking && openPopover}
                              handler={setOpenPopover}
                              animate={{
                                mount: { scale: 1, y: 0, transition: { duration: 0.3 } },
                                unmount: { scale: 0, y: 25, transition: { duration: 0.3 } },
                              }}
                            >
                              <div
                                onClick={(e) => {
                                  if (tracking) {
                                    setDisplayTotalCounting((e) => !e)
                                    setDisplayCounting(false)
                                  }
                                }}
                                className="w-full cursor-pointer group justify-between items-center flex pl-3 pr-8  dark:hover:bg-neutral-800 hover:bg-neutral-200" >
                                <PopoverHandler {...triggers}>

                                  <div className="flex items-center" >
                                    <p
                                      className={
                                        classNames(
                                          "w-full",
                                          tracking ? "dark:text-white" : "text-neutral-400"
                                        )
                                      } >
                                      Total Counting

                                    </p>
                                    <Tooltip content={
                                      <div className="w-80">
                                        <Typography color="white" className="font-medium">Total counting</Typography>
                                        <Typography
                                          variant="small"
                                          color="white"
                                          className="font-normal opacity-80"
                                        >
                                          Show the cumulative count of detected objects and actions throughout the video.
                                        </Typography>
                                      </div>
                                    }>
                                      <InformationCircleIcon
                                        strokeWidth={2}
                                        className="text-blue-gray-500 w-5 h-5 cursor-pointer ml-2"
                                      />
                                    </Tooltip>
                                  </div>
                                </PopoverHandler>

                                <Checkbox
                                  id="total_counting"
                                  color="blue"
                                  ripple={true}
                                  onChange={(e) => console.log(e)}
                                  checked={tracking ? displayTotalCounting : tracking}
                                />
                              </div>

                              <PopoverContent className="z-50" {...triggers} >
                                <p>To display total counting you need to obtain predictions with <strong>Tracking enabled</strong></p>
                              </PopoverContent>
                            </Popover>
                          )}
                        </>
                      ) : (
                        <>
                          <div
                            onClick={(e) => {
                              setDisplayCounting((e) => !e)
                              setDisplayTotalCounting(false)
                            }} className="w-full cursor-pointer group justify-between items-center flex pl-3 pr-8  dark:hover:bg-neutral-800 hover:bg-neutral-200" >
                            <div className="flex items-center" >
                              <p
                                className={
                                  classNames(
                                    "w-full",
                                  )
                                } >
                                Instant Counting
                              </p>
                              <Tooltip content={
                                <div className="w-80">
                                  <Typography color="white" className="font-medium">Instant counting</Typography>
                                  <Typography
                                    variant="small"
                                    color="white"
                                    className="font-normal opacity-80"
                                  >
                                    Display the number of detected objects and actions on the current frame or image.
                                  </Typography>
                                </div>
                              }>
                                <InformationCircleIcon
                                  strokeWidth={2}
                                  className="text-blue-gray-500 w-5 h-5 cursor-pointer ml-2"
                                />
                              </Tooltip>
                            </div>

                            <Checkbox
                              id="instant_counting"
                              color="blue"
                              ripple={true}
                              onChange={(e) => console.log(e)}
                              checked={displayCounting}
                            />
                          </div>
                        </>
                      )}

                    </div>


                    {((areasType === "DEFAULT" && delimitedAreas.length > 0) || (areasType === "PARKING" && parkingLotAreas.length > 0)) && (
                      <>
                        {/* Separator*/}
                        <div className="w-[100%] h-[2px] flex items-center justify-center shadow-md shadow-blue-500 my-4" >
                          <div className="w-[85%] h-[1px] dark:bg-neutral-100 bg-primary-500 opacity-50  shadow-md shadow-blue-500" />
                        </div>

                        <div className="area items" >
                          <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={fromBottom()}
                            className={
                              classNames(
                                "flex items-center w-full cursor-pointer ",
                              )} >
                            {areasType === "DEFAULT" ? (
                              <Disclosure as="div" className="w-full items-start justify-start px-4" >
                                <Disclosure.Button className={
                                  classNames(
                                    "w-full group justify-between items-center flex p-3 group rounded-lg",
                                    " dark:hover:bg-neutral-800 dark:ui-open:bg-neutral-800 ",
                                    "hover:text-primary-500 ui-open:text-primary-500 hover:border-primary-500 ui-open:border-primary-500 border mb-2"
                                  )
                                } >
                                  Displayed Areas
                                  <div className="flex items-center" >
                                    <ChevronDownIcon className="h-4 w-4 ml-4 ui-open:rotate-180 " />
                                    {activeAreas.length > 0 ? (
                                      <EyeIcon onClick={(e) => {
                                        e.stopPropagation()
                                        handleToggleAllAreas()
                                        // onCategoryToggle(item.id)
                                      }} className="h-6 w-6 p-[2px] dark:text-blue-100 ml-2 hover:text-blue-500 invisible group-hover:visible" />
                                    ) : (
                                      <EyeSlashIcon onClick={(e) => {
                                        e.stopPropagation()
                                        handleToggleAllAreas()
                                        // onCategoryToggle(item.id)
                                      }} className="h-6 w-6 p-[2px] ml-2 dark:hover:bg-black" />
                                    )}
                                  </div>
                                </Disclosure.Button>
                                <Transition
                                  enter="transition duration-100 ease-out"
                                  enterFrom="transform scale-95 opacity-0"
                                  enterTo="transform scale-100 opacity-100"
                                  leave="transition duration-75 ease-out"
                                  leaveFrom="transform scale-100 opacity-100"
                                  leaveTo="transform scale-95 opacity-0"
                                >
                                  <Disclosure.Panel as="div" className="pb-2 max-h-52 overflow-scroll scrollbar-hide w-full ">
                                    {delimitedAreas.map((i: any, index: any) => (
                                      <div
                                        key={`${i.ID}`}
                                        onClick={() => handleToggleArea(i.ID)}
                                        className={
                                          classNames(
                                            "py-4 px-4  pl-8 flex items-center justify-between relative group",
                                            activeAreas.includes(i.ID) ? "dark:hover:bg-neutral-600 dark:hover:text-white" : "text-neutral-400",
                                            activeAreas.includes(i.ID) ? "hover:text-primary-500" : "text-neutral-400",
                                          )}>

                                        <div className={classNames(
                                          "absolute left-2 top-2 bottom-2 w-[2px] opacity-0 group-hover:opacity-100",
                                          activeAreas.includes(i.ID) ? "bg-blue-500" : "bg-neutral-500"
                                        )} />

                                        <span>Area</span>
                                        <div
                                          className={
                                            classNames(
                                              "w-6 h-4 block ml-2 rounded-xl",
                                              displayedParkingAreas.free ? "opacity-100" : "opacity-50"
                                            )
                                          }
                                          style={{
                                            backgroundColor: ("rgba(" + i["COLOUR"].map((item: number) => (item + "")).join(", ") + ")"),
                                          }} />
                                        {/* <img className={classNames(i.active ? "backdrop-brightness-125" : "brightness-50", "w-6 rounded-full")} src={i.icon ?? ItemIcon} alt="car" /> */}
                                      </div>
                                    ))}
                                  </Disclosure.Panel>
                                </Transition>
                              </Disclosure>

                            ) : (
                              <Disclosure as="div" className="w-full items-start justify-start px-4" >
                                <Disclosure.Button className={
                                  classNames(
                                    "w-full group justify-between items-center flex p-3 group rounded-lg",
                                    " dark:hover:bg-neutral-800 dark:ui-open:bg-neutral-800 ",
                                    "hover:text-primary-500 ui-open:text-primary-500 hover:border-primary-500 ui-open:border-primary-500 border mb-2"
                                  )
                                } >
                                  Displayed Areas
                                  <div className="flex items-center" >
                                    <ChevronDownIcon className="h-4 w-4 ml-4 ui-open:rotate-180 " />
                                    {isAllParkingAreasDisplayed ? (
                                      <EyeIcon onClick={(e) => {
                                        e.stopPropagation()
                                        handleToggleAllAreas()
                                        // onCategoryToggle(item.id)
                                      }} className="h-6 w-6 p-[2px] dark:text-blue-100 ml-2 hover:text-blue-500 invisible group-hover:visible" />
                                    ) : (
                                      <EyeSlashIcon onClick={(e) => {
                                        e.stopPropagation()
                                        handleToggleAllAreas()
                                        // onCategoryToggle(item.id)
                                      }} className="h-6 w-6 p-[2px] ml-2 dark:hover:bg-black" />
                                    )}
                                  </div>
                                </Disclosure.Button>
                                <Transition
                                  enter="transition duration-100 ease-out"
                                  enterFrom="transform scale-95 opacity-0"
                                  enterTo="transform scale-100 opacity-100"
                                  leave="transition duration-75 ease-out"
                                  leaveFrom="transform scale-100 opacity-100"
                                  leaveTo="transform scale-95 opacity-0"
                                >
                                  <Disclosure.Panel as="div" className="pb-2 max-h-52 overflow-scroll scrollbar-hide w-full px-2 ">
                                    <div
                                      onClick={() => setDisplayedParkingAreas((e) => ({ ...e, free: !e.free }))}
                                      className={
                                        classNames(
                                          "py-4 px-4  pl-8 flex items-center justify-between border-b border-neutral-50 relative group",
                                          displayedParkingAreas.free ? "dark:hover:bg-neutral-600 dark:hover:text-white" : "text-neutral-400",
                                          displayedParkingAreas.free ? "hover:text-primary-500" : "text-neutral-400",
                                        )}>
                                      <div className={classNames(
                                        "absolute left-2 top-2 bottom-2 w-[2px] opacity-0 group-hover:opacity-100",
                                        displayedParkingAreas.free ? "bg-blue-500" : "bg-neutral-500"
                                      )} />

                                      <span>Free Areas</span>
                                      <div
                                        className={
                                          classNames(
                                            "w-6 h-4 block ml-2 rounded-xl",
                                            displayedParkingAreas.free ? "opacity-100" : "opacity-50"
                                          )
                                        }
                                        style={{
                                          backgroundColor: ("rgb(0,181,54)"),
                                        }} />
                                    </div>

                                    <div
                                      onClick={() => setDisplayedParkingAreas((e) => ({ ...e, occupied: !e.occupied }))}
                                      className={
                                        classNames(
                                          "py-4 px-4  pl-8 flex items-center justify-between border-b border-neutral-50 relative group",
                                          displayedParkingAreas.occupied ? "dark:hover:bg-neutral-600 dark:hover:text-white" : "text-neutral-400",
                                          displayedParkingAreas.occupied ? "hover:text-primary-500" : "text-neutral-400",
                                        )}>
                                      <div className={classNames(
                                        "absolute left-2 top-2 bottom-2 w-[2px] opacity-0 group-hover:opacity-100",
                                        displayedParkingAreas.occupied ? "bg-blue-500" : "bg-neutral-500"
                                      )} />

                                      <span>Occupied Areas</span>
                                      <div
                                        className={
                                          classNames(
                                            "w-6 h-4 block ml-2 rounded-xl",
                                            displayedParkingAreas.occupied ? "opacity-100" : "opacity-50"
                                          )
                                        }
                                        style={{
                                          backgroundColor: ("rgb(254,83,90)"),
                                        }} />
                                    </div>
                                  </Disclosure.Panel>
                                </Transition>
                              </Disclosure>

                            )}

                          </motion.div>
                        </div>
                      </>

                    )}

                    {/* Separator*/}
                    <div className="w-[100%] h-[2px] flex items-center justify-center shadow-md shadow-blue-500 my-4" >
                      <div className="w-[85%] h-[1px] dark:bg-neutral-100 bg-primary-500 opacity-50  shadow-md shadow-blue-500" />
                    </div>

                    <div className="general items" >

                      {PrimaryMenu.map((item, index) => (
                        <motion.div
                          key={item.name}
                          initial="hidden"
                          animate="visible"
                          variants={fromBottom()}
                          className={
                            classNames(
                              "flex items-center w-full cursor-pointer ",
                            )} >
                          <Disclosure as="div" className="w-full items-start justify-start px-4" >
                            <Disclosure.Button className={
                              classNames(
                                "w-full group justify-between items-center flex p-3 group rounded-lg",
                                " ui-open:shadow-inner hover:shadow-inner ui-open:dark:shadow-primary-500",
                                "hover:text-primary-500 ui-open:text-primary-500 hover:border-primary-500 ui-open:border-primary-500 border mb-4"
                              )
                            } >
                              {item.name}
                              <div className="flex items-center" >
                                <ChevronDownIcon className="h-4 w-4 ml-4 ui-open:rotate-180 " />
                                {item.active ? (
                                  <EyeIcon onClick={(e) => {
                                    e.stopPropagation()
                                    onCategoryToggle(item.id)
                                  }} className="h-6 w-6 p-[2px] dark:text-blue-100 ml-2 hover:text-blue-500 invisible group-hover:visible" />
                                ) : (
                                  <EyeSlashIcon onClick={(e) => {
                                    e.stopPropagation()
                                    onCategoryToggle(item.id)
                                  }} className="h-6 w-6 p-[2px] ml-2 dark:hover:bg-black" />
                                )}
                              </div>
                            </Disclosure.Button>
                            <Transition
                              enter="transition duration-100 ease-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-100 opacity-100"
                              leave="transition duration-75 ease-out"
                              leaveFrom="transform scale-100 opacity-100"
                              leaveTo="transform scale-95 opacity-0"
                            >
                              <Disclosure.Panel as="div" className="pb-2 max-h-52 overflow-scroll scrollbar-hide w-full ">
                                {item.items.map((i, index) => (
                                  <div
                                    key={`${i.id}_${item.id}`}
                                    onClick={() => onItemToggle(item.id, i.id)}
                                    className={
                                      classNames(
                                        "py-4 px-4  pl-8 flex items-center justify-between relative group",
                                        i.active ? "dark:hover:bg-neutral-600 dark:hover:text-white" : "text-neutral-400",
                                        i.active ? "hover:text-primary-500" : "text-neutral-400",

                                      )}>
                                    <div className={classNames(
                                      "absolute left-2 top-2 bottom-2 w-[2px] opacity-0 group-hover:opacity-100",
                                      i.active ? "bg-blue-500" : "bg-neutral-500"
                                    )} />
                                    <span>{i.name}</span>
                                    <img className={classNames(i.active ? "backdrop-brightness-125" : "brightness-50", "w-6 rounded-full")} src={i.icon ?? ItemIcon} alt="car" />
                                  </div>
                                ))}
                              </Disclosure.Panel>
                            </Transition>
                          </Disclosure>
                        </motion.div>
                      ))}
                    </div>
                  </>
                )}
              </>
            )}
          </div>

        </motion.div>
      </motion.div>
      {/* Mobile Menu */}
      <div className="w-full flex lg:hidden relative z-40">
        <motion.div
          variants={translateFromTop()}
          animate={expanded ? "visible" : "hidden"}
          initial={"hidden"}
          className={
            classNames(
              " border border-l-0 border-neutral-900 z-10 relative bg-neutral-700 text-neutral-50 ",
              "w-full flex"
            )}>
          <Disclosure className="w-full" as="nav">
            {({ open }) => (
              <>
                <div className="mx-auto w-full px-2 sm:px-6 lg:px-8">
                  <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                      {/* Mobile menu button*/}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>

                    {/* Menu Tablet */}
                    <div className="flex w-full items-center  sm:items-stretch sm:justify-between">
                      <div className="hidden w-full sm:block">
                        <div className="flex gap-2 w-full h-full items-center justify-center" >
                          {/* {menu.map((item, index) => (
                            <motion.div
                              key={index}
                              initial="hidden"
                              animate="visible"
                              variants={fromBottom()}
                              className={
                                classNames(
                                  "flex items-center w-full cursor-pointer group",

                                )} >
                              <Menu as="div" className="relative w-full inline-block text-left z-40 hover:bg-neutral-800/40">
                                {({ open }) => (
                                  <>
                                    <div>
                                      <Menu.Button className=" inline-flex items-center w-full justify-center gap-x-1.5 rounded-md text-white px-2 py-2 text-sm font-semibold shadow-sm hover:bg-neutral-800/40 ui-open:bg-neutral-800/50">
                                        {item.name}
                                        <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                      </Menu.Button>
                                    </div>

                                    {open && (
                                      <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                      >
                                        <Menu.Items static className="absolute group-first:left-0 group-last:right-0  z-40  mt-2 w-56 origin-top-right rounded-md bg-neutral-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                          <div className="py-1 max-h-52 overflow-x-auto ">
                                            {item.items.map((i, index) => (
                                              <div onClick={() => onItemToggle(item.id, i.id)}
                                                className={
                                                  classNames(
                                                    "py-4 px-4  pl-8 flex items-center justify-between border-b border-neutral-50 bg-neutral-800/75 ",
                                                    i.active ? "hover:bg-neutral-600" : "hover:bg-neutral-700 text-neutral-400"
                                                  )}>
                                                <span>{i.name}</span>
                                                <img className={classNames(i.active ? "backdrop-brightness-125" : "brightness-50", "w-6")} src={i.icon ?? ItemIcon} alt="car" />
                                              </div>
                                            ))}
                                          </div>
                                        </Menu.Items>
                                      </Transition>
                                    )}
                                  </>
                                )}
                              </Menu>
                            </motion.div>
                          ))} */}

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <Disclosure.Panel className="sm:hidden">
                  <div className="space-y-1 px-2 pb-3">

                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </motion.div>
      </div>
    </>
  );
}
