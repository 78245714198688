/**
 * Animated pattern
 * Used on @Login @SignUp and @RecoverPassword pages
 */

import { motion } from 'framer-motion'

export function LoginPattern() {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 800 800"><g fill="none" stroke-width="1" stroke="#2084F9" stroke-linecap="round" stroke-dasharray="12.5 0">
        <line x1="509" y1="400" x2="291" y2="400" transform="rotate(0, 400, 400)" stroke="url(#pulse-1)" opacity="0.81"></line>
        <line x1="377.5" y1="400" x2="422.5" y2="400" transform="rotate(4, 400, 400)" stroke="url(#pulse-1)" opacity="0.43"></line>
        <line x1="672" y1="400" x2="128" y2="400" transform="rotate(8, 400, 400)" stroke="url(#pulse-1)" opacity="0.22"></line>
        <line x1="667" y1="400" x2="133" y2="400" transform="rotate(12, 400, 400)" stroke="url(#pulse-1)" opacity="0.81"></line>
        <line x1="771.5" y1="400" x2="28.5" y2="400" transform="rotate(16, 400, 400)" stroke="url(#pulse-1)" opacity="0.75"></line>
        <line x1="52.5" y1="400" x2="747.5" y2="400" transform="rotate(20, 400, 400)" stroke="url(#pulse-1)" opacity="0.17"></line>
        <line x1="222.5" y1="400" x2="577.5" y2="400" transform="rotate(24, 400, 400)" stroke="url(#pulse-1)" opacity="0.15"></line>
        <line x1="769.5" y1="400" x2="30.5" y2="400" transform="rotate(28, 400, 400)" stroke="url(#pulse-1)" opacity="0.96"></line>
        <line x1="336" y1="400" x2="464" y2="400" transform="rotate(32, 400, 400)" stroke="url(#pulse-1)" opacity="0.91"></line>
        <line x1="328" y1="400" x2="472" y2="400" transform="rotate(36, 400, 400)" stroke="url(#pulse-1)" opacity="0.52"></line>
        <line x1="358" y1="400" x2="442" y2="400" transform="rotate(40, 400, 400)" stroke="url(#pulse-1)" opacity="0.11"></line>
        <line x1="436.5" y1="400" x2="363.5" y2="400" transform="rotate(44, 400, 400)" stroke="url(#pulse-1)" opacity="0.66"></line>
        <line x1="743" y1="400" x2="57" y2="400" transform="rotate(48, 400, 400)" stroke="url(#pulse-1)" opacity="0.47"></line>
        <line x1="282.5" y1="400" x2="517.5" y2="400" transform="rotate(52, 400, 400)" stroke="url(#pulse-1)" opacity="0.90"></line>
        <line x1="768" y1="400" x2="32" y2="400" transform="rotate(56, 400, 400)" stroke="url(#pulse-1)" opacity="0.59"></line>
        <line x1="542.5" y1="400" x2="257.5" y2="400" transform="rotate(60, 400, 400)" stroke="url(#pulse-1)" opacity="0.31"></line>
        <line x1="450.5" y1="400" x2="349.5" y2="400" transform="rotate(64, 400, 400)" stroke="url(#pulse-1)" opacity="0.99"></line>
        <line x1="506" y1="400" x2="294" y2="400" transform="rotate(68, 400, 400)" stroke="url(#pulse-1)" opacity="0.09"></line>
        <line x1="578" y1="400" x2="222" y2="400" transform="rotate(72, 400, 400)" stroke="url(#pulse-1)" opacity="0.31"></line>
        <line x1="23" y1="400" x2="777" y2="400" transform="rotate(76, 400, 400)" stroke="url(#pulse-1)" opacity="0.78"></line>
        <line x1="786.5" y1="400" x2="13.5" y2="400" transform="rotate(80, 400, 400)" stroke="url(#pulse-1)" opacity="0.09"></line>
        <line x1="237.5" y1="400" x2="562.5" y2="400" transform="rotate(84, 400, 400)" stroke="url(#pulse-1)" opacity="0.47"></line>
        <line x1="341" y1="400" x2="459" y2="400" transform="rotate(88, 400, 400)" stroke="url(#pulse-1)" opacity="0.86"></line>
        <line x1="637.5" y1="400" x2="162.5" y2="400" transform="rotate(92, 400, 400)" stroke="url(#pulse-1)" opacity="0.79"></line>
        <line x1="758.5" y1="400" x2="41.5" y2="400" transform="rotate(96, 400, 400)" stroke="url(#pulse-1)" opacity="0.64"></line>
        <line x1="633.5" y1="400" x2="166.5" y2="400" transform="rotate(100, 400, 400)" stroke="url(#pulse-1)" opacity="0.22"></line>
        <line x1="10" y1="400" x2="790" y2="400" transform="rotate(104, 400, 400)" stroke="url(#pulse-1)" opacity="0.26"></line>
        <line x1="19.5" y1="400" x2="780.5" y2="400" transform="rotate(108, 400, 400)" stroke="url(#pulse-1)" opacity="0.24"></line>
        <line x1="504" y1="400" x2="296" y2="400" transform="rotate(112, 400, 400)" stroke="url(#pulse-1)" opacity="0.99"></line>
        <line x1="644" y1="400" x2="156" y2="400" transform="rotate(116, 400, 400)" stroke="url(#pulse-1)" opacity="0.97"></line>
        <line x1="410" y1="400" x2="390" y2="400" transform="rotate(120, 400, 400)" stroke="url(#pulse-1)" opacity="0.47"></line>
        <line x1="664.5" y1="400" x2="135.5" y2="400" transform="rotate(124, 400, 400)" stroke="url(#pulse-1)" opacity="0.81"></line>
        <line x1="306" y1="400" x2="494" y2="400" transform="rotate(128, 400, 400)" stroke="url(#pulse-1)" opacity="0.20"></line>
        <line x1="50" y1="400" x2="750" y2="400" transform="rotate(132, 400, 400)" stroke="url(#pulse-1)" opacity="0.96"></line>
        <line x1="478.5" y1="400" x2="321.5" y2="400" transform="rotate(136, 400, 400)" stroke="url(#pulse-1)" opacity="0.52"></line>
        <line x1="100" y1="400" x2="700" y2="400" transform="rotate(140, 400, 400)" stroke="url(#pulse-1)" opacity="0.16"></line>
        <line x1="29.5" y1="400" x2="770.5" y2="400" transform="rotate(144, 400, 400)" stroke="url(#pulse-1)" opacity="0.28"></line>
        <line x1="752.5" y1="400" x2="47.5" y2="400" transform="rotate(148, 400, 400)" stroke="url(#pulse-1)" opacity="0.77"></line>
        <line x1="759" y1="400" x2="41" y2="400" transform="rotate(152, 400, 400)" stroke="url(#pulse-1)" opacity="0.92"></line>
        <line x1="566.5" y1="400" x2="233.5" y2="400" transform="rotate(156, 400, 400)" stroke="url(#pulse-1)" opacity="0.55"></line>
        <line x1="357" y1="400" x2="443" y2="400" transform="rotate(160, 400, 400)" stroke="url(#pulse-1)" opacity="0.61"></line>
        <line x1="558.5" y1="400" x2="241.5" y2="400" transform="rotate(164, 400, 400)" stroke="url(#pulse-1)" opacity="0.82"></line>
        <line x1="603.5" y1="400" x2="196.5" y2="400" transform="rotate(168, 400, 400)" stroke="url(#pulse-1)" opacity="0.19"></line>
        <line x1="543" y1="400" x2="257" y2="400" transform="rotate(172, 400, 400)" stroke="url(#pulse-1)" opacity="0.88"></line>
        <line x1="224" y1="400" x2="576" y2="400" transform="rotate(176, 400, 400)" stroke="url(#pulse-1)" opacity="0.68"></line>
        <line x1="281" y1="400" x2="519" y2="400" transform="rotate(180, 400, 400)" stroke="url(#pulse-1)" opacity="0.46"></line>
        <line x1="152.5" y1="400" x2="647.5" y2="400" transform="rotate(184, 400, 400)" stroke="url(#pulse-1)" opacity="0.84"></line>
        <line x1="618.5" y1="400" x2="181.5" y2="400" transform="rotate(188, 400, 400)" stroke="url(#pulse-1)" opacity="0.13"></line>
        <line x1="525.5" y1="400" x2="274.5" y2="400" transform="rotate(192, 400, 400)" stroke="url(#pulse-1)" opacity="0.38"></line>
        <line x1="38.5" y1="400" x2="761.5" y2="400" transform="rotate(196, 400, 400)" stroke="url(#pulse-1)" opacity="0.58"></line>
        <line x1="282" y1="400" x2="518" y2="400" transform="rotate(200, 400, 400)" stroke="url(#pulse-1)" opacity="0.82"></line>
        <line x1="775.5" y1="400" x2="24.5" y2="400" transform="rotate(204, 400, 400)" stroke="url(#pulse-1)" opacity="0.48"></line>
        <line x1="82.5" y1="400" x2="717.5" y2="400" transform="rotate(208, 400, 400)" stroke="url(#pulse-1)" opacity="0.87"></line>
        <line x1="368" y1="400" x2="432" y2="400" transform="rotate(212, 400, 400)" stroke="url(#pulse-1)" opacity="0.51"></line>
        <line x1="461.5" y1="400" x2="338.5" y2="400" transform="rotate(216, 400, 400)" stroke="url(#pulse-1)" opacity="0.16"></line>
        <line x1="163" y1="400" x2="637" y2="400" transform="rotate(220, 400, 400)" stroke="url(#pulse-1)" opacity="0.44"></line>
        <line x1="247" y1="400" x2="553" y2="400" transform="rotate(224, 400, 400)" stroke="url(#pulse-1)" opacity="0.79"></line>
        <line x1="770.5" y1="400" x2="29.5" y2="400" transform="rotate(228, 400, 400)" stroke="url(#pulse-1)" opacity="0.11"></line>
        <line x1="128.5" y1="400" x2="671.5" y2="400" transform="rotate(232, 400, 400)" stroke="url(#pulse-1)" opacity="0.29"></line>
        <line x1="94.5" y1="400" x2="705.5" y2="400" transform="rotate(236, 400, 400)" stroke="url(#pulse-1)" opacity="0.33"></line>
        <line x1="566" y1="400" x2="234" y2="400" transform="rotate(240, 400, 400)" stroke="url(#pulse-1)" opacity="0.80"></line>
        <line x1="48" y1="400" x2="752" y2="400" transform="rotate(244, 400, 400)" stroke="url(#pulse-1)" opacity="0.31"></line>
        <line x1="688.5" y1="400" x2="111.5" y2="400" transform="rotate(248, 400, 400)" stroke="url(#pulse-1)" opacity="0.93"></line>
        <line x1="644.5" y1="400" x2="155.5" y2="400" transform="rotate(252, 400, 400)" stroke="url(#pulse-1)" opacity="0.92"></line>
        <line x1="784" y1="400" x2="16" y2="400" transform="rotate(256, 400, 400)" stroke="url(#pulse-1)" opacity="0.86"></line>
        <line x1="604" y1="400" x2="196" y2="400" transform="rotate(260, 400, 400)" stroke="url(#pulse-1)" opacity="0.57"></line>
        <line x1="444.5" y1="400" x2="355.5" y2="400" transform="rotate(264, 400, 400)" stroke="url(#pulse-1)" opacity="0.29"></line>
        <line x1="158" y1="400" x2="642" y2="400" transform="rotate(268, 400, 400)" stroke="url(#pulse-1)" opacity="0.07"></line>
        <line x1="327.5" y1="400" x2="472.5" y2="400" transform="rotate(272, 400, 400)" stroke="url(#pulse-1)" opacity="0.08"></line>
        <line x1="748" y1="400" x2="52" y2="400" transform="rotate(276, 400, 400)" stroke="url(#pulse-1)" opacity="0.84"></line>
        <line x1="50" y1="400" x2="750" y2="400" transform="rotate(280, 400, 400)" stroke="url(#pulse-1)" opacity="0.26"></line>
        <line x1="311.5" y1="400" x2="488.5" y2="400" transform="rotate(284, 400, 400)" stroke="url(#pulse-1)" opacity="0.27"></line>
        <line x1="312.5" y1="400" x2="487.5" y2="400" transform="rotate(288, 400, 400)" stroke="url(#pulse-1)" opacity="0.64"></line>
        <line x1="41.5" y1="400" x2="758.5" y2="400" transform="rotate(292, 400, 400)" stroke="url(#pulse-1)" opacity="0.68"></line>
        <line x1="681" y1="400" x2="119" y2="400" transform="rotate(296, 400, 400)" stroke="url(#pulse-1)" opacity="0.42"></line>
        <line x1="595" y1="400" x2="205" y2="400" transform="rotate(300, 400, 400)" stroke="url(#pulse-1)" opacity="0.23"></line>
        <line x1="353" y1="400" x2="447" y2="400" transform="rotate(304, 400, 400)" stroke="url(#pulse-1)" opacity="0.27"></line>
        <line x1="640" y1="400" x2="160" y2="400" transform="rotate(308, 400, 400)" stroke="url(#pulse-1)" opacity="0.18"></line>
        <line x1="761.5" y1="400" x2="38.5" y2="400" transform="rotate(312, 400, 400)" stroke="url(#pulse-1)" opacity="0.15"></line>
        <line x1="712" y1="400" x2="88" y2="400" transform="rotate(316, 400, 400)" stroke="url(#pulse-1)" opacity="0.33"></line>
        <line x1="154" y1="400" x2="646" y2="400" transform="rotate(320, 400, 400)" stroke="url(#pulse-1)" opacity="0.26"></line>
        <line x1="100" y1="400" x2="700" y2="400" transform="rotate(324, 400, 400)" stroke="url(#pulse-1)" opacity="0.19"></line>
        <line x1="573.5" y1="400" x2="226.5" y2="400" transform="rotate(328, 400, 400)" stroke="url(#pulse-1)" opacity="0.30"></line>
        <line x1="78" y1="400" x2="722" y2="400" transform="rotate(332, 400, 400)" stroke="url(#pulse-1)" opacity="0.28"></line>
        <line x1="152" y1="400" x2="648" y2="400" transform="rotate(336, 400, 400)" stroke="url(#pulse-1)" opacity="0.78"></line>
        <line x1="482" y1="400" x2="318" y2="400" transform="rotate(340, 400, 400)" stroke="url(#pulse-1)" opacity="0.93"></line>
        <line x1="640.5" y1="400" x2="159.5" y2="400" transform="rotate(344, 400, 400)" stroke="url(#pulse-1)" opacity="0.46"></line>
        <line x1="678" y1="400" x2="122" y2="400" transform="rotate(348, 400, 400)" stroke="url(#pulse-1)" opacity="0.19"></line>
        <line x1="752.5" y1="400" x2="47.5" y2="400" transform="rotate(352, 400, 400)" stroke="url(#pulse-1)" opacity="0.98"></line>
        <line x1="790.5" y1="400" x2="9.5" y2="400" transform="rotate(356, 400, 400)" stroke="url(#pulse-1)" opacity="0.16"></line>
        </g>

        <defs>
        <motion.linearGradient
          animate={{
            x1: [800, 1000],
            x2: [0, 800],
            y1: [0, 800],
            y2: [800, 0]
          }}
          transition={{
            duration: 10,
            repeat: Infinity,
            ease: 'linear'
          }}
          id="pulse-1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2084F9" stopOpacity="0" />
          <stop stopColor="#f142b6" />
          <stop offset="1" stopColor="#2084F9" stopOpacity="0" />
        </motion.linearGradient>

        <motion.linearGradient
          animate={{
            x1: [1000, 300],
            x2: [1500 , 500],
            y1: [700, 700 / 2],
            y2: [700 * 2, 700]
          }}
          transition={{
            duration: 10,
            repeat: Infinity,
            ease: 'linear'
          }}
          id="pulse-2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2084F9" stopOpacity="0" />
          <stop stopColor="#2084F9" />
          <stop offset="1" stopColor="#f142b6" stopOpacity="0" />
        </motion.linearGradient>

      </defs>
      </svg>
    </>
  );
}
