
/**
 * Feedback Context Provider
 * 
 * 
 */

import { createContext, useContext, useState, useEffect, ReactNode } from 'react';

//-----------------------------------------------------------------------------


//-----------------------------------------------------------------------------

// Type definition
interface FeedbackContextData {
  displayBanner: boolean
  setDisplayBanner: React.Dispatch<React.SetStateAction<boolean>>
}

type FeedbackProviderProps = {
  children: ReactNode;
}

export type DelimitedArea = {
  COLOUR: number[]
  EVENT_TYPE: string
  ID: number
  POINTS: number[][]
  TARGET_CLASS_NAME: string
}
//-----------------------------------------------------------------------------

// Context
export const feedbackContext = createContext<FeedbackContextData>({} as FeedbackContextData);

// Provider
export const FeedbackProvider = ({ children }: FeedbackProviderProps) => {
  const [selectedFrame, setSelectedFrame] = useState()
  const [displayBanner, setDisplayBanner] = useState(false)
  
  // The context value to be provided to the consumers
  const value = {
    displayBanner, setDisplayBanner
  };

  // Provide the context value to the children components
  return (
    <feedbackContext.Provider value={value} >
      {children}
    </feedbackContext.Provider>
  );
};