import { classNames } from "../utils";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Button contents
   */
  label?: string;
}

export function Button({ backgroundColor, label, primary, size = 'medium', ...props }: ButtonProps) {
  const mode = primary ? 'button_primary' : 'button_secondary';
  const sizeStyle = `button_${size}`
  return (
    <>
      <button
        style={{
          backgroundColor
        }}  
        {...props}
        className={
          classNames(
            "h-6 cursor-pointer py-1 px-2 text-white rounded text-xs flex items-center disabled:cursor-default md:px-4 md:h-10 md:text-base sm:text-sm ",
            "bg-gradient-to-r from-cyan-500 to-blue-500 font-bold",
            `${props.className}`
            )}>
        {label ?? props.children}
      </button>
    </>
  );
}
