/**
 * Hook: useRegionConfiguration
 * This hook provides utilities to handle and update video predictions based on
 * region configurations and delimited areas (like parking areas). It also 
 * offers utilities to identify intersections between predictions and defined 
 * areas.
 */
import { useContext } from "react";
import { DelimitedArea, regionConfigurationContext } from "../contexts/ReagionConfigurationContext";
import { predictionContext } from "../contexts/PredictionsContext";
import { doPolygonAndRectIntersect, reduceRectSize } from "../utils/regions";
import { FormattedPredictionType } from "../types/PredictionType";

type AreaIntersectionResult = {
  id: number; // Replace string with the actual type of poly.ID if different
  color: string;
}[];


export function useRegionConfiguration() {

  const value = useContext(regionConfigurationContext)
  const pContext = useContext(predictionContext)

  const { videoPredictions, setVideoPredictions } = pContext
  // const {delimitedAreas, activeAreas} = value

  const updateVideoPredictionsWithAreas = (delimitedAreas: DelimitedArea[]) => {
    if (!videoPredictions) return

    const updatedPredictions = videoPredictions.map((predictions) => {
      const updatedObjects = predictions.objects.map((object) => {

        // Start with a cleared out areas array
        let updatedAreas = [];

        const newAreas = verifyIntersectedArea(object, delimitedAreas);
        newAreas.forEach((newArea) => {
          const existingAreaIndex = updatedAreas.findIndex((area: any) => area.id === newArea.id);
          if (existingAreaIndex === -1) {
            updatedAreas.push(newArea);
          } else {
            updatedAreas[existingAreaIndex].color = newArea.color;
          }
        });

        return { ...object, areas: updatedAreas };
      });

      return { ...predictions, objects: updatedObjects };
    });

    setVideoPredictions(updatedPredictions);
  };

  const updateVideoPredictionsWithParkingAreas = (delimitedAreas: DelimitedArea[]) => {
    if (!videoPredictions) return

    const updatedPredictions = videoPredictions.map((predictions: any) => {

      const updatedObjects = predictions.objects.map((object: any) => {

        // Start with a cleared out areas array
        let updatedAreas: any[] = [];

        // const newAreas = verifyIntersectedArea(object, delimitedAreas);

        let newAreas: any[] = [];

        if (delimitedAreas.length >= 0) {
          const reducedBoundingBox = reduceRectSize(object.boundingBox, 0.5);

          for (let poly of delimitedAreas) {
            if (doPolygonAndRectIntersect(poly.POINTS, reducedBoundingBox)) {
              newAreas.push({ id: poly.ID, color: `rgb(254,83,90)` });
            }
          }
        }

        newAreas.forEach((newArea: any) => {
          const existingAreaIndex = updatedAreas.findIndex((area: any) => area.id === newArea.id);
          if (existingAreaIndex === -1) {
            updatedAreas.push(newArea);
          } else {
            updatedAreas[existingAreaIndex].color = `rgb(254,83,90)`;
          }
        });

        return { ...object, areas: updatedAreas };
      });

      return { ...predictions, objects: updatedObjects };
    });

    setVideoPredictions(updatedPredictions);
  };


  const verifyIntersectedArea = (object: FormattedPredictionType, delimitedAreas: DelimitedArea[]) => {
    let areas:AreaIntersectionResult = [];

    if (delimitedAreas.length === 0) return areas;

    const reducedBoundingBox = reduceRectSize(object.boundingBox);

    for (let poly of delimitedAreas) {
      if (doPolygonAndRectIntersect(poly.POINTS, reducedBoundingBox)) {
        const [r, g, b] = poly.COLOUR;
        areas.push({ id: poly.ID, color: `rgba(${r}, ${g}, ${b}, ${1})` });
      }
    }

    return areas;
  };

  const getAreaColor = (area: { id: number; color: string }, opacity: number = 1): string | undefined => {
    if (!area) return;

    const [r, g, b] = area.color;
    const fillColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;
    return fillColor;
  };


  return { ...value, updateVideoPredictionsWithAreas, updateVideoPredictionsWithParkingAreas };
}


