/**
 * This module provides a context and provider for file loading operations. 
 * It manages the state for files, their associated metadata, predictions, and other related attributes.
 */

import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import request from 'superagent';

//-----------------------------------------------------------------------------

// Type definition for the FileLoader context.
interface FileLoaderContextData {
  /** Predictions associated with the loaded image. */
  imagePredictions: any;
  setImagePredictions: React.Dispatch<any>;

  /** Predictions associated with the loaded video. */
  videoPredictions: any;
  setVideoPredictions: React.Dispatch<any>;

  /** URL of the uploaded file. */
  fileUrl: string | null;
  setFileUrl: React.Dispatch<React.SetStateAction<string | null>>;

  /** The file object of the uploaded file. */
  selectedFile: any;
  setSelectedFile: React.Dispatch<any>;

  /** All detected classes in the file. */
  detectedClasses: Set<string> | undefined;
  setDetectedClasses: React.Dispatch<React.SetStateAction<Set<string> | undefined>>;

  /** Metadata associated with the loaded file. */
  metadata: any;
  setMetadata: React.Dispatch<any>;

  /** Type of the uploaded file: image, video or null. */
  fileType: "video" | "image" | null;
  setFileType: React.Dispatch<React.SetStateAction<"video" | "image" | null>>;

  /** Original size of the uploaded image. */
  originalImageSize: any;
  setOriginalImageSize: React.Dispatch<any>;

  /** Size of the uploaded file. */
  imageSize: any;
  setFileSize: React.Dispatch<any>;

  /** Preview frames for the uploaded video. */
  previewFrames: string[];
  setPreviewFrames: React.Dispatch<React.SetStateAction<string[]>>;

  /** Metadata for the uploaded video. */
  videoMetadata: VideoMetadata | null;
  setVideoMetadata: React.Dispatch<React.SetStateAction<VideoMetadata | null>>;

  /** uploaded file ID to keep track if current file has been uploaded to GCP */
  storedFileID: string
  setStoredFileID: React.Dispatch<React.SetStateAction<string>>
}

// Props type for the FileLoaderProvider.
type FileLoaderProviderProps = {
  children: ReactNode;
}

// Metadata structure for video files.
type VideoMetadata = {
  src: string;
  duration: number;
  width: number;
  height: number;
}

//-----------------------------------------------------------------------------

// Context
export const fileLoaderContext = createContext<FileLoaderContextData>({} as FileLoaderContextData);

// Provider
export const FileLoaderProvider = ({ children }: FileLoaderProviderProps) => {
  const [imagePredictions, setImagePredictions] = useState<any>(null)
  const [videoPredictions, setVideoPredictions] = useState<any>(null)
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [detectedClasses, setDetectedClasses] = useState<Set<string>>()
  const [metadata, setMetadata] = useState<any>(null)
  const [fileType, setFileType] = useState<"video" | "image" | null>(null)
  const [originalImageSize, setOriginalImageSize] = useState<any>(null)
  const [imageSize, setFileSize] = useState<any>(null)
  const [previewFrames, setPreviewFrames] = useState<string[]>([])
  const [videoMetadata, setVideoMetadata] = useState<VideoMetadata | null>(null)
  const [storedFileID, setStoredFileID] = useState<string | null>(null)

  // The context value to be provided to the consumers
  const value = {
    imagePredictions,
    setImagePredictions,
    videoPredictions,
    setVideoPredictions,
    fileUrl,
    setFileUrl,
    selectedFile,
    setSelectedFile,
    detectedClasses, setDetectedClasses,
    metadata, setMetadata,
    fileType, setFileType,
    originalImageSize, setOriginalImageSize,
    imageSize, setFileSize,
    previewFrames, setPreviewFrames,
    videoMetadata, setVideoMetadata,
    storedFileID, setStoredFileID
  };

  // Provide the context value to the children components
  return (
    <fileLoaderContext.Provider value={value} >
      {children}
    </fileLoaderContext.Provider>
  );
};