/**
 * Confirmation Modal for selecting the type of detection.
 * Allows the user to choose detection categories that AI models will analyze.
 * The user can either select a pre-defined detection category stored on @INDUSTRIES_MENU or create a custom one.
 * Each category has a unique set of detection items, and the user can add or remove items from the selected category.
 */

import { Dialog, Disclosure, Listbox, Transition } from '@headlessui/react';
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { PencilSquareIcon, XMarkIcon, QuestionMarkCircleIcon, KeyIcon, PencilIcon, CheckIcon } from '@heroicons/react/24/outline'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { Form } from '@unform/web'
import { classNames } from '../../utils';
import Icon from "../../assets/icons/predictions/car.svg"
import { fromBottom } from '../../utils/animations';
import { motion } from "framer-motion";
import { INDUSTRIES_MENU, MENU_ITEMS } from '../../mock/menu';
import { Accordion, AccordionBody, Checkbox, Popover, PopoverContent, PopoverHandler, Switch } from '@material-tailwind/react';
import { FormHandles, SubmitHandler } from '@unform/core';
import * as Yup from 'yup';
import { Input } from '../Input';
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Collapse,

} from "@material-tailwind/react";

import { usePredictionsMenu } from '../../hooks/usePredictionsMenu';
import Custom from "../../assets/images/custom3.jpeg"
import { useRegionConfiguration } from '../../hooks/useRegionConfiguration';
import { BiPlus, BiPencil } from "react-icons/bi"
import { FiChevronDown } from "react-icons/fi"
//----------------------------------------------------------------
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { usePredictions } from '../../hooks/usePredictions';
import { useAuth } from '../../hooks/useAuth';
//----------------------------------------------------------------

const people = [
  {
    id: 1,
    name: 'Wade Cooper',
    avatar:
      'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 2,
    name: 'Arlene Mccoy',
    avatar:
      'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 3,
    name: 'Devon Webb',
    avatar:
      'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80',
  },
  {
    id: 4,
    name: 'Tom Cook',
    avatar:
      'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 5,
    name: 'Tanya Fox',
    avatar:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 6,
    name: 'Hellen Schmidt',
    avatar:
      'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 7,
    name: 'Caroline Schultz',
    avatar:
      'https://images.unsplash.com/photo-1568409938619-12e139227838?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 8,
    name: 'Mason Heaney',
    avatar:
      'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 9,
    name: 'Claudie Smitham',
    avatar:
      'https://images.unsplash.com/photo-1584486520270-19eca1efcce5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 10,
    name: 'Emil Schaefer',
    avatar:
      'https://images.unsplash.com/photo-1561505457-3bcad021f8ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
]


interface PredictionsModalProps {
  children?: React.ReactNode
  isOpen?: boolean
  detectionType: "image" | "video"
  onConfirm: (detections: string[], tracking: boolean, token: string) => void
  onCancel: () => void
  onSelectDelimitedArea: () => void
}

//----------------------------------------------------------------
export function PredictionsModal({ isOpen, detectionType, onConfirm, onCancel, onSelectDelimitedArea, children }: PredictionsModalProps) {
  // refs
  const formRef = useRef<FormHandles>(null)
  const cancelButtonRef = useRef(null)
  // custom hooks
  const { setDisplayCounting, setDisplayTotalCounting, setTracking, savedToken, setSavedToken } = usePredictionsMenu()
  // react hooks
  const [isCustom, setIsCustom] = useState(false)
  const [preselectedMenu, setPreselectedMenu] = useState(INDUSTRIES_MENU)
  const [detectableItems, setDetectableItems] = useState<typeof INDUSTRIES_MENU[0] | null>(null)
  const [menuItems, setMenuItems] = useState(MENU_ITEMS)
  const [selectedItems, setSelectedItems] = useState<any[]>([])
  const [openItem, setOpenItem] = useState<number | null>(null);
  const [usedTokens, setUsedTokens] = useState(0)
  const [isChecked, setIsChecked] = useState(true)
  const [openPopover, setOpenPopover] = useState(false);
  const [tokenPopover, setTokenPopover] = useState(false);
  const [regionPopover, setRegionPopover] = useState(false);
  const [isRegionChecked, setIsRegionChecked] = useState(false);
  const [detectionsCollapse, setDetectionsCollapse] = useState(true);
  const { delimitedAreas } = useRegionConfiguration()
  const { tokenList, setTokenList, listUserTokensFromFirebase } = usePredictions()
  const { authUser } = useAuth()

  const [selected, setSelected] = useState<any>(null)

  //popover triggers
  const tokenTriggers = {
    onMouseEnter: () => setTokenPopover(true),
    onMouseLeave: () => setTokenPopover(false),
  };
  const triggers = {
    onMouseEnter: () => setOpenPopover(true),
    onMouseLeave: () => setOpenPopover(false),
  };

  const regionTriggers = {
    onMouseEnter: () => setRegionPopover(true),
    onMouseLeave: () => setRegionPopover(false),
  };
  //----------------------------------------------------------------------------

  useEffect(() => {
    setPreselectedMenu(preselectedMenu.map((m) => {
      if (m.id === preselectedMenu[1].id) {
        return { ...m, active: true }
      }
      return { ...m, active: false }
    }))
    setIsCustom(false)
    setDetectableItems(preselectedMenu.filter((i) => i.id === preselectedMenu[1].id)[0])
  }, [])

  useEffect(() => {
    setUsedTokens(detectionType === "image" ? 1 : 2)
  }, [detectionType,])

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldValue('token', savedToken);
    }
  }, [savedToken, formRef, isOpen])

  const loadTokens = async () => {
    if (authUser) {
      const tokens = await listUserTokensFromFirebase(authUser.uid)
      if (tokens) {
        setTokenList(tokens)
        const activeToken = tokens.find((t) => t.isMain === true)
        setSelected(activeToken)
      }
    }
  }

  useEffect(() => {
    (async () => {
      await loadTokens()
    })()
  }, [])

  const handleOpen = (value: number) => {
    setOpenItem(openItem === value ? null : value);
  };

  const handleSelectPreset = (id: string) => {
    setPreselectedMenu(preselectedMenu.map((m) => {
      if (m.id === id) {
        return { ...m, active: true }
      }
      return { ...m, active: false }
    }))
    setIsCustom(false)
    setDetectableItems(preselectedMenu.filter((i) => i.id === id)[0])
  }

  const handleSelectCustom = () => {
    // setPreselectedMenu(INDUSTRIES_MENU)
    setIsCustom(!isCustom)
    setDetectableItems(null)
    setPreselectedMenu(preselectedMenu.map((m) => {
      return { ...m, active: false }
    }))
  }

  const getUsedModels = () => {

  }

  const handleConfirmButton: SubmitHandler<FormData> = async (data: any) => {
    try {
      if (tokenList.length === 0) {
        const schema = Yup.object().shape({
          token: Yup.string().required("Please enter a valid token"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      }


      let detections = [] as any

      if (!isCustom && detectableItems) {
        detectableItems.items.map((i) => {
          if (i.id) {
            detections = [...detections, i.id.toLowerCase()]
          }
        })

      } else {
        selectedItems.map((i) => {
          if (i.id) {
            detections = [...detections, i.id.toLowerCase()]
          }
        })
      }

      // const uniqueDetections = Array.from(new Set(detections)) as string[]
      if (detections.length > 0) {
        setTracking(isChecked)
        setDisplayCounting(true)
        setDisplayTotalCounting(false)
        setSavedToken(data.token)
        onConfirm(detections, isChecked, data.token);
      }

    } catch (err: any) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          //@ts-ignore
          validationErrors[error.path] = error.message;
        });
        formRef?.current?.setErrors(validationErrors);
      }
    }
  }

  const handleAddMoreDetection = () => {

    detectableItems && setSelectedItems(detectableItems.items)
    handleSelectCustom()
  }

  const handleSelectItem = (id: string) => {
    menuItems.forEach((category) => {
      category.items.forEach((item) => {
        if (item.id === id) {
          // Check if the item is already in the selectedItems array
          const itemIndex = selectedItems.findIndex((selectedItem) => selectedItem.id === id);

          if (itemIndex !== -1) {
            // If the item is already in the selectedItems array, remove it
            setSelectedItems((prevSelectedItems) =>
              prevSelectedItems.filter((_, index) => index !== itemIndex)
            );
          } else {
            // If the item is not in the selectedItems array, add it
            setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
          }
        }
      });
    });
  };

  const contains = (id: string) => {
    const _i = selectedItems.find((item) => item.id === id)
    if (_i) {
      return true
    } else {
      return false
    }
  }

  return (
    <Transition.Root show={isOpen} as={Fragment} >
      <Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={onCancel} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 dark:bg-black bg-opacity-75 dark:bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className=" relative transform overflow-hidden min-w-[800px]  rounded-lg dark:bg-neutral-900 bg-white text-left shadow-xl transition-all sm:mb-8 pt-4 sm:w-full sm:max-w-lg border dark:border-neutral-600 custom-scrollbar">
                <div className=" dark:bg-neutral-900 bg-white max-h-[80vh] overflow-scroll w-full px-4 pb-4 sm:px-6 sm:pb-4 text-gray-600 dark:text-gray-300 custom-scrollbar">
                  <div className="sm:flex sm:items-start w-full">
                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                      <CodeBracketIcon className="h-6 w-6 text-primary-600" aria-hidden="true" />
                    </div> */}
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">
                        Detection Preferences
                      </Dialog.Title>

                      <div className="mt-2 w-full">
                        {children ? (children) : (
                          <p className="text-sm ">
                            Choose the detection categories you'd like our AI models to analyze.
                          </p>
                        )}

                        {/* MENU */}
                        <div className="flex w-full  mt-2 gap-2 justify-center items-center text-sm">
                          {preselectedMenu.map((item) => (
                            <div
                              onClick={() => handleSelectPreset(item.id)}
                              className={classNames(
                                item.active && "bg-neutral-100 dark:bg-neutral-700 shadow-inner",
                                "p-4 h-[176px] w-40 overflow-hidden flex flex-col items-center relative text-center cursor-pointer rounded-md hover:bg-neutral-100 dark:hover:bg-neutral-700 hover:shadow-inner  hover:text-primary-500"
                              )}>

                              {item.active && (
                                <div className="absolute -top-[55%] -right-[55%] w-32 h-32 bg-blue-500 flex justify-center items-center rotate-45 ">
                                  <CheckIcon className="w-4 h-4 -rotate-45 absolute left-[55%] bottom-1 font-bold text-white" />
                                </div>
                              )}
                              <div className="w-32 h-24 bg-blue-400 mb-2 rounded-md overflow-hidden" >
                                <img className="object-cover h-full w-full" src={item.background} alt="model" />
                              </div>
                              <label >{item.name}</label>
                            </div>
                          ))}

                          <div onClick={handleSelectCustom}
                            className={classNames(
                              isCustom && "bg-neutral-100 dark:bg-neutral-700 shadow-inner",
                              "p-4 h-[176px] w-40 overflow-hidden flex flex-col items-center relative text-center cursor-pointer rounded-md hover:bg-neutral-100 dark:hover:bg-neutral-700 hover:shadow-inner  hover:text-primary-500"
                            )}>

                            <div className="w-32 h-24 bg-blue-400 mb-2 rounded-md overflow-hidden relative" >
                              <img className="object-cover h-full w-full" src={Custom} alt="model" />
                              <PencilSquareIcon className="w-7 h-7 text-white absolute top-1/2 left-1/2"
                                style={{
                                  transform: "translateX(-50%) translateY(-50%)"
                                }}
                              />
                            </div>
                            {isCustom && (
                              <div className="absolute -top-[55%] -right-[55%] w-32 h-32 bg-blue-500 flex justify-center items-center rotate-45 ">
                                <CheckIcon className="w-4 h-4 -rotate-45 absolute left-[55%] bottom-1 font-bold text-white" />
                              </div>
                            )}
                            <label>Custom selection</label>
                          </div>
                        </div>

                        {/* ENABLE TRACKING */}

                        {detectionType === "video" && (
                          <div className="cursor-pointer p-2 flex rounded items-center" >
                            <Checkbox
                              color="blue"
                              id="tracking"
                              onChange={() => {
                                setIsChecked((e) => !e)
                              }}
                              ripple={true}
                              checked={isChecked}
                              label={
                                <p className="text-gray-600 dark:text-gray-300" >
                                  Enable Tracking
                                </p>}
                            />
                            <Popover
                              placement='top-start'
                              open={openPopover}
                              handler={setOpenPopover}
                              animate={{
                                mount: { scale: 1, y: 0, transition: { duration: 0.3 } },
                                unmount: { scale: 0, y: 25, transition: { duration: 0.3 } },
                              }}
                            >
                              <PopoverHandler {...triggers}>
                                <QuestionMarkCircleIcon className="w-6 h-6 text-primary-500 ml-2" />
                              </PopoverHandler>
                              <PopoverContent className="z-50" >
                                <p>Enable object tracking and counting. </p>
                                <p>This will allow you to monitor and quantify the objects in the video</p>
                              </PopoverContent>
                            </Popover>
                          </div>
                        )}



                        <Dialog.Title
                          as="h4"
                          className={
                            classNames(
                              "text-md mt-2 flex items-center font-medium leading-6 text-gray-900 dark:text-gray-200 cursor-pointer",
                              "hover:bg-neutral-200 dark:hover:bg-neutral-800 pl-2"
                            )
                          }
                          onClick={() => setDetectionsCollapse((e) => !e)}
                        >
                          <span className="w-full flex items-center"

                          >
                            Detections
                            <FiChevronDown className="text-gray-900 dark:text-gray-200 w-5 h-5 ml-2" />
                          </span>

                          {isCustom ? (
                            <div className=" flex justify-end w-full" >
                              <span onClick={(e) => {
                                setSelectedItems([])
                                e.stopPropagation()
                              }} className=" text-sm cursor-pointer p-2 rounded hover:bg-neutral-200 dark:hover:bg-neutral-700" >Clear all</span>
                            </div>
                          ) : (
                            <div className=" flex justify-end w-full" >
                              <span onClick={(e) => {
                                handleAddMoreDetection()
                                e.stopPropagation()
                              }} className="  text-sm  cursor-pointer flex items-center p-2 rounded hover:bg-neutral-200 dark:hover:bg-neutral-700" >
                                Add more detections
                                <PlusCircleIcon className="h-6 w-6 ml-2 text-blue-500 font-bold cursor-pointer hover:scale-105 " />
                              </span>
                            </div>
                          )}
                        </Dialog.Title>


                        {isCustom ? (
                          <Collapse open={detectionsCollapse}>
                            <div className="max-h-[280px] min-h-[280px]  h-full w-full  my-2 mb-8 flex overflow-hidden" >

                              <div className="general_items h-full max-h-[280px] min-h-[280px]  w-full p-2  pb-4 rounded shadow-inner ">
                                <Tabs id="custom-animation" value={menuItems[0].id}>
                                  <TabsHeader
                                    className="bg-transparent items-center"
                                    indicatorProps={{
                                      className: "bg-neutral-100 dark:bg-neutral-800 shadow-none text-blue-500",
                                    }}>
                                    {menuItems.map((item, index) => (
                                      <Tab className="h-[48px] text-sm dark:text-neutral-200" key={item.id} value={item.id}>
                                        {item.name}
                                      </Tab>
                                    ))}
                                  </TabsHeader>
                                  <TabsBody
                                    className=" duration-150 transition-all"
                                    animate={{
                                      initial: { y: 250 },
                                      mount: { y: 0, transition: { duration: 0.300 } },
                                      unmount: { y: 250, transition: { duration: 0.300 } },

                                    }}
                                  >
                                    {menuItems.map((item, index) => (
                                      <TabPanel className="grid grid-cols-3 gap-4 max-h-[240px]  overflow-hidden overflow-x-hidden overflow-y-scroll custom-scrollbar" key={item.id} value={item.id}>
                                        {item.items.map((i) => (
                                          <div
                                            key={`${i.id}_${item.id}`}
                                            onClick={() => handleSelectItem(i.id)}
                                            className={
                                              classNames(
                                                "p-4 relative flex flex-col items-center justify-center border-b border-neutral-50 text-center cursor-pointer",
                                                "dark:text-neutral-200",
                                                contains(i.id) ? "hover:bg-neutral-100 dark:hover:bg-neutral-700 text-primary-500" : "hover:bg-neutral-200 dark:hover:bg-neutral-800"
                                              )}>
                                            <img className={classNames(contains(i.id) ? "backdrop-brightness-100" : "brightness-75", "w-10 rounded-full mb-1")} src={i.icon ?? Icon} alt="car" />
                                            <span>{i.name}</span>
                                            {contains(i.id) && (
                                              <div className="w-5 h-5 bg-primary-500 rounded-full absolute top-0 right-0 flex items-center justify-center" >
                                                <CheckIcon className="w-3 h-3 text-white" />
                                              </div>
                                            )}

                                          </div>
                                        ))}
                                      </TabPanel>
                                    ))}
                                  </TabsBody>
                                </Tabs>
                              </div>

                              {/* Separator */}
                              <div className="h-full w-[2px] bg-neutral-300  mx-1 min-h-[280px] " ></div>

                              <div className="general_items h-full max-h-[280px] w-full p-2 overflow-x-hidden overflow-y-scroll custom-scrollbar rounded shadow-inner" >

                                {selectedItems && selectedItems.map((i: any) => (
                                  <div
                                    key={`${i.id}_`}
                                    onClick={() => console.log(i.id)}
                                    className={
                                      classNames(
                                        "py-2 px-4  flex items-center justify-between border-b border-neutral-50 dark:border-neutral-800",
                                        i.active ? "hover:bg-neutral-900/10 font-medium" : "hover:bg-neutral-900/10 text-neutral-400"
                                      )}>
                                    {/* <CustomCheckbox label={i.name} checked={i.active} /> */}
                                    <div className="flex items-center justify-between w-full" >
                                      <div className="flex items-center"  >
                                        <img className={classNames("w-6 mr-4")} src={i.icon ?? Icon} alt={i.id} />
                                        <span>{i.name}</span>
                                      </div>
                                      <XMarkIcon onClick={() => handleSelectItem(i.id)} className="h-5 w-5 cursor-pointer hover:scale-105 hover:text-red-300 " />
                                    </div>
                                  </div>
                                ))}
                              </div>

                            </div>
                          </Collapse>
                        ) : (
                          <Collapse open={detectionsCollapse}>
                            <div className="max-h-[180px] min-h-[180px] w-full overflow-x-hidden overflow-y-scroll custom-scrollbar mt-2" >
                              {detectableItems && detectableItems.items.map((i: any) => (
                                <div
                                  key={`${i.id}__`}
                                  onClick={() => console.log(i.id)}
                                  className={
                                    classNames(
                                      "py-2 px-4 pl-8 flex items-center justify-between border-b border-neutral-50 dark:border-neutral-800 ",
                                      i.active ? "hover:bg-neutral-900/10 font-medium" : "hover:bg-neutral-900/10 text-neutral-400",
                                    )}>
                                  {/* <CustomCheckbox label={i.name} checked={i.active} /> */}
                                  <div className="flex items center" >
                                    <img className={classNames("w-6 mr-4")} src={i.icon ?? Icon} alt={i.id} />
                                    <span>{i.name}</span>
                                  </div>
                                </div>
                              ))}

                            </div>
                          </Collapse>
                        )}

                      </div>
                    </div>
                  </div>

                  {detectionType === "video" && (
                    <>
                      <div className="p-2 flex rounded items-center justify-between" >
                        <div className="cursor-pointer flex rounded items-center">
                          <Checkbox
                            id="region"
                            color="blue"
                            onChange={() => {
                              setIsRegionChecked((e) => !e)
                            }}
                            ripple={true}
                            checked={isRegionChecked}
                            label={
                              <p className="text-gray-600 dark:text-gray-300" >
                                Include Configured Regions
                              </p>}
                          />
                          <Popover
                            placement='top-start'
                            open={regionPopover}
                            handler={setRegionPopover}
                            animate={{
                              mount: { scale: 1, y: 0, transition: { duration: 0.3 } },
                              unmount: { scale: 0, y: 25, transition: { duration: 0.3 } },
                            }}
                          >
                            <PopoverHandler {...regionTriggers}>
                              <QuestionMarkCircleIcon className="w-6 h-6 text-primary-500 ml-2" />
                            </PopoverHandler>
                            <PopoverContent className="z-50" >
                              <p>Includes configured regions. </p>
                              <p>This will allow our models to make specific detections on the configured regions.</p>
                            </PopoverContent>
                          </Popover>
                        </div>

                        {/* {isRegionChecked && (
                      <div className="" >
                        <span onClick={handleAddMoreDetection} className="font-bold  text-sm  cursor-pointer flex items-center p-2 rounded hover:bg-neutral-200 dark:hover:bg-neutral-700" >
                          Edit
                          <BiPencil className="h-6 w-6 ml-2 text-blue-500 font-bold cursor-pointer hover:scale-105 " />
                        </span>
                      </div>
                    )} */}



                        {/* {Object.entries(delimitedArea).filter((item: any) => item[0] !== "ID").map((item: any) => (
                      <div>

                        {!item[0].includes("EVENT") && (
                          <div
                            key={item[0]}
                            className={styles.delimited_area_fragment_detail_div}
                            style={{
                              display: (item[0] === "COLOUR" ? "none" : "flex"),
                            }}>
                            <span
                              className={styles.delimited_area_fragment_title}
                              style={{
                                color: (theme === "Light" ? variables.primaryColourLightMode : variables.primaryColourDarkMode),
                              }}>
                              {item[0].toUpperCase().replaceAll("_", " ").replace("TARGET CLASS ", "")}
                            </span>


                  
                                <span
                                  style={{
                                  }}>
                                  {JSON.stringify(item[1]).replaceAll(",", ", ").replace(" • CLASS", "").replaceAll("\"", "")}
                                </span>

                          </div>
                        )}

                        <div
                          style={{
                            display: ((item[0].includes("NAME") || item[0].includes("EVENT_TYPE")) ? "" : "none"),
                          }} />
                      </div>
                    ))} */}
                      </div>


                      {isRegionChecked && (
                        <div
                          className="px-4 mx-4"
                          onClick={onSelectDelimitedArea}
                        >
                          {delimitedAreas.length > 0 ? (
                            <div className="px-4 cursor-pointer border border-neutral-400 rounded-xl" >

                              {delimitedAreas.map((area: any, index) => (
                                <div key={index} className="px-4 py-2 " >
                                  <div className="flex items-center gap-2" >
                                    <span>
                                      Name:
                                    </span>
                                    <span >
                                      {JSON.stringify(area.TARGET_CLASS_NAME).replaceAll(",", ", ").replace(" • CLASS", "").replaceAll("\"", "")}
                                    </span>

                                    <div
                                      className="w-10 h-4 block ml-2 rounded-xl"
                                      style={{
                                        backgroundColor: ("rgba(" + area["COLOUR"].map((item: number) => (item + "")).join(", ") + ")"),
                                      }} />
                                  </div>

                                  <div className="flex items-center gap-2" >
                                    <span>
                                      Points:
                                    </span>
                                    <span className="text-sm text-gray-500" >
                                      {JSON.stringify(area.POINTS)}
                                    </span>
                                  </div>

                                </div>
                              ))}
                            </div>
                          ) : (
                            <>
                              <div className=" w-full p-4 text-center flex flex-col items-center gap-2 cursor-pointer border-dashed border-neutral-400 dark:border-neutral-400 border-2 rounded-xl">
                                <p className="font-bold  text-sm text-gray-500" >
                                  Add delimited area
                                </p>
                                <BiPlus className="text-gray-500" />
                              </div>
                            </>
                          )}
                        </div>
                      )}

                    </>
                  )}


                </div>

                <div className="w-full px-12 py-2 flex text-end justify-end text-primary-500" >
                  <Popover
                    placement='top-end'
                    open={tokenPopover}
                    handler={setTokenPopover}
                    animate={{
                      mount: { scale: 1, y: 0, transition: { duration: 0.3 } },
                      unmount: { scale: 0, y: 25, transition: { duration: 0.3 } },
                    }}
                  >
                    <PopoverHandler {...tokenTriggers}>
                      <QuestionMarkCircleIcon className="w-6 h-6 cursor-pointer text-primary-500 mr-2" />
                    </PopoverHandler>
                    <PopoverContent className="z-50" >
                      <span>Total tokens consumed for generating your predictions</span>
                    </PopoverContent>
                  </Popover>
                  <p>Used Tokens: {usedTokens}
                  </p>

                </div>

                <Form ref={formRef} onSubmit={handleConfirmButton} className="bg-gray-50 dark:bg-neutral-800  px-4 py-3 sm:flex sm:px-6">

                  <div className="input-wrapper flex items-center  sm:w-full mb-2 sm:mb-0" >
                    <KeyIcon className="h-6 w-6 mx-2 text-primary-600" aria-hidden="true" />
                    {tokenList.length > 0 ? (
                      <Listbox value={selected} onChange={setSelected} horizontal >
                        {({ open }) => (
                          <>
                            <div className="relative  w-full">
                              <Listbox.Button className="block h-10  p-2.5 rounded-md border border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 relative  mr-2 w-full dark:bg-neutral-700 dark:border-neutral-500 dark:text-white sm:text-sm sm:leading-6">
                                <span className="flex items-center">
                                  <span className="ml-3 block truncate">{selected?.token}</span>
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 bottom-12 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {tokenList.map((token) => (
                                    <Listbox.Option
                                      key={token.id}
                                      className={({ active }) =>
                                        classNames(
                                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                          'relative cursor-default select-none py-2 pl-3 pr-9'
                                        )
                                      }
                                      value={token}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <div className="items-center ml-3">
                                            <span
                                              className={classNames(selected ? 'font-semibold' : 'font-normal', ' block truncate')}
                                            >
                                              {token.tokenName}
                                            </span>
                                            <span className={classNames(active ? ' text-gray-300' : ' text-gray-500', "italic")} >
                                              {token?.token}
                                            </span>
                                          </div>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active ? 'text-white' : 'text-indigo-600',
                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                              )}
                                            >
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    ) : (
                      <Input name="token" className="mr-2 w-full dark:bg-neutral-700 dark:border-neutral-500 dark:text-white" type="text" placeholder="Enter your access token" />

                    )}


                  </div>

                  <div className="sm:flex sm:flex-row-reverse" >
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-gradient-to-r from-cyan-500 to-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => formRef?.current?.submitForm()}
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white dark:bg-neutral-500 dark:text-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={onCancel}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>

                  </div>


                </Form>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root >
  )
}
