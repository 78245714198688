
/**
 * Prediction Context Provider
 * 
 * React context for managing and sharing prediction data across the application.
 * The context includes state variables for tracking the progress and estimated 
 * time of predictions, image and video predictions, file URLs, selected files, 
 * and task IDs. The context provider component, `PredictionProvider`, is 
 * responsible for maintaining and updating this context data, which can be 
 * accessed and manipulated by its children components using the 
 * `predictionContext` object.
 * 
 */

import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import request from 'superagent';
import { VideoPredictionsType } from '../types/PredictionType';

//-----------------------------------------------------------------------------

const AVERAGE_PROCESSING_TIME = 120; // 180 seconds or 3 minutes
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://51.124.206.137:8085'
//-----------------------------------------------------------------------------

// Type definition
interface PredictionsContextData {
  isPreparing: boolean
  setIsPreparing: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  progress: number
  setProgress: React.Dispatch<React.SetStateAction<number>>
  estimatedTime: number
  setEstimatedTime: React.Dispatch<React.SetStateAction<number>>
  /**Obtained image predictions*/
  imagePredictions: any
  setImagePredictions: React.Dispatch<any>
  /**Obtained video predictions*/
  videoPredictions: VideoPredictionsType
  setVideoPredictions: React.Dispatch<VideoPredictionsType>
  /**Uploaded file url*/
  fileUrl: string | null
  setFileUrl: React.Dispatch<React.SetStateAction<string | null>>
  /**Uploaded file*/
  selectedFile: any
  setSelectedFile: React.Dispatch<any>
  /**ID for processing task endpoint*/
  taskId: string | null
  setTaskId: React.Dispatch<React.SetStateAction<string | null>>
  /**Token information*/
  tokenInfo: TokenInfo | null
  setTokenInfo: React.Dispatch<React.SetStateAction<TokenInfo | null>>
  /** all detected classes */
  detectedClasses: Set<string> | undefined
  setDetectedClasses: React.Dispatch<React.SetStateAction<Set<string> | undefined>>

  metadata: any
  setMetadata: React.Dispatch<any>

  mainToken: any
  setMainToken: React.Dispatch<React.SetStateAction<any>>

  tokenList: any[]
  setTokenList: React.Dispatch<React.SetStateAction<any[]>>
}

type PredictionsProviderProps = {
  children: ReactNode;
}

type TokenInfo = {
  token_balance: number
  token_description: string
  token_id: string
  token_usage: number
}
//-----------------------------------------------------------------------------

// Context
export const predictionContext = createContext<PredictionsContextData>({} as PredictionsContextData);

// Provider
export const PredictionProvider = ({ children }: PredictionsProviderProps) => {
  const [isPreparing, setIsPreparing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imagePredictions, setImagePredictions] = useState<any>(null)
  const [videoPredictions, setVideoPredictions] = useState<any>(null)
  const [progress, setProgress] = useState(0);
  const [estimatedTime, setEstimatedTime] = useState(AVERAGE_PROCESSING_TIME);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [taskId, setTaskId] = useState<string | null>(null)
  const [taskStatus, setTaskStatus] = useState<string | null>(null)
  //!to be removed or updated with new token system
  const [tokenInfo, setTokenInfo] = useState<TokenInfo | null>(null)
  //
  const [mainToken, setMainToken] = useState<any>(null)
  const [tokenList, setTokenList] = useState<any[]>([])

  const [detectedClasses, setDetectedClasses] = useState<Set<string>>()
  const [metadata, setMetadata] = useState<any>(null)

  const resolvePredictionTask = async (_taskId: string) :Promise<any> => {
    if (!_taskId) {
      setIsLoading(false);
      throw new Error("No task ID provided");
    }
  
    try {
      const req = request.get(apiBaseUrl + `/DeepNeuronicAPI/task/${_taskId}`);
  
      const response = await new Promise((resolve, reject) => {
        req.end((err: any, res: any) => {
          if (err) {
            reject(err);
          } else {
            const _response = res.body;
            resolve(_response);
          }
        });
      }) as any;
  
      console.log(response);
  
      if (response.task_status === "PENDING") {
        // Wait for a short interval before making the request again
        await new Promise((resolve) => setTimeout(resolve, 500));
  
        // Make the request again
        return await resolvePredictionTask(_taskId);
      } else if (response.task_status === "RESOLVED") {
        setIsLoading(false);
        return response;
      } else {
        setIsLoading(false);
        throw new Error("Invalid task status");
      }
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  // The context value to be provided to the consumers
  const value = {
    isLoading,
    progress,
    estimatedTime,
    setIsLoading,
    setProgress,
    setEstimatedTime,
    imagePredictions,
    setImagePredictions,
    videoPredictions,
    setVideoPredictions,
    fileUrl,
    setFileUrl,
    selectedFile,
    setSelectedFile,
    taskId,
    setTaskId,
    tokenInfo, 
    setTokenInfo,
    isPreparing, setIsPreparing,
    detectedClasses, setDetectedClasses,
    metadata, setMetadata,
    mainToken, setMainToken,
    tokenList, setTokenList
  };

  // Provide the context value to the children components
  return (
    <predictionContext.Provider value={value} >
      {children}
    </predictionContext.Provider>
  );
};