/**
 * Custom Hook: useFileLoader
 * 
 * This custom hook provides utility functions and data related to predictions,
 * including API calls for fetching image and video predictions. The hook consumes the
 * `feedbackContext` to manage and share state
 * across the application.
 */

//------------------------------------------------------------------------------
// react
import { useContext } from "react";
// libs
import { feedbackContext } from "../contexts/FeedbackContext";
import { database, storage } from "../services/firebase";
import { ref, push, query, orderByChild, get, child, set, remove, update } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL, getBlob, deleteObject } from "firebase/storage";
import { authContext } from "./useAuth";
//------------------------------------------------------------------------------

//------------------------------------------------------------------------------

export function useFeedback() {
  const pContext = useContext(feedbackContext)
  const { authUser } = useContext(authContext);
  //----------------------------------------------------------

  //TODO admin protection
  const fetchAllFeedbacks = async () => {
    const userDbRef = ref(database, `users/${authUser?.uid}`) 
    // check if admin


    const dbRef = ref(database, `feedback/`);
    const conversionsRef = query(dbRef);
    const snapshot = await get(conversionsRef)

    if (snapshot.exists()) {
      const fireBaseObj = await snapshot.val()
      const formattedData = Object.entries(fireBaseObj).map(([key, value]) => ({
        id: key,
        // @ts-ignore
        ...value
      }));
      console.log("feedback",formattedData)
      return formattedData
    } else {
      console.log("No data available");
      return null
    }
  }

  const setFeedbackReviewState = async (feedbackId:string, reviewed:boolean) => {
    await update(ref(database, `feedback/${feedbackId}`), {
      reviewed
    })
  }

  return {
    ...pContext,
    fetchAllFeedbacks,
    setFeedbackReviewState
  };
}









