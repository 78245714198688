export const TOKENS = [
  { token: "rf_GAb93Ubx44b4U1fxNaNKz8gYVCI3", consumedCredits: 20, totalCredits: 100 },
  { token: "rt_19LpP2xT5PhYKpGkCot5NaJkHugPQa", consumedCredits: 50, totalCredits: 150 },
  { token: "ra_83HcV7yZsR4C4xV6Fob7NcK2I6JtUv", consumedCredits: 10, totalCredits: 75 },
  { token: "re_GgC5d6Ee3Fv9HkLlDfD6EqM2NcYtIz", consumedCredits: 5, totalCredits: 125 },
  { token: "rw_92HtR6Ht8Je7KeU8FyF8HaL3PcBtUx", consumedCredits: 175, totalCredits: 200 },
  { token: "rz_AzN5c4Cz3Qw0ExX6GaG4HrK2IzBtUy", consumedCredits: 55, totalCredits: 100 },
  { token: "rx_BzP4b3Bx2Sv9IxI5HbH3IqL1NzYtUw", consumedCredits: 35, totalCredits: 75 },
  { token: "rc_DcQ3a2Cx1Tv8HxH4IaI2HaK0NcYtUv", consumedCredits: 85, totalCredits: 200 },
  { token: "rf_EeP2z1Pz0Su7GwG3GzJz9NzXtUz", consumedCredits: 95, totalCredits: 150 },
  { token: "rh_FfO1y0Oz9Rt6FvF2FxI8HaZ9NzWtUy", consumedCredits: 15, totalCredits: 125 },
  { token: "your_access_token", consumedCredits: 15, totalCredits: 125 }
];