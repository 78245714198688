import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { CodeBracketIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { MdFeedback } from "react-icons/md"
import { classNames } from '../../utils'
import tutorial from "../../assets/images/regionTutorial.gif"

interface RegionTutorialProps {
  isOpen: boolean
  onClose: () => void
}

export function RegionTutorial({ isOpen, onClose }: RegionTutorialProps) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 dark:bg-black bg-opacity-75 dark:bg-opacity-70 " />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-neutral-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl border dark:border-neutral-600">
                  <div className="bg-white dark:bg-neutral-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">

                      <div className="mt-3 text-center sm:mt-0 relative sm:text-left">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                          Drawing areas
                        </Dialog.Title>

                        <XMarkIcon onClick={onClose} className="absolute -top-2 right-0 w-5 h-5 text-white cursor-pointer hover:scale-125 duration-150" />
                        
                        <div className="mt-2">
                          <p className="text-gray-500 dark:text-gray-300">
                            Create accurate polygons for parking lots, roads, and more in just a few clicks!
                          </p>
                          <div className="flex gap-4 mt-4" >
                            <div className="w-[80%] rounded-md " >
                              <img className='rounded-lg' src={tutorial} alt="tutorial" />
                            </div>
                            <ul className='text-gray-500 dark:text-gray-400 pl-6 pt-4 list-disc' >
                              <li className="mt-2" >
                                <strong className="dark:text-white text-gray-700" >Start Drawing:</strong> Click on the image to begin your polygon.
                              </li>
                              <li className="mt-2" >
                                <strong className="dark:text-white text-gray-700" >Add Points: </strong>Click at each corner or bend to shape your polygon.
                              </li>
                              <li className="mt-2" >
                                <strong className="dark:text-white text-gray-700" >Edit with Ease: </strong>Adjust points or sides as needed for precision.
                              </li>
                            </ul>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 dark:bg-neutral-900 px-4 py-2 sm:flex sm:flex-row sm:px-6 justify-center mt-2">
                    <button
                      type="button"
                      className={classNames(
                        "w-full mr-4 justify-center rounded-md border border-transparent  px-4 py-2 text-base font-medium text-black dark:text-white  sm:ml-3 sm:w-auto sm:text-sm",
                        "focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2",
                        "hover:scale-105 duration-150",
                        "dark:bg-neutral-800"
                      )}
                      onClick={onClose}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
