/**
 * Settings Page Component
 *
 * This component serves as the Settings page, primarily focusing on token management and settings configurations.
 * It enables users to view, edit, and set active tokens used for predictions, along with displaying token consumption details.
 *
 * Features:
 * - Token Management: View and edit tokens, set a primary token, and add new tokens.
 * - Token Consumption Details: Visualize token usage with progress bars and consumption statistics. 
 *
 */

// React
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Libraries
import { Progress, Tooltip, Typography } from '@material-tailwind/react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { BiCopy } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
// Components
import { Button, Navbar, Sidebar } from '../components';
import { useAuth } from '../hooks/useAuth';
import { usePredictions } from '../hooks/usePredictions';
import { classNames } from '../utils';
import { usePredictionsMenu } from '../hooks/usePredictionsMenu';
import { AddTokenModal } from '../components/Modals';

interface SettingsProps {
}

export function Settings({ }: SettingsProps) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true)

  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
  const [tokenOnEdit, setTokenOnEdit] = useState<null | string>(null)


  const { authUser, loadingUser } = useAuth()
  const { listUserTokensFromFirebase, tokenList, setTokenList, mainToken, setMainToken, setTokenAsMain } = usePredictions()

  const [tokenPopover, setTokenPopover] = useState(false);

  const tokenTriggers = {
    onMouseEnter: () => setTokenPopover(true),
    onMouseLeave: () => setTokenPopover(false),
  };

  const handleCloseModal = () => {
    setTokenOnEdit(null)
    setIsTokenModalOpen(false)
  }

  const handleSaveToken = async () => {
    handleCloseModal()
    await loadTokens()

  }

  const handleSetMainToken = async (token: any) => {
    if (authUser) {
      await setTokenAsMain(authUser.uid, token.id)
      setMainToken(token)
      await loadTokens()
    }
  }

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Text copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text to clipboard:', err);
    }
  };

  const getConsumedTokensColor = (consumedTokens: number, totalTokens: number) => {
    const percentage = (consumedTokens / totalTokens) * 100;
    if (percentage >= 90) return 'text-red-500';
    if (percentage >= 80) return 'text-orange-500';
    if (percentage >= 70) return 'text-yellow-500';
    return 'text-green-500';
  }


  const loadTokens = async () => {
    if (authUser) {
      const tokens = await listUserTokensFromFirebase(authUser.uid)
      if (tokens) {
        setTokenList(tokens)
      }
    }
  }

  useEffect(() => {
    (async () => {
      if (!loadingUser) {
        if (authUser) {
          setIsLoading(false)
          await loadTokens()
        } else {
          navigate("/", { replace: true });
        }
      }
    })()
  }, [authUser, loadingUser])

  return (
    <>
      <div className="w-full bg-[#eef0f3] dark:bg-neutral-900 text-neutral-900 dark:text-white" >
        {isTokenModalOpen && (
          <AddTokenModal tokenId={tokenOnEdit} isOpen={isTokenModalOpen} onClose={handleCloseModal} onConfirm={handleSaveToken} />
        )}

        <Navbar>
          <></>
        </Navbar>
        <div className="flex w-full h-full" >
          <Sidebar >
            {isLoading ? (
              <div className="relative flex h-full w-full" >
                <div className="absolute right-0 top-0 bottom-0 left-0  flex items-center justify-center" >
                  <div className="flex flex-col items-center justify-center z-50" >
                    <div className="loading-screen w-full flex items-center justify-center flex-col">
                      <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                      {/* <Player
                        autoplay
                        loop
                        speed={1}
                        src={logoAnimation}
                        style={{ height: '100px', width: '100px' }}
                      >
                      </Player> */}
                      <span className="text-white">Loading settings...</span>

                    </div>
                    <div className="relative h-4 py-1 w-[150%] rounded mt-2 mb-1  overflow-x-hidden" >

                    </div>
                  </div>

                </div>
              </div>
            ) : (
              <div className="relative h-full w-full space-y-8 rounded-2xl md:space-y-0 py-10 px-20">
                <div className="w-full h-[320px] bg-[#eef0f3] dark:bg-neutral-900 text-neutral-900 dark:text-white rounded-xl " >
                  {mainToken ? (
                    <>
                      <h2 className="font-semibold text-lg" >Active Token:</h2>

                      <div className="w-full dark:bg-neutral-800 p-6 mt-8 rounded-xl" >
                        <div className="flex flex-col justify-between h-full" >
                          <p>{mainToken.name}</p>
                          <div className="w-full flex mt-4 px-8" >
                            <input className='dark:bg-neutral-700 bg-white  p-2 rounded-l-lg w-full' type="text" value={mainToken.token} />
                            <button
                              onClick={() => copyToClipboard(mainToken.token)}
                              className={
                                classNames(
                                  "h-6 cursor-pointer py-1 px-2 text-white rounded-r-lg text-xs flex items-center disabled:cursor-default md:px-4 md:h-10 md:text-base sm:text-sm ",
                                  "bg-gradient-to-r from-cyan-500 to-blue-500 font-bold",
                                )} >Copy
                            </button>
                          </div>

                          <div className="mt-6 text-center px-8" >
                            <p className="mb-2" >Consumed tokens: {mainToken.consumedCredits}/{mainToken.totalCredits}</p>
                            <Progress
                              value={50}
                              variant="gradient"
                              className="border border-blue-500/20 bg-blue-500/10 h-3 p-[2px] px-1 rounded-xl"
                            />

                          </div>
                        </div>
                      </div>

                    </>

                  ) : (
                    <>
                      <h2 className="font-semibold text-lg w-full text-center" >Active token not defined</h2>
                      <div className="w-full flex justify-center items-center" >
                        <div
                          onClick={() => {
                            setIsTokenModalOpen(true)
                          }}
                          className="p-8 w-[80%] group cursor-pointer rounded-xl dark:hover:bg-neutral-900/50 flex justify-center" >
                          <div className="p-[2px] border rounded border-primary-400 group-hover:scale-110 duration-150" >
                            <AiOutlinePlus className="w-6 h-6 text-primary-400 group-hover:scale-110" />
                          </div>
                        </div>

                      </div>
                    </>
                  )}


                </div>

                {/* Separator*/}
                <div className="w-[100%] h-[2px] flex items-center justify-center shadow-md shadow-blue-500 my-4" >
                  <div className="w-[85%] h-[1px] bg-neutral-800 dark:bg-white opacity-50  shadow-md shadow-blue-500" />
                </div>

                {mainToken && (
                  <>
                    <div className="w-full  bg-[#eef0f3] dark:bg-neutral-900 text-neutral-900 dark:text-white rounded-xl p-10 py-12" >

                      <h2 className="font-semibold text-lg" >My Tokens:</h2>

                      <div className="w-full dark:bg-neutral-800 p-6 mt-8 rounded-xl" >

                        {/* tokens header */}
                        <div className="flex flex-col justify-between h-full" >

                          <div className="w-full font-bold flex items-center justify-between border-b border-neutral-500 mb-8" >
                            <div className="w-full flex justify-center" >
                              <p className="font-bold w-[100px]" >Token name</p>
                              <p className="ml-8" > Consumed tokens </p>
                            </div>

                            <div className="group w-full px-8 py-2 flex rounded-xl font-bold justify-center" >
                              <p>Hash</p>
                            </div>
                            <div className="flex w-full justify-center" >
                            </div>
                          </div>
                        </div>
                        {/* tokens list */}

                        {tokenList.map((token) => (
                          <div className="flex flex-col justify-between h-full mb-8" >

                            <div className="w-full flex items-center justify-between" >
                              <div className="w-full flex justify-center" >
                                <Tooltip content={
                                  <div className="">
                                    <Typography
                                      variant="small"
                                      color="white"
                                      className="font-normal opacity-80"
                                    >
                                      {token.tokenName}
                                    </Typography>
                                  </div>
                                }>
                                  <p className="font-bold w-[100px] truncate" >{token.tokenName}</p>

                                </Tooltip>
                                <p className="ml-8 w-[140px] text-center" >
                                  <span className={classNames(
                                    getConsumedTokensColor(token.consumedCredits, token.totalCredits)
                                  )} >{token.consumedCredits}</span>
                                  <strong>/{token.totalCredits}</strong>
                                </p>
                              </div>

                              <div className="group w-full px-8 py-2 border-2 border-neutral-900 rounded-xl font-bold relative" >
                                <p>{token.token}</p>
                                <BiCopy onClick={() => copyToClipboard(token.token)} className="group invisible group-hover:visible w-6 h-6 absolute top-2 right-2 cursor-pointer opacity-50 hover:opacity-100" />
                              </div>
                              <div className="flex w-full justify-center" >

                                {token.isMain ? (
                                  <p className="text-neutral-400">Active token</p>
                                ) : (
                                  <>
                                    <PencilSquareIcon
                                      onClick={() => {
                                        setTokenOnEdit(token.id)
                                        setIsTokenModalOpen(true)
                                      }}
                                      className="w-6 h-6 dark:text-white hover:text-primary-400 mr-4 cursor-pointer"

                                    />
                                    <button onClick={() => handleSetMainToken(token)} className=" hover:text-primary-400 " >Set as main</button>
                                  </>

                                )}
                              </div>
                            </div>




                          </div>
                        ))}
                        <div className="w-full flex justify-center items-center" >
                          <div
                            onClick={() => {
                              setIsTokenModalOpen(true)
                            }}
                            className="p-8 w-[80%] group cursor-pointer rounded-xl hover:bg-neutral-900/50 flex justify-center" >
                            <div className="p-[2px] border rounded group-hover:border-primary-400 " >
                              <AiOutlinePlus className="w-6 h-6 group-hover:text-primary-400 " />
                            </div>
                          </div>

                        </div>

                      </div>

                    </div>
                  </>
                )}



              </div>
            )}

          </Sidebar>


        </div>
      </div>
    </>
  );
}
