import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { MenuProvider } from './contexts/PredictionsMenuContext';
import { VideoProcessingProvider } from './contexts/VideoProcessingContext';
import { PredictionProvider } from './contexts/PredictionsContext';
import { DragDropProvider } from './contexts/DragDropContext';
import { AuthProvider } from './hooks/useAuth';
import { FileLoaderProvider } from './contexts/FileLoaderContext';
import { DarkModeProvider } from './hooks/useTheme';
import { RegionConfigurationProvider } from './contexts/ReagionConfigurationContext';
import { FeedbackProvider } from './contexts/FeedbackContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <DarkModeProvider>
        <MenuProvider>
          <FileLoaderProvider>
            <RegionConfigurationProvider>
              <PredictionProvider>
                <VideoProcessingProvider>
                  <FeedbackProvider>
                    <DragDropProvider>
                      <App />
                    </DragDropProvider>
                  </FeedbackProvider>
                </VideoProcessingProvider>
              </PredictionProvider>
            </RegionConfigurationProvider>
          </FileLoaderProvider>
        </MenuProvider>
      </DarkModeProvider>
    </AuthProvider>
  </React.StrictMode>
);
