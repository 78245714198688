/**
 * This module provides a global context and provider for the menu settings and configurations.
 * It manages the state for various display settings, active menus, and saved tokens.
 */

import { createContext, useState, useEffect, ReactNode } from 'react';
import { INDUSTRIES_MENU, MENU_ITEMS } from "../mock/menu";

// Define the structure of the data available in the MenuContext.
interface MenuContextData {
  /** Flag to indicate if counting display is enabled. */
  displayCounting: boolean;
  setDisplayCounting: React.Dispatch<React.SetStateAction<boolean>>;

  /** Flag to indicate if tracking is enabled. */
  tracking: boolean;
  setTracking: React.Dispatch<React.SetStateAction<boolean>>;

  /** Saved token value. */
  savedToken: string;
  setSavedToken: React.Dispatch<React.SetStateAction<string>>;

  /** Flag to indicate if total counting display is enabled. */
  displayTotalCounting: boolean;
  setDisplayTotalCounting: React.Dispatch<React.SetStateAction<boolean>>;

  /** Flag to indicate if all tags should be displayed. */
  displayAllTags: boolean;
  setDisplayAllTags: React.Dispatch<React.SetStateAction<boolean>>;

  /** The currently active menu. */
  activeMenu: Menu;
  setActiveMenu: React.Dispatch<React.SetStateAction<Menu>>;
}

// Props structure for the MenuProvider component.
type MenuProviderProps = {
  children: ReactNode;
}

// Define the structure of a Menu based on the INDUSTRIES_MENU.
type Menu = typeof INDUSTRIES_MENU;

export const predictionMenuContext = createContext<MenuContextData>({} as MenuContextData);

export const MenuProvider = ({ children }: MenuProviderProps) => {
  const [displayCounting, setDisplayCounting] = useState(false)
  const [displayTotalCounting, setDisplayTotalCounting] = useState(false)
  const [tracking, setTracking] = useState(false)
  const [savedToken, setSavedToken] = useState("")
  const [displayAllTags, setDisplayAllTags] = useState(true)
  const [activeMenu, setActiveMenu] = useState<Menu>(INDUSTRIES_MENU)
  
  const value = {
    displayCounting,
    setDisplayCounting,
    tracking,
    setTracking,
    savedToken, setSavedToken,
    displayTotalCounting, setDisplayTotalCounting,
    displayAllTags, setDisplayAllTags,
    activeMenu, setActiveMenu
  };

  return (
    <predictionMenuContext.Provider value={value} >
      {children}
    </predictionMenuContext.Provider>
  );
};










