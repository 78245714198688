import React, { useState, useEffect } from "react";
// import RemoveIcon from '@mui/icons-material/Remove';
import styles from "./delimited_areas_list.module.scss";
import variables from "../../styles/variables.module.scss";
import json_delimited_areas_list_en from "./delimited_areas_list_en.json";
import json_delimited_areas_list_pt from "./delimited_areas_list_pt.json";
import { AiOutlineMinus } from "react-icons/ai"
import { DelimitedAreaFragment } from "./DelimitedAreaFragment";

import { classNames } from '../../utils';
import { useRegionConfiguration } from "../../hooks/useRegionConfiguration";
import { DelimitedArea } from "../../contexts/ReagionConfigurationContext";

type propsType = {
    inBrowser: boolean; // whether we are rendering in the client or server side
    theme: string; // the theme that is currently active
    language: string; // the current language setting
    delimitedAreas: DelimitedArea[]; //
    changeDelimitedAreas: any;
    isEditing: boolean; //
    barTitle?: any; //
    barDescription?: any; //
    // hoveredInstance: number
    // onHover: (instance:number) => void
}


function DelimitedAreasList({ inBrowser, theme, language, delimitedAreas, changeDelimitedAreas, isEditing, barTitle = "", barDescription = "" }: propsType) {
    console.log("DelimitedAreasList")

    const [jsonData, setJsonData]: any = useState(language === "English" ? json_delimited_areas_list_en : json_delimited_areas_list_pt);
    //TODO lift up
    // const [hoveredInstance, setHoveredInstance] = useState(-1);

    const [hoveredInstanceForDelete, setHoveredInstanceForDelete] = useState(-1);
    const [rootDivIsHovered, setRootDivIsHovered] = useState(false);
    const [canOpenDelimitedAreasModal, setCanOpenDelimitedAreasModal] = useState(false);
    const [isAddOrEditButtonHovered, setIsAddOrEditButtonHovered] = useState(false);
    const { setDelimitedAreas, areasType, setParkingLotAreas, parkingLotAreas, hoveredInstance, setHoveredInstance } = useRegionConfiguration()

    useEffect(() => {
        setJsonData(language === "English" ? json_delimited_areas_list_en : json_delimited_areas_list_pt);
    }, [language]);

    useEffect(() => {
        if (!isEditing)
            return;

        const currentValue = localStorage.getItem("activeDelimitedAreaIdDraw");
        if(currentValue !== hoveredInstance.toString()) {
            localStorage.setItem("activeDelimitedAreaIdDraw", hoveredInstance + "");
            window.dispatchEvent(new Event("activeDelimitedAreaIdDrawChange"));
        }
    }, [hoveredInstance]);

    // add event listeners
    useEffect(() => {

        function activeDelimitedAreaIdDrawChange() {
            // setHoveredInstance(parseInt(localStorage.getItem("activeDelimitedAreaIdDraw") as string));
        }
        window.addEventListener('activeDelimitedAreaIdDrawChange', activeDelimitedAreaIdDrawChange);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return (() => {
            window.removeEventListener('activeDelimitedAreaIdDrawChange', activeDelimitedAreaIdDrawChange);
        })

    }, []);

    const handleDeleteClick = (delimitedArea: any) => {
        changeDelimitedAreas(
            "remove",
            delimitedArea["ID"],
            delimitedArea
        )
        setHoveredInstance(-1);
        setHoveredInstanceForDelete(-1);

        window.dispatchEvent(new Event("delimitedAreaFragmentColourShouldUpdate"))
    }

    const determineRootDivBackgroundColour = () => {

        if (rootDivIsHovered)
            return (theme === "Light" ? variables.accentColourLightMode : variables.accentColourDarkMode)
        return ("")
    }

    const handleEditOrAddButtonClick = () => {
        // if (!canOpenDelimitedAreasModal) {
        //     window.dispatchEvent(new Event("showRedBorderAroundThumbnail"));
        //     return;
        // }

        localStorage.setItem("activeDelimitedAreaIdDraw", "-1");
        window.dispatchEvent(new Event("showDelimitedAreasMenu"));
    }

    const getBorderColor = (item: any) => {
        if (hoveredInstanceForDelete === item["ID"]) {
            return theme === "Light" ? variables.deleteColourLightMode : variables.deleteColourDarkMode;
        }
    };

    const copyPolygon = (item: DelimitedArea) => {
        // Deep clone the item object
        const newItem = JSON.parse(JSON.stringify(item));
    
        // Find the maximum ID in the existing delimitedAreas
        let maxID = 1
        if (areasType === "DEFAULT") {
            maxID = delimitedAreas.reduce((max: any, currItem: any) => Math.max(max, currItem.ID), 0);
        } else {
            maxID = parkingLotAreas.reduce((max: any, currItem: any) => Math.max(max, currItem.ID), 0);
        }
    
        // Set the new item's ID to be one greater than the maximum ID
        newItem.ID = maxID + 1;
    
        // Append "copy" to the end of the TARGET_CLASS_NAME, but only if it doesn't already end with "copy"
        if (!newItem.TARGET_CLASS_NAME.trim().endsWith('copy')) {
            newItem.TARGET_CLASS_NAME = `${newItem.TARGET_CLASS_NAME} copy`;
        }
    
        // Slightly shift the POINTS on the X-axis.
        newItem.POINTS = newItem.POINTS.map((point: number[]) => [point[0] + 30, point[1]]);
        console.log("newItem:", newItem)
        
        if (areasType === "DEFAULT") {
            //@ts-ignore Step 4: Update the state array 
            setDelimitedAreas((prevDelimitedAreas) => [...prevDelimitedAreas, newItem]);
        } else {
            setParkingLotAreas((prevDelimitedAreas) => [...prevDelimitedAreas, newItem]);
        }
    };
    
    return (
        <div
            className={classNames(
                styles.delimited_areas_list_root_div,
                "hide-scrollbar"
            )}
            onMouseEnter={() => setRootDivIsHovered(true)}
            onMouseLeave={() => setRootDivIsHovered(false)}
            style={{
                paddingLeft: (isEditing ? "5px" : ""),
                paddingRight: (isEditing ? "5px" : ""),
                paddingTop: (isEditing ? "5px" : ""),
                backgroundColor: (!isEditing ? determineRootDivBackgroundColour() : ""),
            }}>

            <div
                className={styles.text_super_div}
                style={{
                    display: (isEditing ? "none" : ""),
                }}>
                <div className={styles.title_div}>
                    <div
                        className={styles.trailing_title_text}
                        style={{
                            display: (barTitle.split(" / ").length === 1 ? "none" : ""),
                            // color: (theme === "Light" ? variables.primaryColourLightMode : variables.primaryColourDarkMode)
                        }}>
                        {(barTitle.split(" / ").slice(0, barTitle.split(" / ").length - 1).join(" / ") + " / ").replaceAll("_", " ")}
                    </div>
                    <div
                        className={styles.title_text}
                        style={{
                            // color: (theme === "Light" ? variables.primaryColourLightMode : variables.primaryColourDarkMode)
                        }}>
                        {barTitle.split(" / ").slice(barTitle.split(" / ").length - 1).join("").replaceAll("_", " ")}
                    </div>

                    <span
                        className={styles.add_or_edit_button_div}
                        onClick={() => handleEditOrAddButtonClick()}
                        onMouseEnter={() => setIsAddOrEditButtonHovered(true)}
                        onMouseLeave={() => setIsAddOrEditButtonHovered(false)}
                        style={{
                            opacity: (isAddOrEditButtonHovered && canOpenDelimitedAreasModal ? "0.8" : (canOpenDelimitedAreasModal ? "1.0" : "0.3")),
                            cursor: ("pointer"),
                            backgroundColor: (theme === "Light" ? variables.primaryColourLightMode : variables.primaryColourDarkMode)
                        }}>
                        <span
                            className={styles.add_or_edit_button_text}
                            style={{
                                color: (theme === "Light" ? variables.windowBackgroundColourLightMode : variables.windowBackgroundColourDarkMode)
                            }}>
                            {(delimitedAreas.length === 0 ? jsonData.add_button_text : jsonData.edit_button_text).toUpperCase()}
                        </span>
                    </span>
                </div>

                <div className={styles.description_div}>
                    <div
                        className={styles.description_text}
                        dangerouslySetInnerHTML={{ __html: barDescription }}
                        style={{
                            // color: (theme === "Light" ? variables.textColourLightMode : variables.textColourDarkMode)
                        }} />
                </div>
            </div>

            <div
                className={classNames(
                    styles.delimited_areas_list_div,
                    "max-h-[220px] overflow-scroll p-2",
                    "hide-scrollbar"
                )}
                style={{
                    // padding: (!isEditing ? "10px" : "0px"),
                    // paddingTop: "0px",
                    // marginTop: (!isEditing ? "-5px" : "0px"),
                }}>
                {delimitedAreas.map((item: any) => (
                    <div
                        key={item["ID"]}
                        className={classNames(
                            "bg-neutral-200 dark:bg-neutral-800",
                            "text-gray-600 dark:text-gray-400",
                            "rounded-lg relative",
                            "border border-transparent dark:hover:border-white hover:border-neutral-900 ",
                        )}
                        onMouseEnter={() => setHoveredInstance(item["ID"])}
                        onMouseLeave={() => setHoveredInstance(-1)}
                        style={{
                            borderColor: getBorderColor(item),
                        }}>
                        <DelimitedAreaFragment
                            inBrowser={inBrowser}
                            theme={theme}
                            jsonData={jsonData}
                            isEditing={isEditing}
                            delimitedArea={item}
                            delimitedAreaId={item["ID"]}
                            changeDelimitedAreas={changeDelimitedAreas}
                            onCopy={() => copyPolygon(item)}
                        />

                        <div
                            onClick={() => handleDeleteClick(item)}
                            onMouseEnter={() => setHoveredInstanceForDelete(item["ID"])}
                            onMouseLeave={() => setHoveredInstanceForDelete(-1)}
                            className={styles.delete_div}
                            style={{
                                visibility: ((hoveredInstance === item["ID"] && isEditing) ? "visible" : "hidden"),
                                opacity: ((hoveredInstance === item["ID"] && isEditing) ? "1.0" : "0.0"),
                                backgroundColor: (theme === "Light" ? variables.deleteColourLightMode : variables.deleteColourDarkMode),
                            }}>
                            <div className={styles.delete_icon}>
                                <AiOutlineMinus className="text-white w-6 h-6" />
                            </div>
                        </div>

                    </div>
                ))}
            </div>

            <div
                className={styles.no_delimited_areas_div}
                style={{
                    display: (delimitedAreas.length === 0 ? "flex" : "none"),
                    visibility: (delimitedAreas.length === 0 ? "visible" : "hidden"),
                    opacity: (delimitedAreas.length === 0 ? "1.0" : "0.0"),
                    border: "1px dashed " + (theme === "Light" ? variables.borderColourLightMode : variables.borderColourDarkMode),
                }}>
                <div
                    className={styles.no_delimited_areas_text}
                    style={{
                        color: (theme === "Light" ? variables.textColourLightMode : variables.textColourDarkMode)
                    }}>
                    {jsonData.no_delimited_areas_text}
                </div>
            </div>
        </div>
    );
}

export default DelimitedAreasList;