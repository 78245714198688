import { useLocation } from 'react-router-dom';

export function SampleLoader() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const data = queryParams.get('data'); // 'someData'

  return (
    <>
      <h1>SampleLoader</h1>
      <p>Data: {data}</p>
    </>
  );
}
