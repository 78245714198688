/**
 * TourTooltip Component.
 * 
 * This component serves as a custom tooltip used with React Joyride.
 * It displays step-specific content, images, and navigation buttons for a user-friendly interactive tour.
 * Used on @FeedbackModal component and @Dashboard page
 *
 * Features:
 * - Customizable Content: Displays titles, content, and images based on the current step of the tour.
 * - Navigation Buttons: Provides 'Back', 'Next', and 'Dismiss' buttons to navigate through the tour steps.
 * - Responsive Design: Adapts to different step configurations, including image sizes.
 * - Styling: Uses custom styles for dark and light themes.
 */

// Utilities
import { classNames } from '../utils';

interface TourTooltipProps {
  /** Indicates if the tour step is part of a continuous sequence. */
  continuous: boolean;

  /** The current index of the tour step. */
  index: number;

  /** The content and configuration of the current tour step. */
  step: any; // Replace 'any' with a more specific type if available

  /** Props for the 'Back' button. */
  backProps: any;

  /** Props for the 'Close' button. */
  closeProps: any;

  /** Props for the 'Next' button. */
  primaryProps: any;

  /** Additional tooltip props. */
  tooltipProps: any;

  // Add any other props as needed
}


export function TourTooltip({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  // Add any other props you need
}: any) {
  return (
    <div {...tooltipProps}
      className={classNames(
        "p-4 dark:bg-[#181818] rounded-lg shadow-md dark:text-gray-400 max-w-sm",
        step.imageSize === "large" ? " max-w-lg" : ""
      )}>
      {step.title && <div className="font-bold text-lg dark:text-gray-200">{step.title}</div>}
      <div className="text-sm mt-1">{step.content}</div>
      {step.content2 && <div className="text-sm">{step.content2}</div>}
      {step.image && <img className={
        classNames("mt-2 mx-auto", step.imageSize === "small" ? "w-[70%]" : "w-full")}
        src={step.image}
      />}
      <div className="flex justify-end mt-3 space-x-2">
        {index > 0 && (
          <button {...backProps} className="px-4 py-1 text-white hover:text-gray-300 rounded">
            Back
          </button>
        )}
        {continuous && (
          <button {...primaryProps} className="px-4 py-1 text-white underline rounded">
            Next
          </button>
        )}
        {!continuous && (
          <button {...closeProps} className="px-4 py-1 text-white hover:text-gray-300 underline rounded">
            Dismiss
          </button>
        )}
      </div>
    </div>
  );
};
