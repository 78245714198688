/**
 * SvgPolygon Component.
 * 
 * This component is designed for rendering polygons, typically used for delineating areas in an image or video frame.
 * It supports scaling and coloring based on the provided data and the current frame context.
 * Used on @DownloadPreview and @VideoPlayer components
 *
 * Features:
 * - Dynamic Scaling: Adjusts the size of the polygon based on the provided width and height.
 * - Contextual Coloring: Colors the polygon based on the intersection with objects in the current frame and predefined area colors.
 * - Clickable: Optionally supports a click event handler for interaction.
 */

// React
import React from 'react';

// Libraries
import { FrameType } from "../types/PredictionType";
import { doPolygonAndRectIntersect, reduceRectSize } from "../utils/regions";
import { useRegionConfiguration } from "../hooks/useRegionConfiguration";

interface SvgPolygonProps {
    /** The width to scale the polygon to. */
    width: number;

    /** The height to scale the polygon to. */
    height: number;

    /** The original width of the area the polygon represents. */
    originalWidth: number;

    /** The original height of the area the polygon represents. */
    originalHeight: number;

    /** The data defining the points and color of the polygon. */
    delimitedArea: any;

    /** The current frame data used for contextual coloring. */
    currentFrame: FrameType | null;

    /** (Optional) Function to execute when the polygon is clicked. */
    onClick?: (event: any) => any;
}

export function SvgPolygon({onClick, width, height, originalWidth, originalHeight, delimitedArea, currentFrame }: SvgPolygonProps) {
    const { displayedParkingAreas } = useRegionConfiguration()

    const points = delimitedArea.POINTS ?? []

    // Normalize points based on original dimensions
    const normalizedPoints = points.map((point: any) => [
        point[0] / originalWidth,
        point[1] / originalHeight
    ]);

    // Scale normalized points based on new dimensions
    const scaledPoints = normalizedPoints.map((point: any) => [
        point[0] * width,
        point[1] * height
    ]);


    // const isIntersecting = (object: ObjectType): boolean => {
    //     return doPolygonAndRectIntersect(delimitedArea.POINTS, object.boundingBox);
    // };

    const getAreaColor = (): string => {
        if (!currentFrame) {
            const [r, g, b] = delimitedArea["COLOUR"];
            return `rgba(${r}, ${g}, ${b}, 0.3)`;
        } else {
            for (let obj of currentFrame.objects) {
                const reducedBoundingBox = reduceRectSize(obj.boundingBox, 0.8);
                if (doPolygonAndRectIntersect(delimitedArea.POINTS, reducedBoundingBox)) {
                    const areaColor = obj.areas?.find(area => area.id === delimitedArea.ID)?.color;

                    if (areaColor) {
                        const match = areaColor.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);

                        if (match) {

                            const [_, r, g, b] = match;
                            return `rgba(${r}, ${g}, ${b}, ${displayedParkingAreas.occupied ? 0.3 : 0})`;
                        }
                    } else {

                        const [r, g, b] = delimitedArea["COLOUR"];
                        return `rgba(${r}, ${g}, ${b}, ${displayedParkingAreas.free ? 0.3 : 0})`;
                    }
                }
            }

            const [r, g, b] = delimitedArea["COLOUR"];
            return `rgba(${r}, ${g}, ${b}, ${displayedParkingAreas.free ? 0.3 : 0})`;
        }
    };


    const pointsString = scaledPoints.map((point: any) => point.join(',')).join(' ');
    const fillColor = getAreaColor();


    return (
        <svg onClick={onClick} className="z-20 absolute top-0" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
            <polygon points={pointsString} fill={fillColor} stroke={fillColor} />
        </svg>
    );
}