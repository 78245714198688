/**
 * LoadingMask Component.
 * 
 * This component is designed to provide a visual indicator for loading processes, specifically for the predictions feature. 
 * It displays an animated loader, a progress bar, and an optional custom text message.
 * Used on @ImageDrawer @VideoPlayer and @PredictionPage components
 *
 * Features:
 * - Animated Loading Indicator: Utilizes the LottieFiles Player to display a loading animation.
 * - Progress Tracking: Shows a progress bar indicating the completion percentage of the loading process.
 * - Custom Text Display: Allows the display of a custom text message during loading.
 * - Estimation Display: Shows the estimated time for the loading process based on the progress.
 */

// React
import { motion } from "framer-motion";

// Libraries
import Lottie from 'react-lottie-player'

// Components/Assets/Helpers
import logoAnimation from "../utils/animations/logoAnimation.json";
import { usePredictions } from '../hooks/usePredictions';

interface LoadingMaskProps {
  /** Custom text message to display during the loading process. */
  text: string;
}

export function LoadingMask({ text }: LoadingMaskProps) {
  const { progress, estimatedTime, isLoading } = usePredictions(false)

  return (
    <>
      <div className="absolute right-0 top-0 bottom-0 left-0  flex items-center justify-center z-50" >
        <div className="absolute right-0 top-0 bottom-0 left-0 z-40 bg-black opacity-70 flex items-center justify-center" ></div>
        <div className="flex flex-col items-center justify-center z-50" >
          <div className="loading-screen w-full flex items-center justify-center flex-col">
            <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
            <Lottie
              autoPlay
              loop
              speed={1}
              src={logoAnimation}
              style={{ height: '100px', width: '100px' }}
            >
            </Lottie>
            {isLoading ? (
            <span className="text-white">{text}</span>

            ): (
            <span className="text-white">{text ?? "Setting up our models..."}</span>

            )}
          </div>
          <div className="relative h-4 py-1 w-[150%] rounded mt-2 mb-1  overflow-x-hidden" >
            {isLoading && (
              <motion.div
                className={`rounded bg-primary-500 h-full left-0 duration-300 relative overflow-x-hidden`}
                style={{
                  width: `${progress >= 100 ? 100 : progress}%`
                }}
              >
                <motion.div
                  animate={{
                    left: ["-50%", "120%"],
                  }}
                  className={`opacity-75 rounded-3xl absolute bg-gradient-to-r from-primary-500 via-cyan-500  to-primary-500 h-full left-0 w-16`}
                  transition={{
                    duration: 3,
                    ease: "linear",
                    repeat: Infinity,
                  }}>

                </motion.div>
              </motion.div>
            )}

          </div>
          {isLoading && (
            <span className="text-white text-center " >{(estimatedTime / 100 * progress).toFixed(2)}s/{estimatedTime.toFixed(2)}s</span>
          )}
        </div>

      </div>
    </>
  );
}
