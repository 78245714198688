/**
 * Video Processing Context Provider
 * 
 * React context for managing and sharing video processing data across the application. 
 * The context includes state variables for tracking the job ID and the processing 
 * status of video processing tasks. 
 * The context provider component, `VideoProcessingProvider`, is responsible for maintaining
 * and updating this context data, which can be accessed and manipulated by its
 * children components using the `VideoProcessingContext` object.
 * 
 */

import { createContext, useContext, useState, useEffect, FC } from 'react';

interface VideoProcessingContextType {
  jobId: string | null;
  setJobId: (jobId: string | null) => void;
  processingStatus: 'idle' | 'processing' | 'done';
  setProcessingStatus: (status: 'idle' | 'processing' | 'done') => void;
}

export const VideoProcessingContext = createContext<VideoProcessingContextType>({} as VideoProcessingContextType);

export const VideoProcessingProvider: FC = ({ children }:any) => {
  const [jobId, setJobId] = useState<string | null>(null);
  const [processingStatus, setProcessingStatus] = useState<'idle' | 'processing' | 'done'>('idle');

  return (
    <VideoProcessingContext.Provider
      value={{ jobId, setJobId, processingStatus, setProcessingStatus }}
    >
      {children}
    </VideoProcessingContext.Provider>
  );
};
