/**
 * VideoDrawer Component.
 * 
 * This component is responsible for rendering bounding boxes, pulsing dots, and expandable name tags 
 * on a video frame based on the provided data. It utilizes Framer Motion for animations and 
 * conditional rendering based on various states and props.
 * Used on @VideoPlayer component
 *
 * Features:
 * - Dynamic Bounding Boxes: Renders bounding boxes around detected objects in the video frame.
 * - Pulsing Dots: Adds a visual cue to the center of each bounding box.
 * - Expandable Name Tags: Displays additional information about the object when the bounding box is hovered.
 * - Filtering: Allows rendering based on specified filter criteria.
 * - Resizable: Adjusts bounding box sizes according to the video dimensions.
 *
 */

// React and Libraries
import { useEffect, useState, Fragment, useCallback, useMemo } from 'react';
import { motion } from "framer-motion";

// Utilities
import { classNames, getColorStyles, isSmallPersonalObject } from '../utils';

// Types
import { FrameType } from '../types/PredictionType';

// Hooks
import { usePredictionsMenu } from '../hooks/usePredictionsMenu';
import { useRegionConfiguration } from '../hooks/useRegionConfiguration';

interface VideoDrawerProps {
  /**@deprecated*/ 
  frames: any
  /** The original dimensions of the video/image. */
  originalImageSize: ImageSizeObject
  /** The current frame data from the video. */
  currentFrame: FrameType
  /** Indicates if the video is currently playing. */
  isPlaying?: boolean
  /** The currently selected object for displaying detailed information. */
  selectedObject: string | null
  /** An array of class names to filter the displayed objects. */
  filter: string[]
  /** The new dimensions of the video after resizing. */
  videoNewDimensions: ImageSizeObject | null
}

type ImageSizeObject = {
  width: number
  height: number
}


const animationDurationRef = 0.1;

export function VideoDrawer({
  currentFrame,
  originalImageSize,
  isPlaying,
  selectedObject,
  filter,
  videoNewDimensions
}: VideoDrawerProps) {
  // const [imageSize, setImageSize] = useState<ImageSizeObject | null>(null)
  const [visibleTooltip, setVisibleTooltip] = useState<string | null>(selectedObject)
  const [expandedTooltip, setExpandedTooltip] = useState<string | null>(selectedObject)
  const {
    displayAllTags,
  } = usePredictionsMenu()

  const { activeAreas, areasType } = useRegionConfiguration()

  const activeAreasSet = useMemo(() => new Set(activeAreas), [activeAreas]);


  useEffect(() => {
    setExpandedTooltip(selectedObject)
    setVisibleTooltip(selectedObject)
  }, [selectedObject])

  const handleClick = useCallback((id: string | null) => {
    if (isPlaying) {
      setExpandedTooltip((prev) => prev !== null ? null : prev);
      return;
    }
    setExpandedTooltip((prev) => prev === id ? null : id);
  }, [expandedTooltip, isPlaying]);

  const handleMouseLeave = useCallback((id: string) => {
    setVisibleTooltip(null);
    setExpandedTooltip(null);
  }, []);

  const getSize = (size: number) => {
    if (!videoNewDimensions) {
      const _sizes = handleGetVideoSize()
      return _sizes.width * size / originalImageSize.width
    }
    return videoNewDimensions.width * size / originalImageSize.width
  }

  const handleGetVideoSize = useCallback(() => {
    const video = document.getElementById('video') as any
    if (video) {
      const { offsetWidth, offsetHeight } = video;
      // setImageSize({ width: offsetWidth, height: offsetHeight })
      return { width: offsetWidth, height: offsetHeight }
    } else {
      // if image is not rendered yet returns original measurements
      return { ...originalImageSize }
    }
  }, [originalImageSize]);


  const getLabelStyles = (className: string) => {
    switch (className) {
      case "smoke":
        return "bg-[#e4a872]/70 border-y border-[#e4a872] "

      case "fire":
        return "bg-[#E60000]/70 border-y border-[#E60000] "

      default:
        return "bg-neutral-900/70 border-y border-neutral-300"
    }
  }

  return (
    <>
      {currentFrame && currentFrame.objects.map((object, index) => {
        const activeArea = object.areas?.find(area => activeAreasSet.has(area.id));
        const activeParkingColor = object.areas?.find((area) => area.color);

        const borderColor = areasType === "DEFAULT"
          ? (activeArea ? activeArea.color : "")
          : (activeParkingColor ? activeParkingColor.color : "");

        return (
          <Fragment key={`${object.id}_${index}`}>
            {/* Bounding Box */}
            <div
              onClick={() => handleClick(object.id)}
              onMouseLeave={() => handleMouseLeave(object.id)}
              onMouseOver={() => setVisibleTooltip(object.id)}
              className={
                classNames(
                  `border-[2.4px] group cursor-pointer scale-100  z-20 hover:z-30`,
                  visibleTooltip === object.id ? "z-30" : "z-20",
                  !isPlaying && "hover:scale-110",
                  object.classes[0].class === "fire" && "z-30",
                  // Default color
                  `${getColorStyles(object.classes[0].class, filter)}`,
                  "rounded",
                  Number(object.classes[0].confidence) <= 0.1 && "invisible"
                )}
              style={{
                position: "absolute",
                left: getSize(object.boundingBox[0]),
                top: getSize(object.boundingBox[1]),
                width: getSize(object.boundingBox[2]),
                height: getSize(object.boundingBox[3]),
                borderColor: borderColor ?? "rgb(64,64,64)"

              }}
            >
              <div
                onClick={() => handleClick(object.id)}
                onMouseLeave={() => {
                  setVisibleTooltip(null)
                  handleClick(null)
                }}
                onMouseOver={() => setVisibleTooltip(object.id)}
                className={
                  classNames(
                    `border-[2.4px] visible group-hover:invisible cursor-pointer scale-100 hover:scale-105 z-20 hover:z-30`,
                    isSmallPersonalObject(object.classes[0].class) ? "rounded bg-white opacity-20 z-40" : "invisible"
                  )}
                style={{
                  position: "absolute",
                  inset: 0,
                }}
              />
              {/* Pulsing dot */}
              <div
                className={
                  classNames(
                    `animation-pulse w-2 h-2 rounded-full bg-neutral-800`,
                    object.id === visibleTooltip ? "invisible" : "visible",
                    isPlaying ? "invisible" : "visible"
                  )}
                style={{
                  position: "absolute",
                  left: (getSize(object.boundingBox[2]) / 2) - 6,
                  top: (getSize(object.boundingBox[3]) / 2) - 8,
                }}
              />
              {/* expandable name tag */}
              <motion.div
                onMouseLeave={() => setVisibleTooltip(null)}
                onMouseOver={() => setVisibleTooltip(object.id)}
                className={
                  classNames(
                    `transition-all duration-300 overflow-hidden`,
                    visibleTooltip === object.id ? "opacity-100 z-[60]" : "opacity-0",
                    displayAllTags ? "opacity-100" : "opacity-0",
                  )}
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: getSize(object.boundingBox[3]),
                }}
                animate={{
                  height: expandedTooltip === object.id ? 90 : 30,
                  width: expandedTooltip === object.id ? 140 : 100
                }}
                transition={{
                  ease: "easeOut",
                  width: { duration: animationDurationRef },
                  height: { duration: animationDurationRef },
                  pointerEvents: { delay: 0.05 },
                }}
              >
                {expandedTooltip === object.id ? (
                  <div
                    className={
                      classNames(
                        "flex items-center flex-col w-full text-white  justify-center text-sm p-1 rounded z-50",
                        getLabelStyles(object.classes[0].class)
                      )
                    } >
                    <p>
                      {object.name}
                    </p>
                    <p>
                      Object ID: {object.id}
                    </p>

                    <p>
                      Class: {object.classes[0].class}
                    </p>
                    <p>
                      Confidence: <span className={classNames(
                      )} >{object.classes[0].confidence}</span>
                    </p>

                  </div>
                ) : (
                  <div
                    className={
                      classNames(
                        "capitalize items-center flex flex-col w-full text-white  justify-center text-sm p-1 rounded overflow-hidden",
                      )
                    }>

                    <div key={`${object.id}-${object.classes[0].class}-${index}`}
                      className={
                        classNames(
                          "capitalize mt-[2px] items-center flex flex-col w-full text-white  justify-center text-xs p-1 rounded ",
                          // visibleTooltip === object.id ? "bg-neutral-800 z-[60]" : "bg-neutral-700",
                          getLabelStyles(object.classes[0].class)

                        )}
                    >
                      <p>
                        {object.classes[0].class}
                      </p>
                    </div>
                  </div>

                )}
              </motion.div>
            </div>
          </Fragment>
        )
      })}
    </>
  );
}
