import { useEffect, useState } from "react";

type ImageSizeObject = {
  width: number;
  height: number;
};

// Custom hook for handling video size
export const useVideoSize = (videoRef: React.RefObject<HTMLVideoElement>, imageSize: ImageSizeObject) => {
  const [videoNewDimensions, setVideoNewDimensions] = useState<ImageSizeObject | null>(null);

  useEffect(() => {
    // Function to handle the resize event
    const handleGetVideoSize = () => {
      const video = videoRef.current;
      if (video) {
        const { offsetWidth, offsetHeight } = video;
        setVideoNewDimensions({ width: offsetWidth, height: offsetHeight });
      }
    };

    // Ensuring the video element is available before observing
    if (videoRef.current) {
      const resizeObserver = new ResizeObserver(handleGetVideoSize);
      resizeObserver.observe(videoRef.current);

      // Cleanup function
      return () => {
        if (videoRef.current) {
          resizeObserver.unobserve(videoRef.current);
        }
      };
    } else {
      // Use imageSize as fallback
      setVideoNewDimensions(imageSize);
    }
  }, [videoRef, imageSize]); // Watch for changes in videoRef and imageSize

  return videoNewDimensions;
};
