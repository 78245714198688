
import { Dialog, Disclosure, Listbox, Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { calculateDimensions, classNames, getClassIconById, getColorStyles } from '../../utils';
import { useFileLoader } from '../../hooks/useFileLoader';
import { Frame } from '../../types/PredictionType';
import { usePredictions } from '../../hooks/usePredictions';
import { Checkbox, Menu, MenuHandler, MenuItem, MenuList, Tooltip, Typography } from '@material-tailwind/react';
import { MENU_ITEMS } from '../../mock/menu';
import { CheckIcon, ArrowLongRightIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { FEEDBACK_PREDICTIONS_LIMIT } from '../../constants';
import { MdFeedback } from "react-icons/md"
import { useAuth } from '../../hooks/useAuth';
import { Circle, Image, Layer, Rect, Stage, Text, Transformer } from 'react-konva';
import EditIcon from "../../assets/icons/edit.svg"
import MoveIcon from "../../assets/icons/move.svg"
import ArrowIcon from "../../assets/icons/arrow.svg"
import { useFeedback } from '../../hooks/useFeedback';

let nodeAPIUrl = process.env.REACT_APP_NODE_API_URL || "https://nodeserverdn.azurewebsites.net"
nodeAPIUrl = "http://localhost:80"

interface FeedbackModalProps {
  isOpen?: boolean
  feedback: any
  onCancel: () => any
  onConfirm: (reload: boolean) => any
}

type ImageSizeObject = {
  width: number
  height: number
}

const flattenedItems = MENU_ITEMS.reduce((items, category) => {
  //@ts-ignore
  return items.concat(category.items);

}, []);

//constants
const MAX_DISPLAY_WIDTH = 380; // maximum width for display
const MAX_DISPLAY_HEIGHT = 215; // maximum height for display


const CustomNode = ({ x, y, onDragMove, onDragEnd }: any) => {
  const [savedX, saveX] = useState()

  useEffect(() => {
    saveX(x)
  }, [])

  return (
    <Circle
      x={x}
      y={y}
      radius={4} // Customize as needed
      fill="rgba(15,170,218,0.9)" // Customize as needed
      draggable
      stroke={"rgb(27,105,199)"}
      onDragMove={(e) => onDragMove(e, savedX)}
      onDragEnd={onDragEnd}
    />
  );
};

const KonvaButton = ({ x, y, width, height, text, onClick, iconSrc, fillColor, stageRef }: any) => {
  const [icon, setIcon] = useState(null);

  const loadImage = (src: any, callback: any) => {
    const img = new window.Image();
    img.src = src;
    img.onload = () => callback(img);
  };


  useEffect(() => {
    loadImage(iconSrc, setIcon);
  }, [iconSrc]);

  const handleMouseEnter = () => {
    // Change cursor to pointer
    console.log("mouse")
    const stage = stageRef.current;
    if (stage) {
      stage.container().style.cursor = 'pointer';
    }
  };

  const handleMouseLeave = () => {
    // Change cursor back to default
    const stage = stageRef.current;
    if (stage) {
      stage.container().style.cursor = 'default';
    }
  };

  // Calculate the size of the image (e.g., 70% of the rectangle's size)
  const imageSize = Math.min(width, height) * 0.7; // 70% of the smaller dimension
  // Calculate the position to center the image
  const imageX = x + (width - imageSize) / 2;
  const imageY = y + (height - imageSize) / 2;

  return (
    <>
      <Rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fillColor ?? "lightgray"}
        cornerRadius={4}
        onClick={onClick}
        onTap={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />

      {icon ? (
        <Image
          image={icon}
          x={imageX}
          y={imageY}
          width={imageSize}
          height={imageSize}
          onClick={onClick}
          onTap={onClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      ) : (
        <Text
          x={x}
          y={y}
          width={width}
          height={height}
          text={text}
          align="center"
          fill='white'
          verticalAlign="middle"
          fontSize={12}
          onClick={onClick}
          onTap={onClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      )}

    </>
  );
};
//----------------------------------------------------------------

const BoundingBox = ({ shapeProps, isSelected, onHover, imageSize, boundingBox }: any) => {
  const shapeRef = useRef();
  const transformerRef = useRef<any>();

  const [savedProps, setSavedProps] = useState<any>()

  useEffect(() => {
    setSavedProps({
      x: getSize(boundingBox[0]),
      y: getSize(boundingBox[1]),
      width: boundingBox[2],
      height: boundingBox[3],
    })
  }, [])

  useEffect(() => {
    if (isSelected && transformerRef.current) {
      // Attach the transformer to the shapeRef
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const getSize = (size: number) => {
    return MAX_DISPLAY_WIDTH * size / imageSize.width
  }


  return (
    <>
      <Rect
        ref={shapeRef}
        x={getSize(boundingBox[0])}
        y={getSize(boundingBox[1])}
        width={getSize(boundingBox[2])}
        height={getSize(boundingBox[3])}
        onMouseOver={() => onHover(shapeProps.id)}
        onMouseLeave={() => onHover(null)}
        {...shapeProps}
        cornerRadius={1}
        ignoreStroke={false}
        strokeScaleEnabled={false}
      />

    </>
  );
};
//----------------------------------------------------------------

export function CompareFeedback({ isOpen, onCancel, onConfirm, feedback }: FeedbackModalProps) {


  //refs
  const cancelButtonRef = useRef(null)
  const stageRef = useRef(null);

  //hooks
  const { authUser } = useAuth()

  const [selectedFramePredictions, setSelectedFramePredictions] = useState<any>([])
  const originalFramePredictions = useRef<any[]>([]);

  const [hoveredClass, setHoveredClass] = useState<any>(null)
  const [image, setImage] = useState<any>(null);
  const [displayDimensions, setDisplayDimensions] = useState({ width: 380, height: 215 })
  const [isReviewed, setReviewed] = useState(feedback.reviewed ?? false)
  const { setFeedbackReviewState } = useFeedback()

  const loadImg = () => {
    if (!feedback) return
    const { width, height } = feedback.frameDimensions
    const img = new window.Image();
    img.src = feedback?.fileUrl
    img.onload = () => {
      // set image state to the loaded image
      setImage(img);
      const newDimensions = calculateDimensions(width, height, MAX_DISPLAY_WIDTH, MAX_DISPLAY_HEIGHT);
      setDisplayDimensions(newDimensions)
    }
  }

  useEffect(() => {
    setReviewed(feedback.reviewed)
    loadImg()
  }, [isOpen, feedback]);

  const handleConfirm = async (feedbackId: string) => {
    if (isReviewed === feedback.reviewed) return onConfirm(false)

    await setFeedbackReviewState(feedbackId, isReviewed)
    onConfirm(true)
    
  }

  return (
    <Transition.Root show={isOpen} as={Fragment} >
      <Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={onCancel} >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className=" relative transform overflow-hidden min-w-[900px]  rounded-xl dark:bg-[#1a1a1a] bg-white text-left shadow-xl transition-all sm:mb-8 pt-4 sm:w-full sm:max-w-lg border-[0.5px] dark:border-neutral-900">
                <div className=" dark:bg-[#1a1a1a] bg-white max-h-[80vh] overflow-scroll w-full px-4 pb-4 sm:px-6 sm:pb-4 text-gray-600 dark:text-gray-300">

                  <div className="sm:flex  w-full">

                    <div className="text-center w-full">

                      <div className="mt-2 w-full">

                        <div className="flex mt-4 gap-4">
                          <div className="w-full flex justify-center" >
                            <div className="mt-2 relative rounded " >
                              {/* <img id="img" className="rounded-md" src={feedback?.fileUrl} alt="" /> */}
                              <Stage width={displayDimensions.width} height={displayDimensions.height}>
                                <Layer>
                                  <Image image={image} x={0} y={0} width={displayDimensions.width} height={displayDimensions.height} />

                                </Layer>
                                <Layer>
                                  {feedback.predictions.map((prediction: any, index: any) => (
                                    <Fragment>
                                      <BoundingBox
                                        key={index}
                                        stageRef={stageRef}
                                        imageSize={feedback.frameDimensions}
                                        shapeProps={{
                                          ...prediction,
                                          fill: 'transparent',
                                          stroke: prediction.id === hoveredClass ? "#2a91eb" : 'black',
                                          strokeWidth: 2,
                                          cornerRadius: 2
                                        }}
                                        onHover={(e: any) => setHoveredClass(e)}
                                        boundingBox={prediction.originalBoundingBox}
                                      />
                                    </Fragment>
                                  ))}
                                </Layer>
                              </Stage>
                              <div className="w-full text-center italic mt-1 text-sm text-gray-400" >Original Predictions</div>
                            </div>
                          </div>

                          <div className="w-full flex justify-center" >
                            <div className="mt-2 relative rounded" >
                              <Stage width={displayDimensions.width} height={displayDimensions.height}>
                                <Layer>
                                  <Image image={image} x={0} y={0} width={displayDimensions.width} height={displayDimensions.height} />
                                </Layer>

                                <Layer>
                                  {feedback.predictions.map((prediction: any, index: any) => (
                                    <Fragment>
                                      <BoundingBox
                                        key={index}
                                        stageRef={stageRef}
                                        imageSize={feedback.frameDimensions}
                                        shapeProps={{
                                          ...prediction,
                                          fill: 'transparent',
                                          stroke: prediction.id === hoveredClass ? "#2a91eb" : 'black',
                                          strokeWidth: 2,
                                          cornerRadius: 2
                                        }}
                                        onHover={(e: any) => setHoveredClass(e)}
                                        boundingBox={prediction.boundingBox}
                                      />
                                    </Fragment>
                                  ))}
                                </Layer>
                              </Stage>
                              <div className="w-full text-center italic mt-1 text-sm text-gray-400" >Corrected Predictions</div>
                            </div>
                          </div>



                        </div>
                        <div className="w-full mt-4 p-4 rounded-xl bg-[#121212]" >
                          {feedback.predictions.map((prediction: any, index: any) => (
                            <div
                              onMouseOver={() => setHoveredClass(prediction.id)}
                              onMouseLeave={() => setHoveredClass(null)}
                              className="w-full flex items-center gap-4 justify-center mt-2 relative">
                              <div
                                className={
                                  classNames(
                                    "capitalize items-center flex px-2 w-52 dark:text-white  justify-between  p-1 rounded ",
                                    "border cursor-pointer border-neutral-500 ",
                                    prediction.id === hoveredClass ? "dark:border-[#2a91eb] border-primary-500 shadow shadow-primary-500 dark:shadow-[#2a91eb]" : ""
                                  )}>
                                <img className="w-6 h-6" src={getClassIconById(prediction.originalLabel) ?? ""} alt={getClassIconById(prediction.originalLabel) ?? ""} />
                                {prediction.originalLabel}
                                <div></div>
                              </div>

                              <div>

                                <ArrowLongRightIcon className={classNames("w-4 h-4", prediction.id === hoveredClass ? "text-[#2a91eb]" : "text-gray-500")} />

                              </div>

                              <div className={
                                classNames(
                                  "capitalize items-center flex px-2 w-52 dark:text-white  justify-between  p-1 rounded ",
                                  "border cursor-pointer border-neutral-500 relative",
                                  prediction.id === hoveredClass ? "dark:border-[#2a91eb] border-primary-500 shadow shadow-primary-500 dark:shadow-[#2a91eb]" : "")}>
                                <img className="w-6 h-6" src={getClassIconById(prediction.correctedLabel) ?? ""} alt={getClassIconById(prediction.correctedLabel) ?? ""} />

                                {prediction.correctedLabel}
                                <div></div>

                                {prediction.mixedCategories && (
                                  <div className={classNames(
                                    "absolute -right-36 flex gap-2 items-center"
                                  )} >
                                    <ExclamationCircleIcon
                                      className={
                                        classNames(
                                          "w-6 h-6",
                                          "text-[#fa0]"
                                        )
                                      }
                                    />
                                    {prediction.mixedCategories ? "Inconsistent" : ""}
                                  </div>
                                )}

                              </div>


                            </div>
                          ))}

                        </div>

                        <div className="mt-8">
                          <Checkbox
                            color="blue"
                            id="tracking"
                            onChange={() => {
                              setReviewed((e: boolean) => !e)
                            }}
                            ripple={true}
                            checked={isReviewed}
                            label={
                              <p className="text-gray-600 dark:text-gray-300" >
                                Mark as reviewed
                              </p>}
                          />

                        </div>

                        <button
                          type="button"
                          className="inline-flex w-full mt-4 justify-center rounded-md border border-transparent bg-gradient-to-r from-cyan-500 to-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2  sm:w-auto sm:text-sm"
                          onClick={() => handleConfirm(feedback.id)}
                        >
                          Confirm
                        </button>
                      </div>

                    </div>
                  </div>


                </div>


              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root >
  );
}

