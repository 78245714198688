/**
 * Navbar Component.
 * 
 * Navigation bar for the application, providing links to different pages and features.
 * It includes a dynamic logo animation that adapts to the theme, a dark mode toggle, and user profile information.
 * Used on @PredictionPage @Admin @Dashboard and @Settings pages
 * 
 * - Dynamic Logo: Displays an animated logo that changes based on the current theme (light or dark).
 * - Navigation Handling: Facilitates navigation to different routes within the application.
 * - Dark Mode Toggle: Includes a toggle switch to change between light and dark themes.
 * 
 * This component also renders @UserProfile component
 */

// React
import { ReactNode } from 'react';
import { useNavigate } from "react-router-dom";

// Libraries
import { motion } from "framer-motion";
import Lottie from 'react-lottie-player'


// Components/Assets/Helpers
import { classNames } from '../utils';
import logoWhite from "../utils/animations/logoName2.json";
import logoDark from "../utils/animations/logo_dark.json";
import { UserProfile, DarkModeToggle } from './';
import { useAuth, useDarkMode } from '../hooks';

import dnLogo from "../assets/images/DeepNeuronic_full.png"
import dnLogoWhite from "../assets/images/DeepNeuronic_full_white.png"

interface NavbarProps {
  /** Children components to be rendered within the navbar. */
  children: ReactNode;
}

export function Navbar({ children }: NavbarProps) {
  const navigate = useNavigate();
  const { authUser } = useAuth()
  const { theme, setTheme } = useDarkMode()

  const handleNavigation = (route: string) => {
    switch (route) {
      case "home":
        navigate("/");
        break;

      case "predictions":
        navigate("/mypredictions");
        break;

      case "login":
        navigate("/login");
        break;

      default:
        // history.push("/")
        break;
    }
  }

  return (
    <>
      <motion.div
        className={classNames(
          "w-full min-h-[80px] bg-transparent shadow-sm shadow-primary-500 dark:shadow-sm dark:shadow-[#3c3c3c] text-white flex  items-center relative justify-between",
          "bg-white dark:bg-[#101010] "
        )}>

        <div className="w-[380px]" >
          <div className="absolute left-5 top-3 cursor-pointer"
            onClick={() => {
              handleNavigation("home")
            }} >
            <img src={theme === "dark" ? dnLogoWhite : dnLogo} alt="deepneuronic_logo"
              className=" h-[56px] m-0 "
            />
            {/* <Lottie
              autoplay
              loop={false}
              keepLastFrame
              speed={1}
              style={{ margin: 0, width: 150, height: 150  }}
              src={theme === "dark" ? logoWhite : logoDark}
              className="md:w-40 w-24 h-[56px] m-0 "
            /> */}
          </div>
        </div>

        {children}

        <div className="mr-5 relative flex" >
          <DarkModeToggle />

          {authUser ? (
            <UserProfile authUser={authUser} />
          ) : (
            <div>
              <button
                onClick={() => handleNavigation("login")}
                className={classNames(
                  'text-blue-500 hover:scale-105 duration-150 bg-white min-w-[100px] ml-4',
                  'px-3 py-2 rounded-md text-sm font-semibold'
                )}
              >
                Sign in
              </button>
            </div>
          )}
        </div>
      </motion.div>
    </>
  );
}
