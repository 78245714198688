//@ts-nocheck
/**
 * DarkModeToggle Component.
 * Simple dark mode toggle component
 */
import { Switch } from '@material-tailwind/react';
import { MoonIcon } from '@heroicons/react/24/solid'
import { classNames } from '../utils';
import { useDarkMode } from '../hooks/useTheme';

export function DarkModeToggle() {
  // Initialize the theme based on user preference or OS setting
  const { theme, setTheme } = useDarkMode()

  const toggleTheme = () => {
    if (theme === 'dark') {
      setTheme('light');
      localStorage.theme = 'light';
    } else {
      setTheme('dark');
      localStorage.theme = 'dark';
    }
  };

  return (
    <>
      <Switch
        checked={theme === "dark"}
        onChange={toggleTheme}
        label={
          <div>
            <MoonIcon className={classNames(
              "w-5 h-5",
              theme === "dark" ? "text-yellow-300" : ""
            )} />
          </div>
        }
        ripple={true} />
    </>

  );
}
