/**
 * Page component responsible for rendering the user predictions page
 * It handles file uploading, drag and drop events, and calls the API to retrieve image and video predictions. 
 * User can also download predictions and image/video from this page
 *
 * Main functionalities:
 * - Handles file uploading with drag and drop events or file input.
 * - Determines the type of the uploaded file (image or video).
 * - retrieves all user predictions for image and video files.
 * - Manages error handling for the API calls and shows error messages.
 * - Provides a loading screen while waiting for the API response.
 */

//react
import { useCallback, useContext, useEffect, useRef, useState } from "react";
// libs
import { ArrowUpTrayIcon, ArrowDownTrayIcon, EllipsisHorizontalIcon, } from '@heroicons/react/24/outline'
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Button, IconButton } from "@material-tailwind/react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import Lottie from 'react-lottie-player'
import { motion } from "framer-motion";

import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
//components
import { DownloadModal, PredictionsModal } from "../components/Modals";
import { DragDropContext } from "../contexts/DragDropContext";
import DelimitedAreasModal from "../components/DelimitedAreasModal";
import { LoadingMask, Navbar, PredictionCard, Sidebar } from "../components";

// assets
import sample1 from "../assets/videos/sample.mp4"
import sample2 from "../assets/videos/fire_smoke.mp4"
import sample3 from "../assets/videos/smoke_vehicle.mp4"

import sampleData1 from "../assets/predictions/smoke&vehicles.json"
import sampleData2 from "../assets/predictions/sample2.json"
import sampleData3 from "../assets/predictions/sample1.json"
import { object as sampleData } from "../assets/predictions/sample3"

import logoAnimation from "../utils/animations/logoAnimation.json"

// helpers
import { extractFrames, getFileType } from '../utils/helpers';
import { usePredictions } from "../hooks/usePredictions";
import { useAuth } from "../hooks/useAuth";
import { classNames, flattenPredictions, getImageDetectedClasses, getVideoDetectedClasses, handleFormatImageData, handleFormatVideoData } from "../utils";
import { useFileLoader } from "../hooks/useFileLoader";
import usePagination from "../hooks/usePagination";
import { FirebasePredictionsType } from "../types/PredictionType";
import { obtainNewPredictionsTask } from "../services/API";


const EXAMPLES = [
  {
    id: "vehicles",
    name: "Traffic",
    src: sample1,
    predictions: sampleData,
    dimensions: { width: 1280, height: 720 }
  },
  {
    id: "fire_smoke",
    name: "Fire and Smoke",
    src: sample2,
    predictions: sampleData2,
    dimensions: { width: 1280, height: 720 }
  },
  {
    id: "smoke_vehicles",
    name: "Smoke and Vehicles",
    src: sample3,
    predictions: sampleData3,
    dimensions: { width: 1280, height: 720 }
  },
]

const PER_PAGE_DESKTOP = 6

type SelectedPrediction = {
  itemId: string
  fileRef: string
  fileType: string
  status: string
  signedUrl: string
}

export function PredictionsPage() {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  //----------------------------------------------------------------
  // data
  const [predictionsList, setPredictionsList] = useState<any[]>([])
  const [predictions, setPredictions] = useState<any>([])
  const [selectedItem, setSelectedItem] = useState<SelectedPrediction | null>(null)
  //----------------------------------------------------------------
  // overlays
  const [isPredictionsLoading, setIsPredictionsLoading] = useState(true)
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)
  const [isDragActive, setIsDragActive] = useState(false);
  const [isRegionModalOpen, setIsRegionModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [predictionsModal, setPredictionsModal] = useState(false)
  //----------------------------------------------------------------
  // hooks
  const { listUserPredictions } = usePredictions()
  const { labelsPosition, setLabelsPosition } = useContext(DragDropContext)
  const {
    imagePredictions,
    setVideoPredictions,
    videoPredictions,
    setFileUrl,
    selectedFile,
    setSelectedFile,
    processingStatus,
    handleDownloadVideo,
    handleDownloadImage,
    setDetectedClasses,
    setMetadata,
    deletePredictions,
    setImagePredictions,
    checkTaskStatus,
    getImagePredictions,
    getVideoPredictions
  } = usePredictions(true)
  const {
    fileType, setFileType,
    originalImageSize, setOriginalImageSize,
    setFileSize,
    setPreviewFrames,
    videoMetadata, setVideoMetadata,
    setStoredFileID
  } = useFileLoader()
  const { authUser } = useAuth()
  //----------------------------------------------------------------
  // pagination
  const [page, setPage] = useState(1);
  const pageCount = Math.ceil(predictionsList.length / (PER_PAGE_DESKTOP));
  const displayedData = usePagination(predictionsList, PER_PAGE_DESKTOP
  );
  //----------------------------------------------------------------

  //file upload functions
  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    file && handleFileUpload(file);
  };

  const handleFileUpload = (file: File) => {
    // const file = event.target.files[0];
    if (isPredictionsLoading) return
      // Check if the file type starts with 'image/'
  if (!file.type.startsWith('image/')) {
    alert('Please upload an image file.');
    return;
  }
  

    const fileType = getFileType(file)
    setFileType(fileType)
    setImagePredictions(null)
    setVideoPredictions(null)
    setFileUrl(URL.createObjectURL(file));
    // setFile(file)
    setSelectedFile(file)
    // create a new Image and Video object and load the uploaded file
    const img = new Image();
    const video = document.createElement('video');
    img.src = URL.createObjectURL(file);
    video.src = URL.createObjectURL(file);

    // Onload callback to get the width and height of the image
    img.onload = () => {
      const { width, height } = img;
      setOriginalImageSize({ width, height })
      setFileSize({ width, height })
      console.log({ width, height })
    };

    // Onload metadata callback to get the width and height of the video
    video.onloadedmetadata = async () => {
      const { videoWidth: width, videoHeight: height } = video;
      setOriginalImageSize({ width, height })
      setFileSize({ width, height })
      console.log({ width, height })
      setVideoMetadata({
        duration: video.duration,
        src: video.src,
        width: video.videoWidth,
        height: video.videoHeight,
      })

      // Extract frames from the video
      const numberOfFrames = 16; // Change this value to the desired number of frames
      const frames = await extractFrames(video, numberOfFrames);
      setPreviewFrames(frames)
    };
    navigate("/");
    return
  };

  //!refactor file type
  const handleLoadPredictions = async (prediction: FirebasePredictionsType) => {
    if (prediction.status !== "COMPLETED") {
      return
    }
    setIsLoading(true);
    try {
      const fileType = prediction.fileType.includes("video") ? "video" : "image"
      const fileDataPromise = fetch(prediction.signedUrl).then(response => response.blob());


      const [fileBlob] = await Promise.all([fileDataPromise]);
      setSelectedFile(fileBlob);
      setFileUrl(prediction.signedUrl);
      setFileType(fileType);
      setStoredFileID(prediction.fileID);

      if (fileType === "video") {
        const sampleResponse = flattenPredictions(JSON.parse(prediction.json));
        const formatted = handleFormatVideoData(sampleResponse);
        setVideoPredictions(formatted);
        setDetectedClasses(getVideoDetectedClasses(formatted));

        const video = videoRef.current || document.createElement('video');
        video.crossOrigin = "anonymous";
        video.src = prediction.signedUrl;

        video.onloadedmetadata = async () => {
          const { videoWidth: width, videoHeight: height } = video;
          setOriginalImageSize({ width, height });
          setFileSize({ width, height });

          const numberOfFrames = 16;
          const frames = await extractFrames(video, numberOfFrames);
          setPreviewFrames(frames);
        };
      } else {
        console.log("dimensions", prediction.dimensions)
        //handle load image data

        const formattedImagePred = handleFormatImageData(JSON.parse(prediction.json));
        const classes = getImageDetectedClasses(formattedImagePred)
        setDetectedClasses(classes)
        console.log("formattedImagePred", formattedImagePred)
        setImagePredictions(formattedImagePred)
        setOriginalImageSize(prediction.dimensions ?? { width: 1200, height: 700 });

      }

    } catch (error) {
      console.error("Error in handleLoadPredictions:", error);
      // Handle errors as needed
    } finally {
      setIsLoading(false);
      navigate("/");
    }
  };

  const handleDownloadButton = async (classNames: string[], counting: string | null, delimitedAreas: any) => {
    setIsDownloadModalOpen(false)
    if (processingStatus === "processing") return
    if (imagePredictions) {
      await handleDownloadImage(imagePredictions, selectedFile, originalImageSize, classNames,labelsPosition, counting)
      return
    }

    if (!videoMetadata) return
    // Onload metadata callback to get video duration
    const frameRate = Math.round(videoPredictions.length / videoMetadata.duration)
    await handleDownloadVideo(videoPredictions, selectedFile, originalImageSize, frameRate, classNames, counting, labelsPosition, delimitedAreas)

  }

  const openDownloadModal = async (e: any, prediction: any) => {
    console.time("openDownloadModal");
    e.stopPropagation();
    setIsLoading(true)

    try {
      // Parallelize fetch and JSON parsing
      const [response, parsedJson] = await Promise.all([
        fetch(prediction.file),
        JSON.parse(prediction.json)
      ]);

      const fileBlob = await response.blob();
      setMetadata(parsedJson);
      setSelectedFile(fileBlob);
      setFileUrl(prediction.file);
      setFileType("video");
      setOriginalImageSize(prediction.dimensions ?? { width: 1200, height: 700 });

      const formatted = handleFormatVideoData(parsedJson) as any;
      setVideoPredictions(formatted);
      setDetectedClasses(getVideoDetectedClasses(formatted));

      // Use an existing video element if possible
      const video = document.getElementById("hidden-vid") as HTMLVideoElement || document.createElement('video');
      video.crossOrigin = "anonymous";
      video.src = prediction.file ?? "";

      video.onloadedmetadata = async () => {
        setVideoMetadata({
          duration: video.duration,
          src: video.src,
          width: video.videoWidth,
          height: video.videoHeight,
        });

        setFileSize({
          width: video.videoWidth,
          height: video.videoHeight,
        });

        const numberOfFrames = 2;
        const frames = await extractFrames(video, numberOfFrames);
        setPreviewFrames(frames);
      };
    } catch (error) {
      console.error("Error in openDownloadModal:", error);
    }
    finally {
      setIsDownloadModalOpen(true);
      console.timeEnd("openDownloadModal");
      setIsLoading(false)
    }

  };

  const handleDeleteFile = async (idToRemove: string, fileRef: string) => {
    const newItems = predictionsList.filter(item => item.id !== idToRemove);
    try {
      deletePredictions(idToRemove, fileRef)
      setPredictionsList(newItems)
    } catch (err) {
      return
    }
  }


  const obtainNewPredictions = async (models: string[], tracking: boolean, token: string) => {
    // if (!selectedFile) return
    console.log("submit")
    if (!selectedItem) return
    // setWarning(true)
    setPredictionsModal(false)
    if (selectedItem.status === "COMPLETED") {
      await obtainPredictionsForNewRecord(models, tracking, token)
      return
    }

    try {
      const { firebaseRecordRef, taskId } = await obtainNewPredictionsTask(selectedItem.fileRef, authUser.uid, selectedItem.itemId, selectedItem.fileType, true, models)

      setPredictionsList(currentPredictions => currentPredictions.map(p => {
        if (p.id === selectedItem.itemId) {
          return { ...p, status: "PROCESSING" };
        }
        return p;
      }));

      const { data: sampleData, status } = await checkTaskStatus(taskId, authUser.uid, firebaseRecordRef.key)

      if (selectedItem.fileType.includes("video")) {
        const flatData = flattenPredictions(sampleData);
        const formatted = handleFormatVideoData(flatData);
        setDetectedClasses(getVideoDetectedClasses(formatted))
        setVideoPredictions(formatted as any)

        setPredictionsList(currentPredictions => currentPredictions.map(p => {
          if (p.id === selectedItem.itemId) {
            return { ...p, status, ...(formatted && { json: formatted }) };
          }
          return p;
        }));
        return
      } else {
        setMetadata(sampleData)
        const formattedResponse = handleFormatImageData(sampleData);
        const classes = getImageDetectedClasses(formattedResponse)
        setDetectedClasses(classes)

        setPredictionsList(currentPredictions => currentPredictions.map(p => {
          if (p.id === selectedItem.itemId) {
            return { ...p, status, ...(formattedResponse && { json: JSON.stringify(formattedResponse) }) };
          }
          return p;
        }));
      }

    } catch (err) {
      handleError(err)
    }
  }

  const obtainPredictionsForNewRecord = async (models: string[], tracking: boolean, token: string) => {
    if (!selectedItem) return
    // return
    try {
      const { firebaseRecordRef, taskId, pushedData, itemId } = await obtainNewPredictionsTask(selectedItem.fileRef, authUser.uid, selectedItem.itemId, selectedItem.fileType, false, models)
      setPredictionsList(currentPredictions => [...currentPredictions, { ...pushedData, id: itemId, signedUrl: selectedItem.signedUrl }]);


      const { data: sampleData, status } = await checkTaskStatus(taskId, authUser.uid, firebaseRecordRef.key)

      if (selectedItem.fileType.includes("video")) {
        // const flatData = flattenPredictions(sampleData);
        // const formatted = handleFormatVideoData(flatData);
        // setDetectedClasses(getVideoDetectedClasses(formatted))
        // setVideoPredictions(formatted as any)

        setPredictionsList(currentPredictions => currentPredictions.map(p => {
          if (p.id === itemId) {
            return { ...p, status, ...(sampleData && { json: JSON.stringify(sampleData) }) };
          }
          return p;
        }));
        return
      } else {
        setMetadata(sampleData)
        const formattedResponse = handleFormatImageData(sampleData);
        const classes = getImageDetectedClasses(formattedResponse)
        setDetectedClasses(classes)

        setPredictionsList(currentPredictions => currentPredictions.map(p => {
          if (p.id === itemId) {
            return { ...p, status, ...(formattedResponse && { json: formattedResponse }) };
          }
          return p;
        }));
      }

    } catch (err) {
      handleError(err)
    }
  }


  const openNewPredictionsModal = (itemId: string, fileRef: string, fileType: string, status: string, signedUrl: string) => {
    setSelectedItem({ itemId, fileRef, fileType, status, signedUrl })
    setPredictionsModal(true)
  }


  const handleSelectSample = async (video: any) => {
    const modelPredictions = {} as any;

    // setTracking(true)
    switch (video.id) {
      case "smoke_vehicles":
        const _predictions1 = sampleData as any
        console.log(_predictions1)

        setPredictions(handleFormatVideoData(_predictions1[0] as any))
        // setVideoPredictions(_predictions1[0])
        await handleLoadSample(handleFormatVideoData(_predictions1[0] as any), video.src, { width: 1280, height: 720 })

        break;

      case "fire_smoke":
        const _predictions = sampleData2 as any
        // const modelPredictions = {} as any;

        Object.keys(_predictions).forEach((key) => {
          const modelPredictionData = _predictions[key][0]

          Object.keys(modelPredictionData).forEach((innerKey) => {
            if (!modelPredictions[innerKey]) {
              modelPredictions[innerKey] = {};
            }
            modelPredictions[innerKey] = {
              ...modelPredictions[innerKey],
              ...modelPredictionData[innerKey],
            };
          });
        });


        const f = handleFormatVideoData(modelPredictions as any)
        // setPredictions(f)
        // setVideoPredictions(f)
        await handleLoadSample(f, video.src, { width: 1920, height: 1080 })
        break;

      case "vehicles":
        // setVideoPredictions(handleFormatVideoData(sampleData3 as any))
        await handleLoadSample(handleFormatVideoData(sampleData3 as any), video.src, { width: 1280, height: 720 })
        break;

      default:
        break;
    }
  }

  const handleLoadSample = async (predictions: any, file: any, dimensions: any) => {
    const response = await fetch(file)
    const fileBlob = await response.blob()
    const _detectedClasses = getVideoDetectedClasses(predictions)
    setDetectedClasses(_detectedClasses)
    setSelectedFile(fileBlob)
    setFileUrl(file)
    setFileType("video")
    setOriginalImageSize(dimensions)

    const video = document.createElement('video');
    video.src = file;
    // Onload metadata callback to get the width and height of the video
    video.onloadedmetadata = async () => {
      const { videoWidth: width, videoHeight: height } = video;
      setOriginalImageSize({ width, height })
      setFileSize({ width, height })
      console.log({ width, height })
      setVideoMetadata({
        duration: video.duration,
        src: video.src,
        width: video.videoWidth,
        height: video.videoHeight,
      })
      // Extract frames from the video
      const numberOfFrames = 16; // Change this value to the desired number of frames
      const frames = await extractFrames(video, numberOfFrames);
      console.log(frames);
      setPreviewFrames(frames)
    };

    setVideoPredictions(predictions)
    navigate("/");

  }

  const handlePageChange = async (_page: number) => {
    setPage(_page);
    displayedData.jump(_page);
  };

  const getItemProps = (index: any) =>
  ({
    variant: page === index ? "filled" : "text",
    color: page === index ? "blue" : "blue-gray",
    onClick: () => handlePageChange(index),
  } as any);

  const handleError = (err: any) => {
    console.log(err.message)
    console.log(err.json)
    console.log(err.status)

    switch (err.status) {
      case 400:
        // setPredictionError({
        //   title: "Error Precessing video:",
        //   message: err.message
        // })
        break

      case 401:
        // setPredictionError({
        //   title: "Authorization error:",
        //   message: err.message
        // })
        break
    }

  }

  const next = () => {
    if (page === pageCount) return;
    handlePageChange(page + 1);
  };

  const prev = () => {
    if (page === 1) return;
    handlePageChange(page - 1);
  };

  useEffect(() => {
    if (!authUser) {
      // navigate("/", { replace: true });
      return;
    }

    (async () => {
      // Fetch and display the list of predictions immediately
      const initialPredictionsList = await listUserPredictions(authUser.uid);
      setPredictionsList(initialPredictionsList?.reverse() ?? []);
      setIsPredictionsLoading(false);

      // Update statuses in the background
      initialPredictionsList.forEach(async (prediction) => {
        if (prediction.status === "PROCESSING") {
          const { status, data } = await checkTaskStatus(prediction.taskId, authUser.uid, prediction.id);
          // Update the state with the new status and any additional data
          setPredictionsList(currentPredictions => currentPredictions.map(p => {
            if (p.id === prediction.id) {
              return { ...p, status, ...(data && { json: JSON.stringify(data) }) };
            }
            return p;
          }));
        }
      });
    })();
  }, [authUser, navigate]);

  // Drag file event handles
  const [, drop] = useDrop({
    accept: NativeTypes.FILE,
    drop(item, monitor) {
      //@ts-ignore
      const droppedFiles = monitor.getItem().files;
      if (droppedFiles.length > 0) {
        handleFileUpload(droppedFiles[0]);
      }
      setIsDragActive(false);
      return undefined;
    },
    hover(item, monitor) {
      // You can perform actions when a compatible item is being hovered over the component
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const containerStyle = {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: isDragActive ? "rgba(33, 150, 243, 0.2)" : "transparent",
    top: 0,
    left: 0,
    zIndex: isDragActive ? 9999 : -1,
  };

  return (
    <>
      <div className="upload-wrapper"
        // ref={drop}
        style={{ ...containerStyle, position: "fixed" }}
        onDragEnter={() => setIsDragActive(true)}
        onDragLeave={() => setIsDragActive(false)}
      >
        <video id="hidden-video" ref={videoRef} style={{ display: 'none' }} />
        {isDownloadModalOpen && (
          <DownloadModal
            onSelectDelimitedArea={() => setIsRegionModalOpen(true)}
            preSelectedTab={1}
            imageSize={originalImageSize}
            isOpen={isDownloadModalOpen && !isRegionModalOpen}
            onCancel={() => setIsDownloadModalOpen(false)}
            onConfirm={handleDownloadButton}
          />
        )}

        {isLoading && (
          <>
            <LoadingMask text="Setting up predictions" />
          </>
        )}

        {isRegionModalOpen && (
          <DelimitedAreasModal
            key={1}
            inBrowser={true}
            theme={"Light"}
            language={"English"}
            isOpen={isRegionModalOpen}
            onClose={() => setIsRegionModalOpen(false)}
          />
        )}

        {predictionsModal && (
          <PredictionsModal
            detectionType={fileType ?? "image"}
            isOpen={predictionsModal && !isRegionModalOpen}
            onCancel={() => setPredictionsModal(false)}
            onConfirm={obtainNewPredictions}
            onSelectDelimitedArea={() => setIsRegionModalOpen(true)}
          />
        )}

        <Toaster
          position="top-right"
        />

        <div className="w-full duration-300 bg-[#eef0f3] dark:bg-neutral-900 text-neutral-900 dark:text-white" >
          <Navbar>
            <></>
          </Navbar>
          <div className="flex w-full h-full" >
            <Sidebar >
              <div className="flex w-full h-full overflow-x-hidden overflow-y-scroll custom-scrollbar"  >
                {isPredictionsLoading ? (
                  <div className="relative flex h-full w-full" >
                    <div className="absolute right-0 top-0 bottom-0 left-0  flex items-center justify-center" >
                      <div className="flex flex-col items-center justify-center z-50" >
                        <div className="loading-screen w-full flex items-center justify-center flex-col">
                          <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                          <Lottie
                            autoPlay
                            loop
                            speed={1}
                            src={logoAnimation}
                            style={{ height: '100px', width: '100px' }}
                          >
                          </Lottie>
                          <span className="">Loading your predictions...</span>

                        </div>
                        <div className="relative h-4 py-1 w-[150%] rounded mt-2 mb-1  overflow-x-hidden" >

                        </div>
                      </div>

                    </div>
                  </div>
                ) : (
                  <div className="relative h-full w-full space-y-8  rounded-2xl md:space-y-0 p-20 pl-40">

                    <div className="w-full flex items-center justify-between border-b border-primary-500 pb-2" >
                      <h2 className="font-semibold text-xl" >My Predictions</h2>
                      {/* <label
                        htmlFor="file"
                        className={
                          classNames(
                            "h-6 cursor-pointer py-1 px-2 text-white rounded text-xs flex items-center disabled:cursor-default md:px-4 md:h-10 md:text-base sm:text-sm ",
                            "bg-gradient-to-r from-cyan-500 to-blue-500 font-bold",
                          )}>
                        <ArrowUpTrayIcon className="w-5 h-5 text-white mr-2" />
                        Upload
                      </label>
                      <input id="file" type="file" onChange={handleFileInputChange} placeholder="file" className="hidden" /> */}

                    </div>

                    {predictionsList.length === 0 && (
                      <div className="w-full pt-20 text-center" >
                        <p>You don't have any predictions yet</p>
                        <label
                          htmlFor="file" className="cursor-pointer hover:text-primary-500" >Upload file and obtain predictions</label>
                      </div>
                    )}

                    <div className={
                      classNames(
                        "lg:mt-12 grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-2 lg:grid-cols-3 sm:gap-y-8 lg:gap-x-6  justify-center relative z-10 py-8",
                        predictionsList.length > 3 ? "min-h-[494px]" : ""
                      )
                    }>
                      {displayedData.currentData().map((i, index) => (
                        <PredictionCard key={`${index}_`} onSelect={() => handleLoadPredictions(i)}>
                          <div className=" w-full overflow-hidden rounded-t-2xl relative select-none h-[200px]" >
                            {i.fileType.includes("video") ? (
                              <video className="group-hover:scale-110 duration-200" id={i.id} src={i.signedUrl}></video>

                            ) : (
                              <img className="group-hover:scale-110 duration-200" src={i.signedUrl} alt={i.name} />

                            )}
                            {i.status === "PROCESSING" && (
                              <div className="absolute z-10 inset-0 flex items-center justify-center text-neutral-100 bg-primary-900/50" >
                                PROCESSING...
                                <motion.div
                                  className={`rounded bg-primary-500 h-2 duration-300 overflow-x-hidden absolute bottom-10`}
                                  style={{
                                    width: `${80}%`
                                  }}
                                >
                                  <motion.div
                                    animate={{
                                      left: ["-50%", "120%"],
                                    }}
                                    className={`opacity-75 rounded-3xl absolute bg-gradient-to-r from-primary-500 via-cyan-500  to-primary-500 h-full left-0 w-16`}
                                    transition={{
                                      duration: 3,
                                      ease: "linear",
                                      repeat: Infinity,
                                    }}>

                                  </motion.div>
                                </motion.div>
                              </div>
                            )}

                            {i.status === "FAILED" && (
                              <div className="absolute z-10 inset-0 flex items-center justify-center text-neutral-100 bg-red-900/50" >
                                FAILED
                              </div>
                            )}

                          </div>
                          <div className="w-full items-center mt-2 flex  justify-between px-4 py-2" >
                            <div className="bg-black rounded-2xl text-white py-1 px-4 flex items-center text-xs" >
                              <p>{i.fileType}</p>
                            </div>

                            <div className="relative z-50 flex items-center" >
                              {i.status === "COMPLETED" && (
                                <div onClick={(e) => openDownloadModal(e, i)} className=" cursor-pointer" >
                                  <ArrowDownTrayIcon className="text-white w-6 h-8 mr-4 hover:text-primary-500 " />
                                </div>
                              )}

                              <div className=" cursor-pointer" >
                                <Menu>
                                  <MenuHandler>
                                    <EllipsisHorizontalIcon className="text-white w-8 h-6 p-1 hover:text-primary-500" />
                                  </MenuHandler>
                                  <MenuList>
                                    {i.status === "COMPLETED" && (
                                      <MenuItem>Load Predictions</MenuItem>
                                    )}
                                    {i.status !== "PROCESSING" && (
                                      <MenuItem onClick={(e) => {
                                        openNewPredictionsModal(i.id, i.filePath, i.fileType, i.status, i.signedUrl)
                                        e.stopPropagation()
                                      }}>Obtain new Predictions</MenuItem>
                                    )}

                                    <MenuItem onClick={(e) => {
                                      handleDeleteFile(i.id, i.filePath)
                                      e.stopPropagation()
                                    }} >Delete file</MenuItem>
                                  </MenuList>
                                </Menu>
                              </div>

                            </div>

                          </div>
                        </PredictionCard>
                      ))}
                    </div>

                    <div className="flex items-center gap-4 mt-10 w-full justify-center">
                      <Button
                        variant="text"
                        color="blue-gray"
                        className="flex items-center gap-2"
                        onClick={prev}
                        disabled={page === 1}
                      >
                        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Prev
                      </Button>
                      <div className="flex items-center gap-2">
                        {Array.from({ length: pageCount }, (_, index) => (
                          <IconButton key={index + 1} {...getItemProps(index + 1)}>{index + 1}</IconButton>
                        ))}
                      </div>
                      <Button
                        variant="text"
                        color="blue-gray"
                        className="flex items-center gap-2"
                        onClick={next}
                        disabled={page === pageCount}
                      >
                        Next
                        <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
                      </Button>
                    </div>

                    <div className="w-full flex items-center justify-between border-b border-primary-500 pb-2 pt-10" >
                      <h2 className="font-semibold text-xl" >Samples</h2>
                    </div>

                    <div id="cards" className="lg:mt-12 grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-2 lg:grid-cols-3 sm:gap-y-8 lg:gap-x-6 items-center justify-center relative z-10 py-8 pb-20">
                      {EXAMPLES.map((i) => (
                        <PredictionCard key={i.id} onSelect={() => handleSelectSample(i)}>
                          <div className=" w-full h-full overflow-hidden rounded-t-2xl " >
                            {/* <img className="rounded-2xl group-hover:scale-125 duration-200" src={i.cover} alt={i.name} /> */}
                            <video className="group-hover:scale-110 duration-200" src={i.src}></video>
                          </div>
                          <div className="w-full items-center mt-2 flex  justify-between px-4 py-2" >
                            <div className="bg-black rounded-2xl text-white py-1 px-4 flex items-center text-xs" >
                              <p>Video</p>
                            </div>
                            <div className="relative z-50 flex items-center" >
                              <div className=" cursor-pointer" >
                                {/* <ArrowDownTrayIcon className="text-white w-6 h-8 mr-4 hover:text-primary-500 " /> */}
                              </div>
                              <div className=" cursor-pointer" >
                                {/* <Menu>
                                <MenuHandler>
                                  <EllipsisHorizontalIcon className="text-white w-8 h-6 p-1 hover:text-primary-500" />

                                </MenuHandler>
                                <MenuList>
                                  <MenuItem>Menu Item 1</MenuItem>
                                  <MenuItem>Menu Item 2</MenuItem>
                                  <Menu placement="right-start" offset={15}>
                                    <MenuHandler>
                                      <MenuItem>Nested Item</MenuItem>
                                    </MenuHandler>
                                    <MenuList>
                                      <MenuItem>Nested Item 1</MenuItem>
                                      <MenuItem>Nested Item 2</MenuItem>
                                      <MenuItem>Nested Item 3</MenuItem>
                                    </MenuList>
                                  </Menu>
                                  <MenuItem>Menu Item 3</MenuItem>
                                </MenuList>
                              </Menu> */}
                              </div>

                            </div>

                          </div>
                        </PredictionCard>
                      ))}
                    </div>
                  </div>
                )}

              </div>

            </Sidebar>


          </div>


        </div>
      </div>
    </>
  );
}
