//@ts-nocheck

export const doesItemRelyOnUseCase = (item : any, language : any) => {
    return(item[1]["ogJsonDetails"][language].use_case_dependencies.length !== 0);
}

export const isItemUseCaseSelected = (selectedUseCases : any, item : any, language : any) => {
    for (let i = 0; i < item[1]["ogJsonDetails"][language].use_case_dependencies.length; i++) {
        if((item[1]["ogJsonDetails"][language].use_case_dependencies[i] in selectedUseCases) && selectedUseCases[item[1]["ogJsonDetails"][language].use_case_dependencies[i]])
            return(true);
    }

    return(false);
}

export const isDictionary = ( variable : any ) => {
    return(typeof variable === "object" && variable !== null && !Array.isArray(variable));
}

export const findSiblings = ( items : any, unalteredItems : any, sharedSubStrings : any ) => {

    let itemsCopy = [...items];
    let changesMade = false;

    for(let i = 0; i < itemsCopy.length; i++){
    
        if(itemsCopy[i][0].split(" • ").length === 2){

            if(!("" in sharedSubStrings))
                sharedSubStrings[""] = []

            if(itemsCopy[i][0] !== unalteredItems[i][0] || sharedSubStrings[""].filter((item : any) => item[0] === itemsCopy[i][0]).length !== 0)
                continue;

            sharedSubStrings[""].push(unalteredItems[i]);
        }

        else{

            changesMade = true;

            let subString = itemsCopy[i][0].split(" • ").slice(1, -1).join(" • ");

            if(!(subString in sharedSubStrings))
                sharedSubStrings[subString] = []

            sharedSubStrings[subString].push(unalteredItems[i]);
            items[i][0] = items[i][0].split(" • ").slice(0, -1).join(" • ");

        }
    }

    if(!changesMade)
        return;

    findSiblings(
        items,
        unalteredItems,
        sharedSubStrings
    )
}

export const joinItems = ( sharedSubStrings : any, processedAppStateAux : any ) => {

    // remove unwanted values
    Object.entries(sharedSubStrings).forEach(([k, v]) => {

        for(let i = 0; i < v.length; i++){

            if(k === "")
                continue;

            if(v[i][0].split(" • ").length !== (k.split(" • ").length + 2)){
                sharedSubStrings[k] = sharedSubStrings[k].filter((item : any) => item[0] !== v[i][0]);
            }
        }
    })

    // sort the keys by how long they are
    let sortedKeys = structuredClone(Object.entries(sharedSubStrings));

    function compareFn( a : any, b : any ) {
        
        if(a[0].split(" • ").length < b[0].split(" • ").length)
            return(-1);
        
        if(a[0].split(" • ").length > b[0].split(" • ").length)
            return(1);

        return(0);
    }

    sortedKeys.sort(compareFn).reverse();

    // delete duplicates
    for(let i = 0; i < sortedKeys.length; i++){

        if(sortedKeys[i][0].split(" • ").length === 1)
            continue;

        if(sortedKeys[i][0].split(" • ").slice(0, -1).join(" • ") in sharedSubStrings){
            sharedSubStrings[sortedKeys[i][0].split(" • ").slice(0, -1).join(" • ")].push(sortedKeys[i][1]);
            delete sharedSubStrings[sortedKeys[i][0]];
        }
    }

    // put everything in the correct order
    Object.entries(sharedSubStrings).forEach(([k, v]) => {
        
        if(k === ""){
            for(let i = 0; i < v.length; i++){
                processedAppStateAux.push(v[i]);
            }
        }

        else
            processedAppStateAux.push(v);
    })
}

export const isDelimitedAreaValid = (delimitedArea : any) => {
    
    if(delimitedArea === null || delimitedArea === undefined)
        return(false);

    if(delimitedArea["POINTS"].length < 3)
        return(false);

    return(true);
}