/**
 * LoginForm Component.
 * 
 * Basic login form for authentication
 * Includes: email login and google login/sign up
 */

// Libraries ----------------------------------------------------------------
import { useRef, useState } from 'react';
import type { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from "@unform/web";
import * as Yup from 'yup';
// Components & Assets ------------------------------------------------------
import { Input } from './Input';
import { Button } from './Button';
import { GoogleButton } from './GoogleButton';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';


type FormData = {
  email: string
  password: string
}

export function LoginForm() {
  const formRef = useRef<FormHandles>(null)
  const { _signInWithEmailAndPassword, handleGoogleAuth } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();

  const handleSubmit: SubmitHandler<FormData> = async (formData, { reset }) => {
    setIsLoading(true)
    if (isLoading) return
    try {
      const schema = Yup.object().shape({
        email: Yup.string().email("Please provide a valid email address").required(),
        password: Yup.string().min(6).required(),
      });
      await schema.validate(formData, {
        abortEarly: false,
      });
      // Validation passed
      console.log(formData);
      await _signInWithEmailAndPassword(formData.email, formData.password)
      reset();
      navigate("/dashboard")

    } catch (err) {
      const validationErrors = {};
      formRef.current && formRef.current.setData({
        email: formData.email,
        password: formData.password,
      });
      if (err instanceof Yup.ValidationError) {
        // Validation failed
        err.inner.forEach(error => {
          //@ts-ignore
          validationErrors[error.path] = error.message;
        });
        formRef.current && formRef.current.setErrors(validationErrors);
        console.log(err);
      }
    }
    setIsLoading(false)
  }

  const handleLoginWIthGoogle = async () => {
    await handleGoogleAuth();
  }

  const handleSignUp = () => {
    navigate("/signup");
  }


  return (
    <div
      className="max-w-lg min-h-[500px] w-full rounded-md bg-white/80  dark:bg-neutral-800/80 px-4 py-5 sm:p-8 dark:text-white"
      style={{ boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.25)" }}
    >
      <h1 className=" font-bold text-2xl" >Sign In</h1>

      <GoogleButton isOutlined disabled={isLoading} onClick={handleLoginWIthGoogle}>
        Continue in with Google
      </GoogleButton>
      <div className="mt-6 flex items-center w-full justify-center " >
        <div className="w-full mx-4 h-[1px] bg-neutral-100/50" />
        <span >or</span>
        <div className="w-full mx-4 h-[1px] bg-neutral-100/50" />

      </div>

      <Form ref={formRef} className="w-full mt-4" onSubmit={handleSubmit}>
        <div className="text-left " >
          <label htmlFor="email_input" className="block mb-2 text-sm font-medium ">Your email</label>
          <Input id="email_input" name="email" type="email" placeholder="example@email.domain" className="dark:bg-neutral-600 text-white outline-none" />

          <label htmlFor="pw_input" className="block mb-2 mt-6 text-sm font-medium ">Your password</label>
          <Input id="pw_input" name="password" type="password" placeholder="••••••••" className="dark:bg-neutral-600 text-white outline-none" />
        </div>

        <div className="flex items-center justify-end mt-2">
          <p onClick={() => navigate("/recover-password")} className="text-sm cursor-pointer font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</p>
        </div>

        <Button disabled={isLoading} className="mt-6 w-full flex items-center justify-center" type="submit">Login</Button>

      </Form>
      <div className="mt-2 text-right w-full" >
        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
          Don’t have an account yet? <span onClick={handleSignUp} className="cursor-pointer font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</span>
        </p>
      </div>

    </div>

  );
}
